import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TitleCasePipe } from '@angular/common';
import { Storage } from '@ionic/storage';
import { ToastController } from '@ionic/angular';
import { ViewEncapsulation} from "@angular/core";

import {
  ColumnMode, SortDirection, SortPropDir, SortType,
} from '@swimlane/ngx-datatable';
import { UserService } from '../../../../api/services/user.service';
import { UserResource } from '../../../../api/resources/user.resource';

import { Page } from '../../../../../models/page';
import {ValidationManager} from "ng2-validation-manager";
import {UserConnectionsObsvInterface} from "../../../dashboard/connect/user-connections-obsv.interface";
import {lastValueFrom, Observable, of} from "rxjs";
import {OrganizationResource} from "../../../../api/resources/organization.resource";
import {FamilyResource} from "../../../../api/resources/family.resource";
import {FriendsRequestsObsvInterface} from "../../../dashboard/connect/user-requests-obsv.interface";
import {map} from "rxjs/operators";
import {FriendService} from "../../../../api/services/friend/friend.service";

@Component({
  selector: 'user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class UserListComponent implements OnInit {
    private currentUser: any = {};

    causeGroupId: number;

    isLoadingUsers: boolean = false;

    users: UserResource[];

    columnMode: ColumnMode = ColumnMode.force;

    sortType: SortType = SortType.multi;

    private titleCasePipe: TitleCasePipe;

    page: Page;

    sorts: SortPropDir[];

    private userService: UserService;

    form: ValidationManager;
    public columnField: any;

    public userConnections$: UserConnectionsObsvInterface = {
        all: of<UserResource[]&OrganizationResource[]>([]),
        volunteer: of<UserResource[]>([]),
        friend: of<UserResource[]>([]),
        advocate: of<UserResource[]>([]),
        church: of<UserResource[]&OrganizationResource[]>([]),
        provider: of<OrganizationResource[]>([]),
        business: of<OrganizationResource[]>([])
    };
    public userFamilies$: Observable<FamilyResource[]>;
    public userFamilies: FamilyResource[];
    public friendsRequests$: FriendsRequestsObsvInterface = {
        'to-you': of<UserResource[]>([]),
        'to-others': of<UserResource[]>([])
    };
    friendService: FriendService;

    constructor(private httpClient: HttpClient,
                private route: ActivatedRoute,
                private router: Router,
                private storage: Storage,
                private toast: ToastController) {
      this.titleCasePipe = new TitleCasePipe();
      this.page = new Page();
      this.page.pageNumber = 0;
      this.page.size = 10;
      this.sorts = [
        { prop: 'quick_connect_date', dir: SortDirection.desc },{ prop: 'created_at', dir: SortDirection.desc },
      ];
        this.form = new ValidationManager({
            keyword: 'rangeLength:2,50',
        });
    }

    ngOnInit() {

      this.route.params.subscribe((params) => {
        this.causeGroupId = params.hasOwnProperty('id') ? parseInt(params.id) : null;
        this.columnField = params.hasOwnProperty('columnField') ? params.columnField : null;



        this.storage.get('loginRes').then((val) => {
          this.currentUser = val.user;

          const endpoint = this.causeGroupId ? `cause-group/${this.causeGroupId}/user` : UserService.resourceSlug;
          this.userService = new UserService(this.httpClient, endpoint);

          this.loadUsers();


        });
      });
    }

    private loadUsers(): void {
      this.isLoadingUsers = true;

      let params = new HttpParams()
        .set('page', (this.page.pageNumber + 1).toString())
        .set('per_page', this.page.size.toString());

      if (this.columnField) {
          params = params.append('columnSort', this.columnField);
      }

      params = this.addSortsParam(params);
        let formData = this.form.getData();
        const searchData = { ...formData };

        for (const key in searchData) {
            if (searchData[key] !== '' && searchData[key] !== null) params = params.set(key, (searchData[key].toString()));
        }

      this.userService
        .list(params)
        .toPromise()
        .then((value) => {
          this.users = value.data;

          console.log(this.users)

          this.page.totalElements = value.metadata.count;
          this.page.totalPages = value.metadata.last_page;
        })
        .catch((errorResponse) => {
          this.toast.create({
            header: 'Load error',
            message: errorResponse.error.message,
            buttons: [
              {
                text: 'Close',
                role: 'cancel',
                handler: () => {}
              }
            ],
            color: 'danger',
          }).then((toast) => toast.present());
        })
        .finally(() => { this.isLoadingUsers = false; });
    }

    checkRoles(roleArray)
    {
        let found:boolean = false;
        roleArray.forEach(function(roleObject) {
            if(roleObject.name == 'volunteer') found = true;
        });

        return found;

    }

    setPage(pageInfo): void {
      this.page.pageNumber = pageInfo.offset;
      this.loadUsers();
    }

    onSort(event): void {
      this.sorts = event.sorts;
      this.page.pageNumber = 0;
      this.loadUsers();
    }

    export()
    {
        let formData = this.form.getData();
        const searchData = { ...formData };

        let params = new HttpParams();
        params = this.addSortsParam(params);

        for (const key in searchData) {
            if (searchData[key] !== '' && searchData[key] !== null) params = params.set(key, (searchData[key].toString()));
        }

        const options = {
            params,
        };

        this.userService
            .export(this.causeGroupId, options)
            .toPromise()
            .then((data) => {
                window.open(data.url, '_blank');
            })
            .catch((errorResponse) => {
                this.toast.create({
                    header: 'Load error',
                    message: errorResponse.error.message,
                    buttons: [
              {
                text: 'Close',
                role: 'cancel',
                handler: () => {}
              }
            ],
                    color: 'danger',
                }).then((toast) => toast.present());
            })
            .finally(() => { });
    }

    async search()
    {
        this.loadUsers();
    }

    addSortsParam(params: HttpParams): HttpParams {
      this.sorts.forEach((sort, index) => {
        for (const key in sort) {
          params = params.set(`sorts[${index}][${key}]`, sort[key].toString());
        }
      });

      return params;
    }
    goToColumnReport(columnName) {
        if (columnName == 'quick_connect') {
            this.sorts = [
                { prop: 'quick_connect_date', dir: SortDirection.desc }
            ];
        }
        this.router.navigateByUrl(`/admin/cause-group/${this.causeGroupId}/users/${columnName}`);
    }

    protected readonly length = length;
}

/**
 * An object used to get page information from the server
 *
 * @see https://github.com/swimlane/ngx-datatable/blob/master/src/app/paging/model/page.ts
 */
export class Page {
    // The number of elements in the page
    size: number = 0;

    // The total number of elements
    totalElements: number = 0;

    // The total number of pages
    totalPages: number = 0;

    // The current page number
    pageNumber: number = 0;
}

import { Resource } from './resource';
import { NeedOccurrenceResource } from './need-occurrence.resource';
import { UserResource } from './user.resource';
import {OccurrenceRsvpResource} from "./occurrence-rsvp.resource";

export class BackgroundCheckResource extends UserResource {

    need_id: number = null;

}

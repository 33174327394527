<ion-header>
    <ion-toolbar>
        <ion-title class="pdl-16">Privacy Policy</ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="dismissModal()" color="dark">Close</ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">

<!--    <h3><strong>Privacy Policy</strong></h3>-->
    <p>Meet the Need Ministries (“Meet the Need”) is committed to respecting the privacy rights of users   of the Website (all capitalized terms not defined in this Notice have the same meanings as in the Meet the Need <a href="/terms">Terms of   Use</a>). Meet the Need created this Privacy Policy to give You confidence as You visit and use the Website, and to demonstrate its   commitment to fair information practices and to the protection of privacy.</p>
    <p>This Privacy Policy is only applicable to the   Website, and not to any Third-Party Websites, each of which may have data collection, storage and use practices and policies that differ   materially from this Privacy Policy. For additional information, see the section concerning Third-Party Websites below.</p>
    <p>BY   USING THE WEBSITE, YOU REPRESENT AND WARRANT THAT YOU HAVE READ, UNDERSTOOD AND AGREE TO THE TERMS OF THIS PRIVACY POLICY. IF YOU DO NOT   UNDERSTAND OR DO NOT AGREE TO BE BOUND BY THIS PRIVACY POLICY, YOU MUST IMMEDIATELY LEAVE THE WEBSITE.</p>
    <p>Each time You use the   Website, the then-current version of this Privacy Policy will apply. Accordingly, each time You use the Website You should check the   date of this Privacy Policy (which appears at the end) and review any changes since the last time You used the Website. For additional   information, see the section concerning Updates and Changes to Privacy Policy below.</p>
    <h3>1. INFORMATION COLLECTION   PRACTICES</h3>
    <h5>1.1 TYPES OF INFORMATION COLLECTED</h5>
    <p><span lang="en-US" xml:lang="en-US"><strong>(a) Traffic   Data.</strong></span> Like most website operators, Meet the Need automatically gathers information of the sort that browsers   automatically make available, including: (i) IP addresses; (ii) domain servers; (iii) types of computers accessing the Website; and (iv)   types of Web browsers accessing the Website (collectively “Traffic Data”). Traffic Data is anonymous information that does   not personally identify You.</p>
    <p><span lang="en-US" xml:lang="en-US"><strong>(b) Cookies.</strong></span> A “Cookie”   is a string of information that a website stores on a user’s computer, and that the user’s browser provides to the website   each time the user submits a query to the website. The purpose of a Cookie is to identify the user as a unique user of the Website. Meet   the Need uses Cookies to customize Your experience on the Website to Your interests, to ensure that You do not see the same   informational messages repeatedly, and to store Your password so You do not have to re-enter it each time You visit the Website. For   additional information on Meet the Need’s uses of Cookies, see the section concerning Information Use and Disclosure   Practices/Traffic Data and Information Gathered Using Cookies below.</p>
    <p><span lang="en-US" xml:lang="en-US"><strong>(c) Personal   Information.</strong></span> In order for You to use the Services, You must complete the registration form and provide to Meet the Need   certain information that personally identifies You (“Personal Information”). Personal Information includes the following   categories of information: (1) Contact Data (such as Your name, organization name, mailing address, and e-mail address); (2) Religious   Data (such as religious and church affiliation) and (3) Demographic Data (such as Your zip code). In each case, You will be asked to   provide Personal Information; the Website will not gather it surreptitiously.</p>
    <p><span lang="en-US" xml:lang="en-US"><strong>(d)   Information You Make Public</strong></span>. Meet the Need posts the needs of individual Recipients on its Website while Charities are   allowed to post their own needs. Information describing the needs and brief descriptions of the Recipients are contained in the postings   on the Website and are immediately available and accessible to Authorized Registered Users of the Website. Other Personal Information   associated with postings is kept confidential until the Recipient has been matched with an authorized Donor. At that time, limited   Contact Information is disclosed to that authorized Donor so that the Donor may contact the Recipient to arrange for delivery of an item   or performance of a service. The Website may contain features (e.g. forums and message boards) that permit You to upload, post,   transmit, display or distribute content or other information. Any information, including Personal Information, that You choose to   disclose by means of the need postings or other features becomes public information over which Meet the Need is unable to exercise   control. You should exercise caution when deciding to disclose information by means of the need postings or other features, and You   agree to assume all responsibility for doing so.</p>
    <h5>1.2 INFORMATION USE AND DISCLOSURE PRACTICES</h5>
    <p><span lang="en-US" xml:lang="en-US"><strong>(a) Traffic Data and Information Gathered Using Cookies.</strong></span> Meet the Need analyzes Traffic Data   and information gathered using Cookies to help Meet the Need better understand who is using the Website and how they are using it. By   identifying patterns and trends in usage, Meet the Need is able to better design the Website to improve Your experience, and to serve   You more relevant and interesting content. From time to time, Meet the Need may release Traffic Data and information gathered using   Cookies in the aggregate, such as by publishing a report on trends in the usage of the Website. Meet the Need does not attempt to link   information gathered using Cookies to Personal Information.</p>
    <p><strong>(b) Personal   Information</strong></p>
    <blockquote>
        <p>(i) <strong>Generally. </strong>Meet the Need uses Your Contact Data to send You   information about Meet the Need and Meet the Need’s products and services, and to contact You when necessary in connection with   the Services. Meet the Need uses your Religious Data to confirm Your religious and church affiliation. Meet the Need uses Your   Demographic Data to customize and tailor Your experience on the Website. As with Traffic Data and information gathered using Cookies,   from time to time Meet the Need may release Demographic Data in the aggregate, such as by publishing a report on trends in the usage of   the Website.</p>
        <p>(ii) <strong>Third Parties.</strong> Meet the Need considers Your Personal Information to be private and does not   disclose Personal Information to any third parties.</p>
        <p>(iii) <strong>Disclosure to Other Members. </strong>Meet the Need is a   nonprofit intermediary that facilitates the donation of services or items from Donors to Recipients. Until a Recipient’s need has   been matched with an authorized Donor, the Recipient’s identity and certain Personal Information will remain confidential. If you   are a Recipient whose need has been matched with an authorized Donor, Meet the Need discloses Your identity and phone number to the   Donor in order to administer the donation program. As stated in the Terms of Use, Meet the Need is not responsible and hereby disclaims   all responsibility and liability for disclosure, subsequent use and continued protection of Personal Information provided to, viewed, or   downloaded by authorized Donors.</p>
        <p>(iv) <strong>By Law or to Protect Rights</strong>. Meet the Need discloses Personal   Information when required to do so by law, or in response to a subpoena or court order, or when Meet the Need believes in its sole   discretion that disclosure is reasonably necessary to protect the property or rights of Meet the Need, third parties or the public at   large.</p>
        <p>(v) <strong>Business Transfers; Bankruptcy</strong> – Meet the Need reserves the right to transfer all Personal   Information in its possession to a successor organization in the event of a merger, acquisition, or bankruptcy or other sale of all or a   portion of Meet the Need’s assets. Other than to the extent ordered by a bankruptcy or other court, the use and disclosure of all   transferred Personal Information will be subject to this Privacy Policy, or to a new privacy policy if You are given notice of that new   privacy policy and an opportunity to affirmatively opt-out of it. Personal Information submitted or collected after a transfer, however,   may be subject to a new privacy policy adopted by Meet the Need’s successor organization.</p>
    </blockquote>
    <h3>2.   TERMINATING YOUR MEMBERSHIP</h3>
    <p>To terminate Your membership, contact <u><a href="mailto:admin@meettheneed.org">admin@meettheneed.org</a></u>. After termination of Your membership, Your Personal Information may   remain in our archived database and records. However, if You terminate Your membership, Meet the Need does not warranty that Your   Personal Information can be recovered.</p>
    <h3>3. THIRD-PARTY WEBSITES</h3>
    <p>Meet the Need neither owns nor controls Third-Party   Websites. Accordingly, Third-Party Websites are under no obligation to comply with this Privacy Policy. Before visiting or providing   Personal Information to a Third-Party Website or Third-Party Service Provider, You should inform Yourself of the privacy policies and   practices (if any) of that Third-Party Website or Third-Party Service Provider, and should take those steps necessary to, in Your   discretion, protect Your privacy.</p>
    <h3>4. SURVEYS</h3>
    <p>From time to time, Meet the Need may ask You to participate in   surveys designed to help Meet the Need improve the Websites or Services. Any Personal Information provided to Meet the Need in   connection with any survey will be used only in relation to that survey, and will be disclosed to third parties not bound by this   Privacy Policy only in aggregated form.</p>
    <h3>5. USER ABILITY TO ACCESS, UPDATE, AND CORRECT PERSONAL INFORMATION</h3>
    <p>Meet   the Need wants Your Personal Information to be complete and accurate. As stated in the <a href="/terms">Terms of Use</a>, by using the Website, You   represent and warrant that all information You provide on any registration form or otherwise in connection with Your use of the Website   and Services will be complete and accurate, and that You will update that information as necessary to maintain its completeness and   accuracy. To confirm the completeness and accuracy of, or make changes to, Your Personal Information, visit Your personal   profile.</p>
    <h3>6. SECURITY OF PERSONAL INFORMATION</h3>
    <p>Meet the Need has implemented and maintains reasonable security   procedures and practices to protect against the unauthorized access, use, modification, destruction or disclosure of Your Personal   Information.</p>
    <h3>7. LOST OR STOLEN INFORMATION</h3>
    <p>You must promptly notify us if Your user name or password is lost,   stolen or used without permission. In such and event, we will remove that user name or password from Your account and updated our   records accordingly.</p>
    <h3>8. UPDATES AND CHANGES TO PRIVACY POLICY</h3>
    <p>Although most changes are likely to be minor, Meet   the Need reserves the right, at any time and without notice, to add to, update, change or modify this Privacy Policy, simply by posting   such update, change or modification on this page. Any such addition, update, change or modification will be effective immediately upon   posting on the Website. Each time You use the Website, the then-current version of this Privacy Policy will apply. Accordingly, each   time You use the Website You should check the date of this Privacy Policy (which appears at the end) and review any changes since the   last time You used the Website. Unless Meet the Need obtains Your express consent, any revised Privacy Policy will apply only to   information collected after the effective date of such revised Privacy Policy, and not to information collected under any earlier   Privacy Policy.</p>
    <p>Date Posted: April 16, 2008</p>
</ion-content>

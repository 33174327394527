import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/providers/auth/auth-guard.service';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { CauseGroupListComponent } from './cause-group-list/cause-group-list.component';
import { CauseGroupDashboardComponent } from './cause-group-dashboard/cause-group-dashboard.component';
import { UserListComponent } from '../users/user-list/user-list.component';
import { CauseGroupUserPrivilegeComponent } from './cause-group-user-privilege/cause-group-user-privilege.component';
import { TagListComponent } from '../tags/tag-list/tag-list.component';
import { TagEditComponent } from '../tags/tag-edit/tag-edit.component';
import { BackgroundChecksPage } from "./background-checks/background-checks.page";
import { NeedsPage } from "./needs/needs.page";
import { CauseGroupEditComponent } from './cause-group-edit/cause-group-edit.component';
import { CauseGroupUpdateRulesComponent } from "./cause-group-update-rules/cause-group-update-rules.component";

const causeGroupsRoutes: Routes = [
  {
    path: 'admin/cause-groups',
    component: CauseGroupListComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: 'all-cause-groups_list',
      },
    },
  },
  {
    path: 'admin/user/:id/cause-groups',
    component: CauseGroupListComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['cause-groups_list', 'all-cause-groups_list'],
      },
    },
  },
  {
    path: 'admin/cause-group/:id/dashboard',
    component: CauseGroupDashboardComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['cause-groups_dashboard_view', 'all-cause-groups_dashboard_view'],
      },
    },
  },
  {
    path: 'admin/cause-group/:id/users',
    component: UserListComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['cause-groups_users_list', 'all-cause-groups_users_list'],
      },
    },
  },
  {
    path: 'admin/cause-group/:id/users/:columnField',
    component: UserListComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['cause-groups_users_list', 'all-cause-groups_users_list'],
      },
    },
  },
  {
    path: 'admin/background-checks',
    component: BackgroundChecksPage,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['cause-groups_users_list', 'all-cause-groups_users_list'],
      },
    },
  },
  {
    path: 'admin/cause-group/:causeGroupId/needs',
    component: NeedsPage,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['cause-groups_users_list', 'all-cause-groups_users_list'],
      },
    },
  },
  {
    path: 'admin/cause-group/:causeGroupId/user/:userId/privilege',
    component: CauseGroupUserPrivilegeComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['cause-groups_users_privileges_manage', 'all-cause-groups_users_privileges_manage'],
      },
    },
  },

  {
    path: 'admin/cause-group/:id/tags',
    component: TagListComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['tags_list', 'all-tags_list'],
      },
    },
  },
  {
    path: 'admin/cause-group/:causeGroupId/tag/add',
    component: TagEditComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['tags_add', 'all-tags_add'],
      },
    },
  },
  {
    path: 'admin/cause-group/:causeGroupId/tag/:id',
    component: TagEditComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['tags_edit', 'all-tags_edit'],
      },
    },
  },
  {
    path: 'admin/cause-group/:id/edit',
    component: CauseGroupEditComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['cause-groups_edit'],
      },
    },
  },
  {
    path: 'admin/cause-group/:id/update-rules/:date',
    component: CauseGroupUpdateRulesComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['cause-groups_edit']
      }
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(causeGroupsRoutes),
  ],
  exports: [
    RouterModule,
  ],
})
export class CauseGroupsRoutingModule { }

import { Resource } from './resource';
import { EntityResource } from './entity.resource';
import {OfferResource} from "./offer.resource";
import { CauseGroupResource } from './cause-group.resource';
import { UserResource } from './user.resource';

export class OrganizationResource extends Resource {
    id: number = null;

    user_id: number = null;

    name : string = null;

    street : string = null;

    bio : string = null;

    city : string = null;

    state : string = null;

    zip : number = null;

    email : string = null;

    phone : string = null;

    lat: number = null;

    lng: number = null;

    distance: number = null;
    website : string = null;

    facebook : string = null;

    cover_image: string = null;

    type : string = null;

    status : string = null;

    created_at: string = null;

    updated_at: string = null;

    deleted_at: string = null;

    entity_id: number = null;

    entity: EntityResource = null;

    offers :OfferResource[] = null;

    national: boolean = null;

    cause_group: CauseGroupResource = null;

    visible: boolean = false;

    recommendation: any = null;

    claimed_by_user_id: number = null;

    claim_code: string = null;

    administrators: UserResource[] = null;

    volunteers: UserResource[] = null;

    office_location: string = null;

    terms: boolean = false;

    show_complete_profile_msg: boolean = true;
    target: string[]|string = null;

    getCoverImage(): string {
      let cover_image_url;
      if (this.cover_image) {
        cover_image_url = this.cover_image;
      } else {
        switch (this.type) {
          case 'Church':
            cover_image_url = '../assets/img/org-church.png';
            break;
          case 'NonProfit':
            cover_image_url = '../assets/img/org-nonprofit.png';
            break;
          default:
            cover_image_url = '../assets/img/org-default.png';
        }
      }
      return cover_image_url;
    }
}

<ion-content class="ion-padding">
  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-label>
          <h1 *ngIf="userId; else labelBlock">User Documents</h1>
          <ng-template #labelBlock>
            <h1>User Documents</h1>
          </ng-template>
        </ion-label>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="isLoading; else userBlock" class="ion-justify-content-center">
      <ion-col size="auto">
        <ion-spinner></ion-spinner>
      </ion-col>
    </ion-row>

    <ng-template #userBlock>
      <ion-row *ngIf="dataList">
        <ion-row *ngFor="let family of dataList">
          <ion-card>
            <ion-card-header>
              <ion-card-title>{{ family.first_name }} {{ family.last_name}}</ion-card-title>
            </ion-card-header>

            <ion-card-content>
              <p>Death Certificate</p>
              <img *ngIf="family.death_certificate && checkIfIsImage(family.death_certificate)" [src]="family.death_certificate">
              <p *ngIf="!family.death_certificate">No death certificate.</p>
              <ion-button (click)="download(family.death_certificate)">Download</ion-button>
            </ion-card-content>
          </ion-card>
        </ion-row>

        <ion-col *ngIf="dataList.length == 0">
          <p>The user doesn't have any documents.</p>
        </ion-col>
      </ion-row>
    </ng-template>
  </ion-grid>
</ion-content>

import {NgModule} from "@angular/core";
import {DebounceClickDirective} from "./debounce-click.directive";
import {TrimInputDirective} from "./trim-input.directive";
import {IonicMaskDirective} from "./ionicmask.directive";
import {NgxMaskModule} from "ngx-mask";

@NgModule({
  imports: [
    NgxMaskModule.forRoot(),
  ],
  declarations: [
    DebounceClickDirective,
    TrimInputDirective,
    IonicMaskDirective,
  ],
  exports: [DebounceClickDirective, TrimInputDirective, IonicMaskDirective]
})
export class DirectivesModule { }

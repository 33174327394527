import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TitleCasePipe } from '@angular/common';
import { Storage } from '@ionic/storage';
import { ToastController } from '@ionic/angular';

import {
  ColumnMode, SortDirection, SortPropDir, SortType,
} from '@swimlane/ngx-datatable';
import { TagService } from '../../../../api/services/tag.service';
import { TagResource } from '../../../../api/resources/tag.resource';

import { Page } from '../../../../../models/page';
import {ValidationManager} from "ng2-validation-manager";


@Component({
  selector: 'tag-list',
  templateUrl: './tag-list.component.html',
  styleUrls: ['./tag-list.component.css'],
})
export class TagListComponent implements OnInit {
    private currentUser: any = {};

    public causeGroupId: number;

    public isLoadingTags: boolean = false;

    public tags: TagResource[];

    public columnMode: ColumnMode = ColumnMode.force;

    public sortType: SortType = SortType.multi;

    public titleCasePipe: TitleCasePipe;

    public page: Page;

    public sorts: SortPropDir[];

    private tagService: TagService;
    public form:ValidationManager;

    constructor(private httpClient: HttpClient,
                private route: ActivatedRoute,
                private router: Router,
                private storage: Storage,
                private toast: ToastController) {
      this.titleCasePipe = new TitleCasePipe();
      this.page = new Page();
      this.page.pageNumber = 0;
      this.page.size = 10;
      this.sorts = [
        { prop: 'name', dir: SortDirection.asc },
      ];

        this.form = new ValidationManager({
            start_date: 'date',
            end_date: 'date',
            keyword: 'rangeLength:2,50',

        });
    }

    ngOnInit() {
      this.route.params.subscribe((params) => {
        this.causeGroupId = params.hasOwnProperty('id') ? parseInt(params.id) : null;

        this.storage.get('loginRes').then((val) => {
          this.currentUser = val.user;

          const endpoint = this.causeGroupId ? `cause-group/${this.causeGroupId}/tag` : TagService.resourceSlug;
          this.tagService = new TagService(this.httpClient, endpoint);

          this.loadTags();
        });
      });
    }

    private loadTags(): void {
      this.isLoadingTags = true;

      let params = new HttpParams()
        .set('page', (this.page.pageNumber + 1).toString())
        .set('per_page', this.page.size.toString());
      this.sorts.forEach((sort, index) => {
        for (const key in sort) {
          params = params.set(`sorts[${index}][${key}]`, sort[key].toString());
        }
      });

        let formData = this.form.getData();
        const searchData = { ...formData };

        for (const key in searchData) {
            if (searchData[key] !== '' && searchData[key] !== null) params = params.set(key, (searchData[key].toString()));
        }

      this.tagService
        .list(params)
        .toPromise()
        .then((value) => {
          this.tags = value.data;

          this.page.totalElements = value.metadata.count;
          this.page.totalPages = value.metadata.last_page;
        })
        .catch((errorResponse) => {
          this.toast.create({
            header: 'Load error',
            message: errorResponse.error.message,
            buttons: [
              {
                text: 'Close',
                role: 'cancel',
                handler: () => {}
              }
            ],
            color: 'danger',
          }).then((toast) => toast.present());
        })
        .finally(() => { this.isLoadingTags = false; });
    }

    public setPage(pageInfo): void {
      this.page.pageNumber = pageInfo.offset;
      this.loadTags();
    }

    onSort(event): void {
      this.sorts = event.sorts;
      this.page.pageNumber = 0;
      this.loadTags();
    }

    public export():void
    {

        let formData = this.form.getData();
        const searchData = { ...formData };

        let params = new HttpParams();
        for (const key in searchData) {
            if (searchData[key] !== '' && searchData[key] !== null) params = params.set(key, encodeURIComponent(searchData[key].toString()));
        }

        const options = {
            params,
        };
        this.tagService
            .export(options)
            .toPromise()
            .then((data) => {
                window.open(data.url, '_blank');
            })
            .catch((errorResponse) => {
                this.toast.create({
                    header: 'Load error',
                    message: errorResponse.error.message,
                    buttons: [
              {
                text: 'Close',
                role: 'cancel',
                handler: () => {}
              }
            ],
                    color: 'danger',
                }).then((toast) => toast.present());
            })
            .finally(() => { });
    }

    async search()
    {
        this.loadTags();
    }
}

import { Resource } from './resource';
import { NeedResource } from './need.resource';
import {OccurrenceRsvpResource} from "./occurrence-rsvp.resource";

export class NeedOccurrenceResource extends Resource {
    id : number = null;

    need_id : number = null;

    start_date : string = null;

    end_date : string = null;

    status : string = null;

    need: NeedResource = null;

    feedback: string = null;

    cover_image: string = null;

    rsvp: Array<OccurrenceRsvpResource> = null;
}

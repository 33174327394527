import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserResource } from '../../resources/user.resource';
import { FriendshipResource } from '../../resources/friendship.resource';
import { ResourceService } from '../resource.service';
import { ResourceSerializer } from '../../serializers/resource-serializer';

@Injectable()
export class FriendRequestService extends ResourceService<UserResource> {
    static resourceSlug: string = 'friend-request';

    constructor(httpClient: HttpClient, endpoint: string = FriendRequestService.resourceSlug) {
      super(
        httpClient,
        endpoint,
        new ResourceSerializer(UserResource),
      );
    }

    accept(user: UserResource): Observable<UserResource> {
      return this.httpClient
        .post<UserResource>(`${this.url}${this.endpoint}/user/${user.id}/accept`, null)
        .pipe(map((data: any) => this.serializer.fromJson(typeof data.data === 'undefined' ? data : data.data) as UserResource));
    }

    deny(user: UserResource): Observable<UserResource> {
      return this.httpClient
        .post<UserResource>(`${this.url}${this.endpoint}/user/${user.id}/deny`, null)
        .pipe(map((data: any) => this.serializer.fromJson(typeof data.data === 'undefined' ? data : data.data) as UserResource));
    }
}

<ion-header>
  <ion-toolbar>
    <ion-title class="pdl-20">Commitment Options Information</ion-title>
      <ion-buttons slot="end">
          <ion-button (click)="dismissModal()" color="dark">Close</ion-button>
      </ion-buttons>
  </ion-toolbar>
</ion-header>


<ion-content class="ion-padding modal-content">
    <ion-card>
        <ion-button disabled="true" color="danger" size="default"
                    class="icon-text red">
            <ion-icon name="heart-outline"></ion-icon>
        </ion-button>
        <p class="mgt-10">
            Favorite a Provider.
        </p>
    </ion-card>
    <ion-card>
        <ion-button disabled="true" color="danger" size="default"
                    class="icon-text red">
            <ion-icon name="heart"></ion-icon>
        </ion-button>
        <p class="mgt-10">
            Un-Favorite a Provider.
        </p>
    </ion-card>
    <ion-card>
        <ion-button disabled="true" color="secondary" size="default"
                    class="icon-text">
            <ion-icon name="person-add-outline"></ion-icon>
        </ion-button>
        <p class="mgt-10">Become a volunteer of this provider.</p>
    </ion-card>
    <ion-card>
        <ion-button disabled="true" color="secondary" size="default"
                    class="icon-text">
            <ion-icon name="person-remove"></ion-icon>
        </ion-button>
        <p class="mgt-10">Stop being a volunteer of this provider.</p>
    </ion-card>
    <ion-card>
        <ion-button disabled="true" color="success" size="default" class="icon-text connect">
            <ion-icon name="log-in-outline"></ion-icon>
            <ion-label>Connect</ion-label>
        </ion-button>
        <p class="mgt-10">By connecting to a provider they will receive updates when you post needs as part of your
            Provider Support Circle. A confirmation email will be sent notifying them of your
            connection.</p>
    </ion-card>
    <ion-card>
        <ion-button disabled="true"  color="warning" size="default" class="icon-text leave">
            <ion-icon name="log-out"></ion-icon>
            <ion-label>Leave</ion-label>
        </ion-button>
        <p class="mgt-10">If you're already connect to the Provider, you can leave the connection by pressing this
            button.
        </p>
    </ion-card>
    <ion-card>
        <ion-fab class="icon share-button">
            <ion-fab-button disabled="true" class="share orange">
                <ion-icon name="share-social"></ion-icon>
            </ion-fab-button>
        </ion-fab>
        <p class="mgt-10">This button let's you share this Service Provider via Facebook, Twitter or Email.
        </p>
    </ion-card>
    <ion-card>
        <ion-fab class="icon share-button">
            <ion-button disabled="true" size="small" class="btn-small" color="primary">
                <ion-icon src="/assets/ion/_volunteer.svg"></ion-icon>&nbsp;Invite Volunteer
            </ion-button>
        </ion-fab>
        <p class="mgt-10">This button lets you invite a Volunteer for this Service Provider.
        </p>
    </ion-card>
</ion-content>

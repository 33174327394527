import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from "@ionic/angular";

@Component({
  selector: 'app-terms',
  templateUrl: './intent-statement.page.html',
  styleUrls: ['./intent-statement.page.scss'],
})
export class IntentStatementPage implements OnInit {
  @Input() slugData: any;

  constructor(private modal: ModalController) { }

  ngOnInit() {
  }

  dismissModal() {
    this.modal.dismiss();
  }

  showFaithModal() {
    this.modal.dismiss('faith');
  }
}

import { Resource } from './resource';
import { NeedOccurrenceResource } from './need-occurrence.resource';
import { UserResource } from './user.resource';

export class OccurrenceRsvpResource extends Resource {

    static readonly STATUS_APPROVED = 'approved';
    static readonly STATUS_PENDING = 'pending';
    static readonly STATUS_COMPLETED = 'completed';
    static readonly STATUS_REJECTED = 'rejected';

    id : number = null;

    occurrence_id: number = null;

    occurrence: NeedOccurrenceResource = null;

    user: UserResource = null;

    initiated_by: UserResource = null;

    status : string = null;

    created_at: string = null;

    updated_at: string = null;

    commit_option:string = null;

    commitment_amount:number = null;

    additional_items:number = null;

}

//use @Pipe function before each class
import { PipeTransform, Pipe } from "@angular/core";
import {RoleResource} from "../../api/resources/role.resource";
import {FriendshipResource} from "../../api/resources/friendship.resource";

@Pipe({
    name: 'friendshipSearch'
})
export class FriendshipSearchPipe implements PipeTransform {

    transform(friends: FriendshipResource[], term: string, userType: string): FriendshipResource[] {
        if (!term) return friends;
        const regex = new RegExp(term, 'gi');
        return friends.filter((friend: FriendshipResource) =>
            friend[userType].name.search(regex) > -1
        );
    }
}

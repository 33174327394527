import {UserResourceResource} from "../../api/resources/userresource.resource";
import {OrganizationResource} from "../../api/resources/organization.resource";

export default class FormDataHelper {

    static convertModelToFormData(model: any, form: FormData = null, namespace = ''): FormData {
        let formData = form || new FormData();

        for (let propertyName in model) {
            if (!model.hasOwnProperty(propertyName)) continue;
            if (!model[propertyName] && model[propertyName] !== '') continue;
            let formKey = namespace ? `${namespace}[${propertyName}]` : propertyName;
            if (model[propertyName] instanceof Date)
                formData.append(formKey, model[propertyName].toISOString());
            else if (model[propertyName] instanceof Array) {
                model[propertyName].forEach((element, index) => {
                    const tempFormKey = `${formKey}[${index}]`;
                    this.convertModelToFormData(element, formData, tempFormKey);
                });
            }
            else if (typeof model[propertyName] === 'object' && !(model[propertyName] instanceof File))
                this.convertModelToFormData(model[propertyName], formData, formKey);
            else if (model[propertyName] instanceof File)
                formData.append(formKey, model[propertyName]);
            else
                formData.append(formKey, model[propertyName].toString());
        }
        return formData;
    }

  static handleTargetAudience(value: UserResourceResource|OrganizationResource): Array<string> {
    if (value.target && !Array.isArray(value.target)) {
      if (value.target.includes(',')) {
        return value.target.split(',');
      } else {
        return [value.target];
      }
    }
  }
}

//use @Pipe function before each class
import { PipeTransform, Pipe } from "@angular/core";
import { NeedResource } from "../../api/resources/need.resource";
import { RsvpCalculatorService } from "../services/rsvp-calculator.service";

@Pipe({
    name: 'openNeed'
})
export class OpenNeedPipe implements PipeTransform {
    constructor(private rsvpCalculatorService: RsvpCalculatorService) {}

    transform(needs: NeedResource[]): NeedResource[] {
        return needs.filter((need: NeedResource) => {
            if (need.type === NeedResource.TYPE_VOLUNTEER) {
                return this.rsvpCalculatorService.calculateRemainingVolunteers(need, 'all') > 0;
            }
            return this.rsvpCalculatorService.calculateRemaining(need, 'all') > 0;
        });
    }
}

<ion-header>
  <ion-toolbar>
    <ion-title class="pdl-16">Terms</ion-title>
      <ion-buttons slot="end">
          <ion-button (click)="dismissModal()" color="dark">Close</ion-button>
      </ion-buttons>
  </ion-toolbar>
</ion-header>


<ion-content  class="ion-padding">


  <h2>1. AGREEMENT</h2>
  These Terms of Use (the "Agreement") constitute a legally binding agreement by and between Meet the Need Ministries, Inc. ("Meet the Need") and You and/or Your Church or Charitable Organization concerning Your use of Meet the Need website (the "Website") and the services available through the Website (the "Services"). By completing Your registration and clicking on the "I Accept" button below, You will become a Registered User of the Service and You agree to be bound by the terms and conditions of this Agreement for as long as You continue to be a Registered User. You also must agree that you have read, understood and agree to Meet the Need's Privacy Policy (the "Privacy Policy").<br/>

  As used in the Terms of Use:<br/>

  ·         "Charity" means a charitable entity, church, ministry, or missionary organization that holds beliefs consistent with Meet the Need's Statement of Faith and registers with and is approved by Meet the Need as a charitable organization permitted to post its needs to the Website and whose members may register as Donors with full rights and privileges in the Meet the Need system.<br/>

  ·         "Donor" means any person who offers to provide goods, services or money to Recipients on the Website.<br/>

  ·         "Recipient" means any individual who contacts Meet the Need, requests aid and agrees to the Meet the Need's conditions for having their needs posted on Meet the Need. "Recipient" also means a Charity that posts its own needs, needs of local families or needs of its missionaries on the Website.<br/>

  <h2>2. PRIVACY POLICY</h2>
  By using the Website, You consent to the collection and use of certain information about You for the express purpose of administering the Meet the Need Services, as specified in the Privacy Policy incorporated herein by reference. Meet the Need encourages users of the Website to frequently check Meet the Need's Privacy Policy for changes.<br/>

  <h2>3. CHANGES TO AGREEMENT AND PRIVACY POLICY</h2>
  Internet and wireless technology and the applicable laws, rules, and regulations change frequently. ACCORDINGLY, MEET THE NEED RESERVES THE RIGHT TO CHANGE THIS AGREEMENT AND ITS PRIVACY POLICY AT ANY TIME UPON NOTICE TO YOU, TO BE GIVEN BY THE POSTING OF A NEW VERSION OR A CHANGE NOTICE ON THE WEBSITE. IT IS YOUR RESPONSIBILITY TO REVIEW THIS AGREEMENT AND THE PRIVACY POLICY PERIODICALLY. IF AT ANY TIME YOU FIND EITHER UNACCEPTABLE, YOU MUST IMMEDIATELY LEAVE THE WEBSITE AND CEASE USING THE SERVICES. Any use of the Service after such notice will constitute acceptance by You of such changes. Unless Meet the Need obtains Your express consent, any revised Privacy Policy will apply only to information collected by Meet the Need after such time as the revised Privacy Policy takes effect, and not to information collected under any earlier Privacy Policies.<br/>

  <h2>4. INDEMNITY BY YOU</h2>
  YOU AGREE TO DEFEND, INDEMNIFY AND HOLD HARMLESS MEET THE NEED AND ITS OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, AFFILIATES, REPRESENTATIVES, SUBLICENSEES, SUCCESSORS, ASSIGNS, AND CONTRACTORS (COLLECTIVELY, THE "MEET THE NEED PARTIES") AS WELL AS THE CHARITIES AND CHURCHES USING MEET THE NEED AND THEIR OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, AFFILIATES, REPRESENTATIVES, SUBLICENSEES, SUCCESSORS, ASSIGNS, AND CONTRACTORS (COLLECTIVELY, THE "CHARITY AND CHURCH PARTIES") FROM AND AGAINST ANY AND ALL CLAIMS, ACTIONS, DEMANDS, CAUSES OF ACTION AND OTHER PROCEEDINGS (COLLECTIVELY, "CLAIMS"), INCLUDING BUT NOT LIMITED TO LEGAL COSTS AND FEES, ARISING OUT OF OR RELATING TO: (I) YOUR BREACH OF THIS AGREEMENT, INCLUDING WITHOUT LIMITATION ANY REPRESENTATION OR WARRANTY CONTAINED IN THIS AGREEMENT; OR (II) YOUR ACCESS TO OR USE OF THE WEBSITE OR SERVICES; OR (III) YOUR PROVISION TO MEET THE NEED, CHARITIES AND CHURCHES USING MEET THE NEED, OR ANY OF THE MEET THE NEED PARTIES OR CHARITY AND CHURCH PARTIES OF INFORMATION OR OTHER DATA.; OR (IV) ANY ERRORS, OMISSIONS OR DEFECTS IN THE CONTENT; OR (V) ANY INTERRUPTIONS, DELAYS IN TRANSMISSION OR COMPUTER VIRUSES; OR (VI) ANY DISPUTES OR INCIDENTS BETWEEN USERS OF THE WEBSITE OR SERVICES; OR (VII) ANY ALLEGATIONS OF FAILURE TO AUTHENTICATE OR INSPECT; OR (VIII) ANY PRODUCTS, EQUIPMENT OR OTHER ITEMS, DONATED OR OTHERWISE, RECEIVED BY YOU UNDER OUR PROGRAM; OR (IX) THE TRANSPORTATION OF INDIVIDUALS AND/OR DELIVERY OR DONATION OF ANY ITEMS AS A RESULT OF USING THE SERVICE; OR (X) ANY INJURY RESULTING FROM THE INSTALLATION OF DONATED ITEMS OR THE PERFORMANCE OF ANY SERVICES.<br/>

  The Meet the Need parties and the Charity and Church Parties will have the right, but not the obligation, to participate through counsel of their choice in any defense by You of any Claim as to which You are required to defend, indemnify or hold harmless the Meet the Need Parties. You may not settle any Claim without the prior written consent of the concerned Meet the Need Parties and the Charity and Church Parties.<br/>

  <h2>5. ELIGIBILITY</h2>
  Any individuals are permitted to use the service provided they register and are at least 18 years old. By registering, You represent and warrant that You are at least 18 years old, that You will use the Service in accordance with this Agreement, and that Your account is for Your sole, personal use and You will not authorize others to use Your account.<br/>

  Any individual using the Website or Services on behalf of a Charity or Company represents and warrants that they are authorized to act and enter into agreements on behalf of that Charity or Company.<br/>

  Meet The Need reserves the right to refuse the use of the Service to any Donor, Recipient, Church, Charity, or Company at any time at its discretion.<br/>

  <h2>6. NO DUTY TO AUTHENTICATE OR INSPECT</h2>
  Meet the Need and the Charities using Meet the Need do not assume responsibility for verifying the accuracy of representations made by Donors, Charities or Recipients. We expressly disclaim any legal duty to authenticate or evaluate the need or legitimacy of any Donor, Charity, Recipient or visitor who may make use of the Website, Services or make a resulting donation. You agree not provide inaccurate, misleading or false information to Meet the Need or to any other user. In addition, if information You provide to Meet the Need, or another user, subsequently becomes inaccurate, misleading or false, You will promptly notify Meet the Need of such change. MEET THE NEED AND THE CHARITIES USING MEET THE NEED DO NOT: (i) GUARANTEE THE ACCURACY, COMPLETENESS, USEFULNESS OR APPROPRIATENESS OF ANY INFORMATION ON THE SERVICE, AND (ii) ADOPT, ENDORSE NOR ACCEPT RESPONSIBILITY FOR THE ACCURACY OR RELIABILITY OF ANY OPINION, ADVICE, OR STATEMENT MADE BY ANY OTHER PARTY. UNDER NO CIRCUMSTANCES WILL MEET THE NEED BE RESPONSIBLE FOR ANY LOSS OR DAMAGE RESULTING FROM ANYONE'S RELIANCE ON INFORMATION OR OTHER CONTENT POSTED ON THE SERVICE, OR TRANSMITTED TO OR BY ANY USERS.<br/>

  Likewise, to the extent that at service or donation is given in-kind, Meet the Need and the Charities using Meet the Need disclaim any legal duty to inspect the item (or service reputation of the Donor if the donation is a service) and therefore makes no warranty as to the condition and safety of the item or competence of the service performed. Moreover, Meet the Need and the Charities using Meet the Need expressly denies all responsibility associated with the sorting, packing, distributing, transporting or delivery of any item facilitated though the Service.<br/>

  <h2>7. QUASI-CONFIDENTIAL NEED POSTINGS</h2>
  Meet the Need is a nonprofit intermediary that facilitates the donation of services or items from Donors to Recipients. With the exception of the information provided to other users pursuant to the Terms of Use and as provided in Meet the Need's Privacy Policy, Meet the Need will attempt to keep confidential the information supplied to us by You and shall use or disclose such information only for the purposes for which such information was collected, unless required by court order or applicable law. If You are a Recipient, You hereby acknowledge and agree that Your identity will remain anonymous and Your Personal Information will remain confidential only until Your need has been matched with an authorized Donor. Once You have been paired with an authorized Donor, Your telephone number will be disclosed to that authorized Donor so that the Donor can contact You directly to arrange to deliver the donated service or item. Your Personal Information will be used only to administer the Meet the Need's donation program and will not be intentionally disclosed or shared with any third-party other than the authorized Donor who has selected to meet Your need. Meet the Need and the Charities using Meet the Need disclaim all responsibility and liability for the disclosure of Your telephone number or other Personal Information, as further detailed in the Disclaimer described in Section 18(a).<br/>

  <h2>8. LICENSE</h2>
  Subject to Your compliance with the terms and conditions of this Agreement, Meet the Need grants You a non-exclusive, non-sublicensable, revocable as stated in this Agreement, non-transferable license to use the Website, Software and Services. Meet the Need is and remains the owner of all titles, rights, and interests in the Website and Software. Except as expressly set forth herein, this Agreement grants You no rights in or to the intellectual property of Meet the Need or any other party. The license granted in this section is conditioned on Your compliance with the terms and conditions of this Agreement. In the event that You breach any provision of this Agreement, Your rights under this section will immediately terminate.<br/>

  <h2>9. DONATIONS </h2>
  As a 501(c)3 non-profit Public Charity, Meet the Need asks Churches and Charities who use the system to make donations to help cover expenses associated with making the Meet the Need program available to their organization and to help fund expansion.<br/>

  MEET THE NEED MINISTRIES, INC. IS A 501(C)(3) TAX EXEMPT ORGANIZATION. A COPY OF THE OFFICIAL REGISTRATION (#CH-29406) AND FINANCIAL INFORMATION MAY BE OBTAINED FROM THE FLORIDA DIVISION OF CONSUMER SERVICES BY CALLING TOLL-FREE WITHIN THE STATE 1-800-435-7352. REGISTRATION DOES NOT IMPLY ENDORSEMENT, APPROVAL OR RECOMMENDATION BY THE STATE.<br />

  <h2>10. USER INFORMATION/PASSWORD PROTECTION</h2>
  In connection with Your use of certain Services, You may be required to complete a registration form. You represent and warrant that all information You provide on any registration form or otherwise in connection with Your use of the Website and Services will be complete and accurate. You agree not provide misleading or false information to Meet the Need, to any Charities using Meet the Need, or to any other User. In addition, if information You provide to Meet the Need, to any Charities using Meet the Need, or to another User, subsequently becomes incomplete or inaccurate, You will promptly notify Meet the Need, that Charity or other User of such change and update that information as necessary to maintain its completeness and accuracy. For additional information, see the section concerning User Ability to Access, Update, And Correct Personal Information in the Meet the Need's Privacy Policy.<br/>

  You may also be asked to provide, or may be given, a user name and password in connection with certain of the Services. You are entirely responsible for maintaining the confidentiality of Your password. You agree to notify Meet the Need immediately on any unauthorized use of Your account, user name, or password. Meet the Need and any Charity using Meet the Need shall not be liable for any loss that You incur as a result of someone else using Your password, either with or without Your knowledge. You may be held liable for any losses incurred by Meet the Need, its affiliates, officers, directors, employees, consultants, agents, representatives or users due to someone else's use of Your account or password. You may not use the account, user name, or password of someone else at any time.<br/>

  It is possible that users will obtain personal information about You (due to Your use of the Service) and may use such information to harass or injure You. Please carefully select the type of information that You post on the Service or release to others. Meet the Need and the Charities using Meet the Need are not responsible for the use BY OTHERS of any personal information that you disclose on the Service.<br/>

  <h2>11. THIRD-PARTY WEBSITES</h2>
  The Website may be linked with the websites of a number of third parties ("Third-Party Websites"). Meet the Need and the Charities using Meet the Need do not have control over the content and performance of Third-Party Websites. Meet the Need and the Charities using Meet the Need have not reviewed, and cannot review or control, all of the material, including computer software or other goods or services, made available on or through third-party websites. Accordingly, Meet the Need and the Charities using Meet the Need do not represent, warrant or endorse any third-party website, or the accuracy, currency, content, fitness, lawfulness or quality of the information material, goods or services available through third-party websites. Meet the Need and the Charities using Meet the Need disclaim, and You agree to assume, all responsibility and liability for any damages or other harm, whether to You or to third parties, resulting from Your use of third-party websites.<br/>

  You agree that, when linking to or otherwise accessing or using a third-party website, You are responsible for: (i) taking precautions as necessary to protect You and Your computer systems from viruses, worms, Trojan horses, malicious code and other harmful or destructive content; (ii) any downloading, use or purchase of material that is obscene, indecent, offensive, or otherwise objectionable or unlawful, or that contains technical inaccuracies, typographical mistakes and other errors; (iii) any downloading, use or purchase of material that violates the privacy or publicity rights, or infringes the intellectual property and other proprietary rights of third parties, or that is subject to additional terms and conditions, stated or unstated; (iv) all financial charges or other liabilities to third parties resulting from transactions or other activities; and (v) reading and understanding any terms of use or privacy policies that apply to those third-party websites.<br/>

  If You operate a website and wish to link to our Website, You must link to the Website's home page unless permission otherwise has been granted in writing by Meet the Need. Meet the Need reserves the right to reject or terminate any links to the Website.<br/>

  <h2>12. CONSENT TO RECEIVE EMAIL</h2>
  When You provide Meet the Need Your Personal Information when You register or request specific services, You consent to receive periodic email communications from Meet the Need regarding account status, changes in the Terms of Use or Privacy Policy, the status of Your registration with the Website, the status of Your donation or request, and other communications regarding Meet the Need or Your donation or request.<br/>

  <h2>13. USE OF SOFTWARE</h2>
  Meet the Need may make certain software available to You from the Website. If You download software from the Website, the software, including all files and images contained in or generated by the software, and accompanying data (collectively, "Software") are deemed to be licensed to You by Meet the Need for Your personal, noncommercial use only. Meet the Need does not transfer either the title or the intellectual property rights to the Software, and Meet the Need and Meet the Need's licensors retain full and complete title to the Software as well as all intellectual property rights therein. Except as specifically provided in this Agreement, You may not sell, redistribute, or reproduce the Software, nor may You decompile, reverse-engineer, disassemble or otherwise convert the Software to a human-perceivable form.<br/>

  <h2>14. NON-CONFIDENTIAL USER CONTENT</h2>
  By posting, downloading, displaying, performing, transmitting, or otherwise distributing information or other content ("Non-Confidential User Content") to the Website or Service, You are granting Meet the Need, its affiliates, officers, directors, employees, consultants, agents, and representatives a license to use Non-ConfidentialUser Content in connection with the conduct of the Services of Meet the Need. You also grant Meet the Need, its affiliates, officers, directors, employees, consultants, agents, and representatives, including without limitation, a right to copy, distribute, transmit, publicly display, publicly perform, reproduce, edit, translate, and reformat Non-ConfidentialUser Content. You will not be compensated for any Non-ConfidentialUser Content. You agree that Meet the Need may publish or otherwise disclose Your name in connection with Your Non-ConfidentialUser Content. By posting Non-ConfidentialUser Content on the Website or Service, You warrant and represent that You own the rights to the Non-Confidential User Content or are otherwise authorized to post, distribute, display, perform, transmit, or otherwise distribute Non-ConfidentialUser Content.<br/>

  <h2>15. OBJECTIONABLE CONTENT</h2>
  You represent and warrant that You shall not use the Website or Services to upload, post, transmit, display, perform, distribute or otherwise communicate any content, information or materials that: (a) are libelous, defamatory, abusive, or threatening, excessively violent, harassing, obscene, lewd, lascivious, profane, filthy, offensive, racially offensive, sexually oriented, pornographic, may constitute child pornography, may solicit personal information from or exploit in a sexual or violent manner anyone under the age of 18, or are otherwise objectionable; (b) advocate or encourage conduct that could constitute a criminal offense, give rise to civil liability, or otherwise violate any applicable local, state, national, or foreign law or regulation; (c) advertise or otherwise solicit funds or constitute a solicitation for goods or services; or (d) violate any provision of this Agreement or any other Meet the Need agreement or policy (collectively, "Objectionable Content"). MEET THE NEED AND THE CHARITIES USING MEET THE NEED DISCLAIM ANY PERCEIVED, IMPLIED OR ACTUAL DUTY TO MONITOR THE CONTENTS OF THE WEBSITE AND SPECIFICALLY DISCLAIMS ANY RESPONSIBILITY OR LIABILITY FOR INFORMATION PROVIDED HEREON, AS WELL AS SERVICES OR ORGANIZATIONS ADVERTISED HERETHROUGH. Without limiting any of its other remedies, Meet the Need reserves the right to terminate Your use of the Website and Services or Your uploading, posting, transmission, display, performance or distribution of Objectionable Content. Meet the Need, in its sole discretion, may delete any Objectionable Content from its servers. Meet the Need intends to cooperate fully with any law enforcement officials or agencies in the investigation of any violation of this Agreement or of any applicable laws.<br/>

  <h2>16. PROHIBITED USES</h2>
  Meet the Need imposes certain restrictions on Your use of the Website and the Services. You represent and warrant that You will not: (a) violate or attempt to violate any security features of the Website or Services; (b) access content or data not intended for You, or log onto a server or account that You are not authorized to access; (c) attempt to probe, scan, or test the vulnerability of the Services, the Website, or any associated system or network, or breach security or authentication measures without proper authorization; (d) interfere or attempt to interfere with the use of the Website or Services by any other user, host or network, including, without limitation by means of submitting a virus, overloading, "flooding," "spamming," "mail bombing," or "crashing"; (e) use the Website or Services to send unsolicited e-mail, including without limitation promotions or advertisements for products or services; (f) forge any TCP/IP packet header or any part of the header information in any e-mail or in any uploading or posting to, or transmission, display, performance or distribution by means of, the Website or Services; or (g) attempt to modify, reverse-engineer, decompile, disassemble or otherwise reduce or attempt to reduce to a human-perceivable form any of the source code used by the Meet the Need Parties in providing the Website or Services. Any violation of this section may subject You to civil and/or criminal liability.<br/>

  <h2>17. INTELLECTUAL PROPERTY</h2>

  <br/>(a) Compliance with Law<br/>
  You represent and warrant that, when using the Website and Services, You will obey the law and respect the intellectual property rights of Meet the Need and others. Your use of the Website and Services is at all times governed by and subject to laws regarding copyright ownership and use of intellectual property generally. You agree not to upload, post, transmit, display, perform or distribute any content, information or other materials in violation of Meet the Need's or any third party's copyrights, trademarks, or other intellectual property or proprietary rights. YOU SHALL BE SOLELY RESPONSIBLE FOR ANY VIOLATIONS OF ANY LAWS AND FOR ANY INFRINGEMENTS OF MEET THE NEED'S OR THIRD-PARTY RIGHTS CAUSED BY YOUR USE OF THE WEBSITE AND SERVICES. THE BURDEN OF PROVING THAT CONTENT, INFORMATION OR OTHER MATERIALS DO NOT VIOLATE ANY LAWS OR THIRD-PARTY RIGHTS RESTS SOLELY WITH YOU.<br/>

  (b) Trademarks<br/>
  "Meet the Need", the Meet the Need logo, "Powered by Meet the Need" and "G.I.F.T.S." (the "Meet the Need Marks") either are or are intended to become trademarks or registered trademarks of Meet the Need. Other trademarks, service marks, graphics, logos and domain names appearing on the Website may be the trademarks of third parties. Neither Your use of the Website and Services nor this Agreement grant You any right, title or interest in or to, or any license to reproduce or otherwise use, the Meet the Need Marks or any third-party trademarks, service marks, graphics, logos or domain names. You agree that any goodwill in the Meet the Need Marks generated as a result of Your use of the Website and Services will inure to the benefit of Meet the Need, and You agree to assign, and hereby do assign, all such goodwill to Meet the Need. You shall not at any time, nor shall You assist others to, challenge Meet the Need's right, title, or interest in or to, or the validity of, the Meet the Need Marks.<br/>

  (c) Copyrighted Materials; Copyright Notice<br/>
  All content and other materials available through the Website and Services, including without limitation the Meet the Need logo, design, text, graphics, source code, and other files, (collectively, the "Meet the Need Content") and the selection, arrangement and organization thereof, are either owned by Meet the Need or Meet the Need's other licensors and suppliers. Except as explicitly provided, neither Your use of the Website and Services nor this Agreement grant You any right, title or interest in or to the Meet the Need Content . The Meet the Need Content is intended for charitable use only. While You may interact or download a single copy of any portion of the Meet the Need Content for Your personal, noncommercial entertainment, information or use, You may not reproduce, sell, publish, distribute, modify, display, repost or otherwise use any portion of the Meet the Need Content in any way or for any other purpose without the written consent of Meet the Need. Requests regarding use of the Meet the Need Content for any purpose other than personal, noncommercial use should be directed to admin@meettheneed.org. Copyright Â© 2009 to the present, Meet the Need, Inc. ALL RIGHTS RESERVED.<br/>

  (d) DMCA Policy<br/>
  As Meet the Need asks others to respect Meet the Need's intellectual property rights, Meet the Need respects the intellectual property rights of others. If You believe content located on or linked to by the Website violates Your copyright, You are encouraged to notify Meet the Need in accordance with Meet the Need's Digital Millennium Copyright Act Policy. Meet the Need will respond to all such notices, including as required or appropriate by removing the offending material or disabling all links to the offending material. Meet the Need has adopted a policy that provides for the immediate suspension and/or termination of any user who is found to have infringed on the rights of Meet the Need or of a third party, or otherwise violated any intellectual property laws or regulations.<br/>

  <h2>18. DISCLAIMERS; LIMITATION OF LIABILITY</h2>

  (a) Disclaimer Involving Privacy<br/>
  PERSONAL INFORMATION CONTAINED IN POSTINGS ON THE WEBSITE FOR ITEMS NEEDED IS SUPPLIED BY THE INDIVIDUALS REQUESTING THOSE ITEMS, AND MEET THE NEED AND CHARITIES USING MEET THE NEED DO NOT ASSESS OR WARRANT THE ACCURACY OF THE COMPLETENESS OF THAT INFORMATION. YOU ACKNOWLEDGE THAT RELIANCE UPON ANY INFORMATION ON THE WEBSITE SHALL BE AT YOUR SOLE RISK. YOU AGREE TO RELEASE, HOLD HARMLESS AND INDEMNIFY MEET THE NEED AND THE CHARITIES USING MEET THE NEED OF ANY AND ALL LIABILITY ARISING FROM INCORRECT OR INCOMPLETE INFORMATION REGARDING ANY ASPECT OF THE WEBSITE OR SERVICE. CERTAIN PERSONAL INFORMATION CONTAINED IN POSTINGS ON THE WEBSITE FOR ITEMS NEEDED WILL BE DISCLOSED TO DONORS WHO ELECT TO MEET THOSE NEEDS. MEET THE NEED AND THE CHARITIES USING MEET THE NEED ARE NOT RESPONSIBLE AND HEREBY DISCLAIM ALL RESPONSIBILITY AND LIABILITY FOR DISCLOSURE, SUBSEQUENT USE AND CONTINUED PROTECTION OF PERSONAL INFORMATION PROVIDED TO, VIEWED, OR DOWNLOADED BY AUTHORIZED DONORS. Meet the Need and the Charities using Meet the Need ARE NOT responsible for the use BY OTHERS of any personal information that you disclose on the Service.<br/>

  (b) Disclaimer Involving Contact with Others through Meet the Need<br/>
  YOU UNDERSTAND THAT BY EITHER DONATING OR RECEIVING DONATIONS THROUGH MEET THE NEED, YOU MAY ENGAGE IN ACTIVITIES, INVOLVING DONATING ITEMS OR PERFORMING SERVICES THAT INVOLVE THE RISK OF SERIOUS INJURY, INCLUDING PERMANENT DISABILITY AND DEATH, SEVERE SOCIAL AND ECONOMIC LOSSES AND OTHER LOSS INCLUDING, WITHOUT LIMITATION DAMAGE TO PROPERTY AND INVASION OF PRIVACY. You are solely responsible for Your interactions with other users. YOU AGREE TO TAKE NORMAL PRECAUTIONS WHEN MEETING OTHERS THROUGH MEET THE NEED. You agree to review and follow the recommendations set forth in Meet the Need's Safety Tips. MEET THE NEED AND THE CHARITIES USING MEET THE NEED MAKE NO REPRESENTATIONS OR WARRANTIES AS TO THE CONDUCT OF ANY PEOPLE YOU MEET OR WHO CONTACT YOU THROUGH THIS SERVICE, AS WELL AS ANY ISSUES THAT ARISE RELATED TO THE TRANSPORTATION OF ITEMS OR INTERACTIONS WITH RECIPIENTS. YOU KNOWINGLY AND FREELY ASSUME ALL RISKS AND DANGERS AND ALL RESPONSIBILITY FOR ANY LOSSES AND/OR DAMAGES, WHETHER CAUSED IN WHOLE OR IN PART BY THE NEGLIGENCE OR OTHER CONDUCT OF USERS OR THE OWNERS, AGENTS, OFFICERS, DIRECTORS, PARTNERS, SUBSIDIARIES OR EMPLOYEES OF MEET THE NEED, OR OF ANY CHARITIES USING MEET THE NEED, OR BY ANY OTHER PERSON. YOU, ON BEHALF OF YOURSELF, YOUR PERSONAL REPRESENTATIVES AND YOUR HEIRS HEREBY VOLUNTARILY AGREE TO RELEASE, WAIVE, DISCHARGE, HOLD HARMLESS, DEFEND AND INDEMNIFY MEET THE NEED AND OF ANY CHARITIES USING MEET THE NEED, AND THEIR RESPECTIVE OWNERS, AGENTS, OFFICERS, DIRECTORS AND EMPLOYEES FROM ANY AND ALL CLAIMS, ACTIONS OR LOSSES FOR BODILY INJURY, PROPERTY DAMAGE, EMOTIONAL DISTRESS, WRONGFUL DEATH, LOSS OF SERVICES OR OTHERWISE, WHETHER CAUSED BY NEGLIGENCE OR OTHERWISE, WHICH YOU NOW HAVE OR WHICH MAY ARISE IN THE FUTURE IN CONNECTION WITH YOUR PARTICIPATION IN THE MEET THE NEED DONATION PROGRAM, EVENTS OR ACTIVITIES EVEN IF MEET THE NEED OR THE CHARITIES USING MEET THE NEED KNOWS OR HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH ISSUES.<br/>

  (c) DISCLAIMER OF WARRANTIES<br/>
  MEET THE NEED AND THE CHARITIES USING MEET THE NEED, ON BEHALF OF ITSELF AND ITS THIRD-PARTY SERVICE PROVIDERS, LICENSORS AND SUPPLIERS, HEREBY DISCLAIMS ALL WARRANTIES. THE WEBSITE AND SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE." TO THE MAXIMUM EXTENT PERMITTED BY LAW, MEET THE NEED AND THE CHARITIES USING MEET THE NEED, ON BEHALF OF THEMSELVES AND THEIR THIRD-PARTY SERVICE PROVIDERS, LICENSORS AND SUPPLIERS, EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES, EXPRESS OR IMPLIED, REGARDING THE WEBSITE AND SERVICES, INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. NEITHER MEET THE NEED, NOR THE CHARITIES USING MEET THE NEED, NOR ITS THIRD-PARTY SERVICE PROVIDERS, LICENSORS OR SUPPLIERS WARRANT THAT THE WEBSITE OR THE SERVICES WILL MEET YOUR REQUIREMENTS OR THAT THE OPERATION OF THE WEBSITE OR THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE. IN NO EVENT SHALL MEET THE NEED, NOR THE CHARITIES USING MEET THE NEED, NOR THEIR THIRD-PARTY SERVICE PROVIDERS, LICENSORS OR SUPPLIERS BE LIABLE FOR ANY DAMAGES WHATSOEVER, WHETHER DIRECT, INDIRECT, GENERAL, SPECIAL, COMPENSATORY, CONSEQUENTIAL, AND/OR INCIDENTAL, ARISING OUT OF OR RELATING YOUR USE OF THE SERVICE, INCLUDING WITHOUT LIMITATION, DAMAGES FOR LOSS OR CORRUPTION OF DATA OR PROGRAMS OR SERVICE INTERRUPTIONS. THE INFORMATION, WEBSITE AND SERVICES MAY CONTAIN BUGS, ERRORS, PROBLEMS AND OTHER LIMITATIONS.<br/>

  (d) LIMITATION OF LIABILITY<br/>
  THE LIABILITY OF MEET THE NEED AND THE CHARITIES USING MEET THE NEED, AND THEIR THIRD-PARTY SERVICE PROVIDERS, LICENSORS AND SUPPLIERS IS LIMITED. TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL MEET THE NEED AND THE CHARITIES USING MEET THE NEED, OR THEIR THIRD-PARTY SERVICE PROVIDERS, LICENSORS OR SUPPLIERS BE LIABLE FOR DAMAGES OF ANY KIND (INCLUDING, BUT NOT LIMITED TO, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES, LOST PROFITS, LOST DATA OR CONFIDENTIAL OR OTHER INFORMATION, LOSS OF PRIVACY, FAILURE TO MEET ANY DUTY INCLUDING WITHOUT LIMITATION OF GOOD FAITH OR OF REASONABLE CARE, NEGLIGENCE, OR OTHERWISE, REGARDLESS OF THE FORESEEABILITY OF THOSE DAMAGES OR OF ANY ADVICE OR NOTICE GIVEN TO MEET THE NEED, THE CHARITIES USING MEET THE NEED, OR THEIR THIRD-PARTY SERVICE PROVIDERS, LICENSORS AND SUPPLIERS) ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THE WEBSITE OR SERVICES. THIS LIMITATION SHALL APPLY REGARDLESS OF WHETHER THE DAMAGES ARISE OUT OF BREACH OF CONTRACT, TORT, OR ANY OTHER LEGAL THEORY OR FORM OF ACTION. ADDITIONALLY, THE MAXIMUM LIABILITY OF MEET THE NEED, THE CHARITIES USING MEET THE NEED, AND THEIR THIRD-PARTY SERVICE PROVIDERS, LICENSORS AND SUPPLIERS TO YOU UNDER ALL CIRCUMSTANCES WILL BE $250.00. YOU AGREE THAT THIS LIMITATION OF LIABILITY REPRESENTS A REASONABLE ALLOCATION OF RISK AND US A FUNDAMENTAL ELEMENT OF THE BASIS OF THE BARGAIN BETWEEN MEET THE NEED AND YOU. THE WEBSITE AND SERVICES WOULD NOT BE PROVIDED WITHOUT SUCH LIMITATIONS.<br/>

  (e) APPLICATION<br/>
  THE ABOVE DISCLAIMERS, WAIVERS AND LIMITATIONS DO NOT IN ANY WAY LIMIT ANY OTHER DISCLAIMER OF WARRANTIES OR ANY OTHER LIMITATION OF LIABILITY IN ANY OTHER AGREEMENT BETWEEN YOU AND MEET THE NEED, OR BETWEEN YOU AND THE CHARITIES USING MEET THE NEED, OR BETWEEN YOU AND ANY OF MEET THE NEED'S THIRD-PARTY SERVICE PROVIDERS, LICENSORS AND SUPPLIERS. SOME JURISDICTIONS MAY NOT ALLOW THE EXCLUSION OF CERTAIN IMPLIED WARRANTIES OR THE LIMITATION OF CERTAIN DAMAGES, SO SOME OF THE ABOVE DISCLAIMERS, WAIVERS AND LIMITATIONS OF LIABILITY MAY NOT APPLY TO YOU. UNLESS LIMITED OR MODIFIED BY APPLICABLE LAW, THE FOREGOING DISCLAIMERS, WAIVERS AND LIMITATIONS SHALL APPLY TO THE MAXIMUM EXTENT PERMITTED, EVEN IF ANY REMEDY FAILS ITS ESSENTIAL PURPOSE. MEET THE NEED'S CHARITY USERS, THIRD-PARTY SERVICE PROVIDERS, LICENSORS AND SUPPLIERS ARE INTENDED THIRD-PARTY BENEFICIARIES OF THESE DISCLAIMERS, WAIVERS AND LIMITATIONS. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU THROUGH THE WEBSITE OR OTHERWISE SHALL ALTER ANY OF THE DISCLAIMERS OR LIMITATIONS STATED IN THIS SECTION.<br/>

  <h2>19. GENERAL REPRESENTATION AND WARRANTY</h2>
  You represent and warrant that Your use of the Website and Services will be in accordance with this Agreement and any other Meet the Need policies, and with any applicable laws or regulations.<br/>

  <h2>20. GOVERNING LAW; JURISDICTION AND VENUE</h2>
  The Website, Services, all advertising relating thereto and this Agreement, including without limitation this Agreement's interpretation, shall be treated as though this Agreement were executed and performed in Tampa, Florida, and shall be governed by and construed in accordance with the laws of the State of Florida without regard to its conflict of law principles. ANY CAUSE OF ACTION BY YOU ARISING OUT OF OR RELATING TO THE WEBSITE, SERVICES, ALL ADVERTISING RELATING THERETO, OR THIS AGREEMENT MUST BE INSTITUTED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION AROSE OR BE FOREVER WAIVED AND BARRED. ALL ACTIONS SHALL BE SUBJECT TO THE LIMITATIONS SET FORTH IN ABOVE. The language in this Agreement shall be interpreted in accordance with its fair meaning and not strictly for or against either party. THE PROPER VENUE FOR ANY JUDICIAL ACTION ARISING OUT OF OR RELATING TO THE WEBSITE, SERVICES, ALL ADVERTISING RELATING THERETO, OR THIS AGREEMENT WILL BE THE STATE AND FEDERAL COURTS LOCATED IN OR NEAREST TO TAMPA, FLORIDA. THE PARTIES HEREBY STIPULATE TO, AND AGREE TO WAIVE ANY OBJECTION TO, THE PERSONAL JURISDICTION AND VENUE OF SUCH COURTS, AND FURTHER EXPRESSLY SUBMIT TO EXTRATERRITORIAL SERVICE OF PROCESS.<br/>

  <h2>21. TERMINATION</h2>

  (a) By Meet the Need<br/>
  WITHOUT LIMITING ANY OTHER PROVISION OF THIS AGREEMENT, MEET THE NEED RESERVES THE RIGHT TO, IN MEET THE NEED'S SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY USE OF THE WEBSITE AND/OR SERVICES TO ANY PERSON FOR ANY REASON OR FOR NO REASON AT ALL, INCLUDING WITHOUT LIMITATION FOR ANY BREACH OR SUSPECTED BREACH OF ANY REPRESENTATION, WARRANTY OR COVENANT CONTAINED IN THIS AGREEMENT, OR OF ANY APPLICABLE LAW OR REGULATION. Following any termination hereof, Meet the Need reserves the right to send a notice that You have been terminated to other users with whom You have corresponded.<br/>

  (b) Automatic Termination Upon Breach By You<br/>
  This Agreement shall automatically terminate in the event that You breach any of this Agreement's representations, warranties or covenants. Such termination shall be automatic, and shall not require any action by Meet the Need.<br/>

  (c) By You<br/>
  You may terminate this Agreement and Your rights hereunder at any time, for any or no reason at all, by providing to Meet the Need notice of Your intention to do so, in the manner required by this Agreement.<br/>

  (d) Effect of Termination<br/>
  Any termination of this Agreement automatically terminates all rights and licenses granted to You under this Agreement, including all rights to use the Website and Services. Upon termination, Meet the Need may, but has no obligation to, in Meet the Need's sole discretion, delete from Meet the Need's systems Your Personal Information and any other files or information that You made available to Meet the Need or that otherwise relate to Your use of the Website or Services. Subsequent to termination, Meet the Need reserves the right to exercise whatever means its deems necessary to prevent Your unauthorized use of the Website and Services, including without limitation technological barriers such as IP mapping and direct contact with Your Internet Service Provider.<br/>

  (e) Legal Action<br/>
  If Meet the Need, in Meet the Need's discretion, takes legal action against You in connection with any actual or suspected breach of this Agreement, Meet the Need will be entitled to recover from You as part of such legal action, and You agree to pay, Meet the Need's reasonable costs and attorneys' fees incurred as a result of such legal action. The Meet the Need Parties will have no legal obligation or other liability to You or to any third party arising out of or relating to any termination of this Agreement.<br/>

  (f) Survival<br/>
  Upon termination, all rights and obligations created by this Agreement will terminate, except that Sections 2, 4, 17, 18, 20 and 22 will survive any termination of this Agreement.<br/>

  <h2>22. GENERAL</h2>

  (a) Entire Agreement; Amendment<br/>
  This Agreement, accepted upon registration for the Service, constitutes the entire agreement between Meet the Need and You concerning Your use of the Websites. This Agreement and any other written agreements executed between You and Meet the Need, any Charity using Meet the Need, or any Third-Party Service Providers constitute the entire agreement concerning Your use of the Services. This Agreement may only be modified by a written amendment signed by an authorized executive of Meet the Need or by the unilateral amendment of this Agreement by Meet the Need and by the posting by Meet the Need of such amended version.<br/>

  (b) Severability; Waiver<br/>
  If any provision or part of this Agreement is held invalid or unenforceable, that part will be construed to reflect the parties' original intent, and the remaining portions will remain in full force and effect. A waiver by either party of any term or condition of this Agreement or any breach thereof, in any one instance, will not waive such term or condition or any subsequent breach thereof.<br/>

  (c) Assignment<br/>
  This Agreement and all of Your rights and obligations hereunder will not be assignable or transferable by You without the prior written consent of Meet the Need. This Agreement will be binding upon and will inure to the benefit of the parties, their successors and permitted assigns.<br/>

  (d) Relationship<br/>
  You and Meet the Need are independent contractors, and no agency, partnership, joint venture or employee-employer relationship is intended or created by this Agreement.<br/>

  (e) Third-Party Beneficiaries<br/>
  Except for the Meet the Need Parties and the Charity and Church Parties as and to the extent set forth in this Agreement, and Meet the Need's and Charities using Meet the Need's Third-Party Service Providers, licensors and suppliers as and to the extent expressly set forth in this Agreement, there are no third-party beneficiaries to this Agreement.<br/>

  (f) Irreparable Injury<br/>
  You acknowledge and agree that any actual or threatened breach of this Agreement or infringement of proprietary or other third party rights by You would cause irreparable injury to Meet the Need, the Charities using Meet the Need, and Meet the Need's and Charities using Meet the Need's Third-Party Service Providers, licensors and suppliers, and would therefore entitle Meet the Need, the Charities using Meet the Need, or Meet the Need's or the Charities using Meet the Need's Third-Party Service Providers, licensors or suppliers, as the case may be, to injunctive relief.<br/>

  (g) Notices<br/>
  All notices required or permitted to be given under this Agreement must be in writing. You shall give any notice to Meet the Need by means of e-mailing Meet the Need at admin@meettheneed.org. Meet the Need shall give any notice by email sent to the most recent email address, if any, provided by the intended Recipient to Meet the Need. YOU BEAR THE SOLE RESPONSIBILITY OF ENSURING THAT YOUR EMAIL ADDRESS ON FILE WITH MEET THE NEED IS ACCURATE AND CURRENT, AND NOTICE TO YOU SHALL BE DEEMED EFFECTIVE UPON THE SENDING BY MEET THE NEED OF AN EMAIL TO THAT ADDRESS. You agree that any notice received from Meet the Need electronically satisfies any legal requirement that such notice be in writing.<br/>

  (h) Complaints<br/>
  To resolve a complaint regarding the Service, You should contact Meet the Need by clicking on the "Contact Us" button or via email at admin@meettheneed.org.
</ion-content>

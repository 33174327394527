import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Environment as Env } from '../environments/environment.prod';
import {UserService} from "../app/api/services/user.service";
import {AlertController, ModalController} from "@ionic/angular";
import {UserResource} from "../app/api/resources/user.resource";
import { Storage } from '@ionic/storage';
import {StateManagementService} from "../app/api/services/state-management-service.service";
import {EncouragementUpdateType} from "../app/shared/types/EncouragementUpdate.type";
import {EncouragePage} from "../app/pages/encourage/encourage.page";


export type loginRes = {
  user?: UserResource,
}

@Injectable({
  providedIn: 'root',
})
export class EncourageService {
    serverApi: string;

    data: any;

    idRole: any;

    private myData: BehaviorSubject<loginRes> = new BehaviorSubject<loginRes>(null);

    constructor(private http: HttpClient,
                private alertController: AlertController,
                private modalController: ModalController,
                private storage: Storage,
                private appState: StateManagementService,
    ) {
      this.serverApi = Env.server + Env.apiUrl;
      this.load();

    }

    async load() {
      const data = await this.storage.get('loginRes');
      this.myData.next(data);
    }

    getData(): Observable<loginRes> {
      return this.myData;
    }

    updateData(data): void {
      this.myData.next(data);
    }

    getEncourage(slug) {
        if (slug) {
            this.fetchEncourage(slug).subscribe((res) => {
                res.data ? this.showModal(res) : null;
            });
        }
    }
    async showModal(slug) {
        const modal = await this.modalController.create({
            component: EncouragePage,
            componentProps: {
                slugData: slug,
            },
        });
        return modal.present();
    }

    fetchEncourageRegister(slug: string, id?: BigInteger) {
      if (id) {
        this.idRole = `/${id}`;
      } else {
        this.idRole = '';
      }
      const url = `${Env.server}encouragement/${slug}/ob-mission${this.idRole}`;
      return this.http.get(url).pipe(map((res: any) => res));
    }

    fetchEncourage(slug: string) {
      const url = `${Env.server + Env.apiUrl}encouragement/${slug}`;
      return this.http.get(url).pipe(map((res: any) => res));
    }

    fetchQuestionSet(slug: string, questionsSet: BigInteger) {
      const url = `${
        Env.server + Env.apiUrl
      }encouragement/${slug}/${questionsSet}`;
      return this.http.get(url).pipe(map((res: any) => res));
    }

    fetchEncourageFirstResource(slug: string, id?: number) {
      if (id) {
        this.idRole = `/${id}`;
      } else {
        this.idRole = '';
      }
      const url = `${Env.server}encouragement/${slug}/first-resource${this.idRole}`;
      return this.http.get(url).pipe(map((res: any) => res));
    }
    async updateNextStep( step: string, id: number ) {
      try {
        const user = await new UserService( this.http ).updateStep( id, step ).toPromise();
        const encouragementUpdate: EncouragementUpdateType = {
          user: user,
          step: step,
        };
        this.appState.update({encouragementUpdate: encouragementUpdate});
      }
      catch(error) {
          this.handleError(error);
      }
    }
    handleError(err) {
      let errorMessage = '';

      Object.keys(err.error.errors).forEach((key) => {
        errorMessage += `${err.error.errors[key]}<br><br>`;
      });
      this.presentAlert(err.error.message, errorMessage);
    }
    async presentAlert(title, message) {
      const alert = await this.alertController.create({
        header: 'Error',
        subHeader: title,
        message,
        buttons: ['OK'],
      });

      await alert.present();
  }
}

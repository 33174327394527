<ion-content class="ion-padding">
    <ion-grid>
        <ion-row>
            <ion-col>
                <ion-label><h1>Edit Cause Group</h1></ion-label>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col>
                <form [formGroup]="form.getForm()" (ngSubmit)="save()">
                    <ion-list>
                        <ion-row>
                            <app-file-upload
                                title="Upload Your Own Logo"
                                (newCallBackEvent)="presentAlert($event.title, $event.message)"
                                [(selectedImageVisible)] = "selectImageVisible"
                                (imageBlob) = "updateImageBlob($event)"
                                [(selectedFile)]="selectedImageFile"
                                [(approvedImage)]="isImageApproved">
                            </app-file-upload>
                        </ion-row>
                        <ng-container *ngIf="!selectImageVisible && selectedImageBlob == null && selectedImageFile == null && causeGroup != null && causeGroup.cover_image != null">
                            <ion-row>
                                <ion-col size="4" offset="4">
                                    <img alt="Current Image" class="current-org-image" [src]="causeGroup.cover_image">
                                </ion-col>
                            </ion-row>
                        </ng-container>

                        <ion-row>
                            <ion-col>
                                <ion-item class="item-input">
                                    <ion-label class="txt3" position="stacked">Organization Name <span class="required">*</span></ion-label>
                                    <ion-input formControlName="name" placeholder="Name" type="text" required></ion-input>
                                    <ion-note color="danger" *ngIf="form.hasError('name')">
                                    {{ form.getError('name')}}
                                    </ion-note>
                                </ion-item>
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col>
                                <ion-item class="item-input">
                                    <ion-label class="txt3" position="stacked">Email <span class="required">*</span></ion-label>
                                    <ion-input formControlName="email" placeholder="Email" type="text" required></ion-input>
                                    <ion-note color="danger" *ngIf="form.hasError('email')">
                                    {{ form.getError('email')}}
                                    </ion-note>
                                </ion-item>
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col>
                                <ion-item class="item-input">
                                    <ion-label class="txt3" position="stacked">Website</ion-label>
                                    <div class="url-wrapper">
                                        <ion-select formControlName="website_prefix">
                                            <ion-select-option value="https://">https://</ion-select-option>
                                            <ion-select-option value="http://">http://</ion-select-option>
                                        </ion-select>
                                        <ion-input formControlName="website" type="text" placeholder="www.example.com">
                                        </ion-input>
                                    </div>
                                    <ion-note color="danger" *ngIf="form.hasError('website')">
                                    {{ form.getError('website')}}
                                    </ion-note>
                                </ion-item>
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col>
                                <ion-item class="item-input">
                                    <ion-label class="txt3" position="stacked">Phone</ion-label>
                                    <ion-input formControlName="phone" placeholder="Phone" type="text"></ion-input>
                                    <ion-note color="danger" *ngIf="form.hasError('phone')">
                                    {{ form.getError('phone')}}
                                    </ion-note>
                                </ion-item>
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col>
                                <ion-item class="item-input">
                                    <ion-label class="txt3" position="stacked">Background Check</ion-label>
                                    <ion-radio-group (click)="updateFormValidation()" formControlName="background_check">
                                        <ion-row>
                                            <ion-col>
                                                <ion-item lines="none">
                                                    <ion-label>Yes</ion-label>
                                                    <ion-radio slot="start" value="true"></ion-radio>
                                                </ion-item>
                                            </ion-col>
                                            <ion-col>
                                                <ion-item lines="none">
                                                    <ion-label>No</ion-label>
                                                    <ion-radio slot="start" value="false"></ion-radio>
                                                </ion-item>
                                            </ion-col>
                                        </ion-row>
                                    </ion-radio-group>
                                </ion-item>
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col>
                                <ion-item class="item-input" [class.ion-invalid]="form.getForm().controls['background_check_link'].hasError('required')">
                                    <ion-label class="txt3" position="stacked">{{'Background Check Link ' + showStar}}</ion-label>
                                    <div class="url-wrapper">
                                        <ion-select formControlName="background_check_link_prefix">
                                            <ion-select-option value="https://">https://</ion-select-option>
                                            <ion-select-option value="http://">http://</ion-select-option>
                                        </ion-select>
                                        <ion-input formControlName="background_check_link" type="text" placeholder="Background Check Link">
                                        </ion-input>
                                    </div>
                                    <ion-note color="danger" *ngIf="form.getForm().controls['background_check_link'].hasError('required')">
                                        Background check link required
                                    </ion-note>
                                    <ion-note color="danger" *ngIf="form.getForm().controls['background_check_link'].hasError('pattern')">
                                        The provided URL is invalid
                                    </ion-note>
                                </ion-item>
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col>
                                <ion-item class="item-input" [class.ion-invalid]="form.hasError('donation_link')">
                                    <ion-label class="txt3" position="stacked">Donation Link</ion-label>
                                    <div class="url-wrapper">
                                        <ion-select formControlName="donation_link_prefix">
                                            <ion-select-option value="https://">https://</ion-select-option>
                                            <ion-select-option value="http://">http://</ion-select-option>
                                        </ion-select>
                                        <ion-input formControlName="donation_link" type="text" placeholder="Donation Link">
                                        </ion-input>
                                    </div>
                                    <ion-note color="danger" *ngIf="form.hasError('donation_link')">
                                        {{ form.getError('donation_link')}}
                                    </ion-note>
                                </ion-item>
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col>
                                <ion-item class="item-input" [class.ion-invalid]="form.hasError('welcome_text')">
                                    <ion-label class="txt3" position="stacked">Welcome Text *</ion-label>
                                    <ion-textarea formControlName="welcome_text" placeholder="Welcome Text" type="text"
                                        rows="4" maxlength="250" required></ion-textarea>
                                    <ion-note color="danger" *ngIf="form.hasError('welcome_text')">
                                    {{ form.getError('welcome_text')}}
                                    </ion-note>
                                </ion-item>
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col>
                                <ion-item class="item-input" [class.ion-invalid]="form.hasError('welcome_resource_type')">
                                    <ion-label class="txt3" position="stacked">Welcome Resource Type</ion-label>
                                    <ion-select formControlName="welcome_resource_type" placeholder="Select One">
                                        <ion-select-option value="document">Document</ion-select-option>
                                        <ion-select-option value="video">Video</ion-select-option>
                                        <ion-select-option value="slides">Slides</ion-select-option>
                                    </ion-select>
                                    <ion-note color="danger" *ngIf="form.hasError('welcome_resource_type')">
                                    {{ form.getError('welcome_resource_type')}}
                                    </ion-note>
                                </ion-item>
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col>
                                <ion-item class="item-input" [class.ion-invalid]="form.hasError('welcome_resource_link')">
                                    <ion-label class="txt3" position="stacked">Welcome Resource Link *</ion-label>
                                    <div class="url-wrapper">
                                        <ion-select formControlName="welcome_resource_link_prefix">
                                            <ion-select-option value="https://">https://</ion-select-option>
                                            <ion-select-option value="http://">http://</ion-select-option>
                                        </ion-select>
                                        <ion-input formControlName="welcome_resource_link" placeholder="Welcome Resource Link" maxlength="250" type="text">
                                        </ion-input>
                                    </div>
                                    <ion-note color="danger" *ngIf="form.hasError('welcome_resource_link')">
                                    {{ form.getError('welcome_resource_link')}}
                                    </ion-note>
                                </ion-item>
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col>
                                <ion-item class="item-input" [class.ion-invalid]="form.hasError('family_first_resource_link')">
                                    <ion-label class="txt3" position="stacked">Family First Resource Link</ion-label>
                                    <div class="url-wrapper">
                                        <ion-select formControlName="family_first_resource_link_prefix">
                                            <ion-select-option value="https://">https://</ion-select-option>
                                            <ion-select-option value="http://">http://</ion-select-option>
                                        </ion-select>
                                        <ion-input formControlName="family_first_resource_link" placeholder="Family First Resource Link" maxlength="250" type="text">
                                        </ion-input>
                                    </div>
                                    <ion-note color="danger" *ngIf="form.hasError('family_first_resource_link')">
                                    {{ form.getError('family_first_resource_link')}}
                                    </ion-note>
                                </ion-item>
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col>
                                <ion-item class="item-input" [class.ion-invalid]="form.hasError('advocate_first_resource_link')">
                                    <ion-label class="txt3" position="stacked">Advocate First Resource Link</ion-label>
                                    <div class="url-wrapper">
                                        <ion-select formControlName="advocate_first_resource_link_prefix">
                                            <ion-select-option value="https://">https://</ion-select-option>
                                            <ion-select-option value="http://">http://</ion-select-option>
                                        </ion-select>
                                        <ion-input formControlName="advocate_first_resource_link" placeholder="Advocate First Resource Link" maxlength="250" type="text">
                                        </ion-input>
                                    </div>
                                    <ion-note color="danger" *ngIf="form.hasError('advocate_first_resource_link')">
                                        {{ form.getError('advocate_first_resource_link')}}
                                    </ion-note>
                                </ion-item>
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col>
                                <ion-item class="item-input" [class.ion-invalid]="form.hasError('first_resource_link')">
                                    <ion-label class="txt3" position="stacked">First Resource Link</ion-label>
                                    <div class="url-wrapper">
                                        <ion-select formControlName="first_resource_link_prefix">
                                            <ion-select-option value="https://">https://</ion-select-option>
                                            <ion-select-option value="http://">http://</ion-select-option>
                                        </ion-select>
                                        <ion-input formControlName="first_resource_link" placeholder="First Resource Link" maxlength="250" type="text">
                                        </ion-input>
                                    </div>
                                    <ion-note color="danger" *ngIf="form.hasError('first_resource_link')">
                                    {{ form.getError('first_resource_link')}}
                                    </ion-note>
                                </ion-item>
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col>
                                <ion-item class="item-input" [class.ion-invalid]="form.hasError('learning_resource_link')">
                                    <ion-label class="txt3" position="stacked">Learning Resource Link</ion-label>
                                    <small class="pdb-10"><ion-icon name="information-circle-outline" class="pdr-5"></ion-icon>Shows in next steps guide</small>
                                    <div class="url-wrapper">
                                        <ion-select formControlName="learning_resource_link_prefix">
                                            <ion-select-option value="https://">https://</ion-select-option>
                                            <ion-select-option value="http://">http://</ion-select-option>
                                        </ion-select>
                                        <ion-input formControlName="learning_resource_link" placeholder="Learning Resource Link" maxlength="250" type="text">
                                        </ion-input>
                                    </div>
                                    <ion-note color="danger" *ngIf="form.hasError('learning_resource_link')">
                                        {{ form.getError('learning_resource_link')}}
                                    </ion-note>
                                </ion-item>
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col>
                                <ion-item class="item-input" [class.ion-invalid]="form.hasError('statement_faith_link')">
                                    <ion-label class="txt3" position="stacked">Statement of Faith Link</ion-label>
                                    <small class="pdb-10"><ion-icon name="information-circle-outline" class="pdr-5"></ion-icon>If you want a different Statement of Faith</small>
                                    <div class="url-wrapper">
                                        <ion-select formControlName="statement_faith_link_prefix">
                                            <ion-select-option value="https://">https://</ion-select-option>
                                            <ion-select-option value="http://">http://</ion-select-option>
                                        </ion-select>
                                        <ion-input formControlName="statement_faith_link" placeholder="Statement of Faith Link" maxlength="250" type="text">
                                        </ion-input>
                                    </div>
                                    <ion-note color="danger" *ngIf="form.hasError('statement_faith_link')">
                                        {{ form.getError('statement_faith_link')}}
                                    </ion-note>
                                </ion-item>
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col>
                                <ion-item class="item-input" [class.ion-invalid]="form.hasError('statement_intent_link')">
                                    <ion-label class="txt3" position="stacked">Statement of Intent Link</ion-label>
                                    <small class="pdb-10"><ion-icon name="information-circle-outline" class="pdr-5"></ion-icon>If you want a different Statement of Intent</small>
                                    <div class="url-wrapper">
                                        <ion-select formControlName="statement_intent_link_prefix">
                                            <ion-select-option value="https://">https://</ion-select-option>
                                            <ion-select-option value="http://">http://</ion-select-option>
                                        </ion-select>
                                        <ion-input formControlName="statement_intent_link" placeholder="Statement of Intent Link" maxlength="250" type="text">
                                        </ion-input>
                                    </div>
                                    <ion-note color="danger" *ngIf="form.hasError('statement_intent_link')">
                                        {{ form.getError('statement_intent_link')}}
                                    </ion-note>
                                </ion-item>
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col>
                                <ion-item class="item-input" [class.ion-invalid]="form.hasError('disclaimer')">
                                    <ion-label class="txt3" position="stacked">Disclaimer <span class="required">*</span>
                                    </ion-label>
                                    <small class="pdb-10"><ion-icon name="information-circle-outline" class="pdr-5"></ion-icon>Shows in dashboard, family profile and cause group contact page</small>

                                    <ion-textarea formControlName="disclaimer" placeholder="Add Disclaimer" type="text"
                                                  rows="6" maxlength="510" required></ion-textarea>
                                    <ion-note color="danger" *ngIf="form.hasError('disclaimer')">
                                        {{ form.getError('disclaimer')}}
                                    </ion-note>
                                </ion-item>
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col>
                                <ion-item class="item-input" [class.ion-invalid]="form.hasError('advocate_text')">
                                    <ion-label class="txt3" position="stacked">Advocate Text <span class="required">*</span>
                                    </ion-label>
                                    <small class="pdb-10"><ion-icon name="information-circle-outline" class="pdr-5"></ion-icon>Shows in add family page</small>

                                    <ion-textarea formControlName="advocate_text" placeholder="Add Advocate Explanation" type="text"
                                                  rows="10" maxlength="1024" required></ion-textarea>
                                    <ion-note color="danger" *ngIf="form.hasError('advocate_text')">
                                        {{ form.getError('advocate_text')}}
                                    </ion-note>
                                </ion-item>
                            </ion-col>
                        </ion-row>
                        <!-- Background Check Policy field -->
                        <ion-row>
                            <ion-col>
                                <ion-item class="item-input" [class.ion-invalid]="form.hasError('background_check_content')">
                                    <ion-label class="txt3" position="stacked">Backgroud Check Policy Page Content</ion-label>
                                    <small class="pdb-10"><ion-icon name="information-circle-outline" class="pdr-5"></ion-icon>Content for the Backgroud Check Policy page.</small>
                                    <ion-textarea formControlName="background_check_content" placeholder="Add the content" type="text"
                                                  rows="10" maxlength="2048"></ion-textarea>
                                    <ion-note color="danger" *ngIf="form.hasError('background_check_content')">
                                        {{ form.getError('background_check_content')}}
                                    </ion-note>
                                </ion-item>
                            </ion-col>
                        </ion-row>

                        <!-- App Icon Name -->
                        <ion-row>
                            <ion-col>
                                <ion-item class="item-input">
                                    <ion-label class="txt3" position="stacked">App Icon Name</ion-label>
                                    <ion-input formControlName="app_icon_name" placeholder="App Icon Name" type="text"></ion-input>
                                    <ion-note color="danger" *ngIf="form.hasError('app_icon_name')">
                                    {{ form.getError('app_icon_name')}}
                                    </ion-note>
                                </ion-item>
                            </ion-col>
                        </ion-row>
                        <!-- App Icon Image-->
                        <ion-row>
                            <app-file-upload
                                title="Upload Your Own App Icon Image"
                                (newCallBackEvent)="presentAlert($event.title, $event.message)"
                                [(selectedImageVisible)] = "selectImageVisibleIcon"
                                (imageBlob) = "updateImageIconBlob($event)"
                                [(selectedFile)]="selectedImageFileIcon"
                                [(approvedImage)]="isImageIconApproved"
                            >
                            </app-file-upload>
                        </ion-row>
                        <ng-container *ngIf="!selectImageVisibleIcon && selectedImageIconBlob == null &&  selectedImageFileIcon == null && causeGroup != null && causeGroup.app_icon_image != null">
                            <ion-row>
                                <ion-col size="4" offset="4">
                                    <img alt="Current App Icon Image" class="current-org-image" [src]="causeGroup.app_icon_image">
                                </ion-col>
                            </ion-row>
                        </ng-container>
                        <ion-row>
                            <ion-col>
                            <ion-button [disabled]="(form.getForm().controls['background_check'].value === true && form.getForm().controls['background_check_link'].value === '') || !form.getForm().valid || !isImageIconApproved || !isImageApproved" expand="block" type="submit">
                                <ion-icon slot="start" name="save"></ion-icon> Save
                            </ion-button>
                            </ion-col>
                        </ion-row>
                    </ion-list>
                </form>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>

import { Injectable } from "@angular/core";
import { OrganizationResource } from "src/app/api/resources/organization.resource";
import { UserResource } from "src/app/api/resources/user.resource";

@Injectable({
  providedIn: 'root',
})
export class OrganizationCheckerService {

  // TODO: This should be HELPER not a service - using this method on the dashboard page
  isUserOrganization(user: UserResource): boolean {
    const userRoles = user.roles.map((role) => role.name).join(', ').toLowerCase();
    return userRoles.indexOf('church') >= 0 || userRoles.indexOf('businesses') >= 0 || userRoles.indexOf('service-provider-non-profit') >= 0;
  }

  isOrgDataComplete(organization: OrganizationResource): boolean {
    // show this always as true if the user doesn't want to be remembered
    if (!organization.show_complete_profile_msg) {
      return true;
    }
    return !(!organization.name || !organization.email || !organization.bio || !organization.zip ||
      !organization.street || !organization.state || !organization.city);

  }


  // TODO: This should consider the organization-admin role we aren't using in the app - I'm adding this workaround for now.
  isUserOrganizationProgressPage(user: UserResource): boolean {
    const userRoles = user.roles.map((role) => role.name).join(', ').toLowerCase();
    const roleStatement = userRoles.indexOf('church') >= 0 || userRoles.indexOf('businesses') >= 0 || userRoles.indexOf('service-provider-non-profit') >= 0;
    const orgStatement = user.administrator_organizations && user.administrator_organizations.length > 0;
    return roleStatement && orgStatement;
  }
}

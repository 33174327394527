import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {Inject, Injectable} from '@angular/core';
import { OrganizationResource } from '../resources/organization.resource';
import { ResourceService } from './resource.service';
import { ResourceSerializer } from '../serializers/resource-serializer';
import { ListResponse } from '../resources/list-response';

@Injectable()
export class OrganizationService extends ResourceService<OrganizationResource> {
    static resourceSlug: string = 'organization';

    constructor(httpClient: HttpClient, @Inject('organizationServiceEndpoint') endpoint: string = OrganizationService.resourceSlug) {
      super(
        httpClient,
        endpoint,
        new ResourceSerializer(OrganizationResource),
      );
    }

    listAll(options: object = {}, path: string = ''): Observable<ListResponse<OrganizationResource>> {
      return this.httpClient
        .get(`${this.url}${this.endpoint}${path}`, options)
        .pipe(map((response: any) => {
          const listResponse = new ListResponse<OrganizationResource>();
          listResponse.metadata = response.metadata;
          listResponse.data = this.convertData(response.data);

          return listResponse;
        }));
    }

    listNearest(options: object = {}): Observable<ListResponse<OrganizationResource>> {
      return this.listAll(options, '/map');
    }

    approve(item: OrganizationResource): Observable<OrganizationResource> {
      return this.httpClient
        .post<OrganizationResource>(`${this.url}${this.endpoint}/${item.id}/approve`, null)
        .pipe(map((data: any) => this.serializer.fromJson(typeof data.data === 'undefined' ? data : data.data) as OrganizationResource));
    }

    reject(item: OrganizationResource): Observable<OrganizationResource> {
      return this.httpClient
        .post<OrganizationResource>(`${this.url}${this.endpoint}/${item.id}/reject`, null)
        .pipe(map((data: any) => this.serializer.fromJson(typeof data.data === 'undefined' ? data : data.data) as OrganizationResource));
    }

    toggleStatus(active: boolean, organization: OrganizationResource): Observable<OrganizationResource> {
      if(active) {
        return this.approve(organization);
      }
      return this.reject(organization);
    }

    contact(item: OrganizationResource): Observable<OrganizationResource>
    {
        return this.httpClient
            .post<OrganizationResource>(`${this.url}${this.endpoint}/${item.id}/contact`, null)
            .pipe(map((data: any) => this.serializer.fromJson(typeof data.data === 'undefined' ? data : data.data) as OrganizationResource));
    }
    shareViaEmail(organizationId, data:any)
    {
        return this.httpClient
            .post(`${this.url}${this.endpoint}/${organizationId}/shareOrganization`, this.serializer.toJson(data))
            .pipe(map((response: any) => {
                return response;
            }));
    }
    shareToFamilyViaEmail(organizationId, needId, user)
    {
      return this.httpClient
        .post(`${this.url}${this.endpoint}/${organizationId}/need/${needId}/shareOrganization`, this.serializer.toJson(user))
        .pipe(map((response: any) => {
          return response;
        }));
    }
    invite(organizationId, data:any)
    {
        return this.httpClient
            .post(`${this.url}${this.endpoint}/${organizationId}/invite`, this.serializer.toJson(data))
            .pipe(map((response: any) => {
                return response;
            }));
    }

    toggleVisibilty(visible: boolean, resourceId: number, causeGroupId: number): Observable<OrganizationResource> {
      if(visible) {
        return this.visible(resourceId, causeGroupId);
      }
      return this.hide(resourceId, causeGroupId);
    }

    visible(resourceId: number, causeGroupId: number): Observable<OrganizationResource> {
      return this.httpClient
        .post<OrganizationResource>(`${this.url}${this.endpoint}/${resourceId}/cause-group/${causeGroupId}/visible`, null)
        .pipe(map((data: any) => this.serializer.fromJson(typeof data.data === 'undefined' ? data : data.data) as OrganizationResource));
    }

    hide(resourceId: number, causeGroupId: number): Observable<OrganizationResource> {
      return this.httpClient
        .post<OrganizationResource>(`${this.url}${this.endpoint}/${resourceId}/cause-group/${causeGroupId}/hide`, null)
        .pipe(map((data: any) => this.serializer.fromJson(typeof data.data === 'undefined' ? data : data.data) as OrganizationResource));
    }

    claim(organizationId: number): Observable<OrganizationResource> {
      return this.httpClient
        .post<OrganizationResource>(`${this.url}${this.endpoint}/${organizationId}/claim`, {})
        .pipe(map((data: any) => this.serializer.fromJson(typeof data.data === 'undefined' ? data : data.data) as OrganizationResource));
    }

    getByClaimedUserId(id: number): Observable<OrganizationResource> {
      return this.httpClient
        .get(`${this.url}${this.endpoint}/claimed/${id}`)
        .pipe(map((data: any) => this.serializer.fromJson(typeof data.data === 'undefined' ? data : data.data) as OrganizationResource));
    }
    removeClaimedUserId(userId: number, orgId: number): Observable<OrganizationResource> {
        return this.httpClient
            .post<OrganizationResource>(`${this.url}${this.endpoint}/${orgId}/removeclaim`, {})
            .pipe(map((data: any) => this.serializer.fromJson(typeof data.data === 'undefined' ? data : data.data) as OrganizationResource));
    }
}

import { Injectable } from "@angular/core";
import { OrganizationResource } from "src/app/api/resources/organization.resource";
import { UserResource } from "src/app/api/resources/user.resource";
import { EntityConnectionsService } from "../../api/services/entity-connections.service";
import { AlertController, ModalController } from '@ionic/angular';
import {EncouragePage} from "../../pages/encourage/encourage.page";
import {EncourageService} from "../../../providers/encourage.service";
import { OrganizationService} from "../../api/services/organization.service";
import {HttpClient} from "@angular/common/http";
import {StateManagementService} from "../../api/services/state-management-service.service";
import {NextEncouragementService} from "./next-encouragement.service";


@Injectable({
  providedIn: 'root',
})
export class OrganizationConnectService {

  isVolunteerUser: boolean;
  isFavoriteUser: boolean;
  isConnectedUser: boolean = true;

  private isLoadingVolUsers: boolean;

  private isLoadingConnectedUsers: boolean;
  private isLoadingFavorite: boolean;
  private afterSearchProvider: boolean;

  private organizationService: OrganizationService;

  constructor(private entityConnectionsService: EntityConnectionsService,
              private httpClient: HttpClient,
              private alert: AlertController,
              private modalController: ModalController,
              private encourage: EncourageService,
              private appState: StateManagementService,
              private nextEncouragementService: NextEncouragementService
              ) {
  }

  /**
   * Check if user has connected to organization
   *
   * @param user
   * @param org
   */
  checkConnectedUser(user: UserResource, org: OrganizationResource) {

    const memberEdge = 'member_organization'
    const connectPath = `/${user.entity_id}/${memberEdge}`;

    this.entityConnectionsService.list(null, connectPath).toPromise()
        .then((entityConnections) => {

          this.isConnectedUser =   entityConnections.data.some((value) =>

             value.edge
              && value.edge.code === memberEdge
              && value.entity_b === org.entity_id);

        })
      .catch((error) => {
        this.alert.create({
          header: 'Error',
          subHeader: 'Load connection',
          message: error.message,
          buttons: ['OK'],
        }).then((alert) => alert.present());
      })

  }
  checkVolunteerUser(user: UserResource, org: OrganizationResource) {

    const memberEdge = 'member_organization'
    const connectPath = `/${user.entity_id}/${memberEdge}`;

    this.entityConnectionsService.list(null, connectPath).toPromise()
        .then((entityConnections) => {

          this.isVolunteerUser =   entityConnections.data.some((value) =>

              value.edge
              && value.edge.code === memberEdge
              && value.entity_b === org.entity_id);

        })
        .catch((error) => {
          this.alert.create({
            header: 'Error',
            subHeader: 'Load connection',
            message: error.message,
            buttons: ['OK'],
          }).then((alert) => alert.present());
        })

  }
  checkFavoriteUser(user: UserResource, org: OrganizationResource) {

    const favoriteEdge = 'favorite'
    const connectPath = `/${user.entity_id}/${favoriteEdge}`;

    this.entityConnectionsService.list(null, connectPath).toPromise()
        .then((entityConnections) => {

          this.isFavoriteUser =   entityConnections.data.some((value) =>
              value.edge
              && value.edge.code === favoriteEdge
              && value.entity_b === org.entity_id);

        })
        .catch((error) => {
          this.alert.create({
            header: 'Error',
            subHeader: 'Load connection',
            message: error.message,
            buttons: ['OK'],
          }).then((alert) => alert.present());
        })

  }


  onConnectBtnClick(user: UserResource, org: OrganizationResource, encouragement = true) {

    this.isLoadingConnectedUsers = true;
    const memberEdgeSlug = 'member_organization';

    const path = `/${user.entity_id}/${memberEdgeSlug}/${org.entity_id}`;

      //if prov is not claimed yet
      if (!org.claimed_by_user_id) {
        this.alert.create({
          header: 'Pending Organization',
          message: 'This provider is not yet using this platform. Please call them directly to make a connection.',
          buttons: [{
            text: 'Continue',
            handler: () => {


              if (encouragement && !user.next_step['after-connect-to-provider']) {
                this.nextEncouragementService.getEncourage('after-connect-to-provider');
              }
            }
          }]
        }).then((alert) => alert.present());
      }
      else {

        this.alert.create({
          header: 'Connect To Organization',
          message: 'You are connecting to a provider. An email will be sent to the provider. ' +
              'Otherwise, please favorite provider instead to find later. ' +
              'Continue?',
          buttons: [
            {
              text: 'No',
              role: 'cancel',
              handler: () => {
              }
            },{
            cssClass: 'popup-button-primary',
            text: 'Yes',
            handler: () => {

              this.entityConnectionsService.create(<any>{}, path).toPromise()
                  .then(() => {
                    this.afterSearchProvider = true;

                    if (encouragement && !user.next_step['after-connect-to-provider']) {
                      this.nextEncouragementService.getEncourage('after-connect-to-provider');
                    }
                    this.encourage.updateNextStep('after-connect-to-provider', user.id);
                    this.appState.update({updateCirclesSlider: true});

                    this.isConnectedUser = true;

                  })

                  .catch((error) => {
                      this.alert.create({
                        header: 'Error',
                        subHeader: 'Connect issue',
                        message: error.message,
                        buttons: ['OK'],
                      }).then((alert) => alert.present());
                    })

            }
          }]
        }).then((alert) => alert.present());
        this.isLoadingConnectedUsers = false;


      }

  }

  onLeaveBtnClick(user: UserResource, org: OrganizationResource) {

    this.isLoadingConnectedUsers = true;
    const memberEdgeSlug = 'member_organization';
    const path = `/${memberEdgeSlug}/${org.entity_id}`;

    this.entityConnectionsService.delete(user.entity_id, path).toPromise()
        .then(() => {
          this.isConnectedUser = false;
        })
        .catch((error) => {
          this.alert.create({
            header: 'Error',
            subHeader: 'Leave organization',
            message: error.message,
            buttons: ['OK'],
          }).then((alert) => alert.present());
        })
        .finally(() => {
          this.isLoadingConnectedUsers = false;

        });
  }
  //
  onFavoriteBtnClick(user: UserResource, org: OrganizationResource) {

    this.isLoadingFavorite = true;
    const favoriteEdgeSlug = 'favorite';
    const path = `/${user.entity_id}/${favoriteEdgeSlug}/${org.entity_id}`;

    this.entityConnectionsService.create(<any>{}, path).toPromise()
        .then(() => {
          this.isFavoriteUser = true;
        })
        .catch((error) => {
          this.alert.create({
            header: 'Error',
            subHeader: 'Add organization to favorites',
            message: error.message,
            buttons: ['OK'],
          }).then((alert) => alert.present());
        })
        .finally(async () => {
          await this.encourage.updateNextStep('afterFavoriteProvider', user.id);
          this.isLoadingFavorite = false;
        });
  }

  onDeleteFavoriteBtnClick(user,org) {
    this.isLoadingFavorite = true;
    const favoriteEdgeSlug = 'favorite';
    const path = `/${favoriteEdgeSlug}/${org.entity_id}`;
    this.entityConnectionsService.delete(user.entity_id, path).toPromise()
        .then(() => {
          this.isFavoriteUser = false;
        })
        .catch((error) => {
          this.alert.create({
            header: 'Error',
            subHeader: 'Remove organization from favorites',
            message: error.message,
            buttons: ['OK'],
          }).then((alert) => alert.present());
        })
        .finally(() => { this.isLoadingFavorite = false; });
  }

  onVolunteerBtnClick(user,org) {

    this.isLoadingVolUsers = true;
    const volunteerEdgeSlug = 'volunteer';
    const path = `/${user.entity_id}/${volunteerEdgeSlug}/${org.entity_id}`;

    this.entityConnectionsService.create(<any>{}, path).toPromise()
        .then(() => {
          this.isVolunteerUser = true;
        })
        .catch((error) => {
          this.alert.create({
            header: 'Error',
            subHeader: 'Cannot add volunteer',
            message: error.message,
            buttons: ['OK'],
          }).then((alert) => alert.present());
        })
        .finally(() => { this.isLoadingVolUsers = false; });
  }

  onDeleteVolunteerBtnClick(user,org) {

    this.isLoadingVolUsers = true;
    const volunteerEdgeSlug = 'volunteer';
    const path = `/${volunteerEdgeSlug}/${org.entity_id}`;

    this.entityConnectionsService.delete(user.entity_id, path).toPromise()
        .then(() => {
          this.isVolunteerUser = false;
        })
        .catch((error) => {
          this.alert.create({
            header: 'Error',
            subHeader: 'Remove yourself as a volunteer',
            message: error.message,
            buttons: ['OK'],
          }).then((alert) => alert.present());
        })
        .finally(() => {
          this.isLoadingVolUsers = false;
        });
  }

  removeClaimOrganization(user,org) {

    this.organizationService = new OrganizationService(this.httpClient, `user/${user.id}/${OrganizationService.resourceSlug}`);

    this.organizationService
        .removeClaimedUserId(user.id, org.id).toPromise()
        .then((res) => {

        })
        .catch((error) => {
          this.alert.create({
            header: 'Error',
            subHeader: 'Remove claim to organization',
            message: error.message,
            buttons: ['OK'],
          }).then((alert) => alert.present());
        });
  }


}


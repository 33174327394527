import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})

export class CalendarComponent implements OnInit {

  existingDate: string = null;

  constructor(
      private popover: PopoverController
  ) {

  }
  ngOnInit() {

    if (this.existingDate) {
      let existingDate = new Date(this.existingDate).toISOString();
    }
    else {
      let existingDate = new Date().toISOString();
    }
  }
  selectPopover(selectedDate) {
    this.popover.dismiss(selectedDate);
  }
}

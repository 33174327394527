import { Injectable } from '@angular/core';
import { NeedResource } from 'src/app/api/resources/need.resource';
import { OccurrenceRsvpResource } from 'src/app/api/resources/occurrence-rsvp.resource';

@Injectable({
    providedIn: 'root',
})
export class RsvpCalculatorService {

    private filterOccurrenceRsvpsByStatus(occurrence_rsvps: OccurrenceRsvpResource[], statuses: String[]): OccurrenceRsvpResource[] {
        return occurrence_rsvps.filter((occurrence: OccurrenceRsvpResource) => statuses.includes(occurrence.status));
    }

    getDecreasingOccurrenceRsvps(occurrence_rsvps: OccurrenceRsvpResource[], type): OccurrenceRsvpResource[] {

      if (occurrence_rsvps && type== 'completed') {
        return this.filterOccurrenceRsvpsByStatus(
          occurrence_rsvps,
          [ OccurrenceRsvpResource.STATUS_COMPLETED]
        );
      }
        if (occurrence_rsvps && type == 'all') {
            return this.filterOccurrenceRsvpsByStatus(
                occurrence_rsvps,
                [OccurrenceRsvpResource.STATUS_PENDING, OccurrenceRsvpResource.STATUS_APPROVED, OccurrenceRsvpResource.STATUS_COMPLETED]
            );
        }
      return;
    }

    calculateRemaining(need: NeedResource,type): number {
        let totalItems = need.items;
        let itemsMet = 0;


        if(need.occurrence_rsvps && type == 'completed') {
            const committedRsvp = this.getDecreasingOccurrenceRsvps(need.occurrence_rsvps, 'completed');
            itemsMet = this.getItemsMet(committedRsvp);
            return Math.max(itemsMet, 0);

        }
        else if(need.occurrence_rsvps) {
            const committedRsvp = this.getDecreasingOccurrenceRsvps(need.occurrence_rsvps, 'all');
            itemsMet = this.getItemsMet(committedRsvp);
        }

        return Math.max(totalItems - itemsMet, 0);
    }

    getItemsMet(rsvps: OccurrenceRsvpResource[]): number {
        return rsvps.reduce(
            (acc: number, occurrence: OccurrenceRsvpResource) => acc + occurrence.additional_items,
            rsvps.length
        );
    }

    calculateRemainingVolunteers(need: NeedResource, type): number {
        let totalVolunteers = need.volunteers;
        let currentVolunteers = 0;
        let currentCompletedVolunteers = 0;

        if (need.occurrence_rsvps && type == 'completed') {
            currentCompletedVolunteers = this.getDecreasingOccurrenceRsvps(need.occurrence_rsvps, type).length;
            return Math.max(currentCompletedVolunteers, 0);
        }
        else if (need.occurrence_rsvps) {
            currentVolunteers = this.getDecreasingOccurrenceRsvps(need.occurrence_rsvps, type).length;
        }
        return Math.max(totalVolunteers - currentVolunteers, 0);
    }

    needStatus(need): string {

        let status = 'Not Met';

        if (need.items) {
            if (this.calculateRemaining(need,'all') > 0 && this.calculateRemaining(need,'all') !== need.items) {
                status = 'Partially Met';
            }
            else if (this.calculateRemaining(need,'all') == need.items) {
                status = 'Not Met'
            }
            else if (this.calculateRemaining(need,'all')  == 0 ) {
                status = 'Fully Met'
            }

        } else {

            if ((need.volunteers - this.getDecreasingOccurrenceRsvps((need.occurrence_rsvps), 'all')?.length) > 0 &&
                (need.volunteers - this.getDecreasingOccurrenceRsvps((need.occurrence_rsvps), 'all')?.length != (need.volunteers))) {
                status = 'Partially Met';
            }
            else if (this.getDecreasingOccurrenceRsvps((need.occurrence_rsvps),'all').length == 0) {
                status = 'Not Met'
            }
            else if (need.volunteers - this.getDecreasingOccurrenceRsvps((need.occurrence_rsvps), 'all')?.length  == 0) {
                status = 'Fully Met'
            }
        }
        return status;
    }
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResourceService } from './resource.service';
import { TagResource } from '../resources/tag.resource';
import { ResourceSerializer } from '../serializers/resource-serializer';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Injectable()
export class TagService extends ResourceService<TagResource> {
    static resourceSlug: string = 'tag';

    constructor(httpClient: HttpClient, endpoint: string = TagService.resourceSlug) {
      super(
        httpClient,
        endpoint,
        new ResourceSerializer(TagResource),
      );
    }

    export(options:Object):Observable<any>
    {
        return this.httpClient
            .get(`${this.url}${this.endpoint}/export`, options)
            .pipe(map((data: any) => {
                return data;
            }));
    }
}

import { Component, OnInit } from '@angular/core';
import {ResourceService} from "../../../api/services/resource.service";
import {OrganizationResource} from "../../../api/resources/organization.resource";
import {ResourceSerializer} from "../../../api/serializers/resource-serializer";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import { Storage } from '@ionic/storage';
import {OrganizationConnectService} from "../../services/organization-connect.service";
import {UserService} from "../../../api/services/user.service";
import { UserResource } from "../../../api/resources/user.resource";


@Component({
  selector: 'app-my-organizations',
  templateUrl: './my-organizations.component.html',
  styleUrls: ['./my-organizations.component.scss'],
})
export class MyOrganizationsComponent implements OnInit {

  userId;

  private edgeCode;

  activeClaim: boolean = true;

  private organizations: OrganizationResource[];

  totalOrganizations: OrganizationResource[];

  totalFavoriteOrganizations: OrganizationResource[];

  totalConnectedOrganizations: OrganizationResource[];

  totalVolunteerOrganizations: OrganizationResource[];
  currentUser: any;
  removeViewOnly: boolean;
  isLoading: boolean;
  user: UserResource;

  constructor( private httpClient: HttpClient,
               private route: ActivatedRoute,
               private router: Router,
               private storage: Storage,
               private orgConnect: OrganizationConnectService
               ) { }

  async ngOnInit() {
    await this.route.params.subscribe((params) => {
      this.userId = params.hasOwnProperty('id') ? parseInt(params.id) : null;
      if (this.userId) this.loadUser();
      this.storage.get('loginRes').then((val) => {
        this.currentUser = val.user;
        if (!this.userId) this.userId = this.currentUser.id;
        this.loadOrganizations();

        if  ((this.router.url).includes('remove')) {
          this.removeViewOnly = true;
        }
      })
    })
  }
  // @todo
  //create reusable helper

  public loadUser(): void {
    this.isLoading = true;

    new UserService(this.httpClient)
        .read(this.userId)
        .toPromise()
        .then((user) => {
          this.user = user;
        })
        .catch((errorResponse) => {
          console.log(errorResponse)
        })
        .finally(() => { this.isLoading = false; });
  }
  loadOrganizations(): void {
    this.edgeCode = ['organization-admin','member_organization','volunteer','favorite']
    this.totalOrganizations = [];
    this.totalConnectedOrganizations = [];
    this.totalFavoriteOrganizations = [];
    this.totalVolunteerOrganizations = [];
    for (let edge of this.edgeCode) {
      const organizationService = new ResourceService<OrganizationResource>(
          this.httpClient,
          `user/${this.userId}/organization/edge/${edge}`,
          new ResourceSerializer(OrganizationResource),
      );

      organizationService
          .list()
          .subscribe((value) => {
            this.organizations = value.data.length > 0 ? value.data : undefined;

            if (this.organizations != undefined) {

              this.organizations.forEach((val) => {

                if (edge == 'organization-admin') {
                  val['edge'] = 'orgadmin';
                  this.totalOrganizations.push(val);
                }
                else if (edge == 'member_organization') {
                  val['edge'] = 'memberorg';
                  this.totalConnectedOrganizations.push(val)
                }
                else if (edge == 'volunteer') {
                  val['edge'] = 'volorg'
                  this.totalVolunteerOrganizations.push(val)
                }
                else if (edge == 'favorite') {
                  val['edge'] = 'faveorg'
                  this.totalFavoriteOrganizations.push(val)
                }
              });
            }
          });
    }
  }

  protected readonly setTimeout = setTimeout;
}

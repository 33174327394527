import {HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResourceService } from '../resource.service';
import { FriendshipResource } from '../../resources/friendship.resource';
import { ResourceSerializer } from '../../serializers/resource-serializer';

@Injectable()
export class FriendshipService extends ResourceService<FriendshipResource> {
    static resourceSlug: string = 'friendship';

    constructor(httpClient: HttpClient, endpoint: string = FriendshipService.resourceSlug) {
      super(
        httpClient,
        endpoint,
        new ResourceSerializer(FriendshipResource),
      );
    }

    removeFriendshipRole(friendId: number, role: string) {
        const options: { role: string } = { role };
        return this.httpClient
          .post(`${this.url}${this.endpoint}/${friendId}/remove-role`, options );
    }
}

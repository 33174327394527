<ion-row>
    <ion-col size="12">
        <ion-text class="txt3 fs-15 help-text">
            <h3>{{title}}</h3>
            <p *ngIf="subTitle">{{subTitle}}</p>
            <p *ngIf="copyrightText !== ''" class="small copyright">{{copyrightText}}</p>
        </ion-text>

    </ion-col>
    <ion-col size="12">
        <input type="file"
               #fileUploadInput
               (change)="fileChangedHandler($event)" class="hide"
               [accept]="'.' + fileTypes.join(', .')" style="display:none"/>
    </ion-col>
    <ion-col size="6">
        <ion-button expand="block" class="center-button" type="button" (click)="onFileFromOwnBtnClick()">
            {{ buttonTitle}}
        </ion-button>
    </ion-col>
</ion-row>

<ng-container *ngIf="imageCropper">
    <ion-row>
        <ion-col size-xs="12" size-sm="6" size-md="6" size-lg="4" size-xl="4" *ngIf="imageChangedEvent">
            <h3>Uploaded image</h3>
            <image-cropper
                [imageChangedEvent]="imageChangedEvent"
                [maintainAspectRatio]="true"
                [aspectRatio]="1"
                [resizeToWidth]="600"
                format="jpeg"
                (imageCropped)="imageCropped($event)"
            ></image-cropper>
        </ion-col>

        <ion-col *ngIf="croppedImage" size-xs="12" size-sm="6" size-md="6" size-lg="4" size-xl="4">
            <h3>Cropped image</h3>
            <img class="pd-5"
                [src]="croppedImage"
                 alt="Uploaded image"/>
        </ion-col>
    </ion-row>

    <ion-row *ngIf="croppedImage" class="flex flex-row pd-10">
        <ion-button color="danger" (click)="removeImage()">Cancel</ion-button>
        <ion-button color="success" (click)="approveImage()">Approve</ion-button>
    </ion-row>
</ng-container>

<ng-container *ngIf="(selectedImageVisible || selectUpload) && (selectedFile != null || imageBlob != null)">
    <ion-spinner *ngIf="loadingPreview"></ion-spinner>

    <ion-row>
        <ion-col size="4" offset="4" class="image-preview-container">
            <img *ngIf="!selectUpload && !isImageHeic" [ngClass]="{'hide-preview': loadingPreview}" #filePreview alt="File Preview" src="" >
            <label *ngIf="selectUpload">{{selectDocumentFilePath}}</label>
            <label class="pdr-30" *ngIf="isImageHeic">Preview not available. Image will be uploaded after saving.</label>
            <button class="remove-image" (click)="removeImage()">X</button>
        </ion-col>
    </ion-row>
</ng-container>

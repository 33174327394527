<ion-header translucent="true">
    <ion-toolbar>
        <ion-title class="pdl-10">Circles List</ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="dismissModal()" color="dark">Close</ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content>

    <ion-grid>
        <ion-row>
            <ion-col size="12">
                <div class="circle-row">
                    <img src="./assets/svg/circles-icons/family-icon.svg" alt="Family Icon">
                    <div class="content-wrapper">
                        <h3>Family</h3>
                        <p>Individual or family seeking support</p>
                    </div>
                </div>

                <div class="circle-row">
                    <img src="./assets/svg/circles-icons/advocate-icon.svg" alt="Advocate Icon">
                    <div class="content-wrapper">
                        <h3>Advocate</h3>
                        <p>Caring representative for the family that is a part of the Inner Circle of the family being supported</p>
                    </div>
                </div>

                <div class="circle-row">
                    <img src="./assets/svg/circles-icons/friends-icon.svg" alt="Friends Icon">
                    <div class="content-wrapper">
                        <h3>Friends</h3>
                        <p>Invited, trusted friends and family that are part of the Inner Circle of the family being supported</p>
                    </div>
                </div>

                <div class="circle-row">
                    <img src="./assets/svg/circles-icons/church-icon.svg" alt="Church Icon">
                    <div class="content-wrapper">
                        <h3>Church</h3>
                        <p>Fellow Church members ready and willing to help</p>
                    </div>
                </div>

                <div class="circle-row">
                    <img src="./assets/svg/circles-icons/provider-icon.svg" alt="Provider Icon">
                    <div class="content-wrapper">
                        <h3>Provider</h3>
                        <p>Non-Profit, GOVT Agency, or Ministry</p>
                    </div>
                </div>

                <div class="circle-row">
                    <img src="./assets/svg/circles-icons/volunteer-icon.svg" alt="Volunteer Icon">
                    <div class="content-wrapper">
                        <h3>Volunteer</h3>
                        <p>Anyone eager to increase their impact</p>
                    </div>
                </div>

                <div class="circle-row">
                    <img src="./assets/svg/circles-icons/business-icon.svg" alt="Business Icon">
                    <div class="content-wrapper">
                        <h3>Business</h3>
                        <p>For profit company with a big heart</p>
                    </div>
                </div>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>

import {Component, OnInit} from '@angular/core';
import {ModalController} from "@ionic/angular";

@Component({
  selector: 'app-info',
  templateUrl: './info-org-modal.page.html',
  styleUrls: ['./info-org-modal.page.scss'],
})
export class InfoOrgModalPage implements OnInit {
  constructor(private modal: ModalController) { }

  ngOnInit() {
  }

  dismissModal() {
    this.modal.dismiss();
  }

}

<ion-content class="ion-padding">
    <ion-grid>
        <ion-row>
            <ion-col>
                <ion-label>
                    <h1>Administer DB Rules</h1>
                </ion-label>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col>
                <ion-button color="primary" [disabled]="disableForm" (click)="handleClick('updateRules')">
                    <ion-icon slot="start" name="bar-chart"></ion-icon>
                    Update Recommendation Rules
                </ion-button>
            </ion-col>
        </ion-row>
        <form [formGroup]="form.getForm()" class="main-form add-need" (keydown.enter)="$event.preventDefault()">
            <ion-row>
                <ion-col>
                    <tag-input [disabled]="disableForm" [formControlName]="'existing_user'"
                               [onlyFromAutocomplete]="true" [identifyBy]="'id'" [displayBy]="'name'"
                               [validators]="allowedUserValidators" [disable]="!availableUsers"
                               [placeholder] = '' [maxItems]="1"
                               [secondaryPlaceholder]="'Enter an existing user email'" #input>
                        <tag-input-dropdown [matchingFn]="tagMatching" [autocompleteItems]="availableUsers" [identifyBy]="'id'"
                                            [displayBy]="'name'"></tag-input-dropdown>
                        <ng-template let-item="item" let-index="index">
                            <span>
                                <ion-label>{{item.email}}</ion-label>
                            </span>
                            <ion-icon name="close-circle" (click)="input.removeItem(item, index)"></ion-icon>
                        </ng-template>
                    </tag-input>
                    <ion-note color="danger" *ngIf="form.hasError('existing_user')">
                        {{ form.getError('existing_user')}}
                    </ion-note>
                </ion-col>
                <ion-col>
                    <ion-button [disabled]="disableForm" color="primary" (click)="handleClick()">
                        <ion-icon slot="start" name="bar-chart"></ion-icon>
                        Generate Recommendations for User
                    </ion-button>
                </ion-col>
            </ion-row>
    </form>
    </ion-grid>
</ion-content>

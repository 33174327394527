import { Resource } from './resource';
import { UserResource } from './user.resource';

export class FamilyResource extends Resource {
    id: number = null;

    user_id: number = null;

    user: UserResource = null;

    first_name: string = null;

    last_name: string = null;

    gender: string = null;

    email: string = null;

    phone: string = null;

    bio: string = null;

    zip: number = null;

    cover_image : string = null;

    death_certificate : string = null;

    created_at: string = null;

    updated_at: string = null;

    representatives: UserResource[] = [];

    share_url: string = null;

    entity_id: number = null;
    inner_circle: UserResource[] = [];
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { IonicModule } from '@ionic/angular';
import { NgxPermissionsModule } from 'ngx-permissions';

import { CauseGroupListComponent } from './cause-group-list/cause-group-list.component';
import { CauseGroupEditComponent } from './cause-group-edit/cause-group-edit.component';
import { CauseGroupDashboardComponent } from './cause-group-dashboard/cause-group-dashboard.component';
import { CauseGroupUserPrivilegeComponent } from './cause-group-user-privilege/cause-group-user-privilege.component';

import { CauseGroupsRoutingModule } from './cause-groups-routing.module';
import {FileUploadComponentModule} from "../../../shared/components/file-upload/file-upload.component.module";
import {BackgroundChecksPageModule} from "./background-checks/background-checks.module";
import {CauseGroupUpdateRulesComponent} from "./cause-group-update-rules/cause-group-update-rules.component";
import {TagInputModule} from "ngx-chips";
import {NeedsPageModule} from "./needs/needs.module";

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    NgxPermissionsModule.forChild(),
    FormsModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    CauseGroupsRoutingModule,
    FileUploadComponentModule,
    BackgroundChecksPageModule,
    TagInputModule,
    NeedsPageModule
  ],
  declarations: [
    CauseGroupListComponent,
    CauseGroupEditComponent,
    CauseGroupDashboardComponent,
    CauseGroupUserPrivilegeComponent,
    CauseGroupUpdateRulesComponent,
  ],
})
export class CauseGroupsModule {}

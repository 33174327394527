export const Environment = {
  production: false,
  server: 'https://v2-api.link2hope.com/',
  apiUrl: 'api/',
  perPage: 20,
  isTestEnv: false,
  mapboxApiUrl: 'https://api.mapbox.com/geocoding/v5/mapbox.places/',
  mapbox_Token: 'pk.eyJ1IjoicGFzc2lvbmRldiIsImEiOiJjandqem9xbmkwaDgxNGFxZ2VwcXlmaXlzIn0.LnISKimehOGVZtLucxbZng',
  hostMap: {
    'roanoke.link2hope.com' : 'roanoke',
    'mtn.link2hope.com' : 'mtn'
  }
};

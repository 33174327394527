<ion-header>
  <ion-toolbar>
    <ion-title class="pdl-16">Statement of Intent</ion-title>
      <ion-buttons slot="end">
          <ion-button (click)="dismissModal()" color="dark">Close</ion-button>
      </ion-buttons>
  </ion-toolbar>
</ion-header>


<ion-content  class="ion-padding">
    <h2>Statement of Intent of Participating Service Providers</h2>
    <p>
        1. You want to use {{slugData}} to conduct your charitable activities more efficiently, help more families in need, and connect with partner organizations and individuals.
    </p>
    <p>
        2. You understand that the {{slugData}} network includes many Christian churches <a class="text-link" (click)="showFaithModal()">who have agreed to our statement of faith</a> and your organization is willing to work with churches as a source of volunteers and resources.
    </p>
</ion-content>

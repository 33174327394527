import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResourceService } from '../resource.service';
import { UserResource } from '../../resources/user.resource';
import { ResourceSerializer } from '../../serializers/resource-serializer';
import { ListResponse } from '../../resources/list-response';

@Injectable()
export class FriendService extends ResourceService<UserResource> {
    static resourceSlug: string = 'friend';

    constructor(httpClient: HttpClient, endpoint: string = FriendService.resourceSlug) {
      super(
        httpClient,
        endpoint,
        new ResourceSerializer(UserResource),
      );
    }

    listMutual(friendUserId: number): Observable<ListResponse<UserResource>> {
      return this.httpClient
        .get(`${this.url}${this.endpoint}/mutual/${friendUserId}`)
        .pipe(map((response: any) => {
          const listResponse = new ListResponse<UserResource>();
          listResponse.metadata = response.metadata;
          listResponse.data = this.convertData(response.data);

          return listResponse;
        }));
    }
}

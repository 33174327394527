<ion-content >
    <ion-row>
      <ion-col>
        <ion-datetime
            #popoverDate
            [(ngModel)]="existingDate"
            presentation="date"
            showDefaultButtons=true
            (ionChange)="selectPopover(popoverDate.value);popoverDate.confirm(true);"
        >
        </ion-datetime>
      </ion-col>
    </ion-row>
</ion-content>
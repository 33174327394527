import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TitleCasePipe } from '@angular/common';
import { Storage } from '@ionic/storage';
import { ToastController } from '@ionic/angular';

import {
  ColumnMode, SortDirection, SortPropDir, SortType,
} from '@swimlane/ngx-datatable';
import { CauseGroupService } from '../../../../api/services/cause-group.service';
import { CauseGroupResource } from '../../../../api/resources/cause-group.resource';

import { Page } from '../../../../../models/page';
import { Environment as Env } from '../../../../../environments/environment.prod';


@Component({
  selector: 'cause-group-list',
  templateUrl: './cause-group-list.component.html',
  styleUrls: ['./cause-group-list.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CauseGroupListComponent implements OnInit {
    private currentUser: any = {};

    private userId: number;

    public isLoading: boolean = false;

    public causeGroups: CauseGroupResource[];

    public columnMode: ColumnMode = ColumnMode.force;

    public sortType: SortType = SortType.multi;

    private titleCasePipe: TitleCasePipe;

    public page: Page;

    public sorts: SortPropDir[];

    private causeGroupService: CauseGroupService;

    constructor(private httpClient: HttpClient,
                private route: ActivatedRoute,
                private router: Router,
                private storage: Storage,
                private toast: ToastController) {
      this.titleCasePipe = new TitleCasePipe();
      this.page = new Page();
      this.page.pageNumber = 0;
      this.page.size = Env.perPage;
      this.sorts = [
        { prop: 'name', dir: SortDirection.asc },
      ];
    }

    ngOnInit() {
      this.route.params.subscribe((params) => {
        this.userId = params.hasOwnProperty('id') ? parseInt(params.id) : null;

        this.storage.get('loginRes').then((val) => {
          this.currentUser = val.user;

          const causeGroupEndpoint = this.userId ? `user/${this.currentUser.id}/cause-group` : CauseGroupService.resourceSlug;
          this.causeGroupService = new CauseGroupService(this.httpClient, causeGroupEndpoint);

          this.loadCauseGroups();
        });
      });
    }

    private loadCauseGroups(): void {
      this.isLoading = true;

      let params = new HttpParams()
        .set('page', (this.page.pageNumber + 1).toString())
        .set('per_page', this.page.size.toString());
      this.sorts.forEach((sort, index) => {
        for (const key in sort) {
          params = params.set(`sorts[${index}][${key}]`, sort[key].toString());
        }
      });

      this.causeGroupService
        .list(params)
        .toPromise()
        .then((value) => {
          this.causeGroups = value.data;

          this.page.totalElements = value.metadata.count;
          this.page.totalPages = value.metadata.last_page;
        })
        .catch((errorResponse) => {
          this.toast.create({
            header: 'Load error',
            message: errorResponse.error.message,
            buttons: [
              {
                text: 'Close',
                role: 'cancel',
                handler: () => {}
              }
            ],
            color: 'danger',
          }).then((toast) => toast.present());
        })
        .finally(() => { this.isLoading = false; });
    }

    public setPage(pageInfo): void {
      this.page.pageNumber = pageInfo.offset;
      this.loadCauseGroups();
    }

    onSort(event): void {
      this.sorts = event.sorts;
      this.page.pageNumber = 0;
      this.loadCauseGroups();
    }
}

import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse,
} from '@angular/common/http';
import {from, throwError, Observable, of} from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from '../../providers/auth/auth.service';
import { Environment as Env } from '../../environments/environment.prod';
import {StateManagementService} from "../api/services/state-management-service.service";

// @todo: https://github.com/auth0/angular2-jwt/blob/master/src/jwt.interceptor.ts

@Injectable()
export class HttpAuthTokenInterceptor implements HttpInterceptor {
    whitelist: Array<RegExp> = [];

    blacklist: Array<RegExp> = [];

    constructor(public auth: AuthService, private router: Router, private appState: StateManagementService) {
      const serverApi = Env.server + Env.apiUrl;

      // Handle all api requests
      this.whitelist.push(new RegExp(`^${serverApi}`));
      // ... except login and refresh token
      this.blacklist.push(new RegExp(`^${serverApi}login(\/\w*)?$`));
      this.blacklist.push(new RegExp(`^${serverApi}maintenance-mode$`));
      this.blacklist.push(new RegExp(`^${serverApi}welcome(\/\w*)?$`));
      this.blacklist.push(new RegExp(`^${serverApi}token/refresh$`));
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      if (!this.isNeedHandle(request)) {
        return next.handle(request);
      }

      return from(this.auth.getToken())
        .pipe(mergeMap((token) => {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
            },
          });

          return next.handle(request).pipe();
        })).pipe(catchError((error) => {
          if (error instanceof HttpErrorResponse && error.status === 503) {
            this.router.navigateByUrl('/maintenance-mode');
            return of({}) as Observable<HttpEvent<any>>;
          }
          if (error instanceof HttpErrorResponse && error.status === 401) {
            console.log('401 logout');
            this.appState.update({isHidden:true});
            this.logout();
            return of({}) as Observable<HttpEvent<any>>;
          } else {
            return throwError(error);
          }
        }));
    }

    isNeedHandle(request: HttpRequest<any>) {
      return this.whitelist.some((pattern) => pattern.test(request.url))
            && !this.blacklist.some((pattern) => pattern.test(request.url));
    }

    async logout() {
      const causeGroup =  await this.auth.getCauseGroup();
      await this.router.navigateByUrl(`/login/${causeGroup}`)
    }
}

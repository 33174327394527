import {Pipe, PipeTransform, SecurityContext} from "@angular/core";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";

@Pipe({
  name: 'allowStyles',
})
export class AllowStylesPipe implements PipeTransform
{
  constructor( private sanitizer: DomSanitizer){}
  transform(value: string): SafeHtml {
      return this.sanitizer.sanitize(SecurityContext.HTML, value);
    }
}

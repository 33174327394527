/* eslint-disable camelcase */
import { Resource } from './resource';

export class UserAnswerResource extends Resource {
    answer_id: number = null;
    answer: string = null;
    question_id: number = null;
    question: string = null;
    question_order: number = null;
    question_type: string = null;
    question_set_id: number = null;
    question_set_name: string = null;
    question_set_category: string = null;
}


export class Resource {
    public id: number|string = null;

    public _meta: {
        parentSlug: string,
        resourceName: string,
    } = {
      parentSlug: '',
      resourceName: '',
    };
}

/* eslint-disable camelcase */
import { Resource } from './resource';
import { EntityResource } from './entity.resource';
import { FamilyResource } from './family.resource';
import { UserResource } from './user.resource';
import {OccurrenceRsvpResource} from "./occurrence-rsvp.resource";

export class NeedResource extends Resource {
    static readonly ACCESS_PRIVATE = 'private'; // specific users in cause group
    static readonly ACCESS_SEMI_PRIVATE = 'semi-private'; // close friends
    static readonly ACCESS_PUBLIC = 'public'; // volunteers in cause group

    // access_detail
    static readonly ACCESS_SEMI_INNER = 'semi-private-inner'; // close friends
    static readonly ACCESS_SEMI_ADVOCATE = 'semi-private-advocate'; // friends and adv
    static readonly ACCESS_SEMI_CHURCH = 'semi-private-church'; // plus church
    static readonly ACCESS_SEMI_VOLUNTEER = 'semi-private-volunteer'; // plus vols
    static readonly ACCESS_SEMI_PROVIDER = 'semi-private-provider'; // plus prov
    static readonly ACCESS_SEMI_BUSINESS = 'semi-private-business'; // plus business

    static readonly TYPE_VOLUNTEER = 'volunteer';
    static readonly TYPE_ITEM = 'item';

    id : number = null;

    parent_family_id : number = null;

    family : FamilyResource = null;

    title : string = null;

    description : string = null;

    link : string = null;

    type: string = null;

    date_type : string = null;

    start_date : string = null;

    end_date : string = null;

    interval : number = null;

    street : string = null;

    city : string = null;

    state : string = null;

    zip : string = null;

    lat: number = null;

    lng: number = null;

    distance: number = null;
    driver_license : boolean = null;

    background_check_required : boolean = null;

    orientation_training : boolean = null;

    cover_image : string = null;

    min_age : number = null;

    volunteers : number = null;

    items : number = null;

    created_at: string = null;

    updated_at: string = null;

    occurrences: any = null;

    entity_id: number = null;

    entity: EntityResource = null;

    access: string = null;

    access_detail: string = null;

    access_church: boolean = null;

    allowed_users: UserResource[] = [];

    occurrence_rsvps: OccurrenceRsvpResource[] = [];

    commitment_amount:number = null;

    status: string = null;

    rsvpsApproved: string = null;
    rsvpsPending: string = null;
    rsvpsCompleted: string = null;
    rsvpsTotal: string = null;
    rsvpsCompletePercent: string = null;
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {lastValueFrom, Observable, of} from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
import { NgxRolesService, NgxPermissionsService } from 'ngx-permissions';
import { Environment as Env } from '../../environments/environment.prod';
import { UserResource } from '../../app/api/resources/user.resource';
import {GetCauseGroupService} from "../get-cause-group.service";
/** Models * */

// @todo: https://www.npmjs.com/package/@auth0/angular-jwt

@Injectable()
export class AuthService {
    serverApi: string;

    refreshGap: number; // time gap for refreshing token in the end of token ttl

    refreshInProgress: Promise<any>;

    redirectUrl: string;

    constructor(
        private storage: Storage,
        private http: HttpClient,
        private rolesService: NgxRolesService,
        private permissionsService: NgxPermissionsService,
        private getCauseGroupService: GetCauseGroupService,
    ) {
      this.serverApi = Env.server + Env.apiUrl;
      this.refreshGap = 2 * 60 * 1000; // 2 minutes in ms
    }


    /**
     * @desc Login
     * @params { email: any; password: any; causeGroup: any; }
     * @returns { 'status':200,
     *    'access_token':'access_token',
     *    'token_type':'bearer',
     *    'expires_in':3600
     *    }
     *
     *    data stored is token_received
     */
    signinUser(params: { email: any; password: any; causeGroup: any; }): Observable<any> {
      const data = JSON.stringify({
        email: params.email,
        password: params.password,
        causeGroup: (params.causeGroup).toLowerCase()
      });

      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      };
      return this.http.post<any>(`${this.serverApi}login`, data, httpOptions)
        .pipe(
          catchError((_) => of({ status: 505, message: 'There was a general error connecting with the server' })),
        );
    }

    async logout(): Promise<string> {
      try {
        const post$ = this.http.post(`${this.serverApi}logout`, null);
        await lastValueFrom(post$);
      } catch(err){
        console.error('Logout');
      }
      return await this.killTokens();
    }

    /**
     * @desc REFRESH THE TOKEN SINCE IT IS SHORT LIVED.  TESTING TESTING!!!!!!
     *
     * @param token
     * @returns  Observable<any>
     */
    refreshToken(token): Promise<any> {
      const data = null;

      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
      };

      return this.http.post(`${this.serverApi}token/refresh`, data, httpOptions).toPromise().then((val) => val).catch((err) => Promise.reject(err.json().error || 'Server error'));
    }

    getToken(preventRefresh: boolean = false): Promise<any> {
      return this.storage.get('token_data')
        .then(async (v) => {
          const p = JSON.parse(v);
          if (!p) {
            return;
          }

          try {
            const tokenExpiresAt = parseInt(p.token_origin, 10) + p.expires_in * 1000;
            const nowWithGap = Date.now() + this.refreshGap;
            // Token is already expired
            if (tokenExpiresAt < Date.now()) {
              console.info('Token has expired.');
              await this.killTokens()
              return;
            } if (tokenExpiresAt > nowWithGap) {
              // Token is valid
              return p.access_token;
            }

            // Token is valid but will expires soon so try to refresh it
            if (!this.refreshInProgress) {
              const requestTime = Date.now();
              this.refreshInProgress = this.refreshToken(p.access_token)
                .then((tokenData) => {
                  // TODO:  need to check if this is working properly
                  delete tokenData.status;
                  tokenData.token_received = requestTime;
                  tokenData.token_origin = requestTime;
                  this.storage.set('token_data', JSON.stringify(tokenData))
                    .then(() => {
                    });

                  // need to test this several times to make
                  return tokenData.access_token;
                })
                .catch(async (e) => {
                  console.error(e);
                  await this.killTokens() //this.logout();
                });
            }

            return this.refreshInProgress;
          } catch (e) {
            console.error(e);
            await this.killTokens();
          }
        });
    }

    async isAuthenticated(): Promise<boolean> {
      const token = await this.getToken();
      return token && true; // && !isTokenExpired();
    }

    async killTokens(): Promise<string> {
      // this.storage.remove('settings');
      const causeGroup = await this.getCauseGroup();
      await this.storage.remove('routes');
      await this.storage.remove('loginRes');
      await this.storage.remove('regquestions');
      await this.storage.remove('token_data').then(() => this.storage.remove('token_received'));
      await this.storage.remove('loggedUser');
      await this.storage.remove('selectedUser');
      await this.storage.remove('encouragementSteps');
      await this.storage.remove('orgAdminIncompleteMsg');
      await this.storage.remove('clientID');
      await this.storage.remove('firstFamilyId');
      await this.storage.remove('isFamilyBioExist');
      await this.storage.remove('justRegistered');

      return causeGroup;
    }

    initRolesAndPermissions(user: UserResource) {
      const roles = {};
      let permissionsViaRoles: string[] = [];
      let permissionsViaCauseGroups: string[] = [];
      const permissions: string[] = user.permissions.map((permission) => permission.name);

      user.roles.forEach((role) => {
        const permissions = role.permissions.map((permission) => permission.name);
        roles[role.name] = permissions;
        permissionsViaRoles = [...permissionsViaRoles, ...permissions]; // merge
      });
      permissionsViaRoles = Array.from(new Set(permissionsViaRoles).values()); // unique values

      user.causeGroups.forEach((causeGroup) => {
        if (causeGroup.permissions) {
          const permissions = causeGroup.permissions.map((permission) => permission.name);
          permissionsViaCauseGroups = [...permissionsViaCauseGroups, ...permissions]; // merge
        }
      });
      permissionsViaCauseGroups = Array.from(new Set(permissionsViaCauseGroups).values()); // unique values

      let allPermissions: string[] = [...permissions, ...permissionsViaRoles, ...permissionsViaCauseGroups]; // merge
      allPermissions = Array.from(new Set(allPermissions).values()); // unique values

      this.permissionsService.loadPermissions(allPermissions);
      this.rolesService.addRoles(roles);
    }

    /**
     * Send forgot password email
     *
     */
    forgotPassword(email) {
      const data = JSON.stringify({ email });
      const url = `${this.serverApi}password/email`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      };
      return this.http.post<any>(url, data, httpOptions);
    }

    resetPassword(data) {
      const dataStr = JSON.stringify(data);
      const url = `${this.serverApi}password/reset`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      };
      return this.http.post<any>(url, dataStr, httpOptions);
    }
    
    createPassword(data, causeGroup) {
      const dataStr = JSON.stringify(data);
      const url = `${this.serverApi}create-password/${causeGroup}`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      };
      return this.http.post<any>(url, dataStr, httpOptions);
    }

    inviteUser(data)
    {

        const dataStr = JSON.stringify(data);
        const url = `${this.serverApi}invite-register`;

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };
        return this.http.post<any>(url, dataStr, httpOptions);
    }

    inviteRegisterFamily(familyId: number) : Observable<any> {
      const httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
        }),
      };
      return this.http.get(`${this.serverApi}public/family/${familyId}`,  httpOptions);
    }

    async getCauseGroup(): Promise<string> {
      return await this.getCauseGroupService.get();
    }
}

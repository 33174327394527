import { Component, OnInit } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import {Storage} from "@ionic/storage";
import {ToastController} from "@ionic/angular";
import {Page} from "../../../../../models/page";
import {ColumnMode, SortDirection, SortPropDir, SortType} from "@swimlane/ngx-datatable";
import {CauseGroupResource} from "../../../../api/resources/cause-group.resource";
import {TitleCasePipe} from "@angular/common";
import {ValidationManager} from "ng2-validation-manager";
import {NeedService} from "../../../../api/services/need.service";
import {RsvpCalculatorService} from "../../../../shared/services/rsvp-calculator.service";
import {format, getDate, subDays} from "date-fns";
import {CauseGroupService} from "../../../../api/services/cause-group.service";
const DATE_FORMAT_ISO = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'";
@Component({
  selector: 'app-needs',
  templateUrl: './needs.page.html',
  styleUrls: ['./needs.page.scss'],
})
export class NeedsPage implements OnInit {


  private currentUser: any = {};

  private causeGroupId: number;

  public isLoading: boolean = false;

  public rsvpCalculatorService: RsvpCalculatorService = new RsvpCalculatorService();

  public columnMode: ColumnMode = ColumnMode.force;

  public sortType: SortType = SortType.multi;

  private titleCasePipe: TitleCasePipe;

  public page: Page;

  public form:ValidationManager;

  public sorts: SortPropDir[];

  needs;

  public todayDate : any;


  private needService: NeedService;

  constructor(private httpClient: HttpClient,
              private route: ActivatedRoute,
              private router: Router,
              private storage: Storage,
              private toast: ToastController) {

    this.titleCasePipe = new TitleCasePipe();
    this.page = new Page();
    this.page.pageNumber = 0;
    this.page.size = 10;
    this.sorts = [
      { prop: 'need.id', dir: SortDirection.desc },
    ];

    this.form = new ValidationManager({
      keyword: 'rangeLength:2,50',
      need_status: '',
      start_date: 'date',
      end_date: 'date',

    });



  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.causeGroupId = params.hasOwnProperty('causeGroupId') ? parseInt(params.causeGroupId) : null;

      this.storage.get('loginRes').then((val) => {
        this.currentUser = val.user;
        const causeGroup: CauseGroupResource = this.currentUser.causeGroups[0];
        const endpoint = causeGroup ? `cause-group/${causeGroup.id}/needs` : NeedService.resourceSlug;
        this.needService = new NeedService(this.httpClient, endpoint);

        this.todayDate =   format(new Date(), DATE_FORMAT_ISO);
        this.loadNeeds();

        const startDateTime = subDays(new Date(), 730);
        const endDateTime = new Date();
        this.form.setValue({
          start_date: format(startDateTime, "yyyy-MM-dd'T'HH:mm:ss"),
          end_date: format(endDateTime, "yyyy-MM-dd'T'HH:mm:ss"),
        });
      });
    });
  }

  private loadNeeds()
  {
    this.isLoading = true;

    let params = new HttpParams()
        .set('page', (this.page.pageNumber + 1).toString())
        .set('per_page', this.page.size.toString());
    this.sorts.forEach((sort, index) => {
      for (const key in sort) {
        params = params.set(`sorts[${index}][${key}]`, sort[key].toString());
      }
    });

    let formData = this.form.getData();
    const searchData = { ...formData };


    console.log(searchData)

    for (const key in searchData) {
      if (searchData[key] !== '' && searchData[key] !== null) params = params.set(key, (searchData[key].toString()));
    }

    this.needService
        .list(params)
        .toPromise()
        .then((value) => {

          this.needs = value.data;
          this.page.totalElements = value.metadata.count;
          this.page.totalPages = value.metadata.last_page;


        })
        .catch((errorResponse) => {
          this.toast.create({
            header: 'Load error',
            message: errorResponse.error.message,
            buttons: [
              {
                text: 'Close',
                role: 'cancel',
                handler: () => {}
              }
            ],
            color: 'danger',
          }).then((toast) => toast.present());

        })
        .finally(() => { this.isLoading = false; });

  }

  public setPage(pageInfo): void {
    this.page.pageNumber = pageInfo.offset;
    this.loadNeeds();
  }

  onSort(event): void {
    this.sorts = event.sorts;
    this.page.pageNumber = 0;
    this.loadNeeds();
  }

  async export(exportType : 'needs')
  {
    if (!this.form.getValue('start_date')) {
      this.form.setValue({
        start_date: subDays(new Date(), +  (4 * 365) ).toISOString(),
        end_date: new Date().toISOString(),
      });
    }

    if (this.form.isValid()) {
      this.isLoading = true;


      const formData = this.form.getData();
      const searchData = { ...formData };

      let params = new HttpParams();
      for (const key in searchData) {
        if (searchData[key] !== '' && searchData[key] !== null) params = params.set(key, encodeURIComponent(searchData[key].toString()));
      }
      params = params.set('type', encodeURIComponent(exportType));

      const options = {
        params,
      };

      new CauseGroupService(this.httpClient)
          .export(this.causeGroupId, options)
          .toPromise()
          .then((data:any) => {
            window.open(data.url, '_blank');
          })
          .catch((errorResponse) => {
            this.toast.create({
              header: 'Search error',
              message: errorResponse.error.message,
              buttons: [
                {
                  text: 'Close',
                  role: 'cancel',
                  handler: () => {}
                }
              ],
              color: 'danger',

            }).then((toast) => toast.present());
          })
          .finally(() => {
            this.isLoading = false;
          });
    }
  }

  async search() {
      if (this.form.isValid) {
        this.loadNeeds();
      }
  }


  protected readonly getDate = getDate;
}

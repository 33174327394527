import { Resource } from './resource';

export class TagResource extends Resource {
    id: number = null;

    name: string = null;

    slug: string = null;

    category: string = null;

    cause_group_id: number = null;

    created_at: string = null;

    updated_at: string = null;

    deleted_at: string = null;
}

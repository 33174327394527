import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from 'src/providers/auth/auth-guard.service';
import { NgxPermissionsGuard } from 'ngx-permissions';

import { UserListComponent } from './user-list/user-list.component';
import { UserEditComponent } from './user-edit/user-edit.component';
import {UserDocumentsComponent} from "./user-documents/user-documents.component";
import {UserRemoveComponent} from "./user-remove/user-remove.component";

const usersRoutes: Routes = [
  {
    path: 'admin/users',
    component: UserListComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: 'users_list',
      },
    },
  },
  {
    path: 'admin/user/add',
    component: UserEditComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: 'users_add',
      },
    },
  },
  {
    path: 'admin/user/:id',
    component: UserEditComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['users_edit', 'cause-groups_users_edit'],
      },
    },
  },
  {
    path: 'admin/user/remove/:id',
    component: UserRemoveComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['users_edit', 'cause-groups_users_edit'],
      },
    },
  },
  {
    path: 'admin/user/documents/:id',
    component: UserDocumentsComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['users_edit', 'cause-groups_users_edit'],
      },
    },
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(usersRoutes),
  ],
  exports: [
    RouterModule,
  ],
})
export class UsersRoutingModule { }

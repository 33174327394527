<ion-content>
  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-label>
          <h1>Needs</h1>
        </ion-label>

      </ion-col>
        <ion-button (click)="export('needs')" >Export</ion-button>

    </ion-row>
      <ion-row>
          <ion-col>
              <form [formGroup]="form.getForm()" (ngSubmit)="search()">
                  <ion-list>
                      <ion-row>
                          <ion-col size="6">
                              <ion-item class="item-input">
                                  <ion-label class="txt3" position="stacked">Keyword</ion-label>
                                  <ion-input formControlName="keyword" placeholder="Keyword" type="search"></ion-input>
                                  <ion-note color="danger" *ngIf="form.hasError('keyword')">
                                      {{ form.getError('keyword')}}
                                  </ion-note>
                              </ion-item>
                          </ion-col>
                          <ion-col size="6">
                            <ion-item class="item-input" >
                              <ion-label position="stacked" class="ion-no-padding txt-3">Completed Status</ion-label>
                              <ion-select placeholder="All Types" class="ion-no-padding pdb-2" (ionChange)='search()' formControlName="need_status" >
                                <ion-select-option value="">All Types</ion-select-option>
                                <ion-select-option value="notmet">Not Met</ion-select-option>
                                <ion-select-option value="partiallymet">Partially Met</ion-select-option>
                                <ion-select-option value="fullymet">Fully Met</ion-select-option>
                              </ion-select>
                            </ion-item>
                          </ion-col>
                      </ion-row>
                      <ion-row>
                        <ion-col size="6">
                          <ion-item class="item-input">
                            <ion-label class="txt3" position="stacked">Start date</ion-label>
                            <ion-item id="start-date-input" class="ion-text-end">
                              <ion-label>{{form.getValue('start_date') | date:'MM/dd/YY'}}</ion-label>
                            </ion-item>
                            <ion-popover trigger="start-date-input" show-backdrop="false">
                              <ng-template>
                                <ion-datetime
                                    formControlName="start_date"
                                    presentation="date"
                                    showDefaultButtons=true
                                    [max]="form.getValue('end_date')"
                                ></ion-datetime>
                              </ng-template>
                            </ion-popover>
                            <ion-note color="danger" *ngIf="form.hasError('start_date')">
                              {{ form.getError('start_date')}}
                            </ion-note>
                          </ion-item>
                        </ion-col>
                        <ion-col size="6">
                          <ion-item class="item-input">
                            <ion-label class="txt3" position="stacked">End date</ion-label>
                            <ion-item id="end-date-input" class="ion-text-end">
                              <ion-label>{{form.getValue('end_date') | date:'MM/dd/YY'}}</ion-label>
                            </ion-item>
                            <ion-popover trigger="end-date-input" show-backdrop="false">
                              <ng-template>
                                <ion-datetime
                                    formControlName="end_date"
                                    presentation="date"
                                    showDefaultButtons=true
                                    [min]="form.getValue('start_date')"
                                ></ion-datetime>
                              </ng-template>
                            </ion-popover>
                            <ion-note color="danger" *ngIf="form.hasError('end_date')">
                              {{ form.getError('end_date')}}
                            </ion-note>
                          </ion-item>
                        </ion-col>
                      </ion-row>
                          <ion-row class="ion-text-center" >
                              <ion-col>
                                  <ion-button [disabled]="!form.getForm().valid " type="submit" expand="block">
                                      <ion-icon slot="start" name="analytics"></ion-icon>
                                      Show
                                  </ion-button>
                              </ion-col>
                          </ion-row>
                  </ion-list>
              </form>
          </ion-col>
      </ion-row>
    <ion-row *ngIf="isLoading; else userBlock" class="ion-justify-content-center">
      <ion-col size="auto">
        <ion-spinner></ion-spinner>
      </ion-col>
    </ion-row>
    <ng-template #userBlock>
      <ion-row>
        <ion-col>
          <ngx-datatable

                  [rows]="needs"
                  [loadingIndicator]="isLoading"

                  class="material striped"
                  [columnMode]="columnMode"
                  headerHeight="50"
                  rowHeight="auto"
                  footerHeight="50"
                  scrollbarH="true"

                  [externalPaging]="true"
                  [count]="page.totalElements"
                  [offset]="page.pageNumber"
                  [limit]="page.size"
                  (page)="setPage($event)"

                  [sorts]="sorts"
                  [sortType]="sortType"
                  [externalSorting]="true"
                  (sort)="onSort($event)"
          >
          <ngx-datatable-column name="Title" prop="title" [sortable]="true">
              <ng-template let-value="value" ngx-datatable-cell-template let-row='row'>
                  {{row.title}} (#{{row.id}})
                <small *ngIf="row.status == 'pending'" class="txt-danger ion-clear">
                  <br/>
                  [ <i [ngClass]="'ion-md-alert'"></i> PENDING ]
                </small>
              </ng-template>
          </ngx-datatable-column>
            <ngx-datatable-column name="Description" >
                <ng-template let-value="value" ngx-datatable-cell-template let-row='row'>
                    {{row.description}} <br/>
                  <ion-chip *ngFor="let item of row.entity.tags">
                    {{item.slug}}
                  </ion-chip>

                </ng-template>
            </ngx-datatable-column>
          <ngx-datatable-column name="Type / Qty" prop="items"  >
              <ng-template let-value="value" ngx-datatable-cell-template let-row='row'>

                <ion-text *ngIf="row.volunteers > 0 "> {{row.volunteers}} volunteers needed <br/>/ {{row.volunteers - rsvpCalculatorService.getDecreasingOccurrenceRsvps((row.occurrence_rsvps), 'all')?.length }} remaining
                </ion-text>
                <ion-text *ngIf="row.items > 0 "> {{row.items}} items needed <br/>/
                  {{ rsvpCalculatorService.calculateRemaining(row,'all') }}
                  remaining
                </ion-text>
                <ion-text > <br/>/ {{rsvpCalculatorService.getDecreasingOccurrenceRsvps((row.occurrence_rsvps), 'completed')?.length }} completed </ion-text>

                <ion-text [ngClass]="{
                    'txt-success': rsvpCalculatorService.needStatus(row) === 'Fully Met',
                    'txt-danger': rsvpCalculatorService.needStatus(row) === 'Not Met',
                    'txt-warning' :  rsvpCalculatorService.needStatus(row) === 'Partially Met'
                    }">
                  <br/>[ <i [ngClass]="'ion-md-alert'"></i> {{rsvpCalculatorService.needStatus(row)}} ]
                </ion-text>

              </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Date" prop="zip" >
            <ng-template let-value="value" ngx-datatable-cell-template let-row='row'>
              {{row.start_date | date: 'MM/dd/yyyy' }} - {{row.end_date | date: 'MM/dd/yyyy'}}
              <ion-text *ngIf="row.end_date < todayDate  " class="txt-danger ion-clear">
                <br/>[ <i [ngClass]="'ion-md-alert'"></i> EXPIRED]
              </ion-text>
            </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Family" prop="family.last_name" >
              <ng-template let-value="value" ngx-datatable-cell-template let-row='row'>
              {{ row.family?.first_name }} {{ row.family?.last_name }}<br>
              {{ row.family?.email }}<br/>
              {{ row.family?.phone }}, {{ row.family?.zip }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Actions" [sortable]="false" width="200">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <ion-button [routerLink]="['/dashboard/show-need', row.id]" size="small" *ngIf="row.id">
                    <ion-icon name="eye"></ion-icon>
                    View Need
                  </ion-button>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </ion-col>
      </ion-row>
    </ng-template>
  </ion-grid>
</ion-content>

/**
 Notes
 * */
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {lastValueFrom, Observable} from 'rxjs';
import { map } from 'rxjs/operators';
import { Environment as Env } from '../environments/environment.prod';

@Injectable()
export class RecommendationsService {
    serverApi: string;

    constructor(private storage: Storage, private _http:HttpClient) {
      this.serverApi = Env.server + Env.apiUrl;
    }

    fetchRecommendations(userId: number, params: HttpParams = null): Observable<any> {
      const url = `${Env.server + Env.apiUrl}user/${userId}/recommendations`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        params: params,
      };

      return this._http.get(url, httpOptions).pipe(
        map((res:any) => res.data),
      );
    }

    fetchRecommendationsByType(userId: number, recommendationType: string): Observable<any> {
      const url = `${Env.server + Env.apiUrl}user/${userId}/recommendations/${recommendationType}`;

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      };

      return this._http.get(url, httpOptions).pipe(
        map((res:any) => res.data),
      );
    }

    markAsSeen(userId: number, recommendationId: number): Observable<any> {
      return this.update(userId, recommendationId, { seen: true });
    }

    rate(userId: number, recommendationId: number, rate: number): Observable<any> {
      return this.update(userId, recommendationId, { rate });
    }

    relevant(userId: number, recommendationId: number, relevant: boolean): Observable<any> {
      return this.update(userId, recommendationId, { relevant });
    }

    private update(userId: number, recommendationId: number, updateField: any): Observable<any> {
      const url = `${Env.server + Env.apiUrl}user/${userId}/recommendation/${recommendationId}`;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        params: {},
      };

      return this._http.put(url, updateField, httpOptions).pipe(
        map((res:any) => res.data),
      );
    }

  public processRecommendation(userId: number, cgId:number): Observable<any> {
    const url = `${Env.server + Env.apiUrl}user/${userId}/process-recommendations/${cgId}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      params: {},
    };

    return this._http.get(url, httpOptions).pipe(
      map((res:any) => res.data),
    );
  }

  public updateRules(cgId:number): Observable<any> {
    const url = `${Env.server + Env.apiUrl}recommendations/update-rules/${cgId}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      params: {},
    };

    return this._http.get(url, httpOptions).pipe(
      map((res:any) => res.data),
    );
  }

  async setRecommendationRelevance(userId: number,relevant: boolean, index: number, recommendation: any, recommendations: any[]) {
    const res = await lastValueFrom(this.relevant(userId, recommendation.id, relevant));
    recommendation.relevant = res.relevant;
    recommendations[index] = recommendation;
    recommendations[index].recentlyRated = true;
    if (!relevant) {
      await this.rejectRecommendation(userId, index, recommendation, recommendations);
    }
    if (relevant) {
      recommendations[index].recentlyRated = false;
    }
    return recommendations;
  }

  async rejectRecommendation(userId: number, index: number, recommendation: any, recommendations: any[]) {
    recommendations.splice(index,1);
    await lastValueFrom(this.markAsSeen(userId, recommendation.id));
    return recommendations;
  }
}

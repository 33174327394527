<ion-header>
  <ion-toolbar>
    <ion-title class="pdl-20">Agree to Advocate</ion-title>
      <ion-buttons slot="end">
          <ion-button (click)="dismissModal()" color="dark">Close</ion-button>
      </ion-buttons>
  </ion-toolbar>
</ion-header>


<ion-content class="ion-padding modal-content">
    <ion-button class="round button-circle ion-float-right button-advocate icon-advocate" [disabled]="true" disabled="">
        <ion-icon src="/assets/ion/_advocate.svg" class="custom-icon icon-advocate"></ion-icon>
    </ion-button>

    <ion-text class="add-text ion-float-left" [innerHTML]="causeGroup.advocate_text">

    </ion-text>
</ion-content>

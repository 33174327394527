import { Resource } from './resource';
import { EntityResource } from './entity.resource';

export class CauseGroupResource extends Resource {
    id: number = null;

    name: string = null;

    slug: string = null;

    donation_link: string = null;

    created_at: string = null;

    updated_at: string = null;

    // deleted_at: string = null;
    entity_id: number = null;

    entity: EntityResource = null;

    phone:string = null;
    email:string = null;
    website:string = null;
    cover_image: string = null;
    focus: string = null;
    background_check: boolean = null;
    background_check_link: string = null;
    welcome_text: string = null;
    welcome_resource_type: string = null;
    welcome_resource_link: string = null;
    first_resource_link: string = null;
    family_first_resource_link: string = null;
    advocate_first_resource_link: string = null;
    learning_resource_link: string = null;
    disclaimer: string = null;
    permissions: any[] = [];
    app_icon_name: string = null;
    app_icon_image: string = null;
    statement_faith_link: string = null;
    statement_intent_link: string = null;
    advocate_text: string = null;
    background_check_content: string = null;
    analytics: string = null;
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { IonicModule } from '@ionic/angular';
import { NgxPermissionsModule } from 'ngx-permissions';

import { UserListComponent } from './user-list/user-list.component';
import { UserEditComponent } from './user-edit/user-edit.component';
import { UserRemoveComponent } from './user-remove/user-remove.component';

import { UsersRoutingModule } from './users-routing.module';
import {UserDocumentsComponent} from "./user-documents/user-documents.component";
import { MyCircleAdminModuleComponent } from "../../../shared/components/my-circle-admin/my-circle-admin.module.component";
import {
  MyOrganizationModuleComponent
} from "../../../shared/components/my-organizations/my-organization.module.component";
import {PipesModule} from "../../../shared/pipes/pipes.module";
import {DirectivesModule} from "../../../shared/directives/directives.module";

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        NgxPermissionsModule.forChild(),
        FormsModule,
        PipesModule,
        ReactiveFormsModule,
        NgxDatatableModule,
        UsersRoutingModule,
        MyCircleAdminModuleComponent,
        MyOrganizationModuleComponent,
        DirectivesModule
    ],
  declarations: [
    UserListComponent,
    UserEditComponent,
    UserRemoveComponent,
    UserDocumentsComponent
  ],
})
export class UsersModule {}

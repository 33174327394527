<ion-content>
  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-label>
          <h1>Users</h1>
        </ion-label>

      </ion-col>
        <ion-button (click)="export()">Export</ion-button>
        <ion-button (click)="goToColumnReport('quick_connect')"  color="secondary">Direct Connect </ion-button>

    </ion-row>

    <ng-container *ngxPermissionsOnly="'users_add'">
      <ion-row>
        <ion-col size="auto">
          <ion-button [routerLink]="['/admin/user/add']" size="small">
            <ion-icon slot="start" name="add-circle"></ion-icon>
            Create
          </ion-button>
        </ion-col>
      </ion-row>
    </ng-container>
      <ion-row>
          <ion-col>
              <form [formGroup]="form.getForm()" (ngSubmit)="search()">
                  <ion-list>
                      <ion-row>
                          <ion-col size="12">
                              <ion-item class="item-input">
                                  <ion-label class="txt3" position="stacked">Keyword</ion-label>
                                  <ion-input formControlName="keyword" placeholder="Keyword" type="search"></ion-input>
                                  <ion-note color="danger" *ngIf="form.hasError('keyword')">
                                      {{ form.getError('keyword')}}
                                  </ion-note>
                              </ion-item>
                          </ion-col>

                      <ion-row class="ion-text-center">
                          <ion-col>
                              <ion-button [disabled]="!form.getForm().valid" type="submit" expand="block">
                                  <ion-icon slot="start" name="analytics"></ion-icon>
                                  Show
                              </ion-button>
                          </ion-col>
                      </ion-row>
                      </ion-row>
                  </ion-list>
              </form>
          </ion-col>
      </ion-row>

    <ion-row>
      <ion-col>
        <ngx-datatable
          [rows]="users"
          [loadingIndicator]="isLoadingUsers"

          class="material striped"
          [columnMode]="columnMode"
          headerHeight="50"
          rowHeight="auto"
          footerHeight="50"
          scrollbarH="true"

          [externalPaging]="true"
          [count]="page.totalElements"
          [offset]="page.pageNumber"
          [limit]="page.size"
          (page)="setPage($event)"

          [sorts]="sorts"
          [sortType]="sortType"
          [externalSorting]="true"
          (sort)="onSort($event)"
        >
          <!--<ngx-datatable-column name="ID" prop="id" width="40"></ngx-datatable-column>-->
          <ngx-datatable-column name="Name">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <a [routerLink]="['/user', row.id]" size="small" >
                    {{row.name}}
                </a><br/>
                <div class="hidden-sm-up">
                    {{row.email}}

                    <ion-chip *ngIf="row.verified" color="success">
                        <ion-icon name="checkmark"></ion-icon>
                        <ion-label>verified</ion-label>
                    </ion-chip>
                    <ion-chip *ngIf="!row.verified" color="warning">
                        <ion-icon name="close"  ></ion-icon>
                        <ion-label>not  verified</ion-label>
                    </ion-chip>
                </div>

            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Email" >
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
              <div class="hidden-xs-down" >
                  {{row.email}}
                  <ion-chip *ngIf="row.verified" color="success">
                      <ion-icon name="checkmark"></ion-icon>
                      <ion-label>verified</ion-label>
                  </ion-chip>
                  <ion-chip *ngIf="!row.verified" color="warning">
                      <ion-icon name="close"  ></ion-icon>
                      <ion-label>not  verified</ion-label>
                  </ion-chip>
                  <ion-chip *ngIf="!row.active && row.status === 'invited'" color="danger">
                      <ion-icon name="close"></ion-icon>
                      <ion-label>haven't joined</ion-label>
                  </ion-chip>
              </div>
                  <div class="hidden-sm-up">
                      {{ row.getRoleTitles() }}<br/>
                      <ion-button  [routerLink]="['/admin/cause-group', causeGroupId, 'user', row.id, 'privilege']" size="small">
                          <ion-icon slot="icon-only" name="swap-horizontal"></ion-icon>
                      </ion-button>
                      <ion-button [routerLink]="['/admin/user/', row.id]" size="small">
                          <ion-icon name="create"></ion-icon>
                      </ion-button>
                      <!--removed /admin/user/documents link to documents 9/10/24 -->
                      <ion-button size="small" *ngIf="!row.background_check_completed && checkRoles(row.roles)" color="warning">
                          <ion-icon name="warning"></ion-icon>
                      </ion-button>
                  </div>
              </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Date Created" prop="created_at"  >
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
              {{row.created_at | date}}
            </ng-template>
          </ngx-datatable-column>
          <ng-container  *ngIf="columnField == 'quick_connect'; else showRolesBlock" >
          <ngx-datatable-column name="Direct Connect" prop="quick_connect_date" >
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template >
              <b>Submitted On: </b>{{row.quick_connect_date | date}}<br/>
              {{row.quick_connect}}<br/>
              <b *ngIf="row.quick_connect_reasons">Reasons: </b>{{row.quick_connect_reasons}}<br/>
              <b *ngIf="row.quick_connect_time">Best Contact Time: </b>{{row.quick_connect_time}}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Connect Status" prop="connect_status">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{row.connect_status | titleCase}} <br/>
              <b>Notes: </b>{{row.connect_notes}}
            </ng-template>
          </ngx-datatable-column>
          </ng-container>
          <ng-template #showRolesBlock>
            <ngx-datatable-column name="Roles / Connections" [sortable]="false" >
              <ng-template let-row="row" ngx-datatable-cell-template>
                  <div class="hidden-xs-down">
                      {{ row.getRoleTitles() }}<br/>
                    <ion-chip *ngIf="row.getTotalFriendships() > 0; else noFriends"  color="success" [routerLink]="['/user', row.id]">
                      {{row.getTotalFriendships()}}&nbsp;Connection(s)
                    </ion-chip>
                    <ng-template #noFriends >
                      <ion-chip  color="danger" [routerLink]="['/user', row.id]">
                        {{row.getTotalFriendships()}}&nbsp;Connection(s)
                      </ion-chip>
                    </ng-template>
                  </div>
              </ng-template>
            </ngx-datatable-column>
          </ng-template>
          <ngx-datatable-column name="Actions" [sortable]="false"  class="actions">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template class="actions">
              <ng-container *ngIf="causeGroupId; else showUserBlock">
                <div class="hidden-xs-down">
                  <ion-button  [routerLink]="['/admin/cause-group', causeGroupId, 'user', row.id, 'privilege']" size="small">
                      <ion-icon slot="icon-only" name="swap-horizontal"></ion-icon>
                    </ion-button>
                    <ion-button [routerLink]="['/admin/user/', row.id]" size="small">
                      <ion-icon name="create"></ion-icon>
                    </ion-button>
                    <!-- removed user documents link not currently being used 7-30-24 -->
                    <ng-container *ngxPermissionsOnly="['users_background_check']">
                        <ion-button size="small" *ngIf="!row.background_check_completed && checkRoles(row.roles)" color="warning">
                        <ion-icon name="warning"></ion-icon>
                        </ion-button>
                    </ng-container>
                </div>
              </ng-container>
              <ng-template #showUserBlock>
                <ion-button [routerLink]="['/user', row.id]" size="small">
                  <ion-icon slot="icon-only" name="eye"></ion-icon>
                </ion-button>

              </ng-template>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

import {Component, OnInit} from '@angular/core';
import {AlertController, ModalController, ToastController} from "@ionic/angular";
import {FriendService} from "../../../api/services/friend/friend.service";
import {FriendshipService} from "../../../api/services/friend/friendship.service";
import {FriendshipResource} from "../../../api/resources/friendship.resource";
import {UserResource} from "../../../api/resources/user.resource";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import { Storage } from '@ionic/storage';
import {EncouragePage} from "../../../pages/encourage/encourage.page";
import {HandleAdvocateInnerCircleRemovalService} from "../../services/handle-advocate-inner-circle-removal.service";
import {StateManagementService} from "../../../api/services/state-management-service.service";
import {EncourageService} from "../../../../providers/encourage.service";
import {UserService} from "../../../api/services/user.service";
import {lastValueFrom, Observable, of} from "rxjs";
import {ADVOCATE_EDGE_ID, INNER_CIRCLE_EDGE_ID} from "../../../../const";
import {RoleRelantionship} from "../../../api/resources/role-relantionship.resource";
import {NextEncouragementService} from "../../services/next-encouragement.service";
import {FriendRequestService} from "../../../api/services/friend/friend-request.service";
import {ResourceService} from "../../../api/services/resource.service";
import {OrganizationResource} from "../../../api/resources/organization.resource";
import {UserConnectionsObsvInterface} from "../../../pages/dashboard/connect/user-connections-obsv.interface";
import {FamilyResource} from "../../../api/resources/family.resource";
import {FriendsRequestsObsvInterface} from "../../../pages/dashboard/connect/user-requests-obsv.interface";

@Component({
  selector: 'app-my-circle-admin',
  templateUrl: './my-circle-admin.component.html',
  styleUrls: ['./my-circle-admin.component.scss'],
})
export class MyCircleAdminComponent implements OnInit {

  user;

  userId: number;

  readonly FRIENDSHIP_PENDING = FriendshipResource.PENDING;

  readonly FRIENDSHIP_ACCEPTED = FriendshipResource.ACCEPTED;

  readonly FRIENDSHIP_DENIED = FriendshipResource.DENIED;

  readonly FRIENDSHIP_BLOCKED = FriendshipResource.BLOCKED;

  removeViewOnly: boolean = false;

  isLoadingFriendship: boolean = false;

  isLoadingFriends: boolean = false;

  isLoadingPendingFriends: boolean = false;

  friendship: FriendshipResource;

  canBefriend: boolean = true;

  private friendService: FriendService;

  private friendshipService: FriendshipService;

  friends: UserResource[];

  friend;

  commonFriends: UserResource[];
  currentUser: any;
  isToggledAdvocate: boolean;
  isProfile: boolean;
  isCauseGroupAdmin: boolean;
  isLoading: boolean;

  private friendRequestService: FriendRequestService;
  public friendsRequestsToOthers: UserResource[];
  public friendsRequestsToYou: UserResource[];

  constructor( private toast: ToastController,
               private route: ActivatedRoute,
               private router: Router,
               private storage: Storage,
               private httpClient: HttpClient,
               private encourage: EncourageService,
               private appState: StateManagementService,
               private modalController: ModalController,
               private alert: AlertController,
               private handleAdvocateInnerCircleRemovalService: HandleAdvocateInnerCircleRemovalService,
               private nextEncouragementService: NextEncouragementService
               ) { }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.userId = params.hasOwnProperty('id') ? parseInt(params.id) : null;
      this.storage.get('loginRes').then(async (val) => {
        this.currentUser = val.user;
        if (!this.userId) this.userId = this.currentUser.id;
        if  ((this.router.url).includes('remove')) {
          this.removeViewOnly = true;
        }

          this.isProfile = this.userId === this.currentUser.id;

          let userRoles = this.currentUser.roles.map((role) => role.title || role.name).join(', ').toLowerCase();

          if (userRoles.indexOf('cause group admin') >= 0) {
            this.isCauseGroupAdmin = true;
            this.canBefriend = false;
          }

          if (!this.isProfile) {
            this.friendship = new FriendshipResource();
            this.friendService = new FriendService(this.httpClient, `${FriendService.resourceSlug}/${this.userId}/user`);

            if(!this.isCauseGroupAdmin){
              this.friendshipService =  new FriendshipService(this.httpClient, `user/${this.currentUser.id}/${FriendshipService.resourceSlug}/user`);
              await this.loadFriendship();
              await this.loadCommonFriends();
            }
            await this.loadFriends();
            await this.loadFriendRequestsToYou();
            await this.loadFriendRequestsToOthers();
          }

      });
    });
  }

  public async loadFriendship() {
    this.isLoadingFriendship = true;

    try {
      const friendship$ = this.friendshipService.read(this.userId);
      this.friendship = await lastValueFrom(friendship$);
      this.canBefriend = this._canBefriend();
    } catch (error) {
      console.log(error)
    } finally {
      this.isLoadingFriendship = false;
    }
  }

  // @see /vendor/hootlex/laravel-friendships/src/Traits/Friendable::canBefriend()
  private _canBefriend(): boolean {
    // if sender has a friendship with the recipient
    if (this.friendship.id) {
      // if previous friendship was Denied then let the user send friend request
      if (this.friendship.status !== FriendshipResource.DENIED) {
        return false;
      }
    }
    return true;
  }

  addFriend(): void {
    this.isLoadingFriendship = true;

    this.friendshipService
        .create(null, `/${this.userId}`)
        .toPromise()
        .then((friendship) => {
          this.friendship = friendship;
          this.canBefriend = this._canBefriend();
          if(this.user.getRoleTitles().toLowerCase().indexOf('widow') !== -1) {

            if (!this.currentUser.next_step['connectToFamily']) {
              this.nextEncouragementService.getEncourage('connect-to-family');
              this.encourage.updateNextStep('connectToFamily', this.currentUser.id);
            }
          }
        })
        .catch((error) => {
          this.createErrorToast(error, 'Friendship Error')
        })
        .finally(() => { this.isLoadingFriendship = false; });
  }

  async loadCommonFriends() {
    const commonFriends$ = this.friendService.listMutual(this.userId);
    this.commonFriends = (await lastValueFrom(commonFriends$)).data;
  }

  async loadFriends() {
    this.isLoadingFriends = true;

    try {
      const friends$ = this.friendService.list();
      this.friends = (await lastValueFrom(friends$)).data;

    } catch(error) {
      this.createErrorToast(error, 'Loading Friends Error')
    } finally {
      this.isLoadingFriends = false;
    }
  }
  async loadFriendRequestsToYou() {
    this.friendRequestService = new FriendRequestService(
        this.httpClient, `user/${this.userId}/${FriendRequestService.resourceSlug}/recipient/pending`
    );

    try {
      const requestsToYou = await lastValueFrom(this.friendRequestService.list());
      this.friendsRequestsToYou = requestsToYou.data;

    } catch(error) {
      this.createErrorToast(error, 'Requests to you Loading error')
    }
  }

  async loadFriendRequestsToOthers() {
    this.friendRequestService = new FriendRequestService(
        this.httpClient, `user/${this.userId}/${FriendRequestService.resourceSlug}/sender/pending`
    );

    try {
      const requestsToOthers = await lastValueFrom(this.friendRequestService.list());
      this.friendsRequestsToOthers = requestsToOthers.data; //of(requestsToOthers.data);

    } catch (error) {
      this.createErrorToast(error, 'Requests to others Loading error')
    }
  }

  public onDeleteBtnClick(userId: number, friendId: number) {
    let message = 'Do you want remove this connection? This action is not reversible.';

    this.alert.create({
      header: 'Remove Connection - Confirmation',
      message: message,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Yes, Continue',
          role: 'confirm',
          handler: () => {
            this.deleteFriend(userId, friendId);
          }
        }
      ],
    }).then((alert: HTMLIonAlertElement) => alert.present());
  }

  async deleteFriend(friendId: number, userId: number) {
    this.isLoadingFriendship = true;

    try {
      this.friend = await this.loadUser(friendId);

      const hasAdvocateRole: RoleRelantionship = await this.friend.getRoleRelantionshipIfExist(ADVOCATE_EDGE_ID, this.currentUser);
      const hasInnerCircleRole: RoleRelantionship =  await this.friend.getRoleRelantionshipIfExist(INNER_CIRCLE_EDGE_ID, this.currentUser);

      const deleteFriend$ = this.friendshipService.delete(friendId);
      await lastValueFrom(deleteFriend$);

      if (this.isCauseGroupAdmin){

        if (this.user && this.friend){
          await this.handleAdvocateInnerCircleRemovalService.cgHandleAdvocateInnerCircleRemoval(this.user, this.friend);
        }

        await this.loadFriends();
      } else {
        if( hasAdvocateRole || hasInnerCircleRole) {
          await this.handleAdvocateInnerCircleRemovalService.handleAdvocateInnerCircleRemoval(this.friend, this.currentUser);
        }

        this.friendship = new FriendshipResource();
        this.canBefriend = this._canBefriend();
        this.appState.update({updateCirclesSlider: true});
      }

      this.toast.create({
        header: 'Friend removed successfully!',
        buttons: [
          {
            text: 'Close',
            role: 'cancel',
            handler: () => {}
          }
        ],
        color: 'success',
      }).then((toast) => toast.present());
    } catch (error) {
      this.createErrorToast(error, 'Friendship error')
    } finally {
      this.isLoadingFriendship = false;
    }
  }


  getEncourage(slug) {
    this.encourage.fetchEncourage(slug).subscribe((res) => {
      res.data ? this.showModal(res) : null;
    });
  }
  async showModal(slug) {
    const modal = await this.modalController.create({
      component: EncouragePage,
      componentProps: {
        slugData: slug,
      },
    });
    return modal.present();
  }
  public async loadUser(userId): Promise<any> {
    this.isLoading = true;

   try {
    const user$ = new UserService(this.httpClient)
        .read(userId);
        return await lastValueFrom(user$)
    }
    catch (error) {
      this.createErrorToast(error, 'Load error')
    }
    this.isLoading = false;
  }

  createErrorToast(errorResponse, title: string = 'Error') {
    this.toast.create({
      header: title,
      message: errorResponse.message,
      buttons: [
        {
          text: 'Close',
          role: 'cancel',
          handler: () => {}
        }
      ],
      color: 'danger',
    })
        .then((toast) => toast.present());
  }


}

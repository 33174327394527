import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Environment as Env } from '../environments/environment.prod';
import { OnboardingResponseModel } from '../models/OnboardingResponseModel';
import { QuestionData } from '../app/api/interfaces/question-data';
import { PostQuestionObj } from '../app/api/interfaces/post-questions-data';

@Injectable()
export class OnboardingServices {
    serverApi: string;

    constructor(public storage: Storage, private http: HttpClient) {
      this.serverApi = Env.server + Env.apiUrl;
    }

    /**
     * @desc THIS IS JUST THE ONBOARDING PROCESS, WAIT UNTIL THE PERSON HAS COMPLETED THE PROCESS TO LOAD FULL SIDE MENU ROUTES
     *
     */
    onBoardingProcess(id = null): Promise<any> {
      return new Promise((resolve, reject) => {
        const httpOptions = {
          headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        };
        const params = JSON.stringify({ clientID: `${id}` }); // NOTE: add validation token

        this.http.post<OnboardingResponseModel>(`${Env.server + Env.apiUrl}onboarding_process/fetch`, params, httpOptions).toPromise()
          .then((val) => {
            if (typeof val.onboarding !== 'object') {
              resolve(val);
            } else {
              this.storage.set('onboardingProcess', val.onboarding) // this is NOT an error this correct
                .then(() => {
                  resolve(val);
                });
            }
          },
          (reject) => {
            resolve({ status: 419, message: 'there was an issue' });
          });
      });
    }

    updatePosition(data) {
      return this.storage.set('onboardingProcess', data)
        .then(() => true);
    }

    /*
    | @desc this is the server hook
    | --> get step API url extension
    | --> --> send data to server mid process
     */
    addOnboaringAnswer(params): Promise<any> {
      return new Promise((resolve) => {
        resolve(true);
      });
    }

    addStorageParams(ans) {

    }

    /**
     * @desc Registration for the onboarded users
     *
     * // COULD DO... support both single page sign up and Q:A process by checking answer object
     *
     * @param params
     * @param answers
     * @param orgDataParams
     * @returns Promise<any>
     *
     * TODO: add geo location data .  I have it somewhere
     */
    register(params, answers = null, orgDataParams = null): Promise<any> {
      let data : string;
      if (orgDataParams) {
        data = JSON.stringify({
          personData: params, questions: answers, orgData: orgDataParams
        });
      } else if (answers) {
        data = JSON.stringify({
          personData: params, questions: answers
        });
      } else {
        data = JSON.stringify({
          personData: params
        });
      }
      return new Promise((resolve, reject) => {
        const httpOptions = {
          headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        };
        this.http.post<any>(`${Env.server + Env.apiUrl}register`, data, httpOptions).toPromise()
          .then((val) => {
            resolve(val);
          },
          (reject) => {
            resolve({ status: reject.error.status, message: reject.error.message, errors: reject.error.errors });
          })
          .catch((err) => Promise.reject(err.json().error || 'Server error'));
      });
    }

    /**
     * @desc Post registration questions that are answered
     *
     *
     * NOTE: since this is post reg --  and after the person has validated their account a token should then be available
     *
     * @returns {Promise<never>}
     * @param answers
     */
    postRegistrationQuestion(answers) {
      return new Promise((resolve, reject) => {
        const httpOptions = {
          headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        };
        const data = JSON.stringify({ questionResponses: answers });

        this.http.post<any>(`${Env.server + Env.apiUrl}psost_reg/question`, data, httpOptions).toPromise()
          .then((val) => {
            resolve(val);
          },
          () => {
            resolve({ status: 419, message: 'There was an issue' });
          })
          .catch((err) => Promise.reject(err.json().error || 'Server error'));
      });
    }

    fetchQuestions(role) {
      const questions = {
        outer_circle: [
          {
            questionId: 7,
            question: 'What is your role with Perspective Ministries?',
            questionType: 'radio',
            valueSets: [
              {
                title: 'Administrator',
                value: 'admin',
              },
              {
                title: 'Case worker',
                value: 'case_worker',
              },
            ],
          },
        ],
        provider: [
          {
            questionId: 7,
            question: 'Do you offer physical goods or online resources?',
            questionType: 'radio',
            valueSets: [
              {
                title: 'online',
                value: 'digital',
              },
              {
                title: 'physical',
                value: 'physical',
              },
            ],
          },
          {
            questionId: 7,
            question: 'Is your company able to offer discounts to our members?',
            questionType: 'radio',
            valueSets: [
              {
                title: 'Yes',
                value: '1',
              },
              {
                title: 'No',
                value: 0,
              },
            ],
          },
        ],
        members: [
          {
            questionId: 7,
            question: 'Which of the following interest you most?',
            questionType: 'radio',
            valueSets: [
              {
                title: 'volunteering',
                value: 'volunteer',
              },
              {
                title: 'community projects',
                value: 'community',
              },
            ],
          },
          {
            questionId: 8,
            question: 'How active do you want to be?',
            questionType: 'radio',
            valueSets: [
              {
                title: '1 day a week',
                value: 4,
              },
              {
                title: '1 day a month',
                value: 1,
              },
              {
                title: 'Often',
                value: 10,
              },
            ],
          },
        ],
        friends: [
          {
            questionId: 7,
            question: 'Are you here to support a specific member?',
            questionType: 'radio',
            valueSets: [
              {
                title: 'yes',
                value: 1,
              },
              {
                title: 'no',
                value: 0,
              },
            ],
          },
          {
            questionId: 8,
            question: 'How active do you want to be?',
            questionType: 'radio',
            valueSets: [
              {
                title: '1 day a week',
                value: 4,
              },
              {
                title: '1 day a month',
                value: 1,
              },
              {
                title: 'Often',
                value: 10,
              },
            ],
          },
        ],
        supporter: [
          {
            questionId: 7,
            question: 'Do you already have an idea of how to support the family in need?',
            questionType: 'radio',
            valueSets: [
              {
                title: 'yes',
                value: 1,
              },
              {
                title: 'no',
                value: 0,
              },
            ],
          },
          {
            questionId: 8,
            question: 'Have you ever had the opportunity to help a family in need before?',
            questionType: 'radio',
            valueSets: [
              {
                title: 'Yes',
                value: 1,
              },
              {
                title: 'No',
                value: 0,
              },
            ],
          },
        ],
        widow: [
          {
            questionId: 7,
            question: 'How recent was the death of your loved one?',
            questionType: 'radio',
            valueSets: [
              {
                title: '< 1 year',
                value: 1,
              },
              {
                title: '> 1 year',
                value: 2,
              },
            ],
          },
          {
            questionId: 8,
            question: 'Do you have any children at home?',
            questionType: 'radio',
            valueSets: [
              {
                title: 'Yes',
                value: 1,
              },
              {
                title: 'No',
                value: 0,
              },
            ],
          },
        ],
      };
      return questions.hasOwnProperty(role) ? questions[role] : [];
    }

    resourceForDownload(role) {
      const resources = {
        outer_circle: {
          id: 4566,
          title: 'Perspective Ministry Members guide',
          bodyText: 'Based upon your answers we recommend the following guide',
          buttonTitle: 'Download / Read Now',
          resourceLink: '#',
        },
        provider: {
          id: 87643,
          title: 'How to present a meaningful offering',
          bodyText: 'Based upon your answers we recommend the following guide',
          buttonTitle: 'Download / Read Now',
          resourceLink: '#',
        },
        members: {
          id: 24896,
          title: 'Members Guide, Getting Started',
          bodyText: 'Based upon your answers we recommend the following guide',
          buttonTitle: 'Download / Read Now',
          resourceLink: '#',
        },
        friends: {
          id: 475,
          title: 'Friends and Family guide',
          bodyText: 'Based upon your answers we recommend the following guide',
          buttonTitle: 'Download / Read Now',
          resourceLink: '#',
        },
        supporter: {
          id: 246885,
          title: 'Supporting Role guide',
          bodyText: 'Based upon your answers we recommend the following guide',
          buttonTitle: 'Download / Read Now',
          resourceLink: '#',
        },
        widow: {
          id: 85863,
          title: 'Widow Life Online Book',
          bodyText: 'Based upon your answers we recommend the following guide',
          buttonTitle: 'Download / Read Now',
          resourceLink: '#',
        },
      };
      return resources.hasOwnProperty(role) ? resources[role] : {};
    }


    defaultPostRegQuestions(id) {
      return {
        currentPosition: 0,
        totalSteps: 11,
        onboarding: [
          {
            component_name: 'IntroVideoComponent',
            questionId: null,
            requires_server_ineraction: false,
            api_url: '',
            title: 'Mission',
            bodyText: 'Perspective Ministries exists to meet the needs of the widow and fatherless.  Because of this, we are creating a network of service providers '
                    + 'who are safe, reliable and affordable.',
          }],
      };
    }

    fetchQuestionsByRole(questionCategory: string, roleId: number, causeGroupSlug: string): Observable<QuestionData[]> {
      const url = `${Env.server + Env.apiUrl}questions/${questionCategory}`;
      const params: HttpParams = new HttpParams()
        .set('role', roleId.toString())
        .set('limit', '1')
        .set('cause_group', causeGroupSlug);
        // TODO: ListResponse<QuestionData> does not match with the extends Resource class
      return this.http.get(url, { params }).pipe(
        map((res: any) => res.data),
      );
    }

    getUnansweredQuestions(userId: number, category: string, skippedQuestionIds: number[], questionSetType: string = null): Observable<PostQuestionObj> {
      const url = `${Env.server + Env.apiUrl}questions/${category}/unanswered`;
      let params: HttpParams = new HttpParams()
        .set('limit', '1')
        .set('user_id', userId.toString())
      ;
      if (questionSetType) {
        params = params.set('question_set_type', questionSetType);
      }

      if (skippedQuestionIds.length !== 0) {
        params = params.set('except', skippedQuestionIds.join(','));
      }
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        params,
      };
      return this.http.get(url, httpOptions).pipe(
        map((res:any) => res),
      );
    }

    async postAnswersAfterLogin(userId: number, questionId: number, answerIds: number[]) {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
      });
      return this.http.post(
        this.getApiUrl(`user/${userId}/question/${questionId}/answers/${answerIds.join(',')}`),
        null,
        { headers },
      ).toPromise();
    }

    private getApiUrl(url: string) {
      return Env.server + Env.apiUrl + url;
    }

    fetchOnboardingQuestionsByRole(questionCategory: string, roleId: number, causeGroupSlug: string): Observable<any> {
      const url = `${Env.server + Env.apiUrl}questions/${questionCategory}`;
      const params: HttpParams = new HttpParams()
        .set('role', roleId.toString())
        .set('cause_group', causeGroupSlug);
        // TODO: ListResponse<QuestionData> does not match with the extends Resource class
      return this.http.get(url, { params }).pipe(
        map((res: any) => res),
      );
    }
}

<ion-content>
  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-label>
          <h1>Cause Groups</h1>
        </ion-label>
      </ion-col>
    </ion-row>

    <ng-container *ngxPermissionsOnly="'cause-groups_add'">
      <ion-row justify-content-end>
        <ion-col size="auto">
          <ion-button [routerLink]="['/admin/cause-group/add']" size="small">
            <ion-icon slot="start" name="add-circle"></ion-icon>
            Create
          </ion-button>
        </ion-col>
      </ion-row>
    </ng-container>

    <ion-row>
      <ion-col>
        <ngx-datatable
          [rows]="causeGroups"
          [loadingIndicator]="isLoading"

          class="material striped"
          [columnMode]="columnMode"
          headerHeight="50"
          rowHeight="auto"
          footerHeight="50"
          scrollbarH="true"

          [externalPaging]="true"
          [count]="page.totalElements"
          [offset]="page.pageNumber"
          [limit]="page.size"
          (page)="setPage($event)"

          [sorts]="sorts"
          [sortType]="sortType"
          [externalSorting]="true"
          (sort)="onSort($event)"
        >
          <!--<ngx-datatable-column name="ID" prop="id" width="40"></ngx-datatable-column>-->
          <ngx-datatable-column name="Name" prop="name">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{row.name}}
                  <div class="hidden-sm-up">
                    /  {{row.slug}}
                  </div>
              </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Slug" prop="slug" width="140"></ngx-datatable-column>
          <ngx-datatable-column name="Actions" [sortable]="false" width="200">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>

              <ng-container *ngxPermissionsOnly="['cause-groups_dashboard_view', 'all-cause-groups_dashboard_view']">
                <ion-button [routerLink]="['/admin/cause-group', row.id, 'dashboard']" size="small">
                  <ion-icon slot="start" name="analytics"></ion-icon>
                        Dashboard
                </ion-button>
              </ng-container>
              <ng-container *ngxPermissionsOnly="['cause-groups_users_list', 'all-cause-groups_users_list']">
                <ion-button [routerLink]="['/admin/cause-group', row.id, 'users']" size="small">
                  <ion-icon slot="start" name="people"></ion-icon>
                        Users
                </ion-button>
              </ng-container>
                <ng-container *ngxPermissionsOnly="['cause-groups_edit', 'all-cause-groups_edit']">
                    <ion-button [routerLink]="['/admin/cause-group', row.id, 'edit']" size="small" color="warning">
                        <ion-icon slot="start" name="create"></ion-icon>
                            Edit
                    </ion-button>
                </ng-container>
              <ng-container *ngxPermissionsOnly="['cause-groups_users_list', 'all-cause-groups_users_list']">
                <ion-button [routerLink]="['/admin/cause-group', row.id, 'needs']" size="small">
                  <ion-icon slot="start" name="hand-left"></ion-icon>
                        Needs
                </ion-button>
              </ng-container>
              <ng-container *ngxPermissionsOnly="['tags_list', 'all-tags_list']">
                <ion-button [routerLink]="['/admin/cause-group', row.id, 'tags']" size="small">
                  <ion-icon slot="start" name="pricetag"></ion-icon>
                  Tags
                </ion-button>
              </ng-container>
              <!--
              <ng-container *ngxPermissionsOnly="['cause-groups_edit', 'all-cause-groups_edit']">
                <ion-button [routerLink]="['/admin/cause-group', row.id]" size="small">
                  <ion-icon slot="icon-only" name="create"></ion-icon>
                </ion-button>
              </ng-container>
              -->
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

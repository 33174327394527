<ion-content>
  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-label>
          <h1>Background Checks</h1>
        </ion-label>

      </ion-col>
        <ion-button (click)="export()" [disabled]="backgroundCheckDisabled">Export</ion-button>

    </ion-row>
      <ion-row>
          <ion-col>
              <form [formGroup]="form.getForm()" (ngSubmit)="search()">
                  <ion-list>
                      <ion-row>
                          <ion-col size="12">
                              <ion-item class="item-input">
                                  <ion-label class="txt3" position="stacked">Keyword</ion-label>
                                  <ion-input formControlName="keyword" placeholder="Keyword" type="search"></ion-input>
                                  <ion-note color="danger" *ngIf="form.hasError('keyword')">
                                      {{ form.getError('keyword')}}
                                  </ion-note>
                              </ion-item>
                          </ion-col>

                          <ion-row class="ion-text-center" >
                              <ion-col>
                                  <ion-button [disabled]="!form.getForm().valid || backgroundCheckDisabled" type="submit" expand="block">
                                      <ion-icon slot="start" name="analytics"></ion-icon>
                                      Show
                                  </ion-button>
                              </ion-col>
                          </ion-row>
                      </ion-row>
                  </ion-list>
              </form>
          </ion-col>
      </ion-row>
    <ion-row *ngIf="isLoading; else userBlock" class="ion-justify-content-center">
      <ion-col size="auto">
        <ion-spinner></ion-spinner>
      </ion-col>
    </ion-row>
    <ng-template #userBlock>
      <ion-row>
        <ion-col>
          <ngx-datatable
                  [messages]="getNgxDatatableMessages()"
                  [rows]="users"
                  [loadingIndicator]="isLoading"

                  class="material striped"
                  [columnMode]="columnMode"
                  headerHeight="50"
                  rowHeight="auto"
                  footerHeight="50"
                  scrollbarH="true"

                  [externalPaging]="true"
                  [count]="page.totalElements"
                  [offset]="page.pageNumber"
                  [limit]="page.size"
                  (page)="setPage($event)"

                  [sorts]="sorts"
                  [sortType]="sortType"
                  [externalSorting]="true"
                  (sort)="onSort($event)"
          >
          <ngx-datatable-column name="User Name" [sortable]="!backgroundCheckDisabled">
              <ng-template let-value="value" ngx-datatable-cell-template let-row='row'>
                  {{row.name}} (#{{row.id}})
              </ng-template>
          </ngx-datatable-column>
            <ngx-datatable-column name="BG Check"  >
                <ng-template let-value="value" ngx-datatable-cell-template let-row='row'>
                    <ng-container *ngIf="row.background_check_completed == 'pending'">
                        Pending
                    </ng-container>
                    <ng-container *ngIf="row.background_check_completed == 'approved'">
                        Approved
                    </ng-container>
                    <ng-container *ngIf="row.background_check_completed == 'denied'">
                        Denied
                    </ng-container>
                </ng-template>
            </ngx-datatable-column>
          <ngx-datatable-column name="BG Date"  >
              <ng-template let-value="value" ngx-datatable-cell-template let-row='row'>
                  <ng-container *ngIf="row.background_check_date">
                      {{ row.background_check_date }}
                  </ng-container>
              </ng-template>
          </ngx-datatable-column>
            <ngx-datatable-column name="Email" prop="email" [sortable]="!backgroundCheckDisabled"></ngx-datatable-column>
            <ngx-datatable-column name="Actions" [sortable]="false" width="200">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <ng-container *ngxPermissionsOnly="['cause-groups_dashboard_view', 'all-cause-groups_dashboard_view']">

                </ng-container>
                <!--removed /admin/user/documents link to documents 9/10/24 -->
                <ng-container *ngxPermissionsOnly="['cause-groups_edit', 'all-cause-groups_edit']">
                  <ion-button [routerLink]="['/admin/user/', row.id]" size="small" color="warning">
                    <ion-icon slot="icon-only" name="create"></ion-icon>
                    Edit
                  </ion-button>
                  <ion-button [routerLink]="['/dashboard/show-need', row.need_id]" size="small" *ngIf="row.need_id">
                    Show Need
                  </ion-button>
                </ng-container>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </ion-col>
      </ion-row>
    </ng-template>
  </ion-grid>
</ion-content>

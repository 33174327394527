<ion-content class="ion-padding">
  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-label>
          <h1 *ngIf="userId; else labelBlock">Edit User</h1>
          <ng-template #labelBlock>
            <h1>Create User</h1>
          </ng-template>
        </ion-label>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="isLoading; else userBlock" class="ion-justify-content-center">
      <ion-col size="auto">
        <ion-spinner></ion-spinner>
      </ion-col>
    </ion-row>

    <ng-template #userBlock>
      <ion-row>
        <ion-col>
          <form [formGroup]="form.getForm()" (ngSubmit)="save()">
            <ion-list>
              <ion-col>
                <!-- @todo
                <ion-row>
                  <ion-col>
                    select role
                  </ion-col>
                </ion-row>
                -->
                <ion-row>
                  <ion-col>
                    <ion-item class="item-input">
                      <ion-label class="txt3" position="stacked">Email <span class="required">*</span></ion-label>
                      <ion-input formControlName="email" placeholder="Email" type="email" ></ion-input>
                      <ion-note color="danger" *ngIf="form.hasError('email')">
                        {{ form.getError('email')}}
                      </ion-note>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col>
                    <ion-item class="item-input">
                      <ion-label class="txt3" position="stacked">First Name <span class="required">*</span></ion-label>
                      <ion-input formControlName="first_name" placeholder="First Name" type="text"  ></ion-input>
                      <ion-note color="danger" *ngIf="form.hasError('first_name')">
                        {{ form.getError('first_name')}}
                      </ion-note>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
	              <ion-col>
		              <ion-item class="item-input">
			              <ion-label class="txt3" position="stacked">Last Name <span class="required">*</span></ion-label>
			              <ion-input formControlName="last_name" placeholder="Last Name" type="text"   ></ion-input>
			              <ion-note color="danger" *ngIf="form.hasError('last_name')">
				              {{ form.getError('last_name')}}
			              </ion-note>
		              </ion-item>
	              </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col>
                    <ion-item class="item-input">
                      <ion-label class="txt3" position="stacked">Zip</ion-label>
                      <ion-input formControlName="zip_code" placeholder="Zip" type="text" ></ion-input>
                      <ion-note color="danger" *ngIf="form.hasError('zip_code')">
                        {{ form.getError('zip_code')}}
                      </ion-note>
                    </ion-item>
                  </ion-col>
                </ion-row>
              <ion-row>
                  <ion-col>
                      <ion-item class="item-input">
                          <ion-label class="txt3" position="stacked">Phone</ion-label>
                          <ion-input formControlName="phone" type="tel"  minlength="9" maxlength="14" mask="(000) 000-0000" ></ion-input>
                          <ion-note color="danger" *ngIf="form.hasError('phone')">
                              {{ form.getError('phone')}}
                          </ion-note>
                      </ion-item>
                  </ion-col>
              </ion-row>
                <ion-row>
                  <ion-col>
                    <ion-item class="item-input">
                      <ion-label class="txt3" position="stacked">Age Range</ion-label>
                        <ion-select formControlName="dob_range" placeholder="Age Range"  >
                            <ion-select-option value="18-29" >18 - 29</ion-select-option>
                            <ion-select-option value="30-49">30 - 49</ion-select-option>
                            <ion-select-option value="50-64">50 - 64</ion-select-option>
                            <ion-select-option value="65+">65+</ion-select-option>
                        </ion-select>
                      <ion-note color="danger" *ngIf="form.hasError('dob_range')">
                        {{ form.getError('dob_range')}}
                      </ion-note>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ng-container  *ngxPermissionsOnly="['users_background_check']">
                  <ion-row  *ngIf="user && user.quick_connect && userIsFamily">
                    <ion-col>
                      <ion-item class="item-input line-height">
                        <ion-label class="txt3" position="stacked">Direct Connect</ion-label>
                        {{user.quick_connect}}<br/>
                        <b>Submitted On: </b>{{user.quick_connect_date | date}}
                        <b *ngIf="user.quick_connect_reasons">Reasons: </b>{{user.quick_connect_reasons}}
                        <b *ngIf="user.quick_connect_time">Best Contact Time: </b>{{user.quick_connect_time}}
                      </ion-item>
                    </ion-col>
                  </ion-row>
                  <ion-row  *ngIf="user && user.quick_connect && userIsFamily">
                    <ion-col>
                      <ion-item class="item-input">
                        <ion-label class="txt3" position="stacked">Connect Notes <span class="required">*</span></ion-label>
                        <ion-textarea formControlName="connect_notes" placeholder="Notes" type="text"  ></ion-textarea>
                        <ion-note color="danger" *ngIf="form.hasError('connect_notes')">
                          {{ form.getError('connect_notes')}}
                        </ion-note>
                      </ion-item>
                    </ion-col>
                  </ion-row>
                  <ion-row  *ngIf="user && user.quick_connect && userIsFamily">
                    <ion-col>
                      <ion-item class="item-input">
                        <ion-label class="txt3" position="stacked">Connect Status <span class="required">*</span></ion-label>
                        <ion-select formControlName="connect_status" placeholder="Status" type="text"  >
                          <ion-select-option value="pending" >Pending</ion-select-option>
                          <ion-select-option value="in progress">In progress</ion-select-option>
                          <ion-select-option value="completed">Completed</ion-select-option>
                        </ion-select>
                      </ion-item>
                    </ion-col>
                  </ion-row>
                </ng-container>
                <ng-container *ngxPermissionsOnly="['users_background_check']">
                  <ion-row *ngIf="!userIsFamily">
                    <ion-col>
                      <ion-item class="item-input">
                        <ion-label class="txt3" position="stacked">Background Check Completed</ion-label>

                        <ion-select formControlName="background_check_completed" (ionChange)="backgroundCheckChanged()" >
                            <ion-select-option value="pending" >Pending</ion-select-option>
                            <ion-select-option value="approved" >Approved</ion-select-option>
                            <ion-select-option value="denied" >Denied</ion-select-option>
                        </ion-select>
                        <ion-note color="danger" *ngIf="form.hasError('background_check_completed')">
                          {{ form.getError('background_check_completed')}}
                        </ion-note>
                      </ion-item>
                    </ion-col>
                  </ion-row>
                </ng-container>

              <ng-container *ngxPermissionsOnly="['users_background_check']">
                  <ion-row *ngIf="!userIsFamily">
                    <ion-col>
                      <ion-item class="item-input">
                        <ion-label class="txt3" position="stacked">{{backgroundCheckTitle}}</ion-label>
                          <ion-input formControlName="background_check_date" id="background-check-date-input-test" class="ion-text-start w-100" lines="none" (click)="presentPopover()" >
                          </ion-input>
                        <ion-note color="danger" *ngIf="form.hasError('background_check_date')">
                          {{ form.getError('background_check_date')}}
                        </ion-note>
                      </ion-item>
                    </ion-col>
                  </ion-row>
                </ng-container>

                <ng-container *ngxPermissionsOnly="'users_cause-groups_manage'">
                  <ion-row *ngIf="isLoadingCauseGroups; else causeGroupsBlock" class="ion-justify-content-center">
                    <ion-col size="auto">
                      <ion-spinner></ion-spinner>
                    </ion-col>
                  </ion-row>

                  <ng-template #causeGroupsBlock>
                    <ion-row>
                      <ion-col>
                        <ion-item class="item-input">
                          <ion-label class="txt3" position="stacked">Cause Groups</ion-label>
                          <ion-select formControlName="causeGroups" placeholder="Cause Groups" multiple="true">
                            <ion-select-option *ngFor="let causeGroup of causeGroups" [value]="causeGroup.id">{{causeGroup.name}}</ion-select-option>
                          </ion-select>
                          <ion-note color="danger" *ngIf="form.hasError('causeGroups')">
                            {{ form.getError('causeGroups')}}
                          </ion-note>
                        </ion-item>
                      </ion-col>
                    </ion-row>
                  </ng-template>
                </ng-container>

                <ion-row>
                  <ion-col>
                    <ion-button [disabled]="!form.getForm().valid || isLoadingCauseGroups" expand="block" type="submit">
                      <ion-icon slot="start" name="save"></ion-icon>
                      Save
                    </ion-button>
                  </ion-col>
                </ion-row>
              </ion-col>
            </ion-list>
          </form>

          <!--
          <ng-container *ngxPermissionsOnly="'users_delete'">
            <ion-button *ngIf="userId" (click)="presentDeleteConfirm()" expand="block" color="danger">
              <ion-icon slot="start" name="trash"></ion-icon>
              Delete
            </ion-button>
          </ng-container>
          -->
        </ion-col>
      </ion-row>
    </ng-template>
  </ion-grid>
</ion-content>

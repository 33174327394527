import { Resource } from './resource';
import { EntityResource } from './entity.resource';

export class RoleResource extends Resource {
    id: number = null;

    name: string = null;

    title: string = null;

    description: string = null;

    short_description: string = null;

    user_type: string = null;

    created_at: string = null;

    updated_at: string = null;

    entity_id: number = null;

    entity: EntityResource = null;

    event_url: string = null;

    event_type: string = null;
}

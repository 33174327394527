import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, ReplaySubject, Subject} from "rxjs";
import {StateManagementType} from "../../shared/types/StateManagementType.type";
import {MenuType} from "../../shared/types/Menu.type";
import {UserResource} from "../resources/user.resource";
import {CauseGroupResource} from "../resources/cause-group.resource";
import {EncouragementUpdateType} from "../../shared/types/EncouragementUpdate.type";
import {FamilyResource} from "../resources/family.resource";

@Injectable({
  providedIn: 'root'
})
export class StateManagementService {

  // noinspection JSUnusedLocalSymbols
  private isHidden: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  // noinspection JSUnusedLocalSymbols
  private menu: Subject<Array<MenuType>> = new ReplaySubject<Array<MenuType>>();
  // noinspection JSUnusedLocalSymbols
  private user: Subject<UserResource> = new ReplaySubject<UserResource>();
  // noinspection JSUnusedLocalSymbols
  private causeGroup: Subject<CauseGroupResource> = new ReplaySubject<CauseGroupResource>();
  // noinspection JSUnusedLocalSymbols
  private encouragementUpdate: Subject<EncouragementUpdateType> = new ReplaySubject<EncouragementUpdateType>();
  // noinspection JSUnusedLocalSymbols
  private causeGroupName: Subject<string> = new ReplaySubject<string>();
  // noinspection JSUnusedLocalSymbols
  private userFamilies: Subject<FamilyResource[]> = new ReplaySubject<FamilyResource[]>();
  // noinspection JSUnusedLocalSymbols
  private updateCirclesSlider: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  // noinspection JSUnusedLocalSymbols
  private updateRequestsSlider: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  // noinspection JSUnusedLocalSymbols
  private updateNeedsSlider: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  // noinspection JSUnusedLocalSymbols
  private toggledUserFamilies: Subject<FamilyResource[]> = new ReplaySubject<FamilyResource[]>();

  constructor() {
  }

  update(data: StateManagementType) {
    for ( const [key, value] of Object.entries(data)) {
      this[key].next(value);
    }
  }

  getState(property: string = null): Observable<any>{
    if (property) {
      if (this.hasOwnProperty(property)) {
        return this[property].asObservable();
      }
      throw new Error("Property doesn't exist in State Management");
    }
  }
}

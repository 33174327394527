import { Component, OnInit } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import {Storage} from "@ionic/storage";
import {ToastController} from "@ionic/angular";
import {Page} from "../../../../../models/page";
import {ColumnMode, SortDirection, SortPropDir, SortType} from "@swimlane/ngx-datatable";
import {CauseGroupResource} from "../../../../api/resources/cause-group.resource";
import {TitleCasePipe} from "@angular/common";
import {BackgroundCheckResource} from "../../../../api/resources/background-check.resource";
import {BackgroundCheckService} from "../../../../api/services/background-check.service";
import {ValidationManager} from "ng2-validation-manager";

@Component({
  selector: 'app-background-checks',
  templateUrl: './background-checks.page.html',
  styleUrls: ['./background-checks.page.scss'],
})
export class BackgroundChecksPage implements OnInit {
  private currentUser: any = {};

  private userId: number;

  public isLoading: boolean = false;

  public users:BackgroundCheckResource[] = [];

  private backgroundCheckService: BackgroundCheckService;

  public columnMode: ColumnMode = ColumnMode.force;

  public sortType: SortType = SortType.multi;

  private titleCasePipe: TitleCasePipe;

  public page: Page;

  public form:ValidationManager;

  public sorts: SortPropDir[];

  public backgroundCheckDisabled: boolean = false;

  constructor(private httpClient: HttpClient,
              private route: ActivatedRoute,
              private router: Router,
              private storage: Storage,
              private toast: ToastController) {

    this.titleCasePipe = new TitleCasePipe();
    this.page = new Page();
    this.page.pageNumber = 0;
    this.page.size = 10;
    this.sorts = [
      { prop: 'name', dir: SortDirection.asc },
    ];

    this.form = new ValidationManager({
      keyword: 'rangeLength:2,50',
    });
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.userId = params.hasOwnProperty('id') ? parseInt(params.id) : null;

      this.storage.get('loginRes').then((val) => {
        this.currentUser = val.user;
        const endpoint = `/`;
        const causeGroup: CauseGroupResource = this.currentUser.causeGroups[0];
        if (causeGroup.background_check) {
          this.backgroundCheckService = new BackgroundCheckService(this.httpClient, endpoint);
          this.loadBackgroundChecks();
        } else {
          this.backgroundCheckDisabled = true;
        }
      });
    });
  }

  private loadBackgroundChecks()
  {
    this.isLoading = true;

    let params = new HttpParams()
        .set('page', (this.page.pageNumber + 1).toString())
        .set('per_page', this.page.size.toString());
    this.sorts.forEach((sort, index) => {
      for (const key in sort) {
        params = params.set(`sorts[${index}][${key}]`, sort[key].toString());
      }
    });

    let formData = this.form.getData();
    const searchData = { ...formData };

    for (const key in searchData) {
      if (searchData[key] !== '' && searchData[key] !== null) params = params.set(key, (searchData[key].toString()));
    }

    this.backgroundCheckService.backgroundChecks(this.currentUser.id, params)
        .toPromise()
        .then((value) => {

          this.users = value.data;
          console.dir(this.users);
          this.page.totalElements = value.metadata.count;
          this.page.totalPages = value.metadata.last_page;


        })
        .catch((errorResponse) => {
          this.toast.create({
            header: 'Load error',
            message: errorResponse.error.message,
            buttons: [
              {
                text: 'Close',
                role: 'cancel',
                handler: () => {}
              }
            ],
            color: 'danger',
          }).then((toast) => toast.present());

        })
        .finally(() => { this.isLoading = false; });

  }

  public setPage(pageInfo): void {
    this.page.pageNumber = pageInfo.offset;
    this.loadBackgroundChecks();
  }

  onSort(event): void {
    this.sorts = event.sorts;
    this.page.pageNumber = 0;
    this.loadBackgroundChecks();
  }

  public export()
  {
    let formData = this.form.getData();
    const searchData = { ...formData };

    let params = new HttpParams();
    for (const key in searchData) {
      if (searchData[key] !== '' && searchData[key] !== null) params = params.set(key, encodeURIComponent(searchData[key].toString()));
    }

    const options = {
      params,
    };
    this.backgroundCheckService
        .exportBackgroundChecks(this.currentUser.id, options)
        .toPromise()
        .then((data) => {
          window.open(data.url, '_blank');
        })
        .catch((errorResponse) => {
          this.toast.create({
            header: 'Load error',
            message: errorResponse.error.message,
            buttons: [
              {
                text: 'Close',
                role: 'cancel',
                handler: () => {}
              }
            ],
            color: 'danger',
          }).then((toast) => toast.present());
        })
        .finally(() => { });
  }

  async search() {
      if (this.form.isValid && !this.backgroundCheckDisabled) {
        this.loadBackgroundChecks();
      }
  }

  getNgxDatatableMessages() {
    const emptyMessage = this.backgroundCheckDisabled ? 'Background check is disabled' : 'No data to display';
    const totalMessage = 'results';
    return {
        emptyMessage,
        totalMessage
    }
  }

}

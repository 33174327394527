import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Storage } from '@ionic/storage';
import { ValidationManager } from 'ng2-validation-manager';
import { LoadingController, ToastController } from '@ionic/angular';

import { UserResource } from '../../../../api/resources/user.resource';
import { CauseGroupUserService } from '../../../../api/services/cause-group-user.service';

@Component({
  selector: 'cause-group-user-privilege',
  templateUrl: './cause-group-user-privilege.component.html',
  styleUrls: ['./cause-group-user-privilege.component.css'],
})
export class CauseGroupUserPrivilegeComponent implements OnInit {
    public isLoading: boolean = false;

    private causeGroupId: number;

    private userId: number;

    private currentUser: any = {};

    private user: UserResource;

    public form: ValidationManager;

    private loading: any;

    private causeGroupUserService: CauseGroupUserService;

    constructor(private httpClient: HttpClient,
                private route: ActivatedRoute,
                private router: Router,
                private storage: Storage,
                private loader: LoadingController,
                private toast: ToastController) {
      this.form = new ValidationManager({
        isAdmin: '',
      });
    }

    ngOnInit() {
      this.route.params.subscribe((params) => {
        this.causeGroupId = params.hasOwnProperty('causeGroupId') ? parseInt(params.causeGroupId) : null;
        this.userId = params.hasOwnProperty('userId') ? parseInt(params.userId) : null;

        this.form.reset();

        this.storage.get('loginRes').then((val) => {
          this.currentUser = val.user;
          if (this.causeGroupId && this.userId) {
            const endpoint = `cause-group/${this.causeGroupId}/user`;
            this.causeGroupUserService = new CauseGroupUserService(this.httpClient, endpoint);

            this.loadPrivilege();
          }
        });
      });
    }

    private loadPrivilege(): void {
      this.isLoading = true;

      this.causeGroupUserService
        .privilege(this.userId)
        .toPromise()
        .then((response) => {
          this.form.getControl('isAdmin').setValue(response.isAdmin);
        })
        .catch((errorResponse) => {
          this.toast.create({
            header: 'Load error',
            message: errorResponse.error.message,
            buttons: [
              {
                text: 'Close',
                role: 'cancel',
                handler: () => {}
              }
            ],
            color: 'danger',
          }).then((toast) => toast.present());
        })
        .finally(() => { this.isLoading = false; });
    }

    public save() {
      if (this.form.isValid()) {
        this.presentLoading();

        const formData = this.form.getData();

        const observable = formData.isAdmin ? this.causeGroupUserService.promote(this.userId) : this.causeGroupUserService.demote(this.userId);

        observable
          .toPromise()
          .then((user) => {
            this.userId = user.id;
            this.user = user;

            this.toast.create({
              header: 'Congrats!',
              message: 'Saved successfully',
              buttons: [
              {
                text: 'Close',
                role: 'cancel',
                handler: () => {}
              }
            ],
              color: 'success',
              duration: 5000,
            }).then((toast) => toast.present());

            this.router.navigate(['/admin/cause-group', this.causeGroupId, 'users', { _: (new Date()).getTime() }]);
          })
          .catch((errorResponse) => {
            this.toast.create({
              header: 'Save error',
              message: errorResponse.error.message,
              buttons: [
              {
                text: 'Close',
                role: 'cancel',
                handler: () => {}
              }
            ],
              color: 'danger',
            }).then((toast) => toast.present());
          })
          .finally(() => { this.loading.dismiss(); });
      }
    }

    async presentLoading(options: object = { message: 'Please wait...' }) {
      this.loading = await this.loader.create(options);
      return await this.loading.present();
    }
}

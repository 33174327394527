import { OrganizationResource } from './organization.resource';
import { NeedResource } from './need.resource';
import { FamilyResource } from './family.resource';
import { UserResourceResource } from './userresource.resource';
import { UserResource } from './user.resource';

export class CauseGroupDashboardResource {
    organizations: OrganizationResource[] = [];

    needs: NeedResource[] = [];

    families: FamilyResource[] = [];

    resources: UserResourceResource[] = [];

    volunteers: UserResource[] = [];
}

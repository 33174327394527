import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule, Meta, Title } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { NgxPermissionsModule } from 'ngx-permissions';
import { IonicStorageModule, Storage } from '@ionic/storage';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TagInputModule } from 'ngx-chips';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AuthService} from '../providers/auth/auth.service';
import {SettingsService} from '../providers/settings/settings.service';
import {LocationService} from '../providers/location.service';
import { OnboardingServices } from '../providers/onboarding.services';
import { HttpAuthTokenInterceptor } from './interceptors/http-auth-token.interceptor';
import { TagsModule } from './pages/mtn-ui/tags/tags.module';
import { UsersModule } from './pages/mtn-ui/users/users.module';
import { CauseGroupsModule } from './pages/mtn-ui/cause-groups/cause-groups.module';
import { FriendModule } from './pages/friend/friend.module';
import { UserResource } from './api/resources/user.resource';
import { ResourceSerializer } from './api/serializers/resource-serializer';
import {NgxPaginationModule} from "ngx-pagination";
import { ToggleRolesComponent } from './popovers/toogle-roles.component';
import {CalendarComponent} from "./popovers/calendar.component";
import { ClipboardModule } from 'ngx-clipboard';
import { IonicSelectableModule } from 'ionic-selectable';
import { AddHeadLinkService } from './shared/services/add-head-link.service';
import { HowTutorialComponent } from "./pages/how-tutorial/how-tutorial.component";
import {TermsPage} from "./pages/legal/terms/terms.page";
import {PrivacyPage} from "./pages/legal/privacy/privacy.page";
import {FaithStatementPage} from "./pages/legal/faith-statement/faith-statement.page";
import {IntentStatementPage} from "./pages/legal/intent-statement/intent-statement.page";
import {InfoModalPage} from "./pages/dashboard/need/info-modal/info-modal.page";
import {InfoOrgModalPage} from "./pages/dashboard/org/info-org-modal/info-org-modal.page";
import {AdvocateModalPage} from "./pages/dashboard/family/advocate-modal/advocate-modal.page";
import { BackgroundCheckPolicyPage } from './pages/legal/background-check-policy/background-check-policy.page';
import {EncourageService} from "../providers/encourage.service";
import {EncouragePageModule} from "./pages/encourage/encourage.module";
import {StateManagementService} from "./api/services/state-management-service.service";
import {
  BackgroundCheckDescriptionPopoverComponent
} from "./popovers/background-check-description-popover/background-check-description-popover.component";
import {HeaderActionsPopoverComponent} from "./popovers/header-actions-popover/header-actions-popover.component";

@NgModule({
  declarations: [
    AppComponent,
    ToggleRolesComponent,
    CalendarComponent,
    TermsPage,
    PrivacyPage,
    FaithStatementPage,
    IntentStatementPage,
    HowTutorialComponent,
    InfoModalPage,
    InfoOrgModalPage,
    AdvocateModalPage,
    BackgroundCheckPolicyPage,
    BackgroundCheckDescriptionPopoverComponent,
    HeaderActionsPopoverComponent
  ],
  entryComponents: [
    ToggleRolesComponent,
    TermsPage,
    PrivacyPage,
    FaithStatementPage,
    IntentStatementPage,
    HowTutorialComponent,
    InfoModalPage,
    InfoOrgModalPage,
    AdvocateModalPage,
    BackgroundCheckPolicyPage
  ],
  exports: [FormsModule],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    NgxPermissionsModule.forRoot(),
    AppRoutingModule,
    IonicStorageModule.forRoot(),
    TagsModule,
    UsersModule,
    CauseGroupsModule,
    FriendModule,
    HttpClientModule,
    FormsModule,
    // TODO: check if this has to be installed here or only on dashboard
    // NgxQRCodeModule,
    NgxPaginationModule,
    TagInputModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (HttpLoaderFactory),
        deps: [HttpClient],
      },
    }),
    ClipboardModule,
    IonicSelectableModule,
    EncouragePageModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    AuthService,
    SettingsService,
    LocationService,
    OnboardingServices,
    {
      provide: APP_INITIALIZER,
      useFactory: (storage: Storage, authService: AuthService) => function () {
        return storage.get('loginRes')
          .then((val) => {
            if (val && val.hasOwnProperty('user')) {
              const user = (new ResourceSerializer(UserResource)).fromJson(val.user) as UserResource;
              authService.initRolesAndPermissions(user);
            }
          });
      },
      deps: [Storage, AuthService],
      multi: true,
    },

    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpAuthTokenInterceptor,
      multi: true,
    },
    Title,
    Meta,
    AddHeadLinkService,
    EncourageService,
    StateManagementService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { AuthService } from 'src/providers/auth/auth.service';
import { UserResource } from '../api/resources/user.resource';

@Component({
  selector: 'toggle-roles',
  templateUrl: './toggle-roles.component.html',
  styleUrls: ['./toggle-roles.component.scss']
})
export class ToggleRolesComponent {
  goToProfile = null;
  switchUser = null;
  families = [];
  organizations = [];
  selectedUser: UserResource = null;
  loggedUser: UserResource = null;

  constructor(
    private menu: MenuController,
    private auth: AuthService,
    public router: Router,
  ){}

  logout() {
    this.menu.close();
    this.auth
      .logout()
      .then(causeGroup => this.router.navigateByUrl(`/login/${causeGroup}`))
      .then(() => window.location.reload());
  }
}
import { Resource } from './resource';
import { UserResource } from './user.resource';

export class FriendshipResource extends Resource {
    static readonly PENDING = 0;

    static readonly ACCEPTED = 1;

    static readonly DENIED = 2;

    static readonly BLOCKED = 3;

    id: number = null;

    sender_id: number = null;

    sender: UserResource = null;

    recipient_id: number = null;

    recipient: UserResource = null;

    status: number = 0;

    created_at: string = null;

    updated_at: string = null;
}

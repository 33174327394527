import { Injectable } from '@angular/core';

interface UrlData {
  url: string,
  prefix: string,
}

@Injectable({
    providedIn: 'root',
})
export class UrlParserService {
  private url: string;
  private prefix: string;

  constructor(url: string) {
    this.url = url;
    this.parseUrl();
  }

  parseUrl() {
    this.prefix = this.url.match(/http[s]?:\/\//) ? this.url.match(/http[s]?:\/\//)[0] : 'https://';
    this.url = this.url.replace(/http[s]?:\/\//g, '');
  }

  getUrl() : string {
    return this.url;
  }

  getDBUrl() : string {
    return this.prefix + this.url;
  }

  getPrefix() : string {
    return this.prefix;
  }

}

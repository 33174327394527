import { Resource } from './resource';
import { EntityResource } from './entity.resource';

export class EntityConnectionsResource extends Resource {
    id : number = null;

    entity_a : number = null;

    entity_b : number = null;

    edge_id : number = null;

    edge: any = null;

    entity: EntityResource = null;

    created_at: string = null;

    updated_at: string = null;
}

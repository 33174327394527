import { Type } from '@angular/core';
import { Resource } from '../resources/resource';
import { EntityResource } from '../resources/entity.resource';
import { TagResource } from '../resources/tag.resource';
import { NeedOccurrenceResource } from '../resources/need-occurrence.resource';
import { UserResource } from '../resources/user.resource';
import {OrganizationResource} from "../resources/organization.resource";

export class ResourceSerializer {
  constructor(private resourceClass: Type<Resource>) {
  }

  fromJson(json: any, resourceClass?: Type<Resource>): Resource {
    const resource = new (resourceClass || this.resourceClass)();
    Object.getOwnPropertyNames(resource).forEach((p) => {
      let value = json![p];
      if (value !== null) {
        if (Array.isArray(value)) {
          if (p === 'tags') {
            value = value.map((v) => this.fromJson(v, TagResource));
          } else if (p === 'occurrences') {
            value = value.map((v) => this.fromJson(v, NeedOccurrenceResource));
          } else if (['allowed_users', 'representatives'].includes(p)) {
            value = value.map((v) => this.fromJson(v, UserResource));
          } else if (p === 'administrator_organizations') {
            value = value.map((value) => this.fromJson(value, OrganizationResource));
          }
        } else if (typeof value === 'object') {
          if (p === 'entity') {
            value = this.fromJson(value, EntityResource);
          } else if (p === 'occurrence') {
            value = this.fromJson(value, NeedOccurrenceResource);
          } else if (['user', 'initiated_by', 'sender', 'recipient', 'invited_by'].includes(p)) {
            value = this.fromJson(value, UserResource);
          }
        }
        resource[p] = value;
      }
    });

    return resource;
  }

  toJson(resource: Resource): any {
    return resource;
  }
}

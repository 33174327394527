import {Injectable} from "@angular/core";
import {DEFAULT_CAUSE_GROUP_ID} from "../const";
import {Environment} from "../environments/environment.prod";
import {Storage} from "@ionic/storage";
import {UserResource} from "../app/api/resources/user.resource";
import {CauseGroupService} from "../app/api/services/cause-group.service";
import {CauseGroupResource} from "../app/api/resources/cause-group.resource";

@Injectable({
  providedIn: 'root',
})

export class GetCauseGroupService {
  private causeGroup : string = DEFAULT_CAUSE_GROUP_ID;
  constructor(
    private storage: Storage
  ) {
  }

  public async get(): Promise<string> {

    // URL Param
    const urlParams = new URLSearchParams(window.location.search || '');
    if(urlParams.get('causeGroup')) {
      return urlParams.get('causeGroup').toLowerCase();
    }

    // Storage
    const storageCauseGroup = await this.storage.get('causeGroup');
    if (storageCauseGroup) {
      return storageCauseGroup
    }
    const loginRes = await this.storage.get('loginRes');
    if (loginRes) {
      return loginRes.user.causeGroups[0].slug
    }

    // Host Defined on Environment
    const host = window.location.host;
    if (Environment.hostMap[host]) {
      return Environment.hostMap[host];
    }

    return this.causeGroup;
  }

  public async isCauseGroupAdmin(
    currentUser: UserResource,
    causeGroupService: CauseGroupService,
    causeGroup: CauseGroupResource
  ): Promise<boolean> {
    return new Promise<boolean>(async (resolve) => {
      const hasRole = !!currentUser.roles.some(role => role.name === 'cause-group-admin');
      const isAdmin = !!await causeGroupService.isAdmin(currentUser, causeGroup)
        .catch((error) => console.error(error));
      resolve(hasRole && isAdmin);
    });
  }
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResourceService } from './resource.service';
import { RoleResource } from '../resources/role.resource';
import { ResourceSerializer } from '../serializers/resource-serializer';
import { ListResponse } from '../resources/list-response';

@Injectable()
export class RoleService extends ResourceService<RoleResource> {
    static resourceSlug: string = 'role';

    constructor(httpClient: HttpClient) {
      super(
        httpClient,
        RoleService.resourceSlug,
        new ResourceSerializer(RoleResource),
      );
    }

    available(params?: HttpParams): Observable<ListResponse<RoleResource>> {
      return this.list(params, '/available');
    }
}

import {Component, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Storage } from '@ionic/storage';
import { LoadingController, ToastController, AlertController, ModalController} from '@ionic/angular';
import { FriendService } from '../../../api/services/friend/friend.service';
import { UserResource } from '../../../api/resources/user.resource';
import { FriendshipService } from '../../../api/services/friend/friendship.service';
import { RoleService } from '../../../api/services/role.service';
import { RoleResource } from "../../../api/resources/role.resource";
import {FriendRequestService} from "../../../api/services/friend/friend-request.service";
import {lastValueFrom, Observable, of, tap} from "rxjs";
import {map} from "rxjs/operators";
import {StateManagementService} from "../../../api/services/state-management-service.service";
import {HandleAdvocateInnerCircleRemovalService} from "../../../shared/services/handle-advocate-inner-circle-removal.service";
import {MatTabChangeEvent} from "@angular/material/tabs";
import {ResourceService} from "../../../api/services/resource.service";
import {OrganizationResource} from "../../../api/resources/organization.resource";
import {ResourceSerializer} from "../../../api/serializers/resource-serializer";
import {EntityConnectionsService} from "../../../api/services/entity-connections.service";
import {CirclesListComponent} from "../../../shared/components/circles-list/circles-list.component";
import {RoleRelantionship} from "../../../api/resources/role-relantionship.resource";
import {FamilyResource} from "../../../api/resources/family.resource";
import {UserService} from "../../../api/services/user.service";

@Component({
  selector: 'friend-list',
  templateUrl: './friend-list.component.html',
  styleUrls: ['./friend-list.component.scss'],
})
export class FriendListComponent implements OnInit {
    public currentUser: UserResource = new UserResource();

    isLoadingFriends: boolean = true;

    private loading: any;

    dataRoles: RoleResource[];

    public selectedTabLabel: string = "connections";

    public friends$: Observable<UserResource[]>;

    public orgs$: Observable<OrganizationResource[]>;

    private friendService: FriendService;

    private friendshipService: FriendshipService;

    private friendRequestService: FriendRequestService;

    public isLoadingFriendRequests: boolean = false;

    userFamilies: FamilyResource[];
    private userService: UserService;

    activeCircle : string = null;
    public friendRequestsToYou$: Observable<UserResource[]>;
    public friendRequestsToOthers$: Observable<UserResource[]>;
    public searchString: string;
    indexWithResult: number = 0;
    connectionsCounter: number[] = [0,0,0,0];

    constructor(private httpClient: HttpClient,
                private route: ActivatedRoute,
                private storage: Storage,
                private loader: LoadingController,
                private toast: ToastController,
                private alert: AlertController,
                private roleService: RoleService,
                private appState: StateManagementService,
                private handleAdvocateInnerCircleRemovalService: HandleAdvocateInnerCircleRemovalService,
                private entityConnectionsService: EntityConnectionsService,
                private modalController: ModalController) {
      this.friendRequestsToYou$ = of([]);
      this.friendRequestsToOthers$ = of([]);
      this.friends$ = of([]);
      this.orgs$ = of([]);
      this.userService = new UserService(this.httpClient)
    }

    async ngOnInit() {
      this.route.params.subscribe(() => {
        this.storage.get('loginRes').then(async (val) => {
          this.currentUser = val.user;

          this.friendshipService = new FriendshipService(this.httpClient, `user/${this.currentUser.id}/${FriendshipService.resourceSlug}/user`);

          this.friendRequestService = new FriendRequestService(
            this.httpClient, `user/${this.currentUser.id}/${FriendRequestService.resourceSlug}`);

          await this.loadFriendRequestsToYou();
          await this.loadFriendRequestsToOthers();

          await this.loadFriends();
          await this.loadFamilies();
          await this.loadOrganizations();

          this.roleService.available().subscribe((resp) => {
            this.dataRoles = resp.data.filter(role => role.name !== 'organization-admin');
          });
        });
      });
    }

    ngDoCheck() {
      //  Do not do API calls or call any method that does API calls here. The ngDoCheck it's triggering a lot of times in this component.

      const index = this.connectionsCounter.findIndex(counter => counter > 0)
      const activeIndex = (index == -1) ? 0 : index;
      if(activeIndex !== this.indexWithResult) {
        this.indexWithResult = activeIndex
      }
    }

    private async  loadFriendRequestsToYou() {
      this.friendRequestService = new FriendRequestService(
        this.httpClient, `user/${this.currentUser.id}/${FriendRequestService.resourceSlug}/recipient/pending`
      );

      try {
        const requestsToYou = await lastValueFrom(this.friendRequestService.list());
        this.friendRequestsToYou$ = of(requestsToYou.data);

      } catch (errorResponse) {
        this.toast
          .create({
            header: 'Load error',
            message: errorResponse.error.message,
            buttons: [
              {
                text: 'Close',
                role: 'cancel',
                handler: () => {}
              }
            ],
            color: 'danger',
          })
          .then((toast) => toast.present());
      }
    }

    private async loadFamilies() {
      try {
        const familiesByUser$ = this.userService.getFamiliesByUser(this.currentUser.id);
        this.userFamilies = (await lastValueFrom(familiesByUser$)).data;
      } catch (error) {
        console.error(error.message);
      }
    }

    private async loadFriendRequestsToOthers() {

      this.friendRequestService = new FriendRequestService(
        this.httpClient, `user/${this.currentUser.id}/${FriendRequestService.resourceSlug}/sender/pending`
      );

      try {
        const requestsToOthers = await lastValueFrom(this.friendRequestService.list());
        this.friendRequestsToOthers$ = of(requestsToOthers.data);

      } catch (errorResponse) {
        this.toast
          .create({
            header: 'Load error',
            message: errorResponse.error.message,
            buttons: [
              {
                text: 'Close',
                role: 'cancel',
                handler: () => {}
              }
            ],
            color: 'danger',
          })
          .then((toast) => toast.present());
      }
    }



    private async loadFriends() {

      this.friendService = new FriendService(this.httpClient, `${FriendService.resourceSlug}/${this.currentUser.id}/user`);

      try {
        const friendsData = await lastValueFrom(this.friendService.list());
        this.friends$ = of(friendsData.data);

      } catch (error) {
        this.toast.create({
          header: 'Load error',
          message: error.error.message,
          buttons: [
            {
              text: 'Close',
              role: 'cancel',
              handler: () => {}
            }
          ],
          color: 'danger',
        }).then((toast) => toast.present());
      }
    }

    private async loadOrganizations() {

      const organizationService = new ResourceService<OrganizationResource>(this.httpClient,
        `user/${this.currentUser.id}/organization/edge/member_organization`,
        new ResourceSerializer(OrganizationResource));

      try {
        const orgsConnections = await lastValueFrom(organizationService.list());
        this.orgs$ = of(orgsConnections.data);

      } catch (error) {
        this.toast.create({
          header: 'Load error',
          message: error.error.message,
          buttons: [
            {
              text: 'Close',
              role: 'cancel',
              handler: () => {}
            }
          ],
          color: 'danger',
        }).then((toast) => toast.present());
      } finally {
        this.isLoadingFriends = false;
      }
    }


    public deleteFriend(user: UserResource): void {
      this.presentLoading();

      this.friendshipService
        .delete(user.id)
        .toPromise()
        .then(async () => {
          await this.handleAdvocateInnerCircleRemovalService.handleAdvocateInnerCircleRemoval(user, this.currentUser);
          this.appState.update({updateCirclesSlider: true});
          let friends = await this.friends$.toPromise();
          const index = friends.findIndex((value) => value.id === user.id);
          friends.splice(index, 1);
          this.friends$ = of(friends);
        })
        .catch((errorResponse) => {
          this.toast.create({
            header: 'Friendship error',
            message: errorResponse.error.message,
            buttons: [
              {
                text: 'Close',
                role: 'cancel',
                handler: () => {}
              }
            ],
            color: 'danger',
          }).then((toast) => toast.present());
        })
        .finally(() => { this.loading.dismiss(); });
    }

    public accept(friendRequest: UserResource): void {
      this.presentLoading();

      this.friendRequestService = new FriendRequestService(
        this.httpClient, `user/${this.currentUser.id}/${FriendRequestService.resourceSlug}`);

      this.friendRequestService
        .accept(friendRequest)
        .toPromise()
        .then(async (user) => {
          this.toast.create({
            header: 'Congrats!',
            message: 'Friendship Accepted',
            buttons: [
              {
                text: 'Close',
                role: 'cancel',
                handler: () => {}
              }
            ],
            color: 'success',
            duration: 5000,
          }).then((toast) => toast.present());
          let friendRequestToYou = await this.friendRequestsToYou$.toPromise()
          const index = friendRequestToYou.findIndex((value) => value.id === user.id);
          friendRequestToYou.splice(index, 1);
          this.friendRequestsToYou$ = of(friendRequestToYou);
          let friends = await this.friends$.toPromise();
          friends.push(user);
          this.friends$ = of(friends);
          this.appState.update({updateCirclesSlider: true});
        })
        .catch((errorResponse) => {
          this.toast
            .create({
              header: 'Friendship error',
              message: errorResponse.error.message,
              buttons: [
              {
                text: 'Close',
                role: 'cancel',
                handler: () => {}
              }
            ],
              color: 'danger',
            })
            .then((toast) => toast.present());
        })
        .finally(async () => {
          this.loading.dismiss();
        });
    }


  public async onDeleteBtnClick(friendRequest: UserResource, type = 'deny') {
    let message : string = 'Do you want to deny and delete this connection? This action is not reversible.';
    let buttonsArray =[{
            text: 'Cancel',
            role: 'cancel',
        },
        {
            text: 'Yes, Continue',
            role: 'confirm',
            handler: async () => {
                if (type === 'deny') {
                    await this.deny(friendRequest);
                }
                if (type === 'delete') {
                    this.deleteFriend(friendRequest);
                }
            }
        }];
    if (type === 'delete' ) {
      message = 'Do you want remove this connection? This action is not reversible.';
    }

    const confirmationAlert = await this.alert.create({
      header: 'Remove Connection - Confirmation',
      message: message,
      buttons: buttonsArray,
    })

    await confirmationAlert.present();
  }

  public friendshipRoles(friend: UserResource) {

    let rolesArray: string[] = [];
    friend.role_relationship.forEach((role: RoleRelantionship) => {
      if(friend.hasEdge(role, this.currentUser)) {
        rolesArray.push(friend.getRoleName(role.edge_id))
      }
    })

    return rolesArray;
  }

  public isFriendFamily(friendId: number): boolean {
      return this.userFamilies.some((family: FamilyResource): boolean => family.user_id === friendId );
  }

  public async onDeleteBtnClickWithRolesAlert(friend: UserResource, role: string) {
    let roleName = role.charAt(0).toUpperCase() + role.slice(1);

    let message = `Confirm removing <b>${roleName}</b> from <b>${friend.name}</b>?`

    const removeAlert = await this.alert.create({
      header: 'Remove Role Connection',
      message: message,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Remove role',
          role: 'confirm',
          handler: async () => {
            await this.deleteFriendshipRole(friend, role);
          }
        }
      ],
    })

    await removeAlert.present();
  }

  public async deleteFriendshipRole(friend: UserResource, selectedRole: string) {
    await this.presentLoading();

    try {
      const removed$ = this.friendshipService.removeFriendshipRole(friend.id, selectedRole);
      const removed = lastValueFrom(removed$);

      if(selectedRole === 'advocate' || selectedRole === 'inner-circle'){
          await this.handleAdvocateInnerCircleRemovalService.handleAdvocateInnerCircleRemoval(friend, this.currentUser);
      }

      this.appState.update({updateCirclesSlider: true});
      await this.loadFriends();

    } catch(errorResponse) {
      const errorToast = await this.toast.create({
        header: 'Friendship error',
        message: errorResponse.error.message,
        buttons: [
          {
            text: 'Close',
            role: 'cancel',
            handler: () => {}
          }
        ],
        color: 'danger',
      })

      await errorToast.present();
    } finally {
      this.loading.dismiss();
    }
  }

    public async deny(friendRequest: UserResource): Promise<void> {
      await this.presentLoading();

      this.friendRequestService = new FriendRequestService(
        this.httpClient, `user/${this.currentUser.id}/${FriendRequestService.resourceSlug}`);

      try {
        const userObservable$ = this.friendRequestService.deny(friendRequest);
        const user = await lastValueFrom(userObservable$);

        this.toast.create({
          header: 'Request Successful',
          message: 'Friendship Denied',
          buttons: [
            {
              text: 'Close',
              role: 'cancel',
              handler: () => {}
            }
          ],
          color: 'success',
          duration: 5000,
        }).then((toast) => toast.present());
        let friendRequestToYou = await lastValueFrom(this.friendRequestsToYou$);
        const index = friendRequestToYou.findIndex((value) => value.id === user.id);
        friendRequestToYou.splice(index, 1);
        this.friendRequestsToYou$ = of(friendRequestToYou);

        await this.handleAdvocateInnerCircleRemovalService.handleAdvocateInnerCircleRemoval(friendRequest, this.currentUser);
        this.appState.update({updateCirclesSlider: true});


      } catch(errorResponse) {
          this.toast
            .create({
              header: 'Friendship error',
              message: errorResponse.error.message,
              buttons: [
              {
                text: 'Close',
                role: 'cancel',
                handler: () => {}
              }
            ],
              color: 'danger',
            })
            .then((toast) => toast.present());
      }

      this.loading.dismiss();

    }

    public onRemoveConnectionBtnClick(friendRequest: OrganizationResource) {
      let message : string = 'Do you want remove this connection? This action is not reversible.';

      this.alert.create({
        header: 'Remove Connection - Confirmation',
        message: message,
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
          },
          {
            text: 'Yes, Continue',
            role: 'confirm',
            handler: async () => {
              this.removeConnection(friendRequest);
            }
          }
        ],
      }).then((alert: HTMLIonAlertElement) => alert.present());
    }

  public removeConnection(org: OrganizationResource): void {
    this.presentLoading();

    const path = `/member_organization/${org.entity_id}`;

    this.entityConnectionsService.delete(this.currentUser.entity_id, path).toPromise()
      .then(async () => {
        let orgs = await this.orgs$.toPromise();
        const index = orgs.findIndex((value) => value.id === org.id);
        orgs.splice(index, 1);
        this.orgs$ = of(orgs);
      })
      .catch((error) => {
        this.toast.create({
          header: 'Error Removing Organization Connection',
          message: error.error.message,
          buttons: [
            {
              text: 'Close',
              role: 'cancel',
              handler: () => {}
            }
          ],
          color: 'danger',
        }).then((toast) => toast.present());
      })
      .finally(() => { this.loading.dismiss(); });
  }

    presentInactiveAlert() {
      this.alert.create({
        header: 'Inactive User',
        message: 'User is currently receiving new needs emails but has not yet joined our platform.',
        buttons: ['OK']
      }).then((alert) => alert.present());
    }

    async presentLoading(options: object = { message: 'Please wait...' }) {
      this.loading = await this.loader.create(options);
      return await this.loading.present();
    }
  getConnectionsFiltered(searchString: string, selectedCircles: string = null, connections: Observable<UserResource[]>): Observable<UserResource[]> {
    if (!searchString && !selectedCircles) {
      this.updateTabsIndex(this.getObservableLength(connections), connections)
      return connections;
    }
    let regex = searchString ? new RegExp(searchString, 'gi') : new RegExp(null, 'gi');
    const circleRegex = new RegExp(selectedCircles, 'gi')

    return connections.pipe(
      map(friends => friends.filter(friend => {
        const nameMatches = friend.name.search(regex) > -1;
        const roleMatches = friend.role_relationship.some(role => {
          if (friend.hasEdge(role, this.currentUser)) {
            return friend.getRoleName(role.edge_id).search(regex) > -1;
          }
        });

        const circleMatches = friend.role_relationship.some(role => {
          if (friend.hasEdge(role, this.currentUser)) {
            return friend.getRoleName(role.edge_id).search(circleRegex) > -1;
          }
        });

        const hasFamilyRelationship = friend.roles.some(role => role.name.search(circleRegex) > -1);

        if (!searchString) {
          if (selectedCircles == 'family') {
            return circleMatches || hasFamilyRelationship;
          }
          return circleMatches;
        }
        if (!selectedCircles) { return nameMatches || roleMatches; }

        if (selectedCircles == 'family') {
          return (nameMatches || roleMatches) && (circleMatches || hasFamilyRelationship)
        }

        return (nameMatches || roleMatches) && circleMatches;
      })),
      tap((elements: any) => {
        this.updateTabsIndex(elements.length, connections)
      })
    )
  }

  getOrgsFiltered(searchString: string, selectedCircles: string = null): Observable<OrganizationResource[]> {
    if (!searchString && !selectedCircles) {
      this.updateTabsIndex(this.getObservableLength(this.orgs$), this.orgs$)
      return this.orgs$;
    }
    let regex = searchString ? new RegExp(searchString, 'gi') : new RegExp(null, 'gi');
    let circleType: string = null;

    if (selectedCircles === 'service-provider-non-profit') {
      circleType = 'NonProfit';
    }
    if (selectedCircles === 'businesses') {
      circleType = 'Organization';
    }
    if (selectedCircles === 'church') {
      circleType = 'Church';
    }

    return this.orgs$.pipe(
      map(orgs=> orgs.filter(org => {
        const nameMatches = org.name.search(regex) > -1;
        const circleMatches = org.type == circleType;

        if (!searchString) { return circleMatches; }
        if (!selectedCircles) { return nameMatches }
        return nameMatches && circleMatches;
      } )),
      tap((elements: any) => {
        this.updateTabsIndex(elements.length, this.orgs$)
      })
    )
  }

  updateTabsIndex(counter: number, obsv: Observable<UserResource[] | OrganizationResource[]>) {
    switch (obsv) {
      case this.friends$:
        this.connectionsCounter[0] = counter
        break;
      case this.orgs$:
        this.connectionsCounter[1] = counter
        break;
      case this.friendRequestsToYou$:
        this.connectionsCounter[2] = counter
        break;
      case this.friendRequestsToOthers$:
        this.connectionsCounter[3] = counter
        break;
    }
  }

  getObservableLength(obsv: Observable<UserResource[] | OrganizationResource[]>) {
    let elementCount = 0;

    obsv.pipe(
      tap(() => {
        elementCount++;
      })
    );

    return elementCount;
  }

  onTabSelected(event: MatTabChangeEvent){
    this.selectedTabLabel = event.tab.textLabel;
  }

  toggleActiveCircles(circle: string) {
    if (this.activeCircle === circle) {
      this.activeCircle = null;
    } else {
      this.activeCircle = circle;
    }

    this.getConnectionsFiltered(this.searchString, this.activeCircle, this.friends$);
    this.getOrgsFiltered(this.searchString, this.activeCircle);
    this.getConnectionsFiltered(this.searchString, this.activeCircle, this.friendRequestsToYou$);
    this.getConnectionsFiltered(this.searchString, this.activeCircle, this.friendRequestsToOthers$);
  }

    getCircleName(circleRole: string): string {
        let circle_name: string;
        switch (circleRole) {
            case 'family':
                circle_name = 'Family';
                break;
            case 'advocate':
                circle_name = 'Advocate';
                break;
            case 'inner-circle':
                circle_name = 'Friends';
                break;
            case 'volunteer':
                circle_name = 'Volunteer';
                break;
            case 'church':
                circle_name = 'Church';
                break;
            case 'service-provider-non-profit':
                circle_name = 'Provider';
                break;
            case 'businesses':
                circle_name = 'Business';
                break;
            default:
                circle_name = '';
        }

        return circle_name;
    }

  async showCirclesInformation() {
    await this.modalController
      .create({component: CirclesListComponent})
      .then((modal) => {
        modal.present();
        return modal;
      });
  }
}

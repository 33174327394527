<ion-content class="ion-padding">
  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-label>
          <h1>Edit User Privileges</h1>
        </ion-label>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="isLoading; else userBlock" class="ion-justify-content-center">
      <ion-col size="auto">
        <ion-spinner></ion-spinner>
      </ion-col>
    </ion-row>

    <ng-template #userBlock>
      <ion-row>
        <ion-col>
          <form [formGroup]="form.getForm()" (ngSubmit)="save()">
            <ion-list>
              <ion-col>
                <ion-row>
                  <ion-col>
                    <ion-item class="item-input">
                      <ion-label class="txt3">Admin</ion-label>
                      <ion-toggle formControlName="isAdmin" slot="start"></ion-toggle>
                      <ion-note color="danger" *ngIf="form.hasError('isAdmin')">
                        {{ form.getError('isAdmin')}}
                      </ion-note>
                    </ion-item>
                  </ion-col>
                </ion-row>

                <ion-row>
                  <ion-col>
                    <ion-button [disabled]="!form.getForm().valid" expand="block" type="submit">
                      <ion-icon slot="start" name="save"></ion-icon>
                      Save
                    </ion-button>
                  </ion-col>
                </ion-row>
              </ion-col>
            </ion-list>
          </form>
        </ion-col>
      </ion-row>
    </ng-template>
  </ion-grid>
</ion-content>

import {Component, OnInit} from '@angular/core';
import {ModalController} from "@ionic/angular";

@Component({
  selector: 'app-terms',
  templateUrl: './faith-statement.page.html',
  styleUrls: ['./faith-statement.page.scss'],
})
export class FaithStatementPage implements OnInit {

  constructor(private modal: ModalController) { }

  ngOnInit() {
  }

  dismissModal() {
    this.modal.dismiss();
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { IonicModule } from '@ionic/angular';
import { NgxPermissionsModule } from 'ngx-permissions';

import { TagListComponent } from './tag-list/tag-list.component';
import { TagEditComponent } from './tag-edit/tag-edit.component';

import { TagsRoutingModule } from './tags-routing.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    NgxPermissionsModule.forChild(),
    FormsModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    TagsRoutingModule,
  ],
  declarations: [
    TagListComponent,
    TagEditComponent,
  ],
})
export class TagsModule {}

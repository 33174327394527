import {NgModule} from "@angular/core";
import {IonicModule} from "@ionic/angular";
import {CommonModule} from "@angular/common";
import {FileUploadComponent} from "./file-upload.component";
import { ImageCropperModule } from 'ngx-image-cropper';

@NgModule({
  imports: [CommonModule, IonicModule, ImageCropperModule],
  exports: [FileUploadComponent],
  declarations: [FileUploadComponent],
})
export class FileUploadComponentModule {}

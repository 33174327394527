import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class AddHeadLinkService {
  constructor(@Inject(DOCUMENT) private doc: Document) {}

  addLink(name: string, value: string) {
    let link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', name);
    link.setAttribute(
      'href',
      value
    );
    this.doc.head.appendChild(link);
  }
}

import {Directive} from "@angular/core";
import {ControlValueAccessor, NgControl} from "@angular/forms";

@Directive({ selector: '[trimInput]' })
export class TrimInputDirective {
  constructor(
    private ngControl: NgControl
  ) {
    trimInputValueAccessor(ngControl.valueAccessor)
  }
}

function trimInputValueAccessor(valueAccessor: ControlValueAccessor) {
  const original = valueAccessor.registerOnChange;

  valueAccessor.registerOnChange = (fn: (_: unknown) => void) => {
    return original.call(valueAccessor, (value: unknown) => {
      return fn(typeof value === 'string' ? value.trim() : value);
    });
  };
}

// source: https://gist.github.com/NetanelBasal/6c389049d5fc57fd30e2a5635f6441ba

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Environment as Env } from '../environments/environment.prod';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {API_URL} from "../const";

export type LocationType = {
  city: string,
  state: string
}

@Injectable()
export class LocationService {
  private lat: any;
  private lon: any;

  resourceSlug: string = 'public/location'
  protected url: string = API_URL;

  constructor(private _http : HttpClient) {}

  /**
   * @desc get the geo position and return the data unless there are errors and then inform
   *
   * @returns {Promise<any>}
   */
  async getCurrentLocation(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition((position) => {
        resolve(position);
      }, (error) => {
        reject(error);
      });
    });
  }

  async getZipcodeFromLatLon(lat, lon): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.get(
        `${Env.mapboxApiUrl + lon},${lat}.json?access_token=${Env.mapbox_Token}`,
        { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) },
      )
        .toPromise()
        .then((val) => {
          resolve(val);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getLocationDataFromZip(zipcode: string): Observable<LocationType> {
    return this._http
    .post(`${this.url}${this.resourceSlug}`, { "zip" : zipcode })
    .pipe(map((data: any) => {
      return data;
    }));
  }

  storeLocation(lat, lon) {
    localStorage.set('loc', JSON.stringify({ lat, lon }));
  }

  sendLocation(lat, lon) {

  }

  resolveLocaction(coords) {
    // this will give us postal code for location if needed?
    // maybe execute all this on the server side.....
  }
}

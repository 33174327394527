import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { MyCircleAdminComponent } from "./my-circle-admin.component";
import {RouterModule} from "@angular/router";


@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, RouterModule],
  declarations: [MyCircleAdminComponent],
  exports: [MyCircleAdminComponent]
})
export class MyCircleAdminModuleComponent {}

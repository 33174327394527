import { Injectable } from "@angular/core";
import {OnboardingServices} from "../../../providers/onboarding.services";
import {EncouragePage} from "../../pages/encourage/encourage.page";
import {EncourageService} from "../../../providers/encourage.service";
import {ModalController} from "@ionic/angular";

@Injectable({
  providedIn: 'root',
})
export class NextEncouragementService {

  public keepGoing: boolean = false;

  constructor(
              private encourage: EncourageService,
              public modalController: ModalController,
              ) {
  }

  getEncourage(slug, questionnaire: string = null) {
    this.encourage.fetchEncourage(slug).subscribe((res) => {
      res.data ? this.showModal(res, questionnaire) : null;
    });
  }
  async showModal(slug, questionnaire: string = null) {

    questionnaire ? slug.data['cta_primary_link'] = '/post-question?slug='+questionnaire : '';

    const modal = await this.modalController.create({
      component: EncouragePage,
      componentProps: {
        slugData: slug,
      },
    });
    return modal.present();
  }
}


import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Storage } from '@ionic/storage';
import { ValidationManager } from 'ng2-validation-manager';
import { LoadingController, ToastController, AlertController } from '@ionic/angular';
import { NgxPermissionsService } from 'ngx-permissions';
import { SortDirection, SortPropDir } from '@swimlane/ngx-datatable';

import { TagService } from '../../../../api/services/tag.service';
import { TagResource } from '../../../../api/resources/tag.resource';
import { CauseGroupResource } from '../../../../api/resources/cause-group.resource';
import { CauseGroupService } from '../../../../api/services/cause-group.service';

@Component({
  selector: 'tag-edit',
  templateUrl: './tag-edit.component.html',
  styleUrls: ['./tag-edit.component.css'],
})
export class TagEditComponent implements OnInit {
    private causeGroupId: number;

    public isLoading: boolean = false;

    public isLoadingCauseGroups: boolean = false;

    public tagId: number;

    private currentUser: any = {};

    private tag: TagResource;

    public causeGroups: CauseGroupResource[];

    private causeGroupsSorts: SortPropDir[];

    public form: ValidationManager;

    private loading: any;

    private tagService: TagService;

    private causeGroupService: CauseGroupService;

    constructor(private httpClient: HttpClient,
                private route: ActivatedRoute,
                private router: Router,
                private storage: Storage,
                private loader: LoadingController,
                private toast: ToastController,
                private alertController: AlertController,
                private permissionsService: NgxPermissionsService) {
      this.tagService = new TagService(this.httpClient);
      this.causeGroupService = new CauseGroupService(this.httpClient);

      this.form = new ValidationManager({
        name: 'required|rangeLength:2,255',
        category: 'required|rangeLength:2,255',
      });
      this.permissionsService.hasPermission(['all-tags_add', 'all-tags_edit']).then((hasPermission) => {
        if (hasPermission) {
          const causeGroupControl = this.form.buildControl('cause_group_id', 'required|number').control;
          this.form.getForm().addControl('cause_group_id', causeGroupControl);
        }
      });

      this.causeGroupsSorts = [
        { prop: 'name', dir: SortDirection.asc },
      ];
    }

    ngOnInit() {
      this.route.params.subscribe((params) => {
        this.form.reset();

        this.causeGroupId = params.hasOwnProperty('causeGroupId') ? parseInt(params.causeGroupId) : null;
        this.tagId = params.hasOwnProperty('id') ? parseInt(params.id) : null;

        this.storage.get('loginRes').then((val) => {
          this.currentUser = val.user;

          const endpoint = this.causeGroupId ? `cause-group/${this.causeGroupId}/tag` : TagService.resourceSlug;
          this.tagService = new TagService(this.httpClient, endpoint);

          if (this.tagId) {
            this.loadTag();
          } else {
            this.tag = new TagResource();
            this.permissionsService.hasPermission(['all-tags_add', 'all-tags_edit']).then((hasPermission) => {
              if (hasPermission) this.loadCauseGroups();
            });
          }
        });
      });
    }

    private loadTag(): void {
      this.isLoading = true;

      this.tagService
        .read(this.tagId)
        .toPromise()
        .then((tag) => {
          this.tag = tag;

          this.form.setValue({
            name: this.tag.name,
            category: this.tag.category,
          });

          this.permissionsService.hasPermission(['all-tags_add', 'all-tags_edit']).then((hasPermission) => {
            if (hasPermission) {
              this.form.getControl('cause_group_id').setValue(this.tag.cause_group_id);
              this.loadCauseGroups();
            }
          });
        })
        .catch((errorResponse) => {
          this.toast.create({
            header: 'Load error',
            message: errorResponse.error.message,
            buttons: [
              {
                text: 'Close',
                role: 'cancel',
                handler: () => {}
              }
            ],
            color: 'danger',
          }).then((toast) => toast.present());
        })
        .finally(() => { this.isLoading = false; });
    }

    loadCauseGroups() {
      this.isLoadingCauseGroups = true;

      let causeGroupsParams = new HttpParams();

      this.causeGroupsSorts.forEach((sort, index) => {
        for (const key in sort) {
          causeGroupsParams = causeGroupsParams.set(`sorts[${index}][${key}]`, sort[key].toString());
        }
      });

      this.causeGroupService
        .list(causeGroupsParams)
        .toPromise()
        .then((value) => {
          this.causeGroups = value.data;
        })
        .catch((errorResponse) => {
          this.toast.create({
            header: 'Load error',
            message: errorResponse.error.message,
            buttons: [
              {
                text: 'Close',
                role: 'cancel',
                handler: () => {}
              }
            ],
            color: 'danger',
          }).then((toast) => toast.present());
        })
        .finally(() => { this.isLoadingCauseGroups = false; });
    }

    public save() {
      if (this.form.isValid()) {
        this.presentLoading();

        const formData = this.form.getData();

        const tagData = { ...formData };
        if (this.tag.id) tagData.id = this.tag.id;

        Object.assign(this.tag, formData);

        const observable = this.tag.id ? this.tagService.update(tagData) : this.tagService.create(tagData);

        observable
          .toPromise()
          .then((tag) => {
            this.tagId = tag.id;
            this.tag = tag;

            this.toast.create({
              header: 'Congrats!',
              message: 'Saved successfully',
              buttons: [
              {
                text: 'Close',
                role: 'cancel',
                handler: () => {}
              }
            ],
              color: 'success',
              duration: 5000,
            }).then((toast) => toast.present());

            const redirectUrl = this.causeGroupId ? `/admin/cause-group/${this.causeGroupId}/tags` : '/admin/tags';
            this.router.navigate([redirectUrl, { _: (new Date()).getTime() }]);
          })
          .catch((errorResponse) => {
            this.toast.create({
              header: 'Save error',
              message: errorResponse.error.message,
              buttons: [
              {
                text: 'Close',
                role: 'cancel',
                handler: () => {}
              }
            ],
              color: 'danger',
            }).then((toast) => toast.present());
          })
          .finally(() => { this.loading.dismiss(); });
      }
    }

    private delete() {
      if (this.tag.id) {
        this.presentLoading();

        this.form.reset();

        this.tagService
          .delete(this.tag.id)
          .toPromise()
          .then(() => {
            this.tagId = null;
            this.tag = new TagResource();

            this.toast.create({
              header: 'Congrats!',
              message: 'Deleted successfully',
              buttons: [
              {
                text: 'Close',
                role: 'cancel',
                handler: () => {}
              }
            ],
              color: 'success',
              duration: 5000,
            }).then((toast) => toast.present());

            const redirectUrl = this.causeGroupId ? `/admin/cause-group/${this.causeGroupId}/tags` : '/admin/tags';
            this.router.navigate([redirectUrl, { _: (new Date()).getTime() }]);
          })
          .catch((errorResponse) => {
            this.toast.create({
              header: 'Delete error',
              message: errorResponse.error.message,
              buttons: [
              {
                text: 'Close',
                role: 'cancel',
                handler: () => {}
              }
            ],
              color: 'danger',
            }).then((toast) => toast.present());
          })
          .finally(() => { this.loading.dismiss(); });
      }
    }

    async presentDeleteConfirm() {
      const alert = await this.alertController.create({
        header: 'Confirm!',
        message: 'Are you sure you want to delete?',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
          }, {
            text: 'Ok',
            handler: () => this.delete(),
          },
        ],
      });

      await alert.present();
    }

    async presentLoading(options: object = { message: 'Please wait...' }) {
      this.loading = await this.loader.create(options);
      return await this.loading.present();
    }
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ResourceService } from './resource.service';
import { ResourceSerializer } from '../serializers/resource-serializer';
import { CauseGroupResource } from '../resources/cause-group.resource';
import { ListResponse } from '../resources/list-response';


@Injectable()
export class UserCauseGroupService extends ResourceService<CauseGroupResource> {
    static resourceSlug: string = 'cause-group';

    constructor(httpClient: HttpClient, endpoint: string = UserCauseGroupService.resourceSlug) {
      super(
        httpClient,
        endpoint,
        new ResourceSerializer(CauseGroupResource),
      );
    }

    sync(userId: number, ids: {ids: number[]}, options: object = {}): Observable<ListResponse<CauseGroupResource>> {
      return this.httpClient
        .put(`${this.url}user/${userId}/${this.endpoint}`, ids, options)
        .pipe(map((response: any) => {
          const listResponse = new ListResponse<CauseGroupResource>();
          listResponse.metadata = response.metadata;
          listResponse.data = this.convertData(response.data);

          return listResponse;
        }));
    }
}

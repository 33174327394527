import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { NeedResource } from '../resources/need.resource';
import { ResourceService } from './resource.service';
import { ResourceSerializer } from '../serializers/resource-serializer';
import { ListResponse } from '../resources/list-response';

@Injectable()
export class NeedService extends ResourceService<NeedResource> {
    static resourceSlug: string = 'need';

    constructor(httpClient: HttpClient, endpoint: string = NeedService.resourceSlug) {
      super(
        httpClient,
        endpoint,
        new ResourceSerializer(NeedResource),
      );
    }

    listAll(options: object = {}, path: string = ''): Observable<ListResponse<NeedResource>> {
      return this.httpClient
        .get(`${this.url}${this.endpoint}${path}`, options)
        .pipe(map((response: any) => {
          const listResponse = new ListResponse<NeedResource>();
          listResponse.metadata = response.metadata;
          listResponse.data = this.convertData(response.data);

          return listResponse;
        }));
    }

    listNearest(options: object = {}): Observable<ListResponse<NeedResource>> {
      return this.listAll(options, '/map');
    }

    shareViaEmail(needId, userId, data:any)
    {
        return this.httpClient
            .post(`${this.url}shareNeed/${needId}/user/${userId}`, this.serializer.toJson(data))
            .pipe(map((response: any) => {
                return response;
            }));
    }

    getNeedRsvps(needId:number) {
      return this.httpClient.get(`${this.url}need/${needId}/rsvps`)
      .pipe(map( (response: any) => {
        return response;
      }))
    }

    access(needId:number) {
      return this.httpClient.get(`${this.url}public/need/${needId}/access`)
        .pipe(map( (response:any) => {
          return response;
        }))
    }

    getPendingEmails(needId: number) {
      return this.httpClient.get(`${this.url}need/${needId}/pending/emails`)
        .pipe(map((response:any) => {
          return response;
      }));
    }

    setEndPoint(endpoint: string) {
      super.endpoint = endpoint;
    }
    approve(item: NeedResource): Observable<NeedResource> {
        return this.httpClient
            .post<NeedResource>(`${this.url}${this.endpoint}/${item.id}/approve`, null)
            .pipe(map((data: any) => this.serializer.fromJson(typeof data.data === 'undefined' ? data : data.data) as NeedResource));
    }

    reject(item: NeedResource): Observable<NeedResource> {
        return this.httpClient
            .post<NeedResource>(`${this.url}${this.endpoint}/${item.id}/reject`, null)
            .pipe(map((data: any) => this.serializer.fromJson(typeof data.data === 'undefined' ? data : data.data) as NeedResource));
    }
    toggleStatus(active: boolean, resource: NeedResource): Observable<NeedResource> {
        if(active) {
            return this.approve(resource);
        }
        return this.reject(resource);
    }
}

<ion-list>
    <ion-item lines="full">
        <ion-label><h2>Choose How to Interact</h2><small>You can interact as a profile or a family you manage</small></ion-label>
    </ion-item>
    <ng-container *ngIf="selectedUser !== null">
        <ion-radio-group [value]="selectedUser.id">
            <ion-item (click)="switchUser(loggedUser)" lines="full">
                <ion-thumbnail slot="start">
                    <img alt="User Cover Image" [src]=" loggedUser.cover_image || '../assets/img/family.png'" />
                </ion-thumbnail>
                <ion-label><small>{{loggedUser.first_name + ' ' + loggedUser.last_name}}</small></ion-label>
                <ion-radio class="family-radio" slot="end" [value]="loggedUser.id"></ion-radio>
            </ion-item>
            <ion-item *ngFor="let family of families" (click)="switchUser(family.user)" lines="full">
                <ion-thumbnail slot="start">
                    <img alt="Family Cover Image" [src]="family.cover_image || '../assets/img/family.png'" />
                </ion-thumbnail>
                <ion-label><small>{{family.first_name + ' ' + family.last_name}} <em>[FAMILY]</em></small></ion-label>
                <ion-radio class="family-radio" slot="end" [value]="family.user.id"></ion-radio>
            </ion-item>
            <ion-item *ngFor="let organization of organizations" (click)="switchUser(organization.claimed_by_user_id, organization.id)" lines="full">
                <ion-thumbnail slot="start">
                    <img alt="Organization Cover Image" [src]="organization.getCoverImage()" />
                </ion-thumbnail>
                <ion-label><small>{{organization.name}} <em>[ORGANIZATION]</em></small></ion-label>
                <ion-button size="small" color="secondary" class="button-block">
                    <ion-icon name="eye"></ion-icon>
                </ion-button>
            </ion-item>
        </ion-radio-group>
    </ng-container>
    <ion-item lines="none" detail="false" button="true" (click)="goToProfile()">
        <ion-icon name="settings" slot="start"></ion-icon>
        <ion-label><small>Update Your Profile</small></ion-label>
    </ion-item>
    <ion-item lines="none" detail="false" button="true" (click)="logout()">
        <ion-icon name="log-out" slot="start"></ion-icon>
        <ion-label><small>Logout</small></ion-label>
    </ion-item>
</ion-list>

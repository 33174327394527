import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {Inject, Injectable} from '@angular/core';
import { UserResource } from '../resources/user.resource';
import { ResourceService } from './resource.service';
import { ResourceSerializer } from '../serializers/resource-serializer';
import {Resource} from "../resources/resource";
import {CauseGroupResource} from "../resources/cause-group.resource";
import {Form} from "@angular/forms";
import { UserAnswerResource } from '../resources/user-answer.resource';
import { ListResponse } from '../resources/list-response';
import {FamilyResource} from "../resources/family.resource";

@Injectable()
export class UserService extends ResourceService<UserResource> {
    static resourceSlug: string = 'user';

    constructor(httpClient: HttpClient, @Inject('userServiceEndpoint') endpoint: string = UserService.resourceSlug) {
      super(
        httpClient,
        endpoint,
        new ResourceSerializer(UserResource),
      );
    }

    invite(userId: number, item: UserResource): Observable<UserResource> {
      return this.create(item, `/${userId}/invite`);
    }

    inviteFamilyRepresentative(user: UserResource, familyId: number): Observable<UserResource> {
      return this.httpClient
        .post<UserResource>(`${this.url}family/${familyId}/invite-representative`, this.serializer.toJson(user))
        .pipe(map((data: any) => this.serializer.fromJson(typeof data.data === 'undefined' ? data : data.data) as UserResource));
    }

    updateBio(formData: FormData): Observable<UserResource> {
      return this.httpClient
        .post<UserResource>(`${this.url}${this.endpoint}/${formData.get('id')}/bio`, formData)
        .pipe(map((data: any) => {
          return this.serializer.fromJson(typeof data.data === 'undefined' ? data : data.data) as UserResource;
        }));
    }
    updateQuickConnect(formData: FormData): Observable<UserResource> {
      return this.httpClient
        .post<UserResource>(`${this.url}${this.endpoint}/${formData.get('id')}/quickconnect`, formData)
        .pipe(map((data: any) => {
          return this.serializer.fromJson(typeof data.data === 'undefined' ? data : data.data) as UserResource;
        }));
    }

  updateStep(userId, step): Observable<UserResource> {
      return this.httpClient
        .post<UserResource>(`${this.url}${this.endpoint}/${ userId }/step/${ step }`, null )
        .pipe(map((data: any) => {
          return this.serializer.fromJson(typeof data.data === 'undefined' ? data : data.data) as UserResource;
        }));
    }

    updateBioForce(formData: FormData): Observable<UserResource> {
        return this.httpClient
            .post(`${this.url}${this.endpoint}/${formData.get('id')}/bioforce`, formData)
            .pipe(map((data: any) => {
                return this.serializer.fromJson(typeof data.data === 'undefined' ? data : data.data) as UserResource;
            }));
    }


    getCauseGroup(userId, options:object = {}): Observable<CauseGroupResource>
    {
        return this.httpClient
            .get(`${this.url}${this.endpoint}/${userId}/causeGroup`, options)
            .pipe(map((data: any) => {
                let causeGroupSerializer = new ResourceSerializer(CauseGroupResource);
                return causeGroupSerializer.fromJson(typeof data.causeGroup === 'undefined' ? data : data.causeGroup) as CauseGroupResource;
            }));
    }

    export(causeGroupId, options:Object):Observable<any>
    {
        return this.httpClient
            .get(`${this.url}${this.endpoint}/export`,  options )
            .pipe(map((data: any) => {
               return data;
            }));
    }

    getUserAnswers(userId: number, options:object = {}): Observable<ListResponse<UserAnswerResource>> {
      const userAnswersSerializer = new ResourceSerializer(UserAnswerResource);
      return this.httpClient
        .get(`${this.url}${this.endpoint}/${userId}/answers`, options)
        .pipe(map((response: any) => {
          const listResponse = new ListResponse<UserAnswerResource>();
          listResponse.metadata = response.metadata;
          listResponse.data = response.data.map((userAnswer: any) => {
            return userAnswersSerializer.fromJson(userAnswer) as UserAnswerResource;
          });
          return listResponse;
        }));
    }

    getExistingUser(userId: number, email: string): Observable<UserResource>
    {
        const options = {
          headers: new HttpHeaders({
              'Content-Type': 'application/json',
          }),
        };
        return this.httpClient
            .post(`${this.url}${this.endpoint}/${userId}/getByEmail`, JSON.stringify({email: email}), options)
            .pipe(map((data: any) => {
                const userSerializer = new ResourceSerializer(UserResource);
                return userSerializer.fromJson(typeof data.data === 'undefined' ? data : data.data) as UserResource;
            }));
    }

    getFamiliesByUser(userId: number, searchQueryString: string = '') : Observable<ListResponse<FamilyResource>> {
      const familyResourceSerializer = new ResourceSerializer(FamilyResource);
      let params = (new HttpParams());

      if (searchQueryString) {
        params = params.append('query', searchQueryString);
      }
      return this.httpClient
        .get(`${this.url}${this.endpoint}/${userId}/family`, {params})
        .pipe(map( (response : ListResponse<FamilyResource>) => {
          const listResponse = new ListResponse<FamilyResource>();
          listResponse.metadata = response.metadata;
          if (!Array.isArray(response.data)) {
            response.data = Object.keys(response.data).map( key => response.data[key]);
          }
          listResponse.data = response.data.map( (value: FamilyResource) => {
            return familyResourceSerializer.fromJson(value) as FamilyResource;
          });

          return listResponse;
        }))
    };

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResourceService } from './resource.service';
import { ApiReturnResource } from '../resources/api-return.resource';
import { ResourceSerializer } from '../serializers/resource-serializer';
import { EntityConnectionsResource } from '../resources/entity-connections.resource';

@Injectable()
export class EntityConnectionsService extends ResourceService<EntityConnectionsResource> {
    static resourceSlug: string = 'connections/entity';

    constructor(httpClient: HttpClient) {
      super(
        httpClient,
        EntityConnectionsService.resourceSlug,
        new ResourceSerializer(EntityConnectionsResource),
      );
    }
}

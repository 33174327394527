import { PipeTransform, Pipe } from "@angular/core";

@Pipe({
    name: 'titleCase'
})
export class TitleCasePipe implements PipeTransform {


  transform(word: string) {

    if (!word) return word;
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }
}

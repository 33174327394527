import { Injectable } from '@angular/core';
import {
  CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise<boolean | UrlTree>((resolve, reject) => {
      this.authService.isAuthenticated().then((isAuthenticated) => {
        if (isAuthenticated) {
          resolve(true);
        } else {
          // Store the attempted URL for redirecting
          this.authService.redirectUrl = state.url;

          resolve(this.router.parseUrl('/login'));
        }
      });
    });
  }
}

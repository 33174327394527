import {Component, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {isToday} from "date-fns";
import {Validators} from "@angular/forms";
import {CauseGroupUserService} from "../../../../api/services/cause-group-user.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {lastValueFrom, Observable} from "rxjs";
import {UserResource} from "../../../../api/resources/user.resource";
import {ValidationManager} from "ng2-validation-manager";
import {RecommendationsService} from "../../../../../providers/recommendations.service";
import {ToastController} from "@ionic/angular";
import {Storage} from "@ionic/storage";

export type returnStatus = {
  status: string,
  message: string
}

@Component({
  selector: 'cause-group-update-rules',
  templateUrl: './cause-group-update-rules.component.html'
})
export class CauseGroupUpdateRulesComponent implements OnInit {

  public date: string;
  public causeGroupId: number;
  public allowedUserValidators = [Validators.minLength(2), Validators.maxLength(255)];
  public causeGroupUserService: CauseGroupUserService;
  public availableUsers: UserResource[];
  public form: ValidationManager;
  public disableForm = true;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private httpClient: HttpClient,
    private toast: ToastController,
    private storage: Storage
  ) {
  }

  ngOnInit(): void {
    this.form = new ValidationManager({
      existing_user: 'required'
    });
    this.route.params.subscribe(async (params) => {
      this.date = params.hasOwnProperty('date') ? params.date : null;
      this.causeGroupId = params.hasOwnProperty('id') ? parseInt(params.id) : null;
      const date = new Date(this.date);
      if (!isToday(date) || !this.causeGroupId) {
        await this.router.navigateByUrl('/dashboard');
      }
      try {
        this.availableUsers = await this.loadUsers();
        this.disableForm = false;
      } catch (e) {
        if (e.status === 403) {
          const toast = await this.toast.create({
            header: 'Error',
            message: 'The Cause Group ID on the URL is not the correct CG admin Cause Group.',
            buttons: [
              {
                text: 'Close',
                role: 'cancel',
                handler: () => {
                }
              }
            ],
            color: 'danger',
            duration: 5000,
          });
          await toast.present();
        }
      }
    });
  }

  private async loadUsers() : Promise<UserResource[]>
  {
    const endpoint = `cause-group/${this.causeGroupId}/user`;
    this.causeGroupUserService = new CauseGroupUserService(this.httpClient, endpoint);
    let params = new HttpParams();
    params.set('sorts[0][prop]', 'name');
    params.set('sorts[0][dir]', 'asc');
    const user$ = this.causeGroupUserService.list(params);
    return (await lastValueFrom(user$)).data;
  }

  public tagMatching(value: string, target: UserResource): boolean {
    return target.email.includes(value);
  }

  public async handleClick(method: string = 'processRecommendations') {
    if (method === 'processRecommendations' && !this.form.isValid()) {
      return;
    }
    const recommendationsService = new RecommendationsService(this.storage, this.httpClient);
    const formData = this.form.getData();
    let recommendationServiceObservable$: Observable<any>;
    if (method === 'processRecommendations') {
      recommendationServiceObservable$ = recommendationsService.processRecommendation(
        formData['existing_user'][0].id,
        this.causeGroupId
      );
    } else {
      recommendationServiceObservable$ = recommendationsService.updateRules(
        this.causeGroupId
      );
    }
    const returnStatus: returnStatus = await lastValueFrom(recommendationServiceObservable$);
    let header = 'Success';
    let message = '';
    let color = 'success';
    if (returnStatus.status === 'error') {
      header = 'Error!';
      message = method + ' Failure: ';
      color = 'danger';
    }
    const toast = await this.toast.create({
      header,
      message: message + returnStatus.message,
      buttons: [
        {
          text: 'Close',
          role: 'cancel',
          handler: () => {
          }
        }
      ],
      color,
      duration: 5000,
    });
    await toast.present();

  }
}

<ion-content>
  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-label>
          <h1>Tags</h1>
        </ion-label>

      </ion-col>
    </ion-row>

    <ng-container *ngxPermissionsOnly="['tags_add', 'all-tags_add']">
      <ion-row justify-content-end>
        <ion-col size="auto">
          <ion-button [routerLink]="causeGroupId ? ['/admin/cause-group', causeGroupId, 'tag', 'add'] : ['/admin/tag/add']" size="small">
            <ion-icon slot="start" name="add-circle"></ion-icon>
            Create
          </ion-button>
            <ion-button (click)="export()" size="small">Export</ion-button>

        </ion-col>
      </ion-row>
        <ion-row>
            <ion-col>
                <form [formGroup]="form.getForm()" (ngSubmit)="search()">
                    <ion-list>
                        <ion-row>
                            <ion-col size="12">
                                <ion-item class="item-input">
                                    <ion-label class="txt3" position="stacked">Keyword</ion-label>
                                    <ion-input formControlName="keyword" placeholder="Keyword" type="search"></ion-input>
                                    <ion-note color="danger" *ngIf="form.hasError('keyword')">
                                        {{ form.getError('keyword')}}
                                    </ion-note>
                                </ion-item>
                            </ion-col>

                            <ion-row class="ion-text-center" >
                                <ion-col>
                                    <ion-button [disabled]="!form.getForm().valid" type="submit" expand="block">
                                        <ion-icon slot="start" name="analytics"></ion-icon>
                                        Show
                                    </ion-button>
                                </ion-col>
                            </ion-row>
                        </ion-row>
                    </ion-list>
                </form>
            </ion-col>

        </ion-row>
    </ng-container>

    <ion-row>
      <ion-col>
        <ngx-datatable
          [rows]="tags"
          [loadingIndicator]="isLoadingTags"

          class="material striped"
          [columnMode]="columnMode"
          headerHeight="50"
          rowHeight="auto"
          footerHeight="50"
          scrollbarH="true"

          [externalPaging]="true"
          [count]="page.totalElements"
          [offset]="page.pageNumber"
          [limit]="page.size"
          (page)="setPage($event)"

          [sorts]="sorts"
          [sortType]="sortType"
          [externalSorting]="true"
          (sort)="onSort($event)"
        >
          <!--<ngx-datatable-column name="ID" prop="id" width="40"></ngx-datatable-column>-->
          <ngx-datatable-column name="Name" prop="name"></ngx-datatable-column>
          <ngx-datatable-column name="Slug" prop="slug" width="80"></ngx-datatable-column>
          <ngx-datatable-column name="Category" prop="category" [pipe]="titleCasePipe" width="105"></ngx-datatable-column>
          <ngx-datatable-column name="Actions" [sortable]="false" width="70">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
              <ng-container *ngxPermissionsOnly="['tags_edit', 'all-tags_edit']">
                <ion-button [routerLink]="causeGroupId ? ['/admin/cause-group', causeGroupId, 'tag', row.id] : ['/admin/tag', row.id]" size="small">
                  <ion-icon slot="icon-only" name="create"></ion-icon>
                </ion-button>
              </ng-container>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
import { Component, OnInit, Input } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-encourage',
  templateUrl: './encourage.page.html',
  styleUrls: ['./encourage.page.scss'],
})
export class EncouragePage implements OnInit {
    @Input() slugData: any;

    data: any;

    constructor(
        public navParams: NavParams,
        public modalCtrl: ModalController,
        private router: Router,
    ) {
      this.data = navParams.get('slugData');
    }

    ngOnInit() {}

    public handlePrimaryClick() {
      this.modalCtrl.dismiss({
        dismissed: true,
      });

      this.slugData.data.cta_primary_link && this.slugData.data.cta_primary_link !== 'dismiss'
        ? this.router.navigateByUrl(this.slugData.data.cta_primary_link)
        : null;
    }

    public handleSecondaryClick() {
      this.modalCtrl.dismiss({
        dismissed: true,
      });
      this.slugData.data.cta_secondary_link
        ? this.router.navigateByUrl(this.slugData.data.cta_secondary_link)
        : null;
    }

    dismissModal(data: any) {
        this.modalCtrl.dismiss(data);
    }
}

import {Injectable, Injector} from "@angular/core";
import {StateManagementService} from "../../api/services/state-management-service.service";
import {UserResource} from "../../api/resources/user.resource";
import {ADVOCATE_EDGE_ID, INNER_CIRCLE_EDGE_ID} from "../../../const";
import {FamilyResource} from "../../api/resources/family.resource";
import {UserService} from "../../api/services/user.service";
import {lastValueFrom} from "rxjs";
import {EntityConnectionsService} from "../../api/services/entity-connections.service";
import {HttpClient} from "@angular/common/http";
import {Storage} from "@ionic/storage";

@Injectable({
  providedIn: null
})
export class HandleAdvocateInnerCircleRemovalService {
  private httpClient: HttpClient;
  private storage: Storage;
  private appState: StateManagementService;
  constructor( private injector: Injector
  ) {
    this.httpClient = this.injector.get(HttpClient);
    this.storage = this.injector.get(Storage);
    this.appState = this.injector.get(StateManagementService);
  }


  /**
   * Handle Role Removal from user and family
   *
   * @param friendRequest
   * @param currentUser
   */
  public async handleAdvocateInnerCircleRemoval(friendRequest: UserResource, currentUser: UserResource): Promise<void> {
    await this.handleAdvocateFamilyRemoval(friendRequest, currentUser);
    await this.handleInnerCircleFamilyRemoval(friendRequest, currentUser);
  }
  /**
   * Handle Relationships between user and family by CGAdmin
   *
   * @param friendRequest
   * @param user
   */
  public async cgHandleAdvocateInnerCircleRemoval(friendRequest: UserResource, user: UserResource): Promise<void> {

    // remove advocate relationship
    await this.updateFamilyConnections(friendRequest, user);
    await this.updateFamilyConnections(friendRequest, user, 'inner-circle');
  }
  /**
   * Handle Advocate Relationship between user and family
   *
   * @param friendRequest
   * @param currentUser
   */
  private async handleAdvocateFamilyRemoval(friendRequest: UserResource, currentUser: UserResource): Promise<void> {
    const advocateRole = friendRequest.getRoleRelantionshipIfExist(ADVOCATE_EDGE_ID, currentUser);
    if (advocateRole && friendRequest.hasEdge(advocateRole, currentUser)) {
      await this.updateFamilyConnections(friendRequest, currentUser);
    }
  }

  /**
   * Handle Inner Circle Relationship between user and family
   *
   * @param friendRequest
   * @param currentUser
   */
  private async handleInnerCircleFamilyRemoval(friendRequest: UserResource, currentUser: UserResource): Promise<void> {
    const innerCircleRole = friendRequest.getRoleRelantionshipIfExist(INNER_CIRCLE_EDGE_ID, currentUser);
    if (innerCircleRole && friendRequest.hasEdge(innerCircleRole, currentUser)) {
      await this.updateFamilyConnections(friendRequest, currentUser, 'inner-circle');
    }
  }


  private async updateFamilyConnections(
    friendRequest: UserResource,
    currentUser: UserResource,
    edge_slug: string = 'advocate'
  ) {
    let user_id = currentUser.id;
    let userFamily: FamilyResource = null;
    const userService = new UserService(this.httpClient);
    let userFamilies$ = userService.getFamiliesByUser(friendRequest.id);
    let userFamilies = await lastValueFrom(userFamilies$);

    // we need to know this in case the currentUser it's the advocate of the family
    const family = userFamilies.data.filter(family => {
      return family.user_id === user_id
    });

    // this is necessary for inner circles, in case we can't find the family on the friendRequestUser
    if (!userFamilies.data.length || family.length == 0) {
      userFamilies$ = userService.getFamiliesByUser(currentUser.id);
      userFamilies = await lastValueFrom(userFamilies$);
      user_id = friendRequest.id;
    }

    if (userFamilies.data.length === 1) {
      userFamily = userFamilies.data[0] as FamilyResource;
    } else if (userFamilies.data) {
      const family = userFamilies.data.filter(family => {
        return family.user_id === user_id
      });
      if (family) {
        userFamily = family[0];
      }
    }
    if (userFamily) {
      // as we don't know which is the family user (currentUser or friendRequest) we have to confirm we are removing
      // the correct friendship relationship to the family
      const user_entity_id = userFamily.user_id !== currentUser.id ? currentUser.entity_id : friendRequest.entity_id;
      const path = `/${edge_slug}/${userFamily.entity_id}`;
      const entityConnectionsService = new EntityConnectionsService(this.httpClient);
      const delete$ = entityConnectionsService.delete(user_entity_id, path);
      await lastValueFrom(delete$);
      const families = await lastValueFrom(userService.getFamiliesByUser(currentUser.id));
      this.appState.update({toggledUserFamilies: families.data});
      this.appState.update({userFamilies: families.data});
    }
  }
}

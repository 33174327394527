import { NgModule } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { UserSearchPipe } from "./user-search-pipe";
import { OpenNeedPipe } from "./open-need-pipe";
import { ReadMorePipe } from "./read-more-pipe";
import { ArraySortPipe } from "./array-sort-pipe";
import {FriendshipSearchPipe} from "./friendship-search-pipe";
import {AllowStylesPipe} from "./allow-styles-pipe";
import { TitleCasePipe } from "./title-case-pipe";


@NgModule({
    declarations: [UserSearchPipe, OpenNeedPipe, ReadMorePipe, ArraySortPipe, FriendshipSearchPipe, AllowStylesPipe, TitleCasePipe],
    imports: [IonicModule],
    exports: [UserSearchPipe, OpenNeedPipe, ReadMorePipe, ArraySortPipe, FriendshipSearchPipe, AllowStylesPipe, TitleCasePipe]
})
export class PipesModule {}

/* eslint-disable camelcase */
import { Resource } from './resource';
import { CauseGroupResource } from './cause-group.resource';
import {FRIENDSHIP_EDGE_ID} from "../../../const";
import {OrganizationResource} from "./organization.resource";
import {RoleRelantionship} from "./role-relantionship.resource";
import * as ini from "ini";

export class UserResource extends Resource {
    id: number = null;

    first_name: string = null;

    last_name: string = null;

    name: string = null;

    email: string = null;

    zip_code: string = null;

    active: boolean = null;

    rememberence_date : string = null;

    verified: boolean = null;

    status: string = null;

    created_at: string = null;

    updated_at: string = null;

    // deleted_at: string = null;
    entity_id: number = null;

    roles: any[] = [];

    permissions: any[] = [];

    types: string = null;

    causeGroups: CauseGroupResource[] = [];

    invited_by: UserResource = null;

    cover_image : string = null;

    background_check_completed: string = 'pending';

    background_check_date: string = null;

    bio : string = null;

    dob : string = null;

    dob_range : string = null;

    knowledge_source : string = null;

    next_step : any[] = [];

    anniversary : string = null;

    cause_group : string = null;

    administrator_organizations: [] = null;

    first_church: OrganizationResource[] = null;

    role_relationship: RoleRelantionship[] = null;

    quick_connect: string = null;

    quick_connect_reasons: string = null;

    quick_connect_date: string = null;

    quick_connect_time: string = null;

    connect_notes: string = null;

    connect_status: string = null;

    phone: string = null;

    getRoleName($edgeId): string {
      let role_name;
      switch ($edgeId) {
        case 1:
          role_name = 'family';
          break;
        case 14:
          role_name = 'inner-circle';
          break;
        case 15:
          role_name = 'advocate';
          break;
        case 20:
          role_name = 'volunteer';
          break;
        case 21:
          role_name = 'church';
          break;
        case 23:
          role_name = 'org-admin';
          break;
        default:
          role_name = '';
      }

      return role_name;
    }

    getRoleTitles(): string {
      return this.roles.map((role) => role.title || role.name).join(', ');
    }

    isNextStep( stepName: string): boolean {
      if (this.next_step) {
        return Object.keys(this.next_step).includes(stepName);
      }
      return false;
    }

    hasRole(isRole: string): boolean {
      return this.roles.some((role) => role.title.toLowerCase() === isRole || role.name.toLowerCase() === isRole);
    }

    hasAnyRole(roles: string[]): boolean {
      return roles.some((role) => this.hasRole(role));
    }

    hasEdge(role: RoleRelantionship,currentUser: UserResource) {

      return role.edge_id !== FRIENDSHIP_EDGE_ID && ( role.pivot.entity_a == currentUser.entity_id || role.pivot.entity_b == currentUser.entity_id);

    }
    hasAnyRelationshipEdge(role, currentUser): boolean {

      return  this.role_relationship.some(
        (edge) => edge['edge_id'] === role && ( edge['pivot']['entity_a'] == currentUser.entity_id || edge['pivot']['entity_b'] == currentUser.entity_id) )

    }

    getRoleRelantionshipIfExist(roleEdgeId:number, currentUser: UserResource):RoleRelantionship|null {
      const role = this.role_relationship.filter( role => {
        return role.edge_id === roleEdgeId && currentUser.entity_id === role.pivot.entity_b
      });

      return role ? role[0] : null;

    }

    getFriendshipRoles(friend: UserResource, currentUser: UserResource): string[] {
      let rolesArray: string[] = [];
      friend.role_relationship.forEach((role: RoleRelantionship) => {
        if(friend.hasEdge(role, currentUser)) {
          rolesArray.push(friend.getRoleName(role.edge_id))
        }
      })

      return rolesArray;
    }

    getTotalFriendships(): number {

        let totalFriendships = 0;
        this.role_relationship.forEach((role: RoleRelantionship) => {

            if (this.getRoleName(role.edge_id) !== '') {
                totalFriendships++;
            }
        })
        return totalFriendships;
    }


}

import { Environment } from './environments/environment.prod';
import {TermsPage} from "./app/pages/legal/terms/terms.page";
import {PrivacyPage} from "./app/pages/legal/privacy/privacy.page";
import {FaithStatementPage} from "./app/pages/legal/faith-statement/faith-statement.page";
import {IntentStatementPage} from "./app/pages/legal/intent-statement/intent-statement.page";
import { BackgroundCheckPolicyPage } from './app/pages/legal/background-check-policy/background-check-policy.page';

export enum EntityType {
    ORGANIZATION = 'org'
}

export const API_URL = Environment.server + Environment.apiUrl;

export const DEFAULT_CAUSE_GROUP_ID: string = 'mtn';

export const ADVOCATE_ROLE_ID: number = 5;

export const INNER_CIRCLE_ROLE_ID: number = 6;

export const CHURCH_ROLE_ID: number = 7;

export const VOLUNTEER_ROLE_ID: number = 8;

export const BUSINESS_ROLE_ID: number = 9;

export const SERVICE_PROVIDER_ROLE_ID: number = 10;

export const ORGADMIN_ROLE_ID: number = 12;

export const FRIENDSHIP_EDGE_ID: number = 13;

export const INNER_CIRCLE_EDGE_ID: number = 14;

export const ADVOCATE_EDGE_ID: number = 15;

export const VOLUNTEER_EDGE_ID: number = 20;

export const CHURCH_EDGE_ID: number = 21;

export const URL_REGEXP = /^[A-Za-z][A-Za-z\d.+-]*:\/*(?:\w+(?::\w+)?@)?[^\s/]+(?::\d+)?(?:\/[\w#!:.?+=&%@\-/]*)?$/;

export const URL_REGEXP_WITH_WRAPPER = /^(([-\w#!d%+:;?_=&\[\]+]{1,})[.\/])(([-\w!#d%+:;?_=&\[\]+.\/]{2,}))$/;
export const URL_PATTER_VALIDATOR_MANAGER = 'pattern:/(([\\-\\w\\#\\!\\d\\%\\+\\:\\;\\?\\_\\=\\&\\[\\]\\+]{1,})[\\.\\/])(([\\-\\w\\!\\#\\d\\%\\+\\:\\;\\?\\_\\,\\=\\&\\[\\]\\+\\.\\/]{2,}))/';

export const ZIPCODE_REGEX = /^[0-9]{5}(-[0-9]{4})?$/;
export const ZIPCODE_REGEX_PATTERN = 'pattern:/[0-9]{5}(-[0-9]{4})?/';

export const MONETARY_PATTERN_VALIDATOR_MANAGER = 'pattern:/^(?!.*funds|.*bill|.*finance|.*financial|.*money|.*loan|.*utility|.*utilities|.*rent|.*\\$|.*pay|.*dollar).*$/';
export const MONETARY_REGEX_VALIDATOR = /^(?!.*funds|.*money|.*loan|.*\$|.*pay|.*dollar|.*bill|.*finance|.*financial|.*utility|.*utilities|.*rent).*$/;
export const EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const TARGET_AUDIENCES = [
         { abbr: 'family', title: 'Family' },
         { abbr: 'advocate/inner-circle', title: 'Advocate/Inner Circle' },
         { abbr: 'volunteer', title: 'Volunteer' },
         { abbr: 'administrator', title: 'Administrator' }
       ];

export const MODAL_MAPPER: object = {
  terms: {
    page: TermsPage
  },
  privacy: {
    page: PrivacyPage
  },
  faith: {
    page: FaithStatementPage
  },
  intent: {
    page: IntentStatementPage,
  },
  backgroundCheckPolicy: {
    page: BackgroundCheckPolicyPage,
  },
};

export const NEED_AUDIENCE = {
  private: 'Specific Contacts',
  'semi-private': 'Your Connections',
  public: 'General Volunteers'
}

export const SHARE_TARGET_AUDIENCE = {
  need: '/dashboard/show-need/',
  resource: '/resource-detail/',
  organization: '/dashboard/organization/'
}

export const NEED_AUDIENCE_DETAIL = {
  'semi-private-advocate': 'The Family\'s Advocate(s)',
  'semi-private-inner': 'The Family\'s Inner Circle and Advocates',
  'semi-private-church': 'The Family\'s Church, Advocate and Inner Circles',
  'semi-private-volunteer': 'The Family\'s Volunteer, Church, Advocate and Inner Circles',
  'semi-private-provider': 'The Family\'s Provider, Volunteer, Church, Advocate, Inner Circles'
}

export const MAX_FILE_BYTES = 10485760;
export const MAX_FILE_STRING = '10MB';

export const UPLOAD_IMAGES_COPYRIGHT = "By uploading an image, I agree that I created this image or I have the " +
  "proper licensing to use this image."

export const MAX_NUMBER_CURRENT_NEEDS = 3;

export const RECOMMENDATIONS_SLIDER_OPTIONS: object = {
  init: false,
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
    clickable: true,
    dynamicBullets: true,
    enabled: true
  },
  rewind: true,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false,
    pauseOnMouseEnter: true,
  },
  speed: 1200,
  spaceBetween: 10,
  slidesPerView: 1,
  breakpoints: {
    // when window width is >= 768px
    768: {
      slidesPerView: 2,
      spaceBetween: 20,
      pagination: {
        enabled: true
      },
    },
    // when window width is >= 1024px
    1024: {
      slidesPerView: 3,
      spaceBetween: 20,
      pagination: {
        enabled: true
      },
    },
  }
}


export const CHART_PRIMARY_COLORS = [
  '#b3c7b3',
  '#96b695',
  '#72b071',
  '#478146',
  '#275427',
  '#173417'
]

export const CHART_SECONDARY_COLORS = [
  '#91c6d5',
  '#4198b9',
  '#0c83ac',
  '#136597',
  '#034670',
  '#01304a'
]

export const CHART_TERTIARY_COLORS = [
  '#ffd07c',
  '#ffbf65',
  '#ffa30f',
  '#cd8300',
  '#8c5200',
  '#6a3e00'
]

export const NEXT_STEPS_LEVELS_TITLE = {
  'growth': {
    0:'Join',
    1:'Build',
    2:'Engage',
    3:'Grow',
    4:'Impact'
  },
  'impact': {
    0: 'Assess',
    1: 'Learn',
    2: 'Network',
    3: 'Grow',
    4: 'More'
  }
}

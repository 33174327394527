import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from "@ionic/angular";
import {CauseGroupResource} from "../../../../api/resources/cause-group.resource";

@Component({
  selector: 'app-advocate',
  templateUrl: './advocate-modal.page.html',
  styleUrls: ['./advocate-modal.page.scss'],
})
export class AdvocateModalPage implements OnInit {
  @Input() causeGroup: CauseGroupResource;
  constructor(private modal: ModalController) { }

  ngOnInit() {
  }

  dismissModal() {
    this.modal.dismiss();
  }

}

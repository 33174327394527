<ion-header>
  <ion-toolbar>
    <ion-title class="pdl-20">Commitment Options Information</ion-title>
      <ion-buttons slot="end">
          <ion-button (click)="dismissModal()" color="dark">Close</ion-button>
      </ion-buttons>
  </ion-toolbar>
</ion-header>


<ion-content class="ion-padding modal-content">
    <ion-card>
        <ion-button disabled="true" class="button button-block ion-text-wrap inline-block">
            <ion-icon name="water" class="start-icon feature-icon" slot="start"></ion-icon>
            <span class="mgl-15">Meet</span>
        </ion-button>
        <p class="mgt-10">Both parties will be emailed to connect to arrange delivery.</p>
    </ion-card>
    <ion-card>
        <ion-button disabled="true" class="button button-block ion-text-wrap inline-block" color="secondary">
            <ion-icon name="water" class="start-icon feature-icon" slot="start"></ion-icon>
            <span class="mgl-15">Refer</span>
        </ion-button>
        <p class="mgt-10">
            The provider info will be emailed to the family.
            The administrator will review the provider for admission to the directory (new only).
        </p>
    </ion-card>
    <ng-container *ngxPermissionsOnly="['financial_gift']">
        <ion-card>
            <ion-button disabled="true" class="button button-block ion-text-wrap inline-block" color="primary">
                <ion-icon name="water" class="start-icon feature-icon" slot="start"></ion-icon>
                <span class="mgl-15">Donate</span>
            </ion-button>
            <p class="mgt-10">Both parties will be emailed to connect to arrange payment.</p>
        </ion-card>
    </ng-container>
    <ion-card>
        <ion-button disabled="true" class="button button-block ion-text-wrap inline-block" color="warning">
            <ion-icon name="water" class="start-icon feature-icon" slot="start"></ion-icon>
            <span class="mgl-15">Share</span>
        </ion-button>
        <p class="mgt-10">This need will be shared by email with an individual you enter.</p>
    </ion-card>
</ion-content>

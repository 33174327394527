import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/providers/auth/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'welcome',
    pathMatch: 'full',
  },
  {
    path: 'faqs',
    loadChildren:
        () => import('./pages/faq/faq.component.module').then(m => m.FaqComponentModule),
  },
  {
    path: 'stats',
    loadChildren:
            () => import('./pages/stats/stats.component.module').then(m => m.StatsComponentModule),
  },
  {
    path: 'connect',
    loadChildren:
            () => import('./pages/dashboard/connect/connect.component.module').then(m => m.ConnectComponentModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'connect/:session',
    loadChildren:
        () => import('./pages/dashboard/connect/connect.component.module').then(m => m.ConnectComponentModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'grow',
    loadChildren:
            () => import('./pages/dashboard/grow/grow.component.module').then(m => m.GrowComponentModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'grow/:session',
    loadChildren:
        () => import('./pages/dashboard/grow/grow.component.module').then(m => m.GrowComponentModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'impact',
    loadChildren:
      () => import('./pages/dashboard/impact/impact.component.module').then(m => m.ImpactComponentModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'impact/:session',
    loadChildren:
        () => import('./pages/dashboard/impact/impact.component.module').then(m => m.ImpactComponentModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'progress',
    loadChildren:
      () => import('./pages/progress/progress.component.module').then(m => m.ProgressComponentModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    pathMatch: 'full',
    loadChildren:
            () => import('./pages/common/login/login-page.component.module').then(m => m.LoginPageModule),
  },
  {
    path: 'login/:causeGroup',
    pathMatch: 'full',
    loadChildren:
            () => import('./pages/common/login/login-page.component.module').then(m => m.LoginPageModule),
  },
  {
    path: 'forgot-password',
    loadChildren:
            () => import('./pages/common/forgot-password/forgot-password-page.component.module').then(m => m.ForgotPasswordComponentModule),
  },
  {
    path: 'reset-password/:token',
    loadChildren:
            () => import('./pages/common/reset-password/reset-password-page.component.module').then(m => m.ResetPasswordPageComponentModule),
  },
  {
    path: 'create-password/:token/:causeGroup',
    loadChildren:
        () => import('./pages/common/create-password/create-password-page.component.module').then(m => m.CreatePasswordPageComponentModule),
  },
  {
    path: 'create-password/:token/:causeGroup/:invitingId',
    loadChildren:
      () => import('./pages/common/create-password/create-password-page.component.module').then(m => m.CreatePasswordPageComponentModule),
  },
  {
    path: 'create-password/:token/:causeGroup/:invitingId/:needId',
    loadChildren:
      () => import('./pages/common/create-password/create-password-page.component.module').then(m => m.CreatePasswordPageComponentModule),
  },
  {
    path: 'register-organization',
    loadChildren:
            () => import('./pages/common/register/organization/register-organization-page.component.module').then(m => m.RegisterOrganizationPageModule),
  },
  {
    path: 'invite/:familyId/:userId',
    loadChildren:
        () => import('./pages/common/invite-registration/invite-registration.component.module').then(m => m.InviteRegistrationComponentModule),
  },
  {
    path: 'invite-family/:familyId/:userId',
    loadChildren:
        () => import('./pages/common/invite-registration-family/invite-registration-family.component.module').then(m => m.InviteRegistrationFamilyComponentModule),
  },
  {
    path: 'dashboard',
    loadChildren:
            () => import('./pages/dashboard/dashboard.component.module').then(m => m.DashboardComponentModule),
  },
  {
    path: 'gen-error',
    loadChildren:
            () => import('./pages/_error/gen-error.component.module').then(m => m.GenErrorModule),
  },
  {
    path: 'bible-page',
    loadChildren:
            () => import('./pages/bible/bible.component.module').then(m => m.BibleComponentModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'bible-page/:book/:chapter',
    loadChildren:
            () => import('./pages/bible/bible.component.module').then(m => m.BibleComponentModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'bible-page/:book/:chapter/:verse',
    loadChildren:
            () => import('./pages/bible/bible.component.module').then(m => m.BibleComponentModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'bible-reference',
    loadChildren:
            () => import('./pages/bible/bible-reference.component.module').then(m => m.BibleReferenceComponentModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'send-email',
    loadChildren:
            () => import('./pages/email/send-email.component.module').then(m => m.SendEmailComponentModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'guide',
    loadChildren: () => import('./pages/guide/guide.component.module').then(m => m.GuideModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'guide/:level',
    loadChildren: () => import('./pages/guide/guide.component.module').then(m => m.GuideModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'leaderboard',
    loadChildren:
            () => import('./pages/user-ui/leaderboard/leader-board.component.module').then(m => m.LeaderBoardComponentModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'favorites',
    loadChildren:
            () => import('./pages/user-ui/favorites/favorites.component.module').then(m => m.FavoritesComponentModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'recommendations',
    loadChildren:
      () => import('./pages/recommendations/recommendations.component.module').then(m => m.RecommendationsComponentModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'resource-type',
    loadChildren:
            () => import('./pages/resources/resource-type.component.module').then(m => m.ResourceTypeComponentModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'resource-list',
    loadChildren:
            () => import('./pages/resources/resource-list.component.module').then(m => m.ResourceListComponentModule)
  },
  {
    path: 'resource-list/:edgeCode',
    loadChildren:
            () => import('./pages/resources/resource-list.component.module').then(m => m.ResourceListComponentModule),

  },
  {
    path: 'resource-map',
    loadChildren:
            () => import('./pages/resources/resource-map.component.module').then(m => m.ResourceMapComponentModule),

  },
  {
    path: 'add-resource',
    loadChildren:
            () => import('./pages/resources/add-resource.component.module').then(m => m.AddResourceComponentModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'resource-detail/:id',
    loadChildren:
            () => import('./pages/resources/resource-detail.component.module').then(m => m.ResourceDetailComponentModule),
  },
  {
    path: 'resource-upload',
    loadChildren:
            () => import('./pages/resources/resource-upload.component.module').then(m => m.ResourceUploadComponentModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'ob-circles',
    loadChildren:
            () => import('./pages/user-ui/onboarding/circles-page.component.module').then(m => m.CirclesPageComponentModule),
  },
  {
    path: 'ob-mission',
    loadChildren:
            () => import('./pages/user-ui/onboarding/mission.component.module').then(m => m.MissionComponentModule),
  },
  {
    path: 'ob-questions',
    loadChildren:
            () => import('./pages/user-ui/onboarding/questions-timeline.component.module').then(m => m.QuestionsTimelineComponentModule),
  },
  {
    path: 'ob-recommendations',
    loadChildren:
            () => import('./pages/user-ui/onboarding/recommendations.component.module').then(m => m.RecommendationsComponentModule),
  },
  {
    path: 'ob-join',
    loadChildren:
            () => import('./pages/user-ui/onboarding/join-community.component.module').then(m => m.JoinCommunityComponentModule),
  },
  {
    path: 'ob-video',
    loadChildren:
            () => import('./pages/user-ui/onboarding/intro-video.component.module').then(m => m.IntroVideoComponentModule),
  },
  {
    path: 'ob-reg',
    loadChildren:
            () => import('./pages/user-ui/onboarding/registration.component.module').then(m => m.RegistrationComponentModule),
  },
  {
    path: 'post-reg-into',
    loadChildren:
            () => import('./pages/user-ui/questions/post-reg-intro.component.module').then(m => m.PostRegIntroComponentModule),
  },
  {
    path: 'q-encouragement',
    loadChildren:
            () => import('./pages/encouragement/encourage.component.module').then(m => m.EncourageModule),
  },{
    path: 'styles',
    loadChildren:
            () => import('./pages/styles/styles.module').then(m => m.StylesPageModule),
  },
  {
    path: 'post-question',
    loadChildren:
            () => import('./pages/user-ui/questions/post-question-component/post-question.component.module').then(m => m.PostQuestionComponentModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'invite-friends',
    loadChildren:
            () => import('./pages/user-ui/connections/invite-friends.component.module').then(m => m.InviteFriendsComponentModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'invite-friends/friend/:friendId',
    loadChildren:
            () => import('./pages/user-ui/connections/invite-friends.component.module').then(m => m.InviteFriendsComponentModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'invite-friends/family/:familyId',
    loadChildren:
      () => import('./pages/user-ui/connections/invite-friends.component.module').then(m => m.InviteFriendsComponentModule),
    canActivate: [AuthGuard],
  },
    {
    path: 'find-friends',
    loadChildren: () => import('./pages/user-ui/connections/find-friends.component.module').then(m => m.FindFriendsComponentModule),
    canActivate: [AuthGuard],
  },
  {
      path: "list-user",
      loadChildren:
          () => import('./pages/user/list-user.component.module').then(m => m.ListUserComponentModule),
      canActivate: [AuthGuard],
  },
  {
      path: "list-user/:edgeCode",
      loadChildren:
          () => import('./pages/user/list-user.component.module').then(m => m.ListUserComponentModule),
      canActivate: [AuthGuard],
  },
  {
    path: 'profile',
    loadChildren:
            () => import('./pages/user/show-user.component.module').then(m => m.ShowUserComponentModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'user/:id',
    loadChildren:
            () => import('./pages/user/show-user.component.module').then(m => m.ShowUserComponentModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'profile/edit',
    loadChildren:
            () => import('./pages/user/edit-user.component.module').then(m => m.EditUserComponentModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'profile/change-password',
    loadChildren:
            () => import('./pages/user/change-password.component.module').then(m => m.ChangePasswordComponentModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'quick-connect',
    loadChildren:
      () => import('./pages/quick-connect/quick-connect.component.module').then(m => m.QuickConnectComponentModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'admin/user/:id/change-password',
    loadChildren:
            () => import('./pages/user/change-password.component.module').then(m => m.ChangePasswordComponentModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'search',
    loadChildren:
            () => import('./pages/search/search.component.module').then(m => m.SearchComponentModule),
    canActivate: [AuthGuard],
  },


  // admin pages
  {
    path: 'admin/organization',
    loadChildren:
            () => import('./pages/mtn-ui/org-admin/org-admin.component.module').then(m => m.OrgAdminComponentModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'admin/resource',
    loadChildren:
            () => import('./pages/mtn-ui/resource-admin/resource-admin.component.module').then(m => m.ResourceAdminComponentModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'encourage',
    loadChildren: () => import('./pages/encourage/encourage.module').then(m => m.EncouragePageModule),
  },
  {
    path: 'delete-need',
    loadChildren:
            () => import('./pages/delete-need/delete-need.module').then(m => m.DeleteNeedPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'accept-decline',
    loadChildren:
            () => import('./pages/accept-decline/accept-decline.module').then(m => m.AcceptDeclinePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'delete-need',
    loadChildren:
        () => import('./pages/delete-need/delete-need.module').then(m => m.DeleteNeedPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'accept-decline',
    loadChildren:
        () => import('./pages/accept-decline/accept-decline.module').then(m => m.AcceptDeclinePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'commit-options',
    loadChildren: () => import('./pages/dashboard/need/commit-options/commit-options.module').then(m => m.CommitOptionsPageModule),
  },
  { path: 'contact', loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactPageModule)},
  { path: 'background-check-policy', loadChildren: () => import('./pages/background-check-policy/background-check-policy.module').then(m => m.BackgroundCheckPolicyModule)},
  { path: 'terms', loadChildren: () => import('./pages/legal/terms/terms.module').then(m => m.TermsPageModule) },
  { path: 'privacy', loadChildren: () => import('./pages/legal/privacy/privacy.module').then(m => m.PrivacyPageModule) },
  { path: 'statement', loadChildren: () => import('./pages/legal/statement/statement.module').then(m => m.StatementPageModule) },
  {
    path: 'welcome',
    loadChildren: () => import('./pages/user-ui/onboarding/welcome.component.module').then(m => m.WelcomeComponentModule),
  },
  {
    path: 'welcome/:causeGroup',
    loadChildren: () => import('./pages/user-ui/onboarding/welcome.component.module').then(m => m.WelcomeComponentModule),
  },
  {
    path: 'ob-pre-registration-questions',
    loadChildren:
            () => import('./pages/user-ui/onboarding/pre-registration-questions.component.module').then(m => m.PreRegistrationQuestionsComponentModule),
  },
  {
    path: 'ob-first-resource',
    loadChildren:
            () => import('./pages/user-ui/onboarding/first-resource.component.module').then(m => m.FirstResourceComponentModule),
  },
  {
    path: 'claim-organization/:orgId',
    loadChildren:
        () => import('./pages/common/claim-organization/claim-organization.component.module').then(m => m.ClaimOrganizationComponentModule),
  },
  {
    path: 'invite-organization-member/:role/:orgId',
    loadChildren:
            () => import('./pages/user-ui/connections/invite-friends.component.module').then(m => m.InviteFriendsComponentModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'accept-invite-organization/:role/:orgId/:userId/:existingUser',
    loadChildren:
            () => import('./pages/dashboard/org/accept-invite-organization.component.module').then(m => m.AcceptInviteOrganizationComponentModule),
  },
  {
    path: 'maintenance-mode',
    loadChildren: () => import('./pages/user-ui/maintenance-mode/maintenance-mode.module').then(
      m => m.MaintenanceModeModule
    ),
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    relativeLinkResolution: 'legacy'
}),
  ],
  exports: [RouterModule],
  providers: [AuthGuard],
})
export class AppRoutingModule {}

<ion-card  >
  <ion-card-header>
    <ion-card-title>Connections to providers</ion-card-title>
  </ion-card-header>
  <ion-card-content *ngIf="totalOrganizations">
    <h3>Administrator</h3>
    <ion-text *ngIf="!totalOrganizations.length; else totalorgs">No administrator providers</ion-text>
    <ng-template #totalorgs>
      <ion-list>
        <ion-item *ngFor="let org of totalOrganizations" detail="true" lines="none"
                  >
          <ion-avatar *ngIf="org.cover_image" slot="start" [routerLink]="['/dashboard/organization', org.id]">
            <img alt="Organization Cover Image" src="{{org.cover_image}}">
          </ion-avatar>
            <div class="ion-item-content">
                <h3 [routerLink]="['/dashboard/organization', org.id]">{{org.name}}</h3>
                <ion-chip>org-admin</ion-chip>
            </div>
          <!-- shows only to cgadmin on remove -->
          <ion-button *ngIf="activeClaim && removeViewOnly"  (click)="orgConnect.removeClaimOrganization(user, org); activeClaim = false; setTimeout(loadOrganizations(),3000)"
                      color="danger" size="default" class="icon-text leave " slot="end">
            <ion-icon name="log-out"></ion-icon>
            <ion-label>Unclaim</ion-label>
          </ion-button>
        </ion-item>
      </ion-list>
    </ng-template>
  </ion-card-content>
  <ion-card-content *ngIf="totalConnectedOrganizations">
    <h3>Connected</h3>
    <ion-text *ngIf="!totalConnectedOrganizations.length; else totalconnectorgs">No connected providers</ion-text>
    <ng-template #totalconnectorgs>
      <ion-list>
        <ion-item *ngFor="let org of totalConnectedOrganizations" detail="true" lines="none"
                  >
          <ion-avatar *ngIf="org.cover_image" slot="start" [routerLink]="['/dashboard/organization', org.id]">
            <img alt="Organization Cover Image" src="{{org.cover_image}}">
          </ion-avatar>
            <div class="ion-item-content">
                <h3 [routerLink]="['/dashboard/organization', org.id]" >{{org.name}}</h3>
                <ion-chip >connected</ion-chip>
            </div>
          <!-- shows only to cgadmin on remove -->
          <ion-button *ngIf="removeViewOnly" (click)="orgConnect.onLeaveBtnClick(user, org);loadOrganizations()"
                       color="danger" size="default" class="icon-text leave " slot="end">
            <ion-icon name="log-out"></ion-icon>
            <ion-label>Leave</ion-label>
          </ion-button>
        </ion-item>
      </ion-list>
    </ng-template>
  </ion-card-content>
  <ion-card-content *ngIf="totalVolunteerOrganizations">
    <h3>Volunteer</h3>
    <ion-text *ngIf="!totalVolunteerOrganizations.length; else totalvolorgs">No volunteer providers</ion-text>
    <ng-template #totalvolorgs>
      <ion-list>
        <ion-item *ngFor="let org of totalVolunteerOrganizations" detail="true" lines="none"
                  >
          <ion-avatar *ngIf="org.cover_image" slot="start" [routerLink]="['/dashboard/organization', org.id]">
            <img alt="Organization Cover Image" src="{{org.cover_image}}">
          </ion-avatar>
            <div class="ion-item-content">
                <h3 [routerLink]="['/dashboard/organization', org.id]">{{org.name}}</h3>
                <ion-chip >volunteer</ion-chip>
            </div>
          <!-- shows only to cgadmin on remove -->
          <ion-button *ngIf="removeViewOnly" (click)="orgConnect.onDeleteVolunteerBtnClick(user, org);loadOrganizations()"
                       color="danger" size="default" class="icon-text leave " slot="end">
            <ion-icon name="log-out"></ion-icon>
            <ion-label>Leave</ion-label>
          </ion-button>
        </ion-item>
      </ion-list>
    </ng-template>
  </ion-card-content>
  <ion-card-content *ngIf="totalFavoriteOrganizations">
    <h3>Favorite</h3>
    <ion-text *ngIf="!totalFavoriteOrganizations.length; else totalfaveorgs">No favorite providers</ion-text>
    <ng-template #totalfaveorgs>
      <ion-list>
        <ion-item *ngFor="let org of totalFavoriteOrganizations" detail="true" lines="none"
                  >
          <ion-avatar *ngIf="org.cover_image" slot="start" [routerLink]="['/dashboard/organization', org.id]">
            <img alt="Organization Cover Image" src="{{org.cover_image}}">
          </ion-avatar>
            <div class="ion-item-content">
                <h3 [routerLink]="['/dashboard/organization', org.id]">{{org.name}}</h3>
                <ion-chip >favorite</ion-chip>
            </div>
          <!-- shows only to cgadmin on remove -->
          <ion-button *ngIf="removeViewOnly" (click)="orgConnect.onDeleteFavoriteBtnClick(user, org);loadOrganizations()"
                       color="danger" size="default" class="icon-text leave " slot="end">
            <ion-icon name="log-out"></ion-icon>
            <ion-label>Leave</ion-label>
          </ion-button>
        </ion-item>
      </ion-list>
    </ng-template>
  </ion-card-content>
</ion-card>

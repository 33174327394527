import {Inject, Injectable} from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { API_URL } from '../../../const';
import { Resource } from '../resources/resource';
import { ISerializer } from '../serializers/ISerializer';
import { ListResponse } from '../resources/list-response';
import { ResourceSerializer } from '../serializers/resource-serializer';

@Injectable()
export class ResourceService<T extends Resource> {
    static resourceSlug: string = '';

    protected url: string = API_URL;
    protected endpoint: string;

    constructor(
        protected httpClient: HttpClient,
        @Inject('resourceServiceEndpoint') endpoint: string,
        protected serializer: ResourceSerializer,
    ) {
      this.endpoint = endpoint;
    }

    create(item: T, path: string = ''): Observable<T> {
      return this.httpClient
        .post<T>(`${this.url}${this.endpoint}${path}`, this.serializer.toJson(item))
        .pipe(map((data: any) => this.serializer.fromJson(typeof data.data === 'undefined' ? data : data.data) as T));
    }

    update(item: T): Observable<T> {
      return this.httpClient
        .put<T>(`${this.url}${this.endpoint}/${item.id}`,
          this.serializer.toJson(item))
        .pipe(map((data: any) => this.serializer.fromJson(typeof data.data === 'undefined' ? data : data.data) as T));
    }

    updateForce(item: T): Observable<T> {
      return this.httpClient
        .post<T>(`${this.url}${this.endpoint}/${item instanceof FormData ? item.get('id') : item.id}`, // @todo: need refactor other components because passed item must be Resource, not FormData
          this.serializer.toJson(item))
        .pipe(map((data: any) => this.serializer.fromJson(typeof data.data === 'undefined' ? data : data.data) as T));
    }

    read(id: number): Observable<T> {
      return this.httpClient
        .get(`${this.url}${this.endpoint}/${id}`)
        .pipe(map((data: any) => this.serializer.fromJson(typeof data.data === 'undefined' ? data : data.data) as T));
    }

    list(params?: HttpParams, path: string = ''): Observable<ListResponse<T>> {
      return this.httpClient
        .get(`${this.url}${this.endpoint}${path}`, { params })
        .pipe(map((response: any) => {
          const listResponse = new ListResponse<T>();
          listResponse.metadata = response.metadata;
          listResponse.data = this.convertData(response.data);

          return listResponse;
        }));
    }

    delete(id: number, path: string = '') {
      return this.httpClient
        .delete(`${this.url}${this.endpoint}/${id}${path}`);
    }

    protected convertData(data: any, serializer?: ResourceSerializer): T[] {
      const serializerObj = serializer || this.serializer;
      if (!Array.isArray(data)) {
        data = Object.keys(data).map( key => data[key]);
      }
      return data.map((item) => serializerObj.fromJson(item));
    }

    publicRead(id: number): Observable<T> {
      return this.httpClient
        .get(`${this.url}public/${this.endpoint}/${id}`)
        .pipe(map((data: any) => this.serializer.fromJson(typeof data.data === 'undefined' ? data : data.data) as T));
    }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from 'src/providers/auth/auth-guard.service';

import { FriendListComponent } from './friend-list/friend-list.component';
import {RoleService} from "../../api/services/role.service";

const friendRoutes: Routes = [
  {
    path: 'friends',
    component: FriendListComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(friendRoutes),
  ],
  exports: [
    RouterModule,
  ],
  providers: [RoleService]
})
export class FriendRoutingModule { }


  <ion-card *ngIf="commonFriends">
    <ion-card-header>
      <ion-card-title>Common friends</ion-card-title>
    </ion-card-header>
    <ion-card-content>
      <ion-text *ngIf="!commonFriends.length">No common friends</ion-text>
      <ion-list>
        <ion-item *ngFor="let friend of commonFriends" detail="true" lines="none"
                  [routerLink]="['/user', friend.id]">
          <ion-avatar *ngIf="friend.cover_image" slot="start">
            <img alt="Friend Cover Image" src="{{friend.cover_image}}">
          </ion-avatar>
          <ion-label>{{friend.name}}</ion-label>
        </ion-item>
      </ion-list>
    </ion-card-content>
  </ion-card>

<ion-card *ngIf="isCauseGroupAdmin">
  <ion-card-header>
    <ion-card-title>Connections</ion-card-title>
    <ion-card-subtitle><i>Visible only to Cause Group Administrators</i></ion-card-subtitle>
  </ion-card-header>
  <ion-card-content>
    <ion-list>
      <ion-row *ngIf="isLoadingFriends; else friendsBlock" class="ion-justify-content-center">
        <ion-col size="auto">
          <ion-spinner></ion-spinner>
        </ion-col>
      </ion-row>
      <ng-template #friendsBlock>
        <ion-list>
          <ion-item *ngFor="let friend of (friends)">
            <ion-avatar *ngIf="friend.cover_image" class="ion-margin-end">
              <img alt="Friend Cover Image" [src]="friend.cover_image">
            </ion-avatar>
            <ion-avatar *ngIf="!friend.cover_image" class="ion-margin-end"><ion-icon name="person"  class="ion-margin-end"></ion-icon>
            </ion-avatar>

            <ion-label class="ion-wrap">
              <ion-button color="secondary" [routerLink]="['/user', friend.id]" slot="end"  class="ion-float-right">
                <ion-icon slot="icon-only" name="eye"></ion-icon>
                <span>View</span>
              </ion-button>
              <ion-button *ngIf="removeViewOnly"  class="ion-float-right" (click)="onDeleteBtnClick(userId,friend.id)"
                          color="danger">
                <ion-icon slot="end" name="trash"></ion-icon>
                Delete Connection
              </ion-button>
              <h2>
                <a [routerLink]="['/user', friend.id]">{{friend.name}}</a>
              </h2>
              <h2>User Role:
                <ion-chip  class="role"  *ngFor="let role of friend.roles">{{role.name}}</ion-chip>
              </h2>
              <h2>Relationship:
                <ng-container  *ngFor="let role of friend.role_relationship">
                  <!-- filtering only roles between current user and friend -->
<!--                  <ion-chip color="primary" *ngIf="user.hasEdge(role, user)" >{{friend.getRoleName(role.edge_id)}}</ion-chip>-->
                </ng-container>
              </h2>
            </ion-label>
          </ion-item>
        </ion-list>
        <div *ngIf="friends && !friends.length" class="ion-text-center">
          <ion-text>No Results</ion-text>
        </div>
      </ng-template>
    </ion-list>
  </ion-card-content>
  <ion-card-content>
    <ion-list>
      <ng-container *ngIf="friendsRequestsToYou && friendsRequestsToYou.length">
        <ion-card-subtitle>
          <hr/>
          <b>Pending Connections to You</b>
        </ion-card-subtitle>
        <ion-list>

          <ion-item *ngFor="let pFriend of (friendsRequestsToYou)">
            <ion-avatar *ngIf="pFriend.cover_image" class="ion-margin-end">
              <img alt="Friend Cover Image" [src]="pFriend.cover_image">
            </ion-avatar>
            <ion-avatar *ngIf="!pFriend.cover_image" class="ion-margin-end"><ion-icon name="person"  class="ion-margin-end"></ion-icon>
            </ion-avatar>

            <ion-label class="ion-wrap">
              <ion-button color="secondary" [routerLink]="['/user', pFriend.id]" slot="end"  class="ion-float-right">
                <ion-icon slot="icon-only" name="eye"></ion-icon>
                <span>View</span>
              </ion-button>
              <ion-button *ngIf="removeViewOnly"  class="ion-float-right" (click)="onDeleteBtnClick(userId,pFriend.id)"
                          color="danger">
                <ion-icon slot="end" name="trash"></ion-icon>
                Delete Connection
              </ion-button>
              <h2>
                <a [routerLink]="['/user', pFriend.id]">{{pFriend.name}}</a>
              </h2>
              <h2>User Role:
                <ion-chip  class="role"  *ngFor="let role of pFriend.roles">{{role.name}}</ion-chip>
              </h2>
            </ion-label>
          </ion-item>
        </ion-list>
        <div *ngIf="friendsRequestsToYou && !friendsRequestsToYou.length" class="ion-text-center">
          <ion-text>No Results</ion-text>
        </div>
      </ng-container>
    </ion-list>
  </ion-card-content>
  <ion-card-content>
    <ion-list>

      <ng-container *ngIf="friendsRequestsToOthers && friendsRequestsToOthers.length">
        <ion-card-subtitle>
          <hr/>
          <b>Pending Connections to Others</b>
        </ion-card-subtitle>
        <ion-list>

          <ion-item *ngFor="let pFriend of (friendsRequestsToOthers)">
            <ion-avatar *ngIf="pFriend.cover_image" class="ion-margin-end">
              <img alt="Friend Cover Image" [src]="pFriend.cover_image">
            </ion-avatar>
            <ion-avatar *ngIf="!pFriend.cover_image" class="ion-margin-end"><ion-icon name="person"  class="ion-margin-end"></ion-icon>
            </ion-avatar>

            <ion-label class="ion-wrap">
              <ion-button color="secondary" [routerLink]="['/user', pFriend.id]" slot="end"  class="ion-float-right">
                <ion-icon slot="icon-only" name="eye"></ion-icon>
                <span>View</span>
              </ion-button>
              <ion-button *ngIf="removeViewOnly"  class="ion-float-right" (click)="onDeleteBtnClick(userId,pFriend.id)"
                          color="danger">
                <ion-icon slot="end" name="trash"></ion-icon>
                Delete Connection
              </ion-button>
              <h2>
                <a [routerLink]="['/user', pFriend.id]">{{pFriend.name}}</a>
              </h2>
              <h2>User Role:
                <ion-chip  class="role"  *ngFor="let role of pFriend.roles">{{role.name}}</ion-chip>
              </h2>
            </ion-label>
          </ion-item>
        </ion-list>
        <div *ngIf="friendsRequestsToYou && !friendsRequestsToYou.length" class="ion-text-center">
          <ion-text>No Results</ion-text>
        </div>
      </ng-container>
    </ion-list>
  </ion-card-content>
</ion-card>
<ng-container *ngIf="!isProfile && !isCauseGroupAdmin" >
  <ion-row class="ion-justify-content-center">
    <ion-col size="auto">
      <ng-container *ngIf="!isLoadingFriendship && friendship"  >
        <ng-container *ngIf="friendship.status === 0">
          <p
              *ngIf="friendship.sender_id === currentUser.id && friendship.recipient_id === userId">
            <ion-icon name="people"></ion-icon> Connection request sent.
          </p>
          <p
              *ngIf="friendship.sender_id === userId && friendship.recipient_id === currentUser.id">
            <ion-icon name="people"></ion-icon> Connection request received.<br>See '<a
              [routerLink]="['/friends']">Connection Requests</a>'.
          </p>
        </ng-container>
      </ng-container>
      <ion-button *ngIf="canBefriend; else deleteFriendBtn" (click)="addFriend()">
        <ion-icon slot="start" name="add-circle"></ion-icon>
        Add Connection
      </ion-button>
      <ng-template #deleteFriendBtn>
        <ion-button *ngIf="friendship && friendship.status === 1 && friendship.sender_id === userId" (click)="onDeleteBtnClick(userId, friendship.recipient_id)"
                    color="danger">
          <ion-icon slot="start" name="trash"></ion-icon>
          Delete Connection
        </ion-button>
        <ion-button *ngIf="friendship && friendship.status === 1 && friendship.recipient_id === userId" (click)="onDeleteBtnClick(userId, friendship.sender_id)"
                    color="danger">
          <ion-icon slot="start" name="trash"></ion-icon>
          Delete Connection
        </ion-button>
      </ng-template>
    </ion-col>
  </ion-row>
</ng-container>


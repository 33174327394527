import { Resource } from './resource';
import { TagResource } from './tag.resource';
import { CauseGroupResource } from './cause-group.resource';

export class EntityResource extends Resource {
    name: string = null;

    entity_type_id: number = null;

    value_snapshot: string = null;

    version: string = null;

    configuration_value_snapshot: string = null;

    clean_up_after: string = null;

    last_modified_by: number = null;

    entity_type_fieldset_id: number = null;

    parent_id: number = null;

    cause_group: CauseGroupResource = null;

    tags: TagResource[] = [];
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { formatDate } from '@angular/common';
import { Storage } from '@ionic/storage';
import { ValidationManager } from 'ng2-validation-manager';
import { AlertController, LoadingController, ToastController, PopoverController } from '@ionic/angular';
import { NgxPermissionsService } from 'ngx-permissions';
import { SortDirection, SortPropDir } from '@swimlane/ngx-datatable';
import { UserService } from '../../../../api/services/user.service';
import { UserResource } from '../../../../api/resources/user.resource';
import { CauseGroupService } from '../../../../api/services/cause-group.service';
import { EntityConnectionsService } from '../../../../api/services/entity-connections.service';
import { UserCauseGroupService } from '../../../../api/services/user-cause-group.service';
import { CauseGroupResource } from '../../../../api/resources/cause-group.resource';
import {
  HandleAdvocateInnerCircleRemovalService
} from "../../../../shared/services/handle-advocate-inner-circle-removal.service";
import {CalendarComponent} from "../../../../popovers/calendar.component";

@Component({
  selector: 'user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.css'],
})
export class UserEditComponent implements OnInit {
    public isLoading: boolean = false;

    public isLoadingCauseGroups: boolean = false;

    private causeGroupEdgeSlug: string = 'cause-group';

    public userId: number;

    private currentUser: any = {};

    public user: UserResource;

    public causeGroups: CauseGroupResource[];

    private userCauseGroups: CauseGroupResource[];

    private causeGroupsSorts: SortPropDir[];

    public form: ValidationManager;

    private loading: any;

    private userService: UserService;

    private causeGroupService: CauseGroupService;

    private entityConnectionsService: EntityConnectionsService;

    private userCauseGroupService: UserCauseGroupService;
    public backgroundCheckTitle: string = 'Background Check Date';
    public userIsFamily: boolean = false;

    constructor(private httpClient: HttpClient,
                private route: ActivatedRoute,
                private router: Router,
                private storage: Storage,
                private loader: LoadingController,
                private toast: ToastController,
                private popover: PopoverController,
                private alertController: AlertController,
                private permissionsService: NgxPermissionsService,
                private handleAdvocateInnerCircleRemovalService: HandleAdvocateInnerCircleRemovalService) {
      this.userService = new UserService(this.httpClient);
      this.causeGroupService = new CauseGroupService(this.httpClient);
      this.entityConnectionsService = new EntityConnectionsService(this.httpClient);
      this.userCauseGroupService = new UserCauseGroupService(this.httpClient);

      this.form = new ValidationManager({
        email: 'required|email',
        first_name: 'required|rangeLength:2,45|alphaSpace',
        last_name: 'required|rangeLength:2,45|alphaSpace',
        background_check_completed: '',
        background_check_date: 'date',
        dob_range: '',
        zip_code: '',
        phone: '',
        causeGroups: '',
        connect_notes: '',
        connect_status: ''
      });

      this.causeGroupsSorts = [
        { prop: 'name', dir: SortDirection.asc },
      ];
    }

    ngOnInit() {
      this.route.params.subscribe((params) => {
        this.userId = params.hasOwnProperty('id') ? parseInt(params.id) : null;

        this.form.reset();
        const emailControl = this.form.getControl('email');
        this.userId ? emailControl.disable() : emailControl.enable();

        this.storage.get('loginRes').then((val) => {
          this.currentUser = val.user;
          if (this.userId) {
            this.loadUser();
          } else {
            this.user = new UserResource();
            this.permissionsService.hasPermission('users_cause-groups_manage').then((hasPermission) => {
              if (hasPermission) this.loadCauseGroups();
            });
          }
        });
      });

    }
    async presentPopover() {
        const pop = await this.popover.create({
            component: CalendarComponent,
            cssClass: 'datetime-class',
            side:"top",
            alignment: "start",
            showBackdrop: false,
            translucent: true,
            componentProps: {
                date: this.user.background_check_date
            }

        });

        pop.onDidDismiss().then((selectedDate) => {
            if (selectedDate.data) {

                this.form.setValue({
                    background_check_date: formatDate(selectedDate.data,'MM/dd/yyyy', 'en-US')
                });
            }
        });
        return setTimeout(() => {
            pop.present();
        }, 600);
    }


    private loadUser(): void {
      this.isLoading = true;

      this.userService
        .read(this.userId)
        .toPromise()
        .then((user) => {
          this.user = user;

            this.form.setValue({
            email: this.user.email,
            first_name: this.user.first_name,
            last_name: this.user.last_name,
            zip_code: this.user.zip_code,
            phone: this.user.phone,
            dob_range: this.user.dob_range,
            connect_notes: this.user.connect_notes,
            connect_status: this.user.connect_status,
            background_check_completed: this.user.background_check_completed,
            background_check_date: this.user.background_check_date ? formatDate(this.user.background_check_date, 'MM/dd/yyyy', 'en-US') : ''

            });

          this.permissionsService.hasPermission('users_background_check').then((hasPermission) => {
            if (hasPermission) {
              const control = this.form.getControl('background_check_completed');
              control.setValue(this.user.background_check_completed);
              const controlDate = this.form.getControl('background_check_date');
              controlDate.setValue(this.user.background_check_date ? formatDate(this.user.background_check_date, 'MM/dd/yyyy', 'en-US'): '');

              this.form.getControl('email').disable({onlySelf:true});
              this.form.getControl('first_name').disable({onlySelf:true});
              this.form.getControl('last_name').disable({onlySelf:true});
              this.form.getControl('zip_code').disable({onlySelf:true});
              this.form.getControl('phone').disable({onlySelf:true});
              this.form.getControl('dob_range').disable({onlySelf:true});
            }
          });
            let userRoles = this.user.roles.map((role) => role.title || role.name).join(', ').toLowerCase();
            this.userIsFamily = userRoles.indexOf('family') >= 0;

          this.permissionsService.hasPermission('users_cause-groups_manage').then((hasPermission) => {
            if (hasPermission) this.loadCauseGroups();
          });
        })
        .catch((errorResponse) => {
          this.toast.create({
            header: 'Load error',
            message: errorResponse.error.message,
            buttons: [
              {
                text: 'Close',
                role: 'cancel',
                handler: () => {}
              }
            ],
            color: 'danger',
          }).then((toast) => toast.present());
        })
        .finally(() => { this.isLoading = false; });
    }

    loadCauseGroups() {
      this.isLoadingCauseGroups = true;

      let causeGroupsParams = new HttpParams();

      this.causeGroupsSorts.forEach((sort, index) => {
        for (const key in sort) {
          causeGroupsParams = causeGroupsParams.set(`sorts[${index}][${key}]`, sort[key].toString());
        }
      });

      this.causeGroupService
        .list(causeGroupsParams)
        .toPromise()
        .then((value) => {
          this.causeGroups = value.data;

          if (!this.user.entity_id) return;

          // @todo: use userCauseGroupService
          const path = `/${this.user.entity_id}/${this.causeGroupEdgeSlug}`;
          this.entityConnectionsService
            .list(null, path)
            .toPromise()
            .then((entityConnections) => {
              this.userCauseGroups = entityConnections.data.map((value) => value.entity.cause_group);

              const selectedIds = this.userCauseGroups.map((causeGroup) => causeGroup.id);

              this.form.getControl('causeGroups').setValue(selectedIds);
            });
        })
        .catch((errorResponse) => {
          this.toast.create({
            header: 'Load error',
            message: errorResponse.error.message,
            buttons: [
              {
                text: 'Close',
                role: 'cancel',
                handler: () => {}
              }
            ],
            color: 'danger',
          }).then((toast) => toast.present());
        })
        .finally(() => { this.isLoadingCauseGroups = false; });
    }

    public async save() {
      if (this.form.isValid()) {
        this.presentLoading();

        const formData = this.form.getData();

        // user
        const userData = { ...formData };
        // Walk around for validations problem with first_name and last_name, because this was not being sent as the
        // controls were disabled for certain cases
        const hasPermission = await this.permissionsService.hasPermission('users_background_check');
        if (hasPermission) {
          const formValues = this.form.getForm().getRawValue();
          userData.first_name = formValues.first_name;
          userData.last_name = formValues.last_name;
        }

        userData.email = userData.email?.toLowerCase();

        const bgcheck_date = userData.background_check_date;
        userData.background_check_date = new Date(bgcheck_date);

        if (this.user.id) userData.id = this.user.id;
        if (userData.hasOwnProperty('causeGroups')) delete userData.causeGroups;

        Object.assign(this.user, userData);

        const observable = this.user.id ? this.userService.update(userData) : this.userService.create(userData);

        const userPromise = observable
          .toPromise()
          .then((user) => {
            this.userId = user.id;
            this.user = user;
          });

        // cause group
        const causeGroupPromise = new Promise<void>((resolve, reject) => {
          this.permissionsService.hasPermission('users_cause-groups_manage')
            .then((hasPermission) => {
              if (hasPermission) {
                const syncCauseGroup = () => {
                  const causeGroupsData = {
                    ids: [...formData.causeGroups],
                  };
                  this.userCauseGroupService
                    .sync(this.user.id, causeGroupsData)
                    .toPromise()
                    .then((value) => {
                      this.user.causeGroups = value.data;
                      resolve();
                    })
                    .catch((error) => {
                      reject(error);
                    });
                };
                this.user.id ? syncCauseGroup() : userPromise.then(syncCauseGroup);
              } else {
                resolve();
              }
            });
        });

        Promise.all([userPromise, causeGroupPromise])
          .then(() => {
            this.toast.create({
              header: 'Congrats!',
              message: 'Saved successfully',
              buttons: [
              {
                text: 'Close',
                role: 'cancel',
                handler: () => {}
              }
            ],
              color: 'success',
              duration: 5000,
            }).then((toast) => toast.present());

            this.router.navigate(['/admin/users', { _: (new Date()).getTime() }]);
          })
          .catch((errorResponse) => {
            this.toast.create({
              header: 'Save error',
              message: errorResponse.error.message,
              buttons: [
              {
                text: 'Close',
                role: 'cancel',
                handler: () => {}
              }
            ],
              color: 'danger',
            }).then((toast) => toast.present());
          })
          .finally(() => { this.loading.dismiss(); });
      }
    }

    private delete() {
      if (this.user.id) {
        this.presentLoading();

        this.form.reset();

        this.userService
          .delete(this.user.id)
          .toPromise()
          .then(async () => {
            this.userId = null;
            this.user = new UserResource();

            await this.handleAdvocateInnerCircleRemovalService.handleAdvocateInnerCircleRemoval(
              this.user,
              this.currentUser
            );

            this.toast.create({
              header: 'Congrats!',
              message: 'Deleted successfully',
              buttons: [
              {
                text: 'Close',
                role: 'cancel',
                handler: () => {}
              }
            ],
              color: 'success',
              duration: 5000,
            }).then((toast) => toast.present());


            this.router.navigate(['/admin/users', { _: (new Date()).getTime() }]);
          })
          .catch((errorResponse) => {
            this.toast.create({
              header: 'Delete error',
              message: errorResponse.error.message,
              buttons: [
              {
                text: 'Close',
                role: 'cancel',
                handler: () => {}
              }
            ],
              color: 'danger',
            }).then((toast) => toast.present());
          })
          .finally(() => { this.loading.dismiss(); });
      }
    }

    async presentDeleteConfirm() {
      const alert = await this.alertController.create({
        header: 'Confirm!',
        message: 'Are you sure you want to delete?',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
          }, {
            text: 'Ok',
            handler: () => this.delete(),
          },
        ],
      });

      await alert.present();
    }

    async presentLoading(options: object = { message: 'Please wait...' }) {
      this.loading = await this.loader.create(options);
      return await this.loading.present();
    }

  public backgroundCheckChanged() {
    if (this.user.background_check_completed === this.form.getValue('background_check_completed')) {
      this.backgroundCheckTitle = 'Background Check Date';
      this.updateFormValidations();
      this.form.setValue({background_check_date: this.user.background_check_date ? formatDate( this.user.background_check_date, 'MM/dd/yyyy', 'en-US') : ''});
      return;
    }
    this.backgroundCheckTitle = 'Background Check Date*';
    this.updateFormValidations('required|date');
  }

  private updateFormValidations(type: string = 'date'): void {
      this.form.getForm().removeControl('background_check_date');
      const dateFormControl = this.form.buildControl('background_check_date', type).control;
      this.form.getForm().addControl('background_check_date', dateFormControl);
      this.form.setValue({background_check_date: this.user.background_check_date ? formatDate(this.user.background_check_date, 'MM/dd/yyyy', 'en-US') : ''});
  }
}

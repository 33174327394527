import {Component} from "@angular/core";
import {ModalController} from "@ionic/angular";

@Component({
  selector: 'app-circles-list',
  templateUrl: './circles-list.component.html',
  styleUrls: ['./circles-list.component.scss']
})

export class CirclesListComponent {

  constructor(private modalController: ModalController) { }

  dismissModal() {
    this.modalController.dismiss();
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { BackgroundChecksPage } from './background-checks.page';
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {NgxPermissionsModule} from "ngx-permissions";

const routes: Routes = [
  {
    path: '',
    component: BackgroundChecksPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    NgxPermissionsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    NgxDatatableModule
  ],
  declarations: [BackgroundChecksPage]
})
export class BackgroundChecksPageModule {}

import {Component, OnInit} from '@angular/core';
import {LoadingController, ModalController} from "@ionic/angular";
import Swiper from "swiper";

@Component({
  selector: 'app-how-tutorial',
  templateUrl: './how-tutorial.component.html',
  styleUrls: ['./how-tutorial.component.scss'],
})
export class HowTutorialComponent implements OnInit {

  private loading: HTMLIonLoadingElement;

  tutorialSwiper: Swiper;
  tutorialSteps: any;
  howStepsSlides;

  slidesEnded : boolean = false;

  constructor(private modalController: ModalController,
              private loader: LoadingController) {
  }

  ngOnInit() {
    this.loadSteps();

    this.loadSwiperConfig();
    this.tutorialSwiper.init();

    switch (window.location.pathname) {
      case "/dashboard":
        this.tutorialSwiper.slideTo(0)
        break;
      case "/dashboard/show-fam":
        this.tutorialSwiper.slideTo(1);
        break;
      case "/dashboard/list-fam":
        this.tutorialSwiper.slideTo(2);
        break;
      case "/friends":
        this.tutorialSwiper.slideTo(3);
        break;
      case "/resource-list":
        this.tutorialSwiper.slideTo(4);
        break;
      case "/dashboard/search-orgs":
        this.tutorialSwiper.slideTo(5);
        break;
      case "/guide":
        this.tutorialSwiper.slideTo(0);
        break;
      default:
        this.tutorialSwiper.slideTo(0);
        break;
    }
  }

  loadSwiperConfig() {
    this.tutorialSwiper = new Swiper('.tutorial-swiper-container', {
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
        dynamicBullets: true,
        enabled: true
      },
      speed: 1200,
      spaceBetween: 10,
      slidesPerView: 1
    });
  }

  dismissModal() {
    this.modalController.dismiss();
  }

  nextSlide() {
    this.tutorialSwiper.slideNext()
  }

  previousSlide() {
    this.tutorialSwiper.slidePrev()
  }

  loadSteps() {
    this.tutorialSteps = {

      // Hiding first how tutorial slider for now - we will redesign this component soon
      // 1: {
      //   image : '/assets/img/tutorial/1.png',
      //   header: 'Home Page & Recommendations',
      //   text: 'On your home page, you may access menus, continue on your path to grow and impact others, and view your recommendations. Recommendations are different resources and providers unique to your journey as you interact in the community.',
      // },
      2: {
        image : '/assets/img/tutorial/2.png',
        header: 'Family Page',
        text: 'After adding or accessing a family page, you may access needs, invite users to join on social media, personally thru QR code or via email. Only inner circle users may have access to see the family page.',
      },
      3: {
        image : '/assets/img/tutorial/6.png',
        header: 'Family Needs',
        text: 'From the family home page, advocates and families may post family needs, either items or volunteers and add all necessary information for volunteers to help.',
      },
      4: {
        image : '/assets/img/tutorial/3.png',
        header: 'Connections',
        text: 'You may build your circles of support by inviting new users or adding existing users by their email. You must choose a role when adding. Users may have multiple roles as they grow and impact others.',
      },
      5: {
        image : '/assets/img/tutorial/4.png',
        header: 'Learning Resources',
        text: 'Learning Resources may be links, videos or documents that are shared by users within our community. You may save existing resources or add new ones. Learning Resources stay hidden until approved by the administrator.',
      },
      6: {
        image : '/assets/img/tutorial/5.png',
        header: 'Service Provider Search',
        text: 'Service Providers are organizations (both local and national) that offer a variety of services that may be applicable to you. Search by type, keyword, and location and save, connect, or favorite an existing provider. Anyone can add a Service Provider but they are hidden until approved by the administrator.',
      },
      7: {
        image : '/assets/img/tutorial/7.png',
        header: 'Next Steps',
        text: 'Our guide was created to direct you to the next logical step in your journey.  There are five levels with different steps at each level.  See what you have completed and what is next. Watch your "tree" grow and "water" others\' trees as you connect further in the community. ',
      }
    }

    this.howStepsSlides = [];
    for (let k = 2; k <= 7; k++) {
      this.howStepsSlides.push(this.tutorialSteps[k]);
    }

  }

  async presentLoading(options: object = { message: 'Please wait...' }) {
    this.loading = await this.loader.create(options);
    return this.loading.present();
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { CirclesListComponent } from "./circles-list.component";

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
  ],
  declarations: [CirclesListComponent],
  exports: [CirclesListComponent],
})
export class CirclesListComponentModule {}

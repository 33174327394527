<ion-content class="ion-padding">
    <ion-grid>
        <ion-row>
            <ion-col>
                <ion-label>
                    <h1>Cause Group Dashboard</h1>
                </ion-label>
            </ion-col>
        </ion-row>

        <ion-row>
            <ion-col>
                <form [formGroup]="form.getForm()" (ngSubmit)="loadInfo()">
                    <ion-list>
                        <ion-row>
                            <ion-col size="12">
                                <ion-item class="item-input">
                                    <ion-label class="txt3" position="stacked">Keyword</ion-label>
                                    <ion-input formControlName="keyword" placeholder="Keyword" type="search"></ion-input>
                                    <ion-note color="danger" *ngIf="form.hasError('keyword')">
                                        {{ form.getError('keyword')}}
                                    </ion-note>
                                </ion-item>
                            </ion-col>
                            <ion-col size="6">
                                <ion-item class="item-input">
                                    <ion-label class="txt3" position="stacked">Start date</ion-label>
                                    <ion-item id="start-date-input" class="ion-text-end">
                                        <ion-label>{{form.getValue('start_date') | date:'MM/dd/YY'}}</ion-label>
                                    </ion-item>
                                    <ion-popover trigger="start-date-input" show-backdrop="false">
                                        <ng-template>
                                            <ion-datetime
                                                formControlName="start_date"
                                                presentation="date"
                                                showDefaultButtons=true
                                                [max]="form.getValue('end_date')"
                                            ></ion-datetime>
                                        </ng-template>
                                    </ion-popover>
                                    <ion-note color="danger" *ngIf="form.hasError('start_date')">
                                        {{ form.getError('start_date')}}
                                    </ion-note>
                                </ion-item>
                            </ion-col>
                            <ion-col size="6">
                                <ion-item class="item-input">
                                    <ion-label class="txt3" position="stacked">End date</ion-label>
                                    <ion-item id="end-date-input" class="ion-text-end">
                                        <ion-label>{{form.getValue('end_date') | date:'MM/dd/YY'}}</ion-label>
                                    </ion-item>
                                    <ion-popover trigger="end-date-input" show-backdrop="false">
                                        <ng-template>
                                            <ion-datetime
                                                formControlName="end_date"
                                                presentation="date"
                                                showDefaultButtons=true
                                                [min]="form.getValue('start_date')"
                                            ></ion-datetime>
                                        </ng-template>
                                    </ion-popover>
                                    <ion-note color="danger" *ngIf="form.hasError('end_date')">
                                        {{ form.getError('end_date')}}
                                    </ion-note>
                                </ion-item>
                            </ion-col>
                        </ion-row>
                        <ion-row class="ion-text-center">
                            <ion-col>
                                <ion-button [disabled]="!form.getForm().valid" type="submit" expand="block">
                                    <ion-icon slot="start" name="analytics"></ion-icon>
                                    Show
                                </ion-button>
                            </ion-col>
                        </ion-row>
                    </ion-list>
                </form>
            </ion-col>
        </ion-row>

        <ion-row>
            <ion-col>
                <ion-item-group>
                    <ion-item-divider color="light">
                        <ion-label>
                            <h2>Service Providers</h2>
                        </ion-label>
                        <ion-badge slot="end">{{causeGroupDashboard.organizations.length}}</ion-badge>
                        <ion-button slot="end" (click)="export('organizations')" *ngIf="causeGroupDashboard.organizations.length > 0" >Export</ion-button>
                        <ion-label slot="end">
                            <h2>Active</h2>
                        </ion-label>
                        <ion-label slot="end">
                            <h2>Visible</h2>
                        </ion-label>
                    </ion-item-divider>

                    <ion-item *ngFor="let organization of causeGroupDashboard.organizations">
                        <ion-thumbnail slot="start">

                            <img [src]="organization.getCoverImage()" alt="OrganizationLogo">

                        </ion-thumbnail>
                        <ion-label>
                            {{organization.name}}<br/>
                            <small>Status: <strong>{{ organization.status }}</strong></small>
                        </ion-label>
                        <small>Added On: <strong>{{ organization.created_at | date: 'MM/dd/yyyy' }}</strong></small>
                        <ng-container *ngxPermissionsOnly="['organizations_view', 'all-organizations_view']">
                            <ion-button [routerLink]="['/dashboard/organization', organization.id]" size="small">
                                <ion-icon name="eye" slot="icon-only"></ion-icon>
                            </ion-button>
                        </ng-container>
                        <ion-toggle slot="end" [disabled]="causeGroupId !== organization.cause_group?.id"
                            [ngModel]="organization.status === 'active'" 
                            (ngModelChange)="onActiveToggleChange($event, organization)" 
                            [class.toggle-visibility]="causeGroupId !== organization.cause_group?.id">
                        </ion-toggle>
                        <ion-toggle slot="end" [disabled]="causeGroupId === organization.cause_group?.id"
                            [ngModel]="organization.visible"
                            (ngModelChange)="onVisibleToggleChange($event, organization)"
                            [class.toggle-visibility]="causeGroupId === organization.cause_group?.id">
                        </ion-toggle>
                    </ion-item>
                </ion-item-group>

                <ion-item-group>
                    <ion-item-divider color="light">
                        <ion-label>
                            <h2>Needs</h2>
                        </ion-label>
                        <ion-badge slot="end">{{causeGroupDashboard.needs.length}}</ion-badge>

                        <ion-button slot="end" [routerLink]="['/admin/cause-group', this.causeGroupId, 'needs']" *ngIf="causeGroupDashboard.needs.length > 0" >Full</ion-button>
                        <ion-button slot="end" (click)="export('needs')" *ngIf="causeGroupDashboard.needs.length > 0" >Export</ion-button>

                    </ion-item-divider>

                    <ion-item *ngFor="let need of causeGroupDashboard.needs">
                        <ion-thumbnail slot="start">
                            <img alt="Need Cover Image" [src]="need.cover_image || './assets/img/org-nonprofit.png'">
                        </ion-thumbnail>
                        <ion-label>{{need.title}}
                        <span *ngIf="need.status=='pending'" class="color-danger">
                            <i [ngClass]="'ion-md-alert'"></i>PENDING
                        </span>

                        </ion-label>

                        <small>Added On: <strong>{{ need.created_at | date: 'MM/dd/yyyy' }}</strong><br>
                        Expires: <strong>{{ need.end_date | date: 'MM/dd/yyyy' }}</strong></small>
                        <ion-button [routerLink]="['/dashboard/show-need', need.id]" size="small">
                            <ion-icon name="eye" slot="icon-only"></ion-icon>
                        </ion-button>
                        <small>
                        <ion-toggle slot="end"
                                    [ngModel]="need.status === 'active'"
                                    (ngModelChange)="onActiveToggleChange($event, need)" >
                        </ion-toggle>
                        </small>
                    </ion-item>
                </ion-item-group>

                <ion-item-group>
                    <ion-item-divider color="light">
                        <ion-label>
                            <h2>Families</h2>
                        </ion-label>
                        <ion-badge slot="end">{{causeGroupDashboard.families.length}}</ion-badge>
                        <ion-button slot="end" (click)="export('families')" *ngIf="causeGroupDashboard.families.length > 0" >Export</ion-button>

                    </ion-item-divider>

                    <ion-item *ngFor="let family of causeGroupDashboard.families">
                        <ion-label><a [routerLink]="['/user/'+family.user_id]">{{family.first_name + ' ' + family.last_name}}</a></ion-label>
                        <small>Added On: <strong>{{ family.created_at | date: 'MM/dd/yyyy' }}</strong></small>
                      <ion-button [routerLink]="['/dashboard/family/' + family.id + '/add-representative']" type="button" color="dark" size="small">
                        ADD
                        <ion-icon slot="end" name="add-circle"></ion-icon>
                      </ion-button>
                        <ion-button [routerLink]="['/dashboard/show-fam', family.id]"  size="small">
                            <ion-icon name="eye" slot="icon-only"></ion-icon>
                        </ion-button>
                    </ion-item>
                </ion-item-group>

                <ion-item-group>
                    <ion-item-divider color="light">
                        <ion-label>
                            <h2>Learning Resources</h2>
                        </ion-label>
                        <ion-badge slot="end">{{causeGroupDashboard.resources.length}}</ion-badge>
                        <ion-button slot="end" (click)="export('resources')" *ngIf="causeGroupDashboard.resources.length > 0" >Export</ion-button>
                        <ion-label slot="end">
                            <h2>Active</h2>
                        </ion-label>
                        <ion-label slot="end">
                            <h2>Visible</h2>
                        </ion-label>
                    </ion-item-divider>

                    <ion-item *ngFor="let resource of causeGroupDashboard.resources">
                        <ion-thumbnail slot="start">
                            <ng-container *ngIf="resource.cover_image; else defaultTypeIcon">
                                <img alt="Resource Cover Image" [src]="resource.cover_image">
                            </ng-container>
                            <ng-template #defaultTypeIcon>
                                <div class="icon-container">
                                    <ion-icon name="{{resource.getIconImage()}}" size="large" class="large"></ion-icon>
                                </div>
                            </ng-template>
                        </ion-thumbnail>
                        <ion-label>{{resource.title}}</ion-label>

                        <small>Added On: <strong>{{ resource.created_at | date: 'MM/dd/yyyy' }}</strong></small>
                        
                        <ion-button [routerLink]="['/resource-detail', resource.id]"  size="small">
                            <ion-icon name="eye" slot="icon-only"></ion-icon>
                        </ion-button>
                        <ion-toggle slot="end" [disabled]="causeGroupId !== resource.cause_group?.id"
                            [ngModel]="resource.status === 'active'" 
                            (ngModelChange)="onActiveToggleChange($event, resource)" 
                            [class.toggle-visibility]="causeGroupId !== resource.cause_group?.id">
                        </ion-toggle>
                        <ion-toggle slot="end" [disabled]="causeGroupId === resource.cause_group?.id"
                            [ngModel]="resource.visible"
                            (ngModelChange)="onVisibleToggleChange($event, resource)"
                            [class.toggle-visibility]="causeGroupId === resource.cause_group?.id">
                        </ion-toggle>
                    </ion-item>
                </ion-item-group>

                <ion-item-group>
                    <ion-item-divider color="light">
                        <ion-label>
                            <h2>Volunteers</h2>
                        </ion-label>
                        <ion-badge slot="end">{{causeGroupDashboard.volunteers.length}}</ion-badge>
                        <ion-button slot="end" (click)="export('volunteers')" *ngIf="causeGroupDashboard.volunteers.length > 0" >Export</ion-button>

                    </ion-item-divider>

                    <ion-item *ngFor="let volunteer of causeGroupDashboard.volunteers">
                        <ion-thumbnail slot="start" *ngIf="volunteer.cover_image">
                            <img alt="Volunteer Cover Image" [src]="volunteer.cover_image">
                        </ion-thumbnail>
                      <ion-label>{{volunteer.name}}<br> <small>{{ volunteer.email}}</small></ion-label>
                        <small>Added On: <strong>{{ volunteer.created_at | date: 'MM/dd/yyyy' }}</strong></small>
                        <ion-button [routerLink]="['/user', volunteer.id]" size="small">
                            <ion-icon name="eye" slot="icon-only"></ion-icon>
                        </ion-button>
                    </ion-item>
                </ion-item-group>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>

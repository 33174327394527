import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { UserResourceResource } from '../resources/userresource.resource';
import { ResourceService } from './resource.service';
import { ResourceSerializer } from '../serializers/resource-serializer';
import { ListResponse } from '../resources/list-response';

@Injectable()
export class UserResourceService extends ResourceService<UserResourceResource> {
    static resourceSlug: string = 'resource';

    constructor(httpClient: HttpClient, endpoint: string = UserResourceService.resourceSlug) {
      super(
        httpClient,
        endpoint,
        new ResourceSerializer(UserResourceResource),
      );
    }

    listAll(options: object = {}): Observable<ListResponse<UserResourceResource>> {
      return this.httpClient
        .get(`${this.url}${this.endpoint}`, options)
        .pipe(map((response: any) => {
          const listResponse = new ListResponse<UserResourceResource>();
          listResponse.metadata = response.metadata;
          listResponse.data = this.convertData(response.data);

          return listResponse;
        }));
    }

    approve(item: UserResourceResource): Observable<UserResourceResource> {
      return this.httpClient
        .post<UserResourceResource>(`${this.url}${this.endpoint}/${item.id}/approve`, null)
        .pipe(map((data: any) => this.serializer.fromJson(typeof data.data === 'undefined' ? data : data.data) as UserResourceResource));
    }

    reject(item: UserResourceResource): Observable<UserResourceResource> {
      return this.httpClient
        .post<UserResourceResource>(`${this.url}${this.endpoint}/${item.id}/reject`, null)
        .pipe(map((data: any) => this.serializer.fromJson(typeof data.data === 'undefined' ? data : data.data) as UserResourceResource));
    }

    toggleStatus(active: boolean, resource: UserResourceResource): Observable<UserResourceResource> {
      if(active) {
        return this.approve(resource);
      }
      return this.reject(resource);
    }

    shareViaEmail(resourceId, data:any)
    {
        return this.httpClient
            .post(`${this.url}${this.endpoint}/${resourceId}/shareResource`, this.serializer.toJson(data))
            .pipe(map((response: any) => {
                return response;
            }));
    }

    toggleVisibilty(visible: boolean, resourceId: number, causeGroupId: number): Observable<UserResourceResource> {
      if(visible) {
        return this.visible(resourceId, causeGroupId);
      }
      return this.hide(resourceId, causeGroupId);
    }

    visible(resourceId: number, causeGroupId: number): Observable<UserResourceResource> {
      return this.httpClient
        .post<UserResourceResource>(`${this.url}${this.endpoint}/${resourceId}/cause-group/${causeGroupId}/visible`, null)
        .pipe(map((data: any) => this.serializer.fromJson(typeof data.data === 'undefined' ? data : data.data) as UserResourceResource));
    }

    hide(resourceId: number, causeGroupId: number): Observable<UserResourceResource> {
      return this.httpClient
        .post<UserResourceResource>(`${this.url}${this.endpoint}/${resourceId}/cause-group/${causeGroupId}/hide`, null)
        .pipe(map((data: any) => this.serializer.fromJson(typeof data.data === 'undefined' ? data : data.data) as UserResourceResource));
    }
}

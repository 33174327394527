<ion-content  class="max-grid modal">
  <ion-grid class="bg-light">

    <ion-row >
      <ion-col  size="auto" class="encouragement ">
        <div class="transbox">
          <ion-button  (click)="dismissModal({})" class="button button-clear button-small button-dismiss">X</ion-button>
        </div>
        <div class="img-holder">
          <img alt="Encouragement Image" [src]="data.data.image" class="svg-art" />
        </div>

        <p class="statement">
          {{data.data.statement}}
        </p>
        <p class="proverb verse">
          {{data.data.verse}}
          <span class="subtitle"></span>
        </p>
      </ion-col>
    </ion-row>

    <!-- <ion-button (click)="handlePrimaryClick()" expand="block">
      {{data.data.cta_primary_text}}
    </ion-button> -->

      <ion-row>
          <ion-col>
              <h3 class="ion-text-center">{{data.data.cta_primary_text}} <ion-icon name="caret-forward"  ></ion-icon></h3>
          </ion-col>
      </ion-row>

    <ion-row>
      <!-- FIRST BUTTON LINKS TO DASHBOARD, SECOND IS CUSTOM -->
      <ion-col>
        <ion-button color="dark" class="button button-block button-icon-top" (click)="dismissModal({})">
          <ion-icon name="caret-back" slot="start"  ></ion-icon>
          <span class="fontStyle">BACK</span>
        </ion-button>
      </ion-col>
      <ion-col>
        <ion-button  class="button button-icon-top button-block" (click)="handlePrimaryClick()">
          <span class="fontStyle">NEXT </span>
          <ion-icon name="caret-forward" slot="end"  ></ion-icon>
        </ion-button>
      </ion-col>
    </ion-row>

  </ion-grid>
</ion-content>

import { Resource } from './resource';
import { EntityResource } from './entity.resource';
import { CauseGroupResource } from './cause-group.resource';
import {EventType} from "../../shared/types/EventType.type";

export class UserResourceResource extends Resource {
    id: number = null;

    target: string[]|string = null;

    title: string = null;

    user_id : string = null;

    type : EventType = {};

    status : string = null;

    description : string = null;

    url : string = null;

    address : string = null;

    address2 : string = null;

    city : string = null;

    state : string = null;

    zip : number = null;

    country : string = null;

    lat: number = null;

    lng: number = null;

    distance: number = null;

    start_datetime : string = null;

    end_datetime: string = null;

    upload: string = null;

    cover_image: string = null;

    filetype: string = null;

    created_at: string = null;

    updated_at: string = null;

    deleted_at: string = null;

    entity_id: number = null;

    entity: EntityResource = null;

    cause_group: CauseGroupResource = null;

    visible: boolean = null;

    organization: string = null;

    recommendation: any = null;

    next_step: any = null;

    first_church: any = null;

    role_relationship: any = null;

    event_url: string = null;

    event_type: string = null;
    
    getIconImage(): string {
      if (this.cover_image) {
        return this.cover_image
      }
      if (this.type.hasOwnProperty('id')){
        if (this.type.id == 1) {
          return 'videocam';
        }
        if (this.type.id == 2) {
          return 'book';
        }
        if (this.type.id == 3) {
          return 'calendar';
        }
        if (this.type.id == 4) {
          return 'document';
        }
      } else {
        // this is a hack because on the search page, we are not using Eloquent to build the query, so we can not
        // use the "with" method that laravel provides, so we are getting what the table has as a raw value for "type"
        if (this.type == 1) {
          return 'videocam';
        }
        if (this.type == 2) {
          return 'book';
        }
        if (this.type == 3) {
          return 'calendar';
        }
        if (this.type == 4) {
          return 'document';
        }
      }
    }
}

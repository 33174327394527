import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from 'src/providers/auth/auth-guard.service';
import { NgxPermissionsGuard } from 'ngx-permissions';

import { TagListComponent } from './tag-list/tag-list.component';
import { TagEditComponent } from './tag-edit/tag-edit.component';

const tagsRoutes: Routes = [
  {
    path: 'admin/tags',
    component: TagListComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: 'all-tags_list',
      },
    },
  },
  {
    path: 'admin/tag/add',
    component: TagEditComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: 'all-tags_add',
      },
    },
  },
  {
    path: 'admin/tag/:id',
    component: TagEditComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: 'all-tags_edit',
      },
    },
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(tagsRoutes),
  ],
  exports: [
    RouterModule,
  ],
})
export class TagsRoutingModule { }

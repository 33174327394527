<ion-header>
  <ion-toolbar>
    <ion-title class="pdl-16">Statement of Faith</ion-title>
      <ion-buttons slot="end">
        <ion-button (click)="dismissModal()" color="dark">Close</ion-button>
      </ion-buttons>
  </ion-toolbar>
</ion-header>


<ion-content  class="ion-padding">
    <h2>Statement of Faith of Participating Churches</h2>
    <p>
        1. We believe that the Bible (composed of the 66 books of Old and New Testaments), is the divinely inspired Word of God, the only infallible rule of faith and practice, to which nothing should be added, taken away or distorted.
    </p>
    <p>
        2. We believe that there is only one God, eternal and self-existing in three persons (Father, Son, and Holy Spirit) who are to be equally loved, honored, and worshipped. Jesus Christ is the eternal and only begotten Son of God, was conceived by the Holy Spirit, and became incarnate as true Man, and is true God.
    </p>
    <p>
        3. We believe that man and woman were created in the image of God, that the first man Adam sinned and thereby incurred not only physical death, but also spiritual death, which was eternal separation from God and through him sin and death are upon all men.
    </p>
    <p>
        4. We believe that the Lord Jesus Christ paid the penalty for our sins in His death on the cross and that only those who repent and believe in Him are justified, not by works, but based on His atoning death alone.
    </p>
    <p>
        5. We believe that the Lord Jesus Christ was resurrected in body, ascended into heaven, presently lives there for us as our High Priest and Advocate, and that He will return to earth visibly and bodily and will judge all people in righteousness.
    </p>
</ion-content>

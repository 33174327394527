import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from "@ionic/angular";

@Component({
  selector: 'app-bg-check',
  templateUrl: './background-check-policy.page.html',
  styleUrls: ['./background-check-policy.page.scss'],
})
export class BackgroundCheckPolicyPage implements OnInit {
  @Input() contentData: any;
  constructor(private modal: ModalController) { }

  ngOnInit() {
  }

  dismissModal() {
    this.modal.dismiss();
  }
}
import { Component, OnInit } from '@angular/core';
import {UserResource} from "../../../../api/resources/user.resource";
import {UserService} from "../../../../api/services/user.service";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import {Storage} from "@ionic/storage";
import {LoadingController} from "@ionic/angular";
import {FamilyService} from "../../../../api/services/family.service";
import {FamilyResource} from "../../../../api/resources/family.resource";
import {TitleCasePipe} from "@angular/common";
import {Page} from "../../../../../models/page";
import { Environment as Env } from '../../../../../environments/environment.prod';


@Component({
  selector: 'app-user-documents',
  templateUrl: './user-documents.component.html',
  styleUrls: ['./user-documents.component.scss'],
})
export class UserDocumentsComponent implements OnInit {
  isLoading: boolean = false;

  userId: number;

  private currentUser: any = {};

  private user: UserResource;

  dataList: FamilyResource[];

  private loading: any;

  private userService: UserService;
  private familyService: FamilyService;

  private titleCasePipe: TitleCasePipe;

  private page: Page;

  constructor(private httpClient: HttpClient,
              private route: ActivatedRoute,
              private router: Router,
              private storage: Storage,
              private loader: LoadingController) {
    this.userService = new UserService(this.httpClient);
    this.familyService = new FamilyService(this.httpClient);
    this.titleCasePipe = new TitleCasePipe();
    this.page = new Page();
    this.page.pageNumber = 0;
    this.page.size = Env.perPage;
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.userId = params.hasOwnProperty('id') ? parseInt(params.id) : null;




      this.storage.get('loginRes').then((val) => {
        this.currentUser = val.user;
        if (this.userId) {
          this.loadFamily();
        } else {
          this.user = new UserResource();
          // this.permissionsService.hasPermission('users_cause-groups_manage').then((hasPermission) => {
          //   if (hasPermission) this.loadCauseGroups();
          // });
        }
      });
    });
  }

  async presentLoading(options: object = { message: 'Please wait...' }) {
    this.loading = await this.loader.create(options);
    return await this.loading.present();
  }

  private async loadFamily()
  {
    const familyResourceListResponse = await this.userService.getFamiliesByUser(this.userId).toPromise();
    this.isLoading = true;
    this.dataList = familyResourceListResponse.data;

    this.isLoading = false;
    this.page.totalElements = familyResourceListResponse.data.length;
    this.page.totalPages = 1;
  }

  checkIfIsImage(url:string)
  {
    return (url.match(/\.(jpg|gif|png)$/)!= null);
  }

  download(url)
  {

    window.open(url, '_blank');

  }

}

//use @Pipe function before each class
import { PipeTransform, Pipe } from "@angular/core";
import {UserResource} from "../../api/resources/user.resource";
import {RoleResource} from "../../api/resources/role.resource";

@Pipe({
    name: 'userSearch'
})
export class UserSearchPipe implements PipeTransform {

    transform(friends: UserResource[], term: string): UserResource[] {
        if (!term) return friends;
        const regex = new RegExp(term, 'gi');
        return friends.filter((friend: UserResource) =>
            friend.name.search(regex) > -1 || friend.roles.some((role: RoleResource) => role.name.search(regex) > -1)

        );
    }
}

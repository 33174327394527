import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {Inject, Injectable} from '@angular/core';
import { ResourceService } from './resource.service';
import { ResourceSerializer } from '../serializers/resource-serializer';
import { CauseGroupResource } from '../resources/cause-group.resource';
import { CauseGroupDashboardResource } from '../resources/cause-group-dashboard.resource';
import { OrganizationResource } from '../resources/organization.resource';
import { NeedResource } from '../resources/need.resource';
import { FamilyResource } from '../resources/family.resource';
import { UserResourceResource } from '../resources/userresource.resource';
import { UserResource } from '../resources/user.resource';
import { EntityConnectionsService } from './entity-connections.service';


@Injectable()
export class CauseGroupService extends ResourceService<CauseGroupResource> {
    static resourceSlug: string = 'cause-group';

    private entityConnectionsService: EntityConnectionsService;
    private causeGroup:CauseGroupResource;

    constructor(httpClient: HttpClient, @Inject('causeGroupServiceEndpoint') endpoint: string = CauseGroupService.resourceSlug) {
      super(
        httpClient,
        endpoint,
        new ResourceSerializer(CauseGroupResource),
      );
      this.entityConnectionsService = new EntityConnectionsService(httpClient);
    }

    dashboard(id: number, options: object = {}): Observable<CauseGroupDashboardResource> {
      return this.httpClient
        .get(`${this.url}${this.endpoint}/${id}/dashboard`, options)
        .pipe(map((response: any) => {
          const causeGroup = new CauseGroupDashboardResource();
          causeGroup.organizations = <any> this.convertData(response.data.organizations, new ResourceSerializer(OrganizationResource));
          causeGroup.needs = <any> this.convertData(response.data.needs, new ResourceSerializer(NeedResource));
          causeGroup.families = <any> this.convertData(response.data.families, new ResourceSerializer(FamilyResource));
          causeGroup.resources = <any> this.convertData(response.data.resources, new ResourceSerializer(UserResourceResource));
          causeGroup.volunteers = <any> this.convertData(response.data.volunteers, new ResourceSerializer(UserResource));

          return causeGroup;
        }));
    }

    async isAdmin(user: UserResource, causeGroup: CauseGroupResource): Promise<boolean> {
      return new Promise<boolean>((resolve, reject) => {
        const path = `/${user.entity_id}/admin-cause-group`;

        this.entityConnectionsService.list(null, path).toPromise()
          .then((entityConnections) => {
            const isAdmin = entityConnections.data.some((value) => value.edge
                            && value.edge.code === 'admin-cause-group'
                            && value.entity_b === causeGroup.entity_id);
            resolve(isAdmin);
          })
          .catch((error) => { reject(error); });
      });
    }

    export(id:number, options:object = {}):Observable<CauseGroupDashboardResource>
    {
        console.dir(options);
        return this.httpClient
            .get(`${this.url}${this.endpoint}/${id}/export`, options)
            .pipe(map((response: any) => {
                return response;
                // const causeGroup = new CauseGroupDashboardResource();
                // causeGroup.organizations = <any> this.convertData(response.data.organizations, new ResourceSerializer(OrganizationResource));
                // causeGroup.needs = <any> this.convertData(response.data.needs, new ResourceSerializer(NeedResource));
                // causeGroup.families = <any> this.convertData(response.data.families, new ResourceSerializer(FamilyResource));
                // causeGroup.resources = <any> this.convertData(response.data.resources, new ResourceSerializer(UserResourceResource));
                // causeGroup.volunteers = <any> this.convertData(response.data.volunteers, new ResourceSerializer(UserResource));
                //
                // return causeGroup;
            }));
    }

    isValid(causeGroupdId: string): Promise<boolean> {
      return new Promise(resolve => {
        this.httpClient.head(`${this.url}${this.endpoint}/${causeGroupdId}`)
        .toPromise()
        .then(()=> resolve(true))
        .catch(error => {
          console.log(`An error occurred while looking for the cause group ${causeGroupdId}: ${error.message}`);
          resolve(false);
        });
      });
    }
    getBySlug(causeGroupdId: string): Observable<CauseGroupResource> {
      return this.httpClient
          .get<CauseGroupResource>(`${this.url}${this.endpoint}/${causeGroupdId}`)
          .pipe(map(( data: any) => this.serializer.fromJson(typeof data.data ==='undefined'? data : data.data) as CauseGroupResource));
    }

}

import {HttpClient, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import {Observable} from 'rxjs';
import { ResourceService } from './resource.service';
import { FamilyResource } from '../resources/family.resource';
import { ResourceSerializer } from '../serializers/resource-serializer';
import { UserResource } from '../resources/user.resource';
import { NeedResource } from '../resources/need.resource';

@Injectable()
export class FamilyService extends ResourceService<FamilyResource> {
    static resourceSlug: string = 'family';

    constructor(httpClient: HttpClient, endpoint: string = FamilyService.resourceSlug) {
      super(
        httpClient,
        endpoint,
        new ResourceSerializer(FamilyResource),
      );
    }

    addRepresentative(email: string, familyId: number): Observable<UserResource> {
      const options = { email };
      return this.httpClient
        .post<UserResource>(`${this.url}${this.endpoint}/${familyId}/add-representative`, options)
        .pipe(map((data: any) => this.serializer.fromJson(typeof data.data === 'undefined' ? data : data.data) as UserResource));
    }

    addNeed(item: NeedResource, familyId: number): Observable<NeedResource> {
      return this.httpClient
        .post<NeedResource>(`${this.url}${this.endpoint}/${familyId}/need`, this.serializer.toJson(item))
        .pipe(map((data: any) => this.serializer.fromJson(typeof data.data === 'undefined' ? data : data.data) as NeedResource));
    }

    addDeathCertificate(data: any, familyId:number): Observable<FamilyResource> {
        return this.httpClient
            .post(`${this.url}${this.endpoint}/${familyId}/add-death-certificate`, this.serializer.toJson(data))
            .pipe(map((data: any) => {
                return this.serializer.fromJson(typeof data.data === 'undefined' ? data : data.data) as FamilyResource;
            }));
    }
    checkByEmail(params?:HttpParams ): Observable<any> {
      return this.httpClient
        .get(`${this.url}${this.endpoint}/checkByEmail`, {params})
        .pipe(map((data: any) => {
          return data;
        }));
    }
    resendFamilyInvite(familyId:number ): Observable<any> {
        return this.httpClient
            .post(`${this.url}family/${familyId}/resendFamilyInvite`, null)
            .pipe(map((data: any) => {
                return data;
            }));
    }

      setEndpoint(endpoint: string):void {
      this.endpoint = endpoint;
    }
}

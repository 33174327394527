import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { OccurrenceRsvpResource } from '../resources/occurrence-rsvp.resource';
import { ResourceService } from './resource.service';
import { ResourceSerializer } from '../serializers/resource-serializer';
import {ListResponse} from "../resources/list-response";
import {NeedResource} from "../resources/need.resource";
import {OrganizationResource} from "../resources/organization.resource";
import {BackgroundCheckResource} from "../resources/background-check.resource";

@Injectable()
export class BackgroundCheckService extends ResourceService<BackgroundCheckResource> {
    static resourceSlug: string = 'backgroundChecks';

    constructor(httpClient: HttpClient, endpoint: string = BackgroundCheckService.resourceSlug) {
        super(
            httpClient,
            endpoint,
            new ResourceSerializer(BackgroundCheckResource),
        );
    }


    backgroundChecks(userId:number, params?:HttpParams): Observable<ListResponse<BackgroundCheckResource>>
    {
        return this.httpClient
            .get(`${this.url}user/${userId}/cause-group/backgroundChecks`,{params})
            .pipe(map((data: any) => {
                    const listResponse = new ListResponse<BackgroundCheckResource>();
                    listResponse.metadata = data.metadata;
                    listResponse.data = this.convertData(data.data,new ResourceSerializer(BackgroundCheckResource));
                    return listResponse;
                }

            ));

    }
    exportBackgroundChecks(userId:number, options:Object): Observable<any>
    {
        return this.httpClient
            .get(`${this.url}user/${userId}/cause-group/exportBackgroundChecks`,options)
            .pipe(map((data: any) => {
                   return data;
                }

            ));

    }
}

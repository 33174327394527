import {Component, Input, OnInit, TemplateRef} from '@angular/core';

@Component({
  selector: 'app-header-actions-popover',
  templateUrl: './header-actions-popover.component.html',
  styleUrls: ['./header-actions-popover.component.scss'],
})
export class HeaderActionsPopoverComponent implements OnInit {
  @Input() contentTemplate: TemplateRef<any>;

  constructor() {}

  ngOnInit() {}
}

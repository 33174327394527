<ion-content class="ion-padding">
  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-label>
          <h1 *ngIf="tagId; else labelBlock">Edit Tag</h1>
          <ng-template #labelBlock>
            <h1>Create Tag</h1>
          </ng-template>
        </ion-label>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="isLoading; else tagBlock" class="ion-justify-content-center">
      <ion-col size="auto">
        <ion-spinner></ion-spinner>
      </ion-col>
    </ion-row>

    <ng-template #tagBlock>
      <ion-row>
        <ion-col>
          <form [formGroup]="form.getForm()" (ngSubmit)="save()">
            <ion-list>
              <ion-col>
                <ion-row>
                  <ion-col>
                    <ion-item class="item-input">
                      <ion-label class="txt3" position="stacked">Name <span class="required">*</span></ion-label>
                      <ion-input formControlName="name" placeholder="Name" type="text"></ion-input>
                      <ion-note color="danger" *ngIf="form.hasError('name')">
                        {{ form.getError('name')}}
                      </ion-note>
                    </ion-item>
                  </ion-col>
                </ion-row>

                <ion-row>
                  <ion-col>
                    <ion-item class="item-input">
                      <ion-label class="txt3" position="stacked">Category <span class="required">*</span></ion-label>
                      <ion-select formControlName="category" placeholder="Category">
                        <ion-select-option value="provider">Service Providers</ion-select-option>
                        <ion-select-option value="need">Needs</ion-select-option>
                        <ion-select-option value="resource">Learning Resources</ion-select-option>
                      </ion-select>
                      <ion-note color="danger" *ngIf="form.hasError('category')">
                        {{ form.getError('category')}}
                      </ion-note>
                    </ion-item>
                  </ion-col>
                </ion-row>

                <ng-container *ngxPermissionsOnly="['all-tags_add', 'all-tags_edit']">
                  <ion-row *ngIf="isLoadingCauseGroups; else causeGroupBlock" class="ion-justify-content-center">
                    <ion-col size="auto">
                      <ion-spinner></ion-spinner>
                    </ion-col>
                  </ion-row>

                  <ng-template #causeGroupBlock>
                    <ion-row>
                      <ion-col>
                        <ion-item class="item-input">
                          <ion-label class="txt3" position="stacked">Cause Group <span class="required">*</span></ion-label>
                          <ion-select formControlName="cause_group_id" placeholder="Cause Group">
                            <ion-select-option *ngFor="let causeGroup of causeGroups" [value]="causeGroup.id">{{causeGroup.name}}</ion-select-option>
                          </ion-select>
                          <ion-note color="danger" *ngIf="form.hasError('cause_group_id')">
                            {{ form.getError('cause_group_id')}}
                          </ion-note>
                        </ion-item>
                      </ion-col>
                    </ion-row>
                  </ng-template>
                </ng-container>

                <ion-row>
                  <ion-col>
                    <ion-button [disabled]="!form.getForm().valid || isLoadingCauseGroups" expand="block" type="submit">
                      <ion-icon slot="start" name="save"></ion-icon>
                      Save
                    </ion-button>
                  </ion-col>
                </ion-row>
              </ion-col>
            </ion-list>
          </form>

          <ng-container *ngxPermissionsOnly="['tags_delete', 'all-tags_delete']">
            <ion-button *ngIf="tagId" (click)="presentDeleteConfirm()" expand="block" color="danger">
              <ion-icon slot="start" name="trash"></ion-icon>
              Delete
            </ion-button>
          </ng-container>
        </ion-col>
      </ion-row>
    </ng-template>
  </ion-grid>
</ion-content>

import { Component } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Storage } from '@ionic/storage';
import { ValidationManager } from 'ng2-validation-manager';
import { AlertController, LoadingController, ToastController } from '@ionic/angular';
import { subDays } from 'date-fns';
import { CauseGroupDashboardResource } from '../../../../api/resources/cause-group-dashboard.resource';
import { CauseGroupService } from '../../../../api/services/cause-group.service';
import { UserResourceService } from '../../../../api/services/userresource.service';
import { UserResourceResource } from '../../../../api/resources/userresource.resource';
import { OrganizationService } from '../../../../api/services/organization.service';
import { NeedService } from '../../../../api/services/need.service';
import { OrganizationResource } from '../../../../api/resources/organization.resource';
import { Resource } from '../../../../api/resources/resource';
import {NeedResource} from "../../../../api/resources/need.resource";

@Component({
  selector: 'cause-group-dashboard',
  templateUrl: './cause-group-dashboard.component.html',
  styleUrls: ['./cause-group-dashboard.component.scss'],
})

export class CauseGroupDashboardComponent {
    private loading: any;

    form: ValidationManager;

    causeGroupId: number;

    private currentUser: any = {};

    causeGroupDashboard: CauseGroupDashboardResource;

    constructor(private httpClient: HttpClient,
                private route: ActivatedRoute,
                private router: Router,
                private storage: Storage,
                private alert: AlertController,
                private loader: LoadingController,
                private toast: ToastController) {
      this.form = new ValidationManager({
        start_date: 'date',
        end_date: 'date',
        keyword: 'rangeLength:2,50',

      });
      this.causeGroupDashboard = new CauseGroupDashboardResource();
    }

    ngOnInit() {
      this.route.params.subscribe((params) => {
        if (params.hasOwnProperty('id')) {
          this.causeGroupId = parseInt(params.id);

          this.storage.get('loginRes').then(async (val) => {
            this.currentUser = val;

            this.form.setValue({
              start_date: subDays(new Date(), 7).toISOString(),
              end_date: new Date().toISOString(),
            });

            await this.loadInfo();
          });
        }
      });
    }

    async loadInfo() {
      if (this.form.isValid()) {
        await this.presentLoading();

        this.clearcauseGroupDashboard();

        const formData = this.form.getData();
        const searchData = { ...formData };

        let params = new HttpParams();
        for (const key in searchData) {
            console.log('Key: ' + searchData[key]);
          if (searchData[key] !== '' && searchData[key] !== null) params = params.set(key, searchData[key].toString());
        }

        const options = {
          params,
        };

        new CauseGroupService(this.httpClient)
          .dashboard(this.causeGroupId, options)
          .toPromise()
          .then((data) => {
            this.causeGroupDashboard = data;
          })
          .catch((errorResponse) => {
            this.toast.create({
              header: 'Search error',
              message: errorResponse.error.message,
              buttons: [
              {
                text: 'Close',
                role: 'cancel',
                handler: () => {}
              }
            ],
              color: 'danger',
            }).then((toast) => toast.present());
          })
          .finally(() => { this.loading.dismiss(); });
      }
    }

    clearcauseGroupDashboard() {
      this.causeGroupDashboard.organizations.length = 0;
      this.causeGroupDashboard.needs.length = 0;
      this.causeGroupDashboard.families.length = 0;
      this.causeGroupDashboard.resources.length = 0;
      this.causeGroupDashboard.volunteers.length = 0;
    }

    async presentLoading(options: object = { message: 'Please wait...' }) {
      this.loading = await this.loader.create(options);
      return this.loading.present();
  }

    async export(exportType)
    {
        if (this.form.isValid()) {
            await this.presentLoading();


            const formData = this.form.getData();
            const searchData = { ...formData };

            let params = new HttpParams();
            for (const key in searchData) {
                if (searchData[key] !== '' && searchData[key] !== null) params = params.set(key, encodeURIComponent(searchData[key].toString()));
            }
            params = params.set('type', encodeURIComponent(exportType));

            const options = {
                params,
            };

            new CauseGroupService(this.httpClient)
                .export(this.causeGroupId, options)
                .toPromise()
                .then((data:any) => {
                    // this.causeGroupDashboard = data;
                    window.open(data.url, '_blank');
                })
                .catch((errorResponse) => {
                    this.toast.create({
                        header: 'Search error',
                        message: errorResponse.error.message,
                        buttons: [
              {
                text: 'Close',
                role: 'cancel',
                handler: () => {}
              }
            ],
                        color: 'danger',

                    }).then((toast) => toast.present());
                })
                .finally(() => { this.loading.dismiss(); });
        }
    }

    async onActiveToggleChange(active: boolean, item: UserResourceResource|OrganizationResource|NeedResource) {
      await this.presentLoading();
      // if org has no address
      // add alert to prompt cg admin to add address
      if (item instanceof OrganizationResource && !item.street) {
        this.alert.create({
          header: 'Add Address to Provider',
          message: 'Please enter an address for this provider to appear in the search',
          buttons: [{
            text: 'Edit Provider',
            handler: () => {
              this.router.navigateByUrl(`/dashboard/organization/${item.id}/edit?cgdashboard=true`);
            }
          }]
        }).then((alert) => alert.present());

      }
      const oldStatus = item.status;
      this.getActivatePromise(active, item)
        .then((res: UserResourceResource|OrganizationResource|NeedResource) => {
        item.status = res.status;
      })
      .catch((errorResponse) => {
          this.toast.create({
            header: 'Error',
            message: errorResponse.error.message,
            buttons: ['OK'],
          }).then((toast) => toast.present());
          item.status = oldStatus;
      })
      .finally(() => this.loading.dismiss());
    }


    private getActivatePromise(active: boolean, item: UserResourceResource | OrganizationResource | NeedResource): Promise<Resource> {
      let serviceInstance;
      if (item instanceof UserResourceResource) {
        const resourceEndpoint = `user/${item.user_id}/${UserResourceService.resourceSlug}`;
        serviceInstance = new UserResourceService(this.httpClient, resourceEndpoint);
      }
      else if (item instanceof NeedResource){
          const needResourceEndpoint = `${NeedService.resourceSlug}`;
          serviceInstance = new NeedService(this.httpClient, needResourceEndpoint);
      }
      else {
        const organizationEndpoint = `user/${item.user_id}/${OrganizationService.resourceSlug}`;
        serviceInstance = new OrganizationService(this.httpClient, organizationEndpoint);
      }
      return serviceInstance.toggleStatus(active, item).toPromise();
    }

    async onVisibleToggleChange(visible: boolean, item: UserResourceResource|OrganizationResource) {
      await this.presentLoading();
      this.getVisibleTogglePromise(visible, item)
      .then(() => {
          item.visible = visible;
      }, () => {
        item.visible = !visible;
      })
      .catch((errorResponse) => {
        this.toast.create({
          header: 'Error',
          message: errorResponse.error.message,
          buttons: ['OK'],
        }).then((toast) => toast.present());
        item.visible = !visible;
      })
      .finally(() => this.loading.dismiss());
    }

    private getVisibleTogglePromise(visible: boolean, item: UserResourceResource|OrganizationResource): Promise<Resource> {
      if (item instanceof UserResourceResource) {
        return new UserResourceService(this.httpClient).toggleVisibilty(visible, item.id, this.causeGroupId).toPromise();
      }
      return new OrganizationService(this.httpClient).toggleVisibilty(visible, item.id, this.causeGroupId).toPromise();
    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { FriendListComponent } from './friend-list/friend-list.component';

import { FriendRoutingModule } from './friend-routing.module';
import { PipesModule } from "../../shared/pipes/pipes.module";
import {MatExpansionModule} from "@angular/material/expansion";
import {HandleAdvocateInnerCircleRemovalService} from "../../shared/services/handle-advocate-inner-circle-removal.service";
import {MatTabsModule} from "@angular/material/tabs";
import {EntityConnectionsService} from "../../api/services/entity-connections.service";
import {MatTabGroupGestureModule} from "@angular-material-gesture/mat-tab-group-gesture";
import {CirclesListComponentModule} from "../../shared/components/circles-list/circles-list.component.module";


@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        ReactiveFormsModule,
        FriendRoutingModule,
        PipesModule,
        MatExpansionModule,
        MatTabsModule,
        MatTabGroupGestureModule,
        CirclesListComponentModule
    ],
  declarations: [
      FriendListComponent,
  ],
  providers: [HandleAdvocateInnerCircleRemovalService, EntityConnectionsService]
})
export class FriendModule {}

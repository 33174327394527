<ion-content class="ion-padding">
  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-label>
          <h1 *ngIf="userId; else labelBlock">Remove User</h1>
          <ng-template #labelBlock>
            <h1>Create User</h1>
          </ng-template>
        </ion-label>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="isLoading; else userBlock" class="ion-justify-content-center">
      <ion-col size="auto">
        <ion-spinner></ion-spinner>
      </ion-col>
    </ion-row>

    <ng-template #userBlock>
      <ion-row>
        <ion-col>
          <form [formGroup]="form.getForm()" (ngSubmit)="delete()">
            <ion-card-content *ngIf="user">
              <p >
                <b>User Name:</b> {{user.first_name}} {{user.last_name}}
              </p>
              <p *ngIf=" user.first_church && user.first_church.length > 0" class="church-wrapper">

                <b>Church: </b> <a [routerLink]="['/','dashboard','organization',user.first_church[0].id]"> {{user.first_church[0].name}}</a>
              </p>
              <p *ngIf="user.email">
                <b>Phone:</b> {{user.email}}
              </p>
              <p *ngIf="user.phone">
                <b>Phone:</b> {{user.phone}}
              </p>
              <p *ngIf="user.zip_code">
                <b>Zip:</b> {{user.zip_code}}
              </p>
              <p *ngIf="user.dob_range">
                <b>Age Range:</b> {{user.dob_range}}
              </p>
              <p *ngIf="user.bio">
                <b>About:</b> {{user.bio}}
              </p>
              <p *ngIf="user.knowledge_source" >
                <b>How did you hear about us: </b> {{user.knowledge_source}}
              </p>
              <p *ngIf="user.roles">
                <b>Roles: </b>
                {{user.getRoleTitles() }}
              </p>
            </ion-card-content>


            <ion-list>
              <ion-col>
                <!-- @todo
                <ion-row>
                  <ion-col>
                    select role
                  </ion-col>
                </ion-row>
                -->
                <app-my-organizations></app-my-organizations>
              </ion-col>
              <ion-col size="12" size-lg="6">

                <app-my-circle-admin></app-my-circle-admin>
                <ion-card *ngIf="familyList">
                  <ion-card-header >
                    <ion-card-title>Families</ion-card-title>
                    <ion-card-subtitle *ngIf="isCauseGroupAdmin"><i>Visible Only to Cause Group Administrators</i></ion-card-subtitle>
                  </ion-card-header>
                  <ion-card-content>
                    <ion-text *ngIf="(!familyList.length || !familyListAvailable) && !isCauseGroupAdmin">No common families </ion-text>
                    <ng-container *ngFor="let item of familyList" >
                      <ng-container *ngIf="isRepresentative(item, currentUser) || isCauseGroupAdmin">
                        <ion-item size="12">
                          <ion-avatar slot="start"  class="pointer-link">
                            <img alt="Family Cover Image" [src]="item.cover_image ||'../assets/img/family.png'" />
                          </ion-avatar>
                          <ion-label>
                            <ion-button class="ion-float-right button" color="secondary" [routerLink]="['/dashboard/show-fam', item.id]" ><ion-icon name="eye" slot="start" ></ion-icon>
                              <span >View</span>
                            </ion-button>
                            <ion-button *ngIf="!isCauseGroupAdmin"  class="ion-float-right button" color="primary" [routerLink]="'/dashboard/add-fam'" [queryParams]="{id: item.id}"><ion-icon  name="create" slot="start"></ion-icon>
                              <span >Edit {{item.user_id === currentUser.id ? 'Family':'' }}</span>
                            </ion-button>
                            <div class="">{{item.first_name + ' ' + item.last_name}}<br/>
                              <span class="small">added on: {{item.created_at.replace(' ', 'T') | date: 'MM/dd/yyyy'}}</span>
                            </div>


                          </ion-label>
                        </ion-item>
                      </ng-container>
                    </ng-container>
                  </ion-card-content>
                </ion-card>
                <!-- @todo
                  <ng-container>
                    <ng-container *ngxPermissionsOnly="['cause-groups_users_edit']">
                      <ion-card>
                        <ion-card-header >
                          <ion-card-title>Answers</ion-card-title>
                        </ion-card-header>
                        <p *ngIf="user.knowledge_source" >
                          <b>How did you hear about us: </b> {{user.knowledge_source}}
                        </p>
                        <ion-card-content>
                          <ngx-datatable
                              [rows]="userAnswers"
                              [loadingIndicator]="isLoadingUserAnswers"
                              [columnMode]="columnMode.flex"
                              class="material striped"
                              headerHeight="50"
                              rowHeight="auto"
                              footerHeight="50"
                              scrollbarH="true"
                              [limit]="5"
                          >
                            <ngx-datatable-column name="Question" prop="question" [flexGrow]="1"></ngx-datatable-column>
                            <ngx-datatable-column name="Answer" prop="answer" [flexGrow]="1"></ngx-datatable-column>
                          </ngx-datatable>
                        </ion-card-content>
                      </ion-card>
                    </ng-container>
                  </ng-container-->

                <ion-row>
                  <ion-col>
                    <ion-button [disabled]="!form.getForm().valid || isLoadingCauseGroups" expand="block" type="submit">
                      <ion-icon slot="start" name="save"></ion-icon>
                      Save
                    </ion-button>
                  </ion-col>
                </ion-row>
              </ion-col>
            </ion-list>
          </form>

          <ng-container *ngxPermissionsOnly="['users_delete']">
            <ion-button *ngIf="userId" (click)="presentDeleteConfirm()" expand="block" color="danger">
              <ion-icon slot="start" name="trash"></ion-icon>
              Delete User
            </ion-button>
          </ng-container>

        </ion-col>
      </ion-row>
    </ng-template>
  </ion-grid>
</ion-content>

/**
 * THIS CAN / SHOULD TRANSITION INTO A TOTALLY DYNAMIC ROUTE SERVICE WITH DYNAMIC ROUTE ORDER.
 */
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { NgxPermissionsService } from 'ngx-permissions';
import { UserResource } from '../../app/api/resources/user.resource';
import { ResourceSerializer } from '../../app/api/serializers/resource-serializer';
import { OrganizationCheckerService } from 'src/app/shared/services/organization-checker.service';
import {MenuType} from "../../app/shared/types/Menu.type";

@Injectable()
export class SettingsService {
    private commonRoutes: any;

    private causeMtnRoutes: any;

    private orgRoutes: any;

    private publicRoutes: any;

    private pages: any;

    constructor(
      private storage: Storage,
      private permissionsService: NgxPermissionsService,
      private orgCheckerService: OrganizationCheckerService) {
    }

    /**
     *
     * @desc
     * if the origin is from a cause group load their custom attributes
     * else { load MTN default attributes }
     *
     * Store in local storage, wait to complete and then send back results
     *
     * @return Promise [
     */
    fetchGroupConfig(): Promise<any> {
      return new Promise((resolve) => {
        const attr = this.mockGroupSettings();
        // store in localStorage first
        // should only need stringify when in mock mode
        this.storage.set('platformSettings', JSON.stringify(attr))
          .then(() => attr);

        resolve(attr);
      });
    }

    /**
     * @desc FETCH USERS ROUTES THAT ARE SPECIFIC TO THAT CAUSE GROUP AND PERSON
     *
     * store the routes to prevent unnecessary http calls
     *
     * @param user
     * @returns Promise<any>
     */
    async loadRoutes(user): Promise<Array<MenuType>> {
      if (user instanceof UserResource) {
        // do nothing
      } else {
        const loginRes = await this.storage.get('loginRes');
        if (loginRes && loginRes.hasOwnProperty('user')) user = (new ResourceSerializer(UserResource)).fromJson(loginRes.user) as UserResource;
      }

      return new Promise((resolve) => {
        if (user instanceof UserResource) {
          this.mockRoutes(user)
            .then((menu) => {
              resolve(menu);
            });
        } else {
          resolve([]);
        }
      });
    }

    /**
     *
     * ----------------------->   MOCK DATA BEFORE WRITING THE SERVICE    <------------------------- *
     */
    private async mockRoutes(user: UserResource): Promise<any> {
      this.commonRoutes = [
        { title: 'Login', url: 'login' },
      ];

      this.causeMtnRoutes = [
        { title: 'Dashboard', url: 'DashboardComponent' },
        { title: 'Stats', url: 'StatsComponent' },
        { title: 'User Admin', url: 'UserListComponent' },
        { title: 'Needs Review', url: 'ActiveNeedsComponent' },
        { title: 'Calendar Admin', url: 'CalendarsComponent' },
        { title: 'Role Management', url: 'RoleManagementComponent' },
        { title: 'User Onboarding', url: 'UserOnboardingQuestionsComponent' },
      ];

      this.orgRoutes = [
        { title: 'Dashboard', url: 'DashboardComponent' },
        { title: 'Stats', url: 'StatsComponent' },
        { title: 'Associate Users', url: 'AssociateUsersComponent' },
        { title: 'Needs', url: 'ActiveNeedsComponent' },
        { title: 'FAQ', url: 'FaqComponent' },
        { title: 'Privacy Policy', url: 'PrivacyPolicyComponent' },
        { title: 'TOS', url: 'TosComponent' },
      ];

      const menuItems = [];

      if (await this.permissionsService.hasPermission(['cause-groups_list', 'all-cause-groups_list'])) {
        menuItems.push({
          icon: 'aperture',
          title: 'Cause Groups',
          url: await this.permissionsService.hasPermission('all-cause-groups_list') ? '/admin/cause-groups' : `/admin/user/${user.id}/cause-groups`,
        });

        menuItems.push({
          icon: 'warning',
          title: 'Background Checks',
          url: '/admin/background-checks',
        });
      }
      if (await this.permissionsService.hasPermission('all-organizations_list')) {
        menuItems.push({
          icon: 'business', title: 'Service Providers', url: '/admin/organization',
        });
      }
      if (await this.permissionsService.hasPermission('all-resources_list')) {
        menuItems.push({
          icon: 'newspaper', title: 'Learning Resources', url: '/admin/resource',
        });
      }
      if (await this.permissionsService.hasPermission('all-tags_list')) {
        menuItems.push({
          icon: 'pricetag', title: 'Tags', url: '/admin/tags',
        });
      }
      if (await this.permissionsService.hasPermission('users_list')) {
        menuItems.push({
          icon: 'people', title: 'Users', url: '/admin/users',
        });
      }


      // @todo: change to permission
      menuItems.push({
        icon: 'person-add',
        title: 'Connect',
        children: [
          {icon: 'sync', title: 'Circles of Support', url: '/connect?session=connections'},
          {icon: 'people-circle', title: 'My Family(ies)', url: '/connect?session=family'},
          {icon: 'mail', title: 'Requested Connections', url: '/connect?session=requests'},
        ],
      });


      menuItems.push({
        icon: 'leaf',
        title: 'Grow',
        children: [
          {icon: 'newspaper', title: 'Learning Resources', url: '/resource-list'},
          {icon: 'calendar', title: 'Events', url: '/grow?session=events'},
          {icon: 'book', title: 'Bible Verses', url: '/grow?session=bible'},
        ],
      });

      menuItems.push({
        icon: 'water',
        title: 'Impact',

        children: [
          {icon: 'hand-left', title: 'Needs', url: 'impact?session=needs'},
          {icon: 'calendar', title: 'Commitments', url: 'impact?session=needs'},
          {icon: 'business', title: 'Local Service Providers', url: '/dashboard/search-orgs'},
        ],
      });

      menuItems.push({
        icon: 'book',
        title: 'Bible',
        url: '/bible-reference',
      });

      menuItems.push({
        icon: 'list', title: 'Next Steps', url: '/guide',
      });

      if(user.causeGroups.length > 0)
      {
        menuItems.push({
          icon: 'newspaper',
          title: 'Link2Hope',
          children: [
              { icon: 'logo-usd', title: 'Donate to Link2Hope', url: user.causeGroups[0].donation_link, external: true},
              { icon: 'call', title: 'Contact Link2Hope', url: '/contact',},
              { icon: 'help-circle', title: 'FAQs', url: '/faqs' },
              { title: 'Terms of Use', type: 'terms' },
              { title: 'Privacy Policy', type: 'privacy' },

          ]
        });
      }

      menuItems.push({
        icon: 'newspaper',
        title: 'Legal',
        children: [

        ],
      });
      // NOTE: As some of this legal pages are dynamic, I made an override on the app.component.ts to add the Statement
      // of Faith and Statement of Intent on the Primary Menu Legal Section.

      // THIS WILL LATER BE PUBLIC ACCESS ROUTES
      this.publicRoutes = [
        { title: 'Register', url: 'RegistrationComponent', children: '' },
      ];


      return new Promise((resolve) => {
        /*
            switch (userType) {
                case 'endUser':
                    this.pages = this.userRoutes;
                    break;
                case 'orgRoutes':
                    this.pages = this.orgRoutes;
                    break;
                case 'admin':
                    this.pages = this.causeMtnRoutes;
                    break;
                default :
                    // this will later contain 404 page
                    this.pages = this.publicRoutes; // or entryPOINT
                    break;
            }
            */
        this.pages = menuItems;

        this.storage.set('routes', JSON.stringify(this.pages))
          .then(() => {
            resolve(this.pages);
          });
      });
    }

    loadEncouragementConfig(): Promise<any> {
      return new Promise((resolve) => {
        const data = {
          post_redirect: 'DashboardComponent',
          subType: 'base',
          requires_server_ineraction: true,
          api_url: '',
          title: 'Good Job completing the questions we know you a little better now.',
          bodyText: 'You urge me to revise the old Latin version, and, as it were, to sit in judgment on the copies of the Scriptures which are now scattered throughout the whole world; and, inasmuch as they differ from one another, you would have me decide which of them agree with the Greek original. The labour is one of love, but at the same time both perilous and presumptuous; for in judging others I must be content to be judged by all',
          cite_text: 'Weeping may last for the night, but a shout of joy comes in the morning.',
          author: 'PSALM 30:5',
        };

        resolve(data);
      });
    }

    private mockGroupSettings(): Promise<any> {
       return new Promise((resolve) => {
        resolve(42);
      });
    }
}

import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { ValidationManager } from 'ng2-validation-manager';
import {LoadingController, ToastController, AlertController} from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { NgxPermissionsService } from 'ngx-permissions';
import { CauseGroupService } from '../../../../api/services/cause-group.service';
import { CauseGroupResource } from '../../../../api/resources/cause-group.resource';
import FormDataHelper from '../../../../shared/helpers/form-data.helper';
import {URL_PATTER_VALIDATOR_MANAGER, URL_REGEXP_WITH_WRAPPER} from '../../../../../const';
import { UrlParserService } from 'src/app/shared/services/url-parser.service';
import {Validators} from "@angular/forms";
import {StateManagementService} from "../../../../api/services/state-management-service.service";

@Component({
  selector: 'cause-group-edit',
  templateUrl: './cause-group-edit.component.html',
  styleUrls: ['./cause-group-edit.component.scss']
})
export class CauseGroupEditComponent implements OnInit {
  public causeGroup: CauseGroupResource;
  private causeGroupService: CauseGroupService;
  form: ValidationManager;
  private loading: any;
  private currentUser: any = {};
  selectedImageFile = null;
  selectImageVisible = false;
  public selectedImageBlob: string = null;
  selectedImageFileIcon = null;
  public selectedImageIconBlob: string = null;
  public isImageIconApproved = true;
  public isImageApproved = true;
  selectImageVisibleIcon = false;
  public showStar: string = '';
  private urlParser: UrlParserService;
  private formWebsiteLinks: Array<string> = [
    'website',
    'background_check_link',
    'donation_link',
    'welcome_resource_link',
    'family_first_resource_link',
    'advocate_first_resource_link',
    'first_resource_link',
    'learning_resource_link',
    'statement_faith_link',
    'statement_intent_link',
  ];


  constructor(
    private httpClient: HttpClient,
    private permissionsService: NgxPermissionsService,
    private router: Router,
    private route: ActivatedRoute,
    private storage: Storage,
    private loader: LoadingController,
    private toast: ToastController,
    private alertController: AlertController,
    private appState: StateManagementService,
  ) {
    this.form = new ValidationManager({
      name: 'required',
      email: 'required|email',
      phone: '',
      website_prefix: '',
      website: URL_PATTER_VALIDATOR_MANAGER,
      background_check: { rules: 'required', value: 'true' },
      background_check_link_prefix: '',
      background_check_link: URL_PATTER_VALIDATOR_MANAGER,
      donation_link_prefix: '',
      donation_link: URL_PATTER_VALIDATOR_MANAGER,
      welcome_text: 'required',
      welcome_resource_type: '',
      welcome_resource_link_prefix: '',
      welcome_resource_link: `required|${URL_PATTER_VALIDATOR_MANAGER}`,
      family_first_resource_link_prefix: '',
      family_first_resource_link: URL_PATTER_VALIDATOR_MANAGER,
      advocate_first_resource_link_prefix: '',
      advocate_first_resource_link: URL_PATTER_VALIDATOR_MANAGER,
      first_resource_link_prefix: '',
      first_resource_link: URL_PATTER_VALIDATOR_MANAGER,
      statement_faith_link_prefix: '',
      statement_faith_link: URL_PATTER_VALIDATOR_MANAGER,
      statement_intent_link_prefix: '',
      statement_intent_link: URL_PATTER_VALIDATOR_MANAGER,
      disclaimer: 'required',
      advocate_text: 'required',
      background_check_content: '',
      learning_resource_link_prefix: '',
      learning_resource_link: URL_PATTER_VALIDATOR_MANAGER,
      app_icon_name: '',
      app_icon_image: ''
    });

    let prefixObject: Object = {};

    this.formWebsiteLinks.forEach(link => {
      prefixObject[link + '_prefix'] = 'http://';
      this.form.setErrorMessage(link, 'pattern', 'The provided URL is invalid');
    });

    this.form.setValue(prefixObject);
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.form.reset();
      const id = params.hasOwnProperty('id') ? parseInt(params.id) : null;
      this.storage.get('loginRes').then(val => {
        this.currentUser = val.user;
        const endpoint = `user/${this.currentUser.id}/cause-group`;
        this.causeGroupService = new CauseGroupService(
          this.httpClient,
          endpoint
        );
        this.loadCauseGroup(id);
      });
    });
  }

  loadCauseGroup(id: number): void {
    this.presentLoading();

    this.causeGroupService
      .read(id)
      .toPromise()
      .then((causeGroup: CauseGroupResource) => {
        this.causeGroup = causeGroup;
        this.form.setValue(causeGroup);
        this.formWebsiteLinks.forEach(link => {
          if (typeof causeGroup[link] === 'string' && causeGroup[link] !== '') {
            this.urlParser = new UrlParserService(causeGroup[link]);
            this.form
              .getForm()
              .get(link)
              .setValue(this.urlParser.getUrl());
            this.form
              .getForm()
              .get(link + '_prefix')
              .setValue(this.urlParser.getPrefix());
          } else {
            this.form
              .getForm()
              .get(link + '_prefix')
              .setValue('http://');
          }
        });
      })
      .catch(errorResponse => {
        this.toast
          .create({
            header: 'Save error',
            message: errorResponse.error.message,
            buttons: [
              {
                text: 'Close',
                role: 'cancel',
                handler: () => {}
              }
            ],
            color: 'danger'
          })
          .then(toast => toast.present());
      })
      .finally(() => this.loading.dismiss());
  }

  async presentLoading(options: object = { message: 'Please wait...' }) {
    this.loading = await this.loader.create(options);
    return await this.loading.present();
  }

  public save(): void {
    // TODO: check for links
    if (this.form.isValid()) {

      this.presentLoading();
      const causeGroupData = this.form.getData();
      causeGroupData.email = causeGroupData.email.toLowerCase();
      causeGroupData.id = this.causeGroup.id;
      if (this.selectImageVisible == true) {
        causeGroupData.cover_image = this.selectedImageBlob || this.selectedImageFile;
        causeGroupData.filetype = 'image';
      }
      if (this.selectImageVisibleIcon == true) {
        causeGroupData.app_icon_image = this.selectedImageIconBlob || this.selectedImageFileIcon;
        causeGroupData.filetype = 'image';
      }

      this.formWebsiteLinks.forEach(link => {
        if (
          typeof causeGroupData[link] === 'string' &&
          causeGroupData[link] !== ''
        ) {
          this.urlParser = new UrlParserService(
            causeGroupData[link + '_prefix'] + causeGroupData[link]
          );
          causeGroupData[link] = this.urlParser.getDBUrl();
        }
      });

      Object.assign(this.causeGroup, causeGroupData);
      const formData: any = FormDataHelper.convertModelToFormData(
        this.causeGroup
      );
      this.causeGroupService
        .updateForce(formData)
        .toPromise()
        .then(causeGroup => {
          this.causeGroup = causeGroup;
          this.toast
            .create({
              header: 'Congrats!',
              message: 'Saved successfully',
              buttons: [
              {
                text: 'Close',
                role: 'cancel',
                handler: () => {}
              }
            ],
              color: 'success',
              duration: 5000
            })
            .then(toast => toast.present());
            this.appState.update({causeGroup: causeGroup});
        })
        .catch(errorResponse => {
          this.toast
            .create({
              header: 'Save error',
              message: errorResponse.error.message,
              buttons: [
              {
                text: 'Close',
                role: 'cancel',
                handler: () => {}
              }
            ],
              color: 'danger'
            })
            .then(toast => toast.present());
        })
        .finally(() => this.loading.dismiss());

    }
  }

  async presentAlert(title, message) {
    const alert = await this.alertController.create({
      header: 'Error',
      subHeader: title,
      message,
      buttons: ['OK'],
    });

    await alert.present();
  }

  public updateFormValidation() {
     if (this.form.getForm().controls['background_check'].value == 'true') {
       this.form.getForm().get('background_check_link').clearValidators();
       this.form.getForm().get('background_check_link').setValidators([Validators.required, Validators.pattern(URL_REGEXP_WITH_WRAPPER)]);
       this.form.getForm().get('background_check_link').updateValueAndValidity();
       this.showStar = '*';
       return;
     }
     this.showStar = '';
    this.form.getForm().get('background_check_link').clearValidators();
    this.form.getForm().get('background_check_link').setValidators(Validators.pattern(URL_REGEXP_WITH_WRAPPER));
    this.form.getForm().get('background_check_link').updateValueAndValidity();
  }

  updateImageBlob($event: string) {
    this.selectedImageBlob = $event;
  }

  updateImageIconBlob($event: string) {
    this.selectedImageIconBlob = $event;
  }
}

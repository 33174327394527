import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import {MAX_FILE_BYTES, MAX_FILE_STRING, UPLOAD_IMAGES_COPYRIGHT} from "../../../../const";
import {FileUploadDataType} from "../../types/FileUploadData.type";
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit {

  @ViewChild('fileUploadInput', { static: true }) fileUploadInput: ElementRef<HTMLElement>;
  @ViewChild('filePreview') filePreview: ElementRef<HTMLElement>;
  @Input() title: string;
  @Input() subTitle: string = null;
  @Input() searchResultVisible: boolean = false;
  @Input() imageCropper: boolean = false;
  @Input() selectedImageVisible: boolean = false;
  @Input() selectedFile: File = null;
  @Input() approvedImage: boolean = false;
  @Input() copyrightText: string = UPLOAD_IMAGES_COPYRIGHT;
  @Input() fileTypes: Array<string> = ['png', 'jpg', 'jpeg', 'heic'];
  @Input() buttonTitle: string = 'Select Image';
  @Input() selectUpload: boolean = false;

  @Output() newCallBackEvent = new EventEmitter<object>();
  @Output() searchResultVisibleChange = new EventEmitter<boolean>();
  @Output() selectedImageVisibleChange = new EventEmitter<boolean>();
  @Output() selectedFileChange = new EventEmitter<File>();
  @Output() approvedImageChange = new EventEmitter<boolean>();
  @Output() successCallbackEvent = new EventEmitter<boolean>();
  @Output() imageBlob = new EventEmitter<string>();
  @Output() selectUploadChange = new EventEmitter<boolean>();
  public selectDocumentFilePath: string = null;

  imageChangedEvent: Object = '';
  croppedImage: string = '';
  loadingPreview: boolean = true;
  isImageHeic: boolean = false

  constructor() {
  }

  ngOnInit(): void {}

  public onFileFromOwnBtnClick() {
    let fileInput: HTMLElement = this.fileUploadInput.nativeElement;
    fileInput.click();
  }

  public async fileChangedHandler(event) {
    if (!event.target.files[0]) {
      return;
    }
    if (event.target.files[0].size > MAX_FILE_BYTES) {
      this.selectedImageVisibleChange.emit(false);
      this.callParentCallback({
        title: 'Maximum File Size Exceeded',
        message: 'File size must be smaller than ' + MAX_FILE_STRING
      });
      return;
    }
    if (this.hasDocument()) {
      this.loadingPreview = false;
      this.selectUpload = true;

      this.selectedFile = event.target.files[0];
      this.selectDocumentFilePath = event.target.files[0].name;
      this.selectedFileChange.emit(this.selectedFile);
      this.selectUploadChange.emit(true);
      this.successCallbackEvent.emit(true);
    }
    else if (this.isHeicFormat(event.target.files[0])) {
      this.loadingPreview = false;
      this.isImageHeic = true;

      this.selectedImageVisible = true;
      this.selectedFile = event.target.files[0];
      this.selectedFileChange.emit(this.selectedFile);

      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.selectedImageVisibleChange.emit(true);
        this.searchResultVisibleChange.emit(false);
      };

      reader.readAsDataURL(this.selectedFile);
      this.successCallbackEvent.emit(true);
    }
    else {
      this.approvedImage = false;
      this.approvedImageChange.emit(this.approvedImage)

      this.imageChangedEvent = event;
      this.imageCropper = true;
    }
  }

  public callParentCallback(callback: FileUploadDataType) {
    this.newCallBackEvent.emit(callback);
  }

  /**
   * hasDocument
   * @return boolean
   *
   * Returns a boolean that determines if the provided filetypes include a document
   */

  public hasDocument(): boolean {
    return this.fileTypes.includes('pdf');
  }

  public isHeicFormat(file): boolean {
    const regex: RegExp = /\.heic$/i;
    return regex.test(file.name)
  }

  public removeImage() {
    this.imageChangedEvent = null;
    this.croppedImage = null;

    this.imageCropper = false;
    this.approvedImage = true;
    this.approvedImageChange.emit(this.approvedImage);

    this.selectUpload = false;
    this.selectUploadChange.emit(this.selectUpload);
    this.selectedImageVisible = false;
    this.selectedImageVisibleChange.emit(this.selectedImageVisible);

    this.imageBlob.emit(null);
    if (this.filePreview) {
      this.filePreview.nativeElement.setAttribute('src', '');
    }

    this.selectedFile = null;
    this.selectDocumentFilePath = null;
    this.selectedFileChange.emit(this.selectedFile);
    this.successCallbackEvent.emit(false);
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  async approveImage() {
    this.imageCropper = false;
    this.selectedImageVisible = true;
    await this.reader();
  }

  async reader() {
    this.approvedImage = true;
    this.approvedImageChange.emit(this.approvedImage);

    this.selectedImageVisibleChange.emit(true);
    this.searchResultVisibleChange.emit(false);

    // TODO: We should use view children instead of viewChild for the filePreview
    setTimeout(()=>{
    this.loadingPreview = false;
      this.filePreview.nativeElement.setAttribute('src', this.croppedImage);
    },200)

    this.imageBlob.emit(this.croppedImage);
    this.successCallbackEvent.emit(true);
  }
}

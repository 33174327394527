<ion-header translucent="true">
  <ion-toolbar>
    <ion-title>Tutorial</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismissModal()" color="dark">Close</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>

<ion-grid>
    <ion-row>
      <ion-col size="12">
        <ion-text class="txt3 fs-15 help-text">
            <div class="tutorial-swiper-container">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" *ngFor="let step of this.howStepsSlides let i = index">
                        <ion-card class="how-slide-card " >
                            <ng-container *ngIf="step.image">
                                <img alt="How to tutorial explanation" [src]="step.image" class="top-image" />
                            </ng-container>
                            <ion-card-content class="pd-0 mg-0">
                                <div class="pd-20">
                                    <ng-container *ngIf="step.header">
                                        <h1>{{step.header}}</h1>
                                    </ng-container>
                                    <ng-container *ngIf="step.text">
                                        <p>{{step.text}}</p>
                                    </ng-container>
                                </div>
                            </ion-card-content>
                        </ion-card>
                    </div>
                </div>
                <div class="swiper-pagination"></div>
                <ion-button *ngIf="tutorialSwiper.activeIndex > 0" color="dark" class="btn-large slide-arrow ion-float-left" (click)="previousSlide()">
                    <ion-icon name="chevron-back-circle" ></ion-icon>
                </ion-button>
                <ion-button *ngIf="tutorialSwiper.activeIndex < (howStepsSlides?.length - 1)" color="dark" class="btn-large slide-arrow ion-float-right" (click)="nextSlide()">
                    <ion-icon name="chevron-forward-circle" ></ion-icon>
                </ion-button>
            </div>
        </ion-text>
      </ion-col>
    </ion-row>
</ion-grid>
</ion-content>

import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import { Location } from '@angular/common';
import {
  Platform,
  MenuController,
  ModalController,
  PopoverController,
  AlertController,
  ToastController
} from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Storage } from '@ionic/storage';
import { TranslateService } from '@ngx-translate/core';
import { NavigationEnd, Router} from '@angular/router';
import { AuthService } from '../providers/auth/auth.service';
import { SettingsService } from '../providers/settings/settings.service';
import { OnboardingServices } from '../providers/onboarding.services';
import {UserResource} from "./api/resources/user.resource";
import { CauseGroupResource } from './api/resources/cause-group.resource';
import { CauseGroupService } from './api/services/cause-group.service';
import { EncourageService } from '../providers/encourage.service';
import {EncouragePage} from "./pages/encourage/encourage.page";
import { ToggleRolesComponent } from './popovers/toogle-roles.component';
import { HttpClient } from '@angular/common/http';
import { ResourceSerializer } from './api/serializers/resource-serializer';
import { Meta, Title } from '@angular/platform-browser';
import { AddHeadLinkService } from './shared/services/add-head-link.service';
import { HowTutorialComponent } from './pages/how-tutorial/how-tutorial.component';
import { MODAL_MAPPER } from "../const";
import {UserService} from "./api/services/user.service";
import {StateManagementService} from "./api/services/state-management-service.service";
import {lastValueFrom, Observable, Subscription} from "rxjs";
import {FamilyResource} from "./api/resources/family.resource";
import {register} from 'swiper/element/bundle';
import {ClipboardService} from "ngx-clipboard";

register();

declare const gtag: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  rootPage: any = ''; // keeping this blank the ngOnInit has to complete loading before redirecting removing the login flash

  pages: any;

  public footerIsHidden: boolean;

  clientID: string;

  validTokenSubscription: any;

  onboardingProcess: any;

  isLoggedIn: boolean;

  locationData: any; // will be used in offering localized recommendations

  // Selected Side Menu
  selectedMenu: any;

  currentIndex: number;

  helpLink: any = '/dashboard/list-commitments/';

  userCauseGroup: CauseGroupResource = null;
  public causeGroupService: CauseGroupService;
  urlToShare: string = "";

  userRoles;

  currentPopover = null;

  currentUser: UserResource = null;

  selectedUser: UserResource = null;

  loggedUser: UserResource = null;

  userIsFamilyOrAdvocate: boolean = false;

  userType;

  families: FamilyResource[];

  aftertoggle: boolean = false;

  private userService: UserService;

  private causeGroupInstance: CauseGroupService;

  private modalMapper: object = MODAL_MAPPER;

  private canUpdateAppName: boolean = true;
  private hasAnalytics: boolean = false;

  private updateUserInfo$: Subscription;
  private isCauseGroupAdmin: boolean;
  private causeGroupResourceObservable$: Observable<CauseGroupResource> = null;


  constructor(
    public platform: Platform,
    public statusBar: StatusBar,
    public splashScreen: SplashScreen,
    private auth: AuthService,
    private settings: SettingsService,
    private menu: MenuController,
    private modalController: ModalController,
    private os: OnboardingServices,
    private storage: Storage,
    private translate: TranslateService,
    public router: Router,
    private location: Location,
    public encourage: EncourageService,
    public popoverController: PopoverController,
    public alertController: AlertController,
    private httpClient: HttpClient,
    private titleService: Title,
    private metaService: Meta,
    private linkService: AddHeadLinkService,
    private appState: StateManagementService,
    private renderer: Renderer2,
    private toast: ToastController,
    private clipboardService: ClipboardService
  ) {

    this.userService =  new UserService(this.httpClient);
    this.causeGroupInstance = new CauseGroupService(this.httpClient);

    // Subscribers for specific events on the app
    this.appState.getState('isHidden').subscribe( isHidden => {
      this.footerIsHidden = isHidden || this.router.url == 'post-question';
    });

    this.appState.getState('menu').subscribe(menu => {
        //
        // Statements Menu Links
        //
        let statementObject : object = {
          title: '',
          url: '',
          external: false,
          type: ''
        };


        if (this.userCauseGroup.background_check_content !== null && this.userCauseGroup.background_check_content !== '') {
          const index = menu.findIndex(object => object.title === 'Legal');
          let backgroundCheckPolicy = Object.create(statementObject);
          backgroundCheckPolicy ['title'] = 'Background Check Policy';
          backgroundCheckPolicy['type'] = 'backgroundCheckPolicy';
          backgroundCheckPolicy['content'] = this.userCauseGroup.background_check_content;
          menu[index].children.push(backgroundCheckPolicy);
        }

        // Add Statement of Faith to Menu if admin or church  (
        if (
          this.currentUser.hasAnyRole(
            [
              'organization-admin',
              'church',
              'admin',
              'super-admin',
              'cause-group-admin',
            ]
          )
        ) {
          const index = menu.findIndex(object => object.title === 'Legal');
          let faithStatement = Object.create(statementObject);
          faithStatement['title'] = 'Statement Of Faith';
          faithStatement['type'] = 'faith';
          if (this.userCauseGroup.statement_faith_link !== null && this.userCauseGroup.statement_faith_link !== '') {
            faithStatement['external'] = true;
            faithStatement['url'] = this.userCauseGroup.statement_faith_link;
          }
          menu[index].children.push(faithStatement);
        }

        // Add Statement of Intent to Menu if business or non-profit or admin role
        if (
          this.currentUser.hasAnyRole(
            [
              'organization-admin',
              'businesses',
              'service-provider-non-profit',
              'admin',
              'super-admin',
              'cause-group-admin',
            ]
          )
        ) {
          const index = menu.findIndex(object => object.title === 'Legal');
          let intentStatement = Object.create(statementObject);
          intentStatement['title'] = 'Statement Of Intent';
          intentStatement['type'] = 'intent';
          if (this.userCauseGroup.statement_intent_link !== null && this.userCauseGroup.statement_intent_link !== '') {
            intentStatement['external'] = true;
            intentStatement['url'] = this.userCauseGroup.statement_intent_link;
          }
          menu[index].children.push(intentStatement);
        }
        this.pages = menu;

    });

    let that = this;

    this.updateUserInfo$ = this.appState.getState('user').subscribe(async (user) => {
      this.currentUser = await user;
      this.userCauseGroup = user.causeGroups[0];
      user.roles.forEach(function(role) {

        that.helpLink =
          role.user_type == 'give_help'
            ? '/dashboard/list-commitments'
            : '/dashboard/list-need';
        that.userType = role.user_type == 'give_help' ? 'giveHelp' : 'getHelp';
      });

      await this.loadUserInfo(user);
      this.isCauseGroupAdmin = !!this.currentUser.roles.some(role => role.name === 'cause-group-admin');

      const cgData = await this.getCauseGroupInformation(this.userCauseGroup.slug);
      this.hasAnalytics = cgData.analytics  ? this.loadAnalytics(cgData.analytics) : false;

      if (this.hasAnalytics && this.isCauseGroupAdmin == false) {
        this.router.events.subscribe((event) => {
          if (event instanceof NavigationEnd) {
            gtag('config', cgData.analytics, {'page_path': event.urlAfterRedirects});
          }
        })
      }
    });

    this.appState.getState('causeGroup').subscribe(async (causeGroup) => {
      this.userCauseGroup = causeGroup;
      this.storage.ready().then(() => {
        this.storage.get('loginRes').then(res => {
          res.user.causeGroups[0] = causeGroup;
          this.storage.set('loginRes', res);
        });
      });
    });
    this.appState.getState('encouragementUpdate').subscribe(async ({step, user}) => {
      await this.storage.ready();
      const res = await this.storage.get('loginRes');
      res.user.next_step = user.next_step;
      // update first church as well if we have new info
      if (user.first_church && user.first_church.length > 0) {
        res.user.first_church = user.first_church;
      }
      // update administrator organizations as well if we have new info
      if (user.administrator_organizations && user.administrator_organizations.length > 0) {
        res.user.administrator_organizations = user.administrator_organizations;
      }
      let value = res.user.next_step ? res.user.next_step : {};
      value[step] = true;


      await this.storage.set('loginRes', res);
      this.encourage.updateData(res);
    });

    this.appState.getState('causeGroupName').subscribe(async (causeGroupName) => {
      await this.updateNameAndIcons(causeGroupName);
      this.canUpdateAppName = false;

    });

    this.appState.getState('userFamilies').subscribe(async (families) => {
      this.families = families;
    });

  }

  private async getCauseGroupInformation(causeGroupSlug: string) {
    this.causeGroupResourceObservable$ = this.causeGroupResourceObservable$ ?? this.causeGroupInstance.getBySlug(causeGroupSlug);
    return await lastValueFrom(this.causeGroupResourceObservable$);
  }

  async ngOnInit() {
    this.isLoggedIn = false;
    this.onboardingProcess = [];
    // NOTE:: should move this to login page connected to the register button..... shoot....
    this.clientID = '1'; // not setting 0 or 1
    // IF ENTERING FROM A CAUSE GROUP THEY SHOULD ALWAYS HAVE A CLIENT OR CAUSE GROUP ID
    if (typeof location.search.split('clientID=')[1] !== 'undefined') {
      this.clientID = location.search.split('clientID=')[1];
      this.storage.set('clientID', this.clientID).then(() => {
        this.readyStorage();
      });
    } else {
      // when live should probably pull from meta tag
      this.storage.ready().then(() => {
        this.storage.set('clientID', this.clientID).then(() => {
          this.readyStorage();
        });
      });
    }
  }

  ngOnDestroy() {
    this.updateUserInfo$.unsubscribe();
  }

  private async loadUserInfo(user: UserResource) {
    this.currentUser = user;
    this.selectedUser = user;

    if (
      this.currentUser.hasAnyRole([
        'advocate',
        'church',
        'organization-admin',
        'service-provider-non-profit',
        'businesses'
      ])
    ) {
      this.loggedUser = user;
      await this.storage.set('loggedUser', user);
      await this.storage.set('selectedUser', user);
      this.families = await this.getUserFamilies(this.loggedUser);
      this.appState.update({userFamilies: this.families});
    }
  }


  public async updateNameAndIcons(causeGroupName: string = null) {
    const causeGroupSlug = causeGroupName || await this.auth.getCauseGroup();
    const cgData = await this.getCauseGroupInformation(causeGroupSlug);

    this.titleService.setTitle(cgData.name);
    if (typeof cgData.app_icon_name !== 'object'
      && cgData.app_icon_name !== '') {
      this.metaService.addTag({
        name: 'apple-mobile-web-app-title',
        content: cgData.app_icon_name
      });
    }
    if (typeof cgData.app_icon_image !== 'object'
      && cgData.app_icon_image !== '') {
      this.linkService.addLink('apple-touch-icon', cgData.app_icon_image);
      this.linkService.addLink(
        'apple-touch-startup-image',
        cgData.app_icon_image
      );
    }
  }

  loadAnalytics(analyticsTag): boolean  {
    // don't track on local OR v2-dev
    const host = window.location.hostname;
    const devEvn = host.includes('v2-dev');

    if(host != "localhost" && !devEvn) {
      if (!document.getElementById('analyticsTag')) {
        const script = this.renderer.createElement('script');
        const analyticsURL = 'https://www.googletagmanager.com/gtag/js?id=' + analyticsTag;
        this.renderer.setAttribute(script, 'id', 'analyticsTag');
        this.renderer.setAttribute(script, 'src', analyticsURL);
        this.renderer.appendChild(document.head, script);
      }
      return true;
    }
    return false;
  }

  async readyStorage() {
    await this.storage.ready();
    this.storage.get('onboardingProcess').then(val => {
      if (this.isJsonString(val)) {
        this.onboardingProcess = JSON.parse(val);
      } else {
        this.onboardingProcess = val;
      }
    });

    this.auth.isAuthenticated().then(isAuthenticated => {

      if (isAuthenticated) {
        this.storage.get('loginRes').then(res => {
          this.storage.get('loggedUser').then(async (loggedUser) => {

              let user: UserResource;
              if  (loggedUser) {
                user = loggedUser;
                this.loggedUser = new ResourceSerializer(
                  UserResource
                ).fromJson(loggedUser) as UserResource;
                const families = await this.getUserFamilies(this.loggedUser);
                // this will update this.families on the subscription
                this.appState.update({userFamilies: families});
              } else {
                user = res.user;
              }
            this.storage.get('selectedUser').then(selectedUser => {
              if (selectedUser) {
                this.selectedUser = new ResourceSerializer(
                  UserResource
                ).fromJson(selectedUser) as UserResource;
                this.currentUser = new ResourceSerializer(
                  UserResource
                ).fromJson(selectedUser) as UserResource;
              } else {
                this.currentUser = new ResourceSerializer(
                  UserResource
                ).fromJson(user) as UserResource;
              }

              this.userCauseGroup = user.causeGroups[0];
              let that = this;
              this.userRoles = user.roles
                .map(role => role.title || role.name)
                .join(', ')
                .toLowerCase();

              if (
                this.userRoles.indexOf('family') >= 0 ||
                this.userRoles.indexOf('advocate') >= 0
              ) {
                this.userIsFamilyOrAdvocate = true;
              }
              user.roles.forEach(function(role) {
                that.helpLink =
                  role.user_type == 'give_help'
                    ? '/dashboard/list-commitments'
                    : '/dashboard/list-need';
                that.userType =
                  role.user_type == 'give_help' ? 'giveHelp' : 'getHelp';
              });
              this.redirectTo(isAuthenticated);
            });
          });
        });
      }
    });
    if (this.canUpdateAppName) {
      await this.updateNameAndIcons();
    }
  }

  async getUserFamilies(loggedUser): Promise<FamilyResource[]> {

    if (loggedUser && this.loggedUser.hasRole('advocate')) {
      const response = await this.userService
        .getFamiliesByUser(this.loggedUser.id)
        .toPromise();
      return response.data;
    }

  }
  redirectTo(isAuthenticated) {
    if (isAuthenticated) {
      this.settings
        .loadRoutes(this.clientID)
        .then(pagesVal => {
          this.pages = pagesVal;
          try {
            this.rootPage = '/impact';
          } catch (e) {
            this.rootPage = '/impact';
          }
        })
        .then(() => {
          this.initializeApp();
        });
    } else {
      this.settings
        .loadRoutes(this.clientID)
        .then(pagesVal => {
          this.pages = pagesVal;
          try {

            this.rootPage = '/impact';

            this.storage.ready().then(() => {
              this.storage.get('onboardingProcess').then(storageObVal => {
                if (
                  storageObVal !== null &&
                  typeof storageObVal.onboardingSteps !== 'undefined' &&
                  typeof storageObVal.onboardingSteps.registration !==
                    'undefined' &&
                  storageObVal.onboardingSteps.registration != null
                ) {
                  this.callback(storageObVal);
                } else {
                  this.os.onBoardingProcess(this.clientID).then(
                    obVal => {
                      if (parseInt(obVal.status, 10) === 200) {
                        if (this.isJsonString(obVal.onboarding)) {
                          obVal.onboarding = JSON.parse(obVal.onboarding);
                        }
                        this.callback(obVal.onboarding);
                      } else {
                        this.router.navigateByUrl('/gen-error');
                      }
                    }
                  );
                }
              });
            });
          } catch (e) {
            this.rootPage = '/impact';
          }
        })
        .then(() => {
          this.initializeApp();
        });
    }
  }

  callback(val) {
    if (
      parseInt(val.currentPosition, 10) === 0 ||
      typeof val.currentPosition === 'undefined'
    ) {
      this.rootPage = 'login';
    } else {
      // need to account for position number outside the range of the array
      if (
        parseInt(val.currentPosition, 10) <
        val.onboardingSteps.registration.steps.length
      ) {
        this.currentIndex = parseInt(val.currentPosition, 10);
      } else {
        this.currentIndex = 0;
      }
      this.rootPage =
        val.onboardingSteps.registration.steps[this.currentIndex].path;
    }
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.translate.setDefaultLang('en');
    });
  }

  initTranslate() {
    // Set the default language for translation strings, and the current language.
    this.translate.setDefaultLang('en');
    if (this.translate.getBrowserLang() !== undefined) {
      this.translate.use(this.translate.getBrowserLang());
    } else {
      this.translate.use('en'); // Set your language here
    }
  }

  collapseAll(page, pages) {
    // collapses other menus
    this.pages.forEach((page, index) => {
      pages[index].open = false;
    });
    // uncollapses selected
    page.open = true;
  }

  // check which cause group
  // get cause group focus
  // widow for PM
  // inserting this in menus / pages

  // this.auth.user()->getCauseGroup()

  openPage(page, index) {
    // Reset the content nav to have just this page
    // we wo uldn't want the back button to show in this scenario
    if (page.component) {
      this.menu.close();
      // this.router.navigateByUrl(page.component);
    } else if (this.selectedMenu) {
      this.selectedMenu = 0;
    } else {
      this.selectedMenu = index;
    }
  }

  logout() {
    this.menu.close();
    this.auth
      .logout()
      .then(causeGroup => this.router.navigateByUrl(`/login/${causeGroup}`))
      .then(() => window.location.reload());
  }

  profile() {
    this.menu.close();
    this.router.navigateByUrl('/profile');
  }

  dashboard() {
    this.menu.close();
    this.router.navigateByUrl('/profile');
  }

  goBack() {
    this.location.back();
  }

  isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  async presentPopover(ev: any) {

    // pop up encouragement first time they click toggle menu
    if ( (this.selectedUser == this.loggedUser) && !this.loggedUser.next_step['afterToggle'] && !this.aftertoggle) {

        this.aftertoggle = true;
        this.getEncourage('after-toggle');
        this.encourage.updateNextStep('after-toggle', this.loggedUser.id);

    }
    this.currentPopover = await this.popoverController.create({
      component: ToggleRolesComponent,
      componentProps: {
        switchUser: (user: UserResource, orgId) =>
          this.handleUserSwitch.bind(this)(user, orgId),
        families: this.families,
        organizations: this.loggedUser.administrator_organizations,
        selectedUser: this.selectedUser || this.loggedUser,
        loggedUser: this.loggedUser,
        goToProfile: this.handleGoToProfile.bind(this)
      },
      cssClass: 'toggle-roles',
      event: ev,
      showBackdrop: false,
      side:"bottom",
      alignment:"center",
      reference:"trigger",
      trigger:"profile-menu"
    });

    await this.currentPopover.present();
  }

  handleGoToProfile() {
    if (this.currentPopover) {
      this.currentPopover.dismiss().then(() => {
        this.currentPopover = null;
        this.router.navigateByUrl('/profile');
      });
    }
  }

  handleUserSwitch(user: UserResource, orgId?: string) {
    this.storage.ready().then(() => {
      // disable switch for orgsuser temporarily
      // until we know how switch will affect functionality
      if (!orgId) {
        this.storage.set('selectedUser', user);
        this.storage.get('loginRes').then(res => {
          res.user = user;
          this.storage.set('loginRes', res);
        });
        this.selectedUser = user;
        this.currentUser = user;
      }
      if (this.currentPopover) {
        this.currentPopover.dismiss().then(() => {
          this.currentPopover = null;
          if (orgId) {
            this.router.navigateByUrl(`/dashboard/organization/${orgId}`, {replaceUrl:true});
          } else if (this.router.url === '/impact') {
            location.reload();
          } else {
            this.router.navigateByUrl('/impact').then(() => {
              window.location.reload();
            });
          }
        });
      }
    });
  }
  getEncourage(slug) {
    this.encourage.fetchEncourage(slug).subscribe(res => {
      res.data ? this.showModal(res) : null;
    });
  }
  async showModal(slug, type = null, content:string = null) {
    let modal: HTMLIonModalElement;
    let pageComponent;

    if (type === null) {
      pageComponent = EncouragePage;
    } else {
      pageComponent = type;
    }

    modal = await this.modalController.create({
      component: pageComponent,
      componentProps: {
        slugData: slug,
        contentData:content,
      },
    });
    return modal.present();
  }

  async callModal(type: string, url: string = null, external: boolean = false, content: string = null) {
    let pageComponent = null;
    let slug = null;
    this.modalMapper['intent'].name = this.userCauseGroup.name;

    // external urls
    if (external && url !== null && url !== '') {
      window.open(url, '_blank');
      return;
    }

    // angular route navigation
    if (url !== null && url !== '') {
      await this.router.navigateByUrl(url);
      return;
    }

    if (this.modalMapper.hasOwnProperty(type)) {
      pageComponent = this.modalMapper[type].page;
      slug = this.modalMapper[type].hasOwnProperty('name') ? this.modalMapper[type].name : null;
    }

    if (pageComponent === null) {
      return;
    }

    await this.showModal(slug, pageComponent, content);

  }

  async howTutorial() {
    const howTutorialModal = await this.modalController.create({
      component: HowTutorialComponent,
      componentProps: {

      },
    }).then((modal) => {
      modal.present();
      return modal;
    });
    await this.encourage.updateNextStep('how-to-navigate', this.currentUser.id);
    howTutorialModal.onDidDismiss().then();
  }

  async shareCopyLink() {
    this.urlToShare = `${window.location.origin}/welcome/${this.userCauseGroup.slug}`
    this.clipboardService.copy(this.urlToShare);
    await this.linkCopied();
  }

  async linkCopied() {
    const toast = await this.toast.create({
      header: 'Link copied to share Link2Hope.',
      message: 'You can paste the link in your device',
      buttons: [
        {
          text: 'Close',
          role: 'cancel',
          handler: () => {}
        }
      ],
      color: 'success',
    })

    await toast.present();
    setTimeout(() => {toast.dismiss()}, 3000);
  }
}
